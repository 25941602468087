import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';
import colors from '../../scss/variables/colors.module.scss';

const TableLoader = (props: any) => { 
  
  const { t } = useTranslation();

  return (
  <div style={{padding: props.padding || 15, overflow: 'hidden', backgroundColor: 'white', display: 'flex', height: props.height, alignItems: 'center', justifyContent: 'center'}}>
    <ContentLoader 
      speed={2}
      width={'100%'}
      height={props.height || 600}
      title={t('Documents.table.loader')}
      // viewBox={`0 0 1440 ${props.height || 600}`}
      backgroundColor={colors.verylightgrey}
      {...props}
    >
      <rect x='0' y='20' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='20' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='20' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='20' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='20' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='20' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='20' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='20' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='20' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='20' rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y='40' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='40' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='40' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='40' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='40' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='40' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='40' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='40' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='40' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='40' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='80' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='80' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='80' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='80' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='80' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='80' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='80' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='80' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='80' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='80' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='120' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='120' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='120' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='120' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='120' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='120' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='120' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='120' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='120' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='120' rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y='160' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='160' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='160' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='160' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='160' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='160' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='160' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='160' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='160' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='160' rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y='200' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='200' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='200' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='200' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='200' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='200' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='200' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='200' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='200' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='200' rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y='240' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='240' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='240' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='240' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='240' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='240' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='240' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='240' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='240' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='240' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='280' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='280' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='280' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='280' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='280' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='280' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='280' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='280' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='280' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='280' rx='0' ry='0' width='170' height='20' />
      
      <rect x='0' y='320' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='320' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='320' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='320' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='320' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='320' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='320' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='320' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='320' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='320' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='360' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='360' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='360' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='360' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='360' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='360' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='360' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='360' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='360' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='360' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='400' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='400' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='400' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='400' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='400' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='400' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='400' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='400' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='400' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='400' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='440' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='440' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='440' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='440' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='440' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='440' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='440' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='440' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='440' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='440' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='480' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='480' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='480' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='480' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='480' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='480' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='480' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='480' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='480' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='480' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='520' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='520' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='520' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='520' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='520' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='520' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='520' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='520' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='520' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='520' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='560' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='560' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='560' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='560' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='560' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='560' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='560' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='560' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='560' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='560' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='600' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='600' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='600' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='600' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='600' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='600' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='600' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='600' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='600' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='600' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='640' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='640' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='640' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='640' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='640' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='640' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='640' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='640' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='640' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='640' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='680' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='680' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='680' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='680' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='680' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='680' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='680' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='680' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='680' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='680' rx='0' ry='0' width='170' height='20' />

      <rect x='0' y='720' rx='0' ry='0' width='170' height='20' />
      <rect x='180' y='720' rx='0' ry='0' width='170' height='20' /> 
      <rect x='360' y='720' rx='0' ry='0' width='170' height='20' />
      <rect x='540' y='720' rx='0' ry='0' width='170' height='20' /> 
      <rect x='720' y='720' rx='0' ry='0' width='170' height='20' /> 
      <rect x='900' y='720' rx='0' ry='0' width='170' height='20' /> 
      <rect x='1080' y='720' rx='0' ry='0' width='170' height='20' />
      <rect x='1260' y='720' rx='0' ry='0' width='170' height='20' />
      <rect x='1440' y='720' rx='0' ry='0' width='170' height='20' />
      <rect x='1620' y='720' rx='0' ry='0' width='170' height='20' />

    </ContentLoader>
  </div>
)
}
export default TableLoader

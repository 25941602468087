import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import prepareDocuments from 'helpers/prepareDocuments';
import { IDocument, IFolder, DocumentsType } from 'interfaces';
import { ViewType } from 'interfaces/IDocument';
import React from 'react';
import { fetchAvailableDocuments } from 'store/reducers/documentsCreator';

type choosenDocument = {
  permissions: string[];
  key: React.Key;
}

const defaultViewType = localStorage.getItem('viewType') as ViewType;

interface DataState {
  documents: IDocument[];
  folders: IFolder[];
  isLoadingDocuments: boolean;
  errorDocuments: string;
  choosenDocuments: choosenDocument[];
  dataDocumentTable: (IDocument | IFolder)[];
  selectedDocument: IDocument | null;
  selectedFolder: IFolder | null;
  currentFolder: IFolder[];
  viewType: ViewType;
  entityOfContextMenu: IDocument | IFolder | null;
  documentsType: DocumentsType,
}

const initialState: DataState = {
  documents: [],
  folders: [],
  isLoadingDocuments: false,
  errorDocuments: '',
  choosenDocuments: [],
  dataDocumentTable: [],
  selectedDocument: null,
  selectedFolder: null,
  currentFolder: [],
  viewType: 'List', // defaultViewType || 'List',
  entityOfContextMenu: null,
  documentsType: 'files',
};

export const dataDocumentsSlice = createSlice({
  name: 'datadocuments',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableDocuments.fulfilled, (state, action: PayloadAction<{documents: IDocument[], dataForDocumentTable: (IDocument | IFolder)[], folders: IFolder[]}>) => {
      state.isLoadingDocuments = false;
      state.errorDocuments = '';
      state.documents = action.payload.documents;
      state.folders = action.payload.folders;
      state.dataDocumentTable = action.payload.dataForDocumentTable;
    })
    builder.addCase(fetchAvailableDocuments.pending, (state) => {
      state.isLoadingDocuments = true;
    })
    builder.addCase(fetchAvailableDocuments.rejected, (state, action: any) => {      
      if ( action?.error?.message !== 'Aborted' ) {
        state.isLoadingDocuments = false;
        state.errorDocuments = action.payload;
      }
    })
    
  },
  reducers: {
    setDocuments(state, action: PayloadAction<{documents: IDocument[], folders?: IFolder[]}>) {
      state.documents = action.payload.documents;      
      state.folders = action.payload.folders || [];      
      state.dataDocumentTable = prepareDocuments(action.payload.documents, action.payload.folders || []);
    },
    setChoosenDocuments(state, action: PayloadAction<choosenDocument[]>) {
      state.choosenDocuments = action.payload;
    },

    setIsLoadingDocuments(state, action: PayloadAction<boolean>) {
      state.isLoadingDocuments = action.payload
    },

    setSelectedDocument(state, action: PayloadAction<IDocument | null>) {
      state.selectedDocument = action.payload;
    },

    setSelectedFolder(state, action: PayloadAction<IFolder | IDocument | null>) {
      state.selectedFolder = action.payload;
    },
    setDataDocumentTable(state, action: PayloadAction<(IFolder | IDocument)[]>){
      state.dataDocumentTable = action.payload;
    },

    setCurrentFolder(state, action: PayloadAction<IFolder[]>){
      state.currentFolder = action.payload;
    },
    setViewType(state, action: PayloadAction<ViewType>){
      state.viewType = action.payload;
    },
    setEntityOfContextMenu(state, action: PayloadAction<IDocument | IFolder | null>){
      state.entityOfContextMenu = action.payload;
    },
    setDocumentsType(state, action: PayloadAction<DocumentsType>) {
      state.documentsType = action.payload;
    }
  },
});

const documentReducer = dataDocumentsSlice.reducer

export const {
  setDocuments,
  setChoosenDocuments,
  setIsLoadingDocuments,
  setSelectedDocument,
  setSelectedFolder,
  setDataDocumentTable,
  setCurrentFolder,
  setViewType,
  setEntityOfContextMenu,
  setDocumentsType
} = dataDocumentsSlice.actions;

export default documentReducer;

import React from 'react';
import { Button, Table, message } from 'antd';
import TableLoader from 'components/Sceletons/TableLoader';
import { getSubscriptionsColumn } from './columns/subscriptionsColumns';
import organizations from 'api/organizations';

import classes from './OrganizationSchedule.module.scss';
import { DownloadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { getFile } from 'helpers/downloadSomeFile';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { useAppSelector } from 'store/hook';

const Subscriptions = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [isDownloadLoading, setIsDownloadLoading] = React.useState(false);
  const { t, i18n } = useTranslation();
  const columns = React.useMemo(() => getSubscriptionsColumn(t), [i18n.language]);  
  const { userData } = useAppSelector((state) => state.userData);

  React.useEffect(() => {
    getSubscriptions();
  }, []);

  const getSubscriptions = async () => {
    setIsLoading(true);
    try {
      const response = await organizations.getOrganizationSubscriptions();
      setDataTable(
        response.data.map((sub: any) => ({
          ...sub,
          created_at: getDateWithTimeZone(sub.created_at, userData?.date_format!),
        }))
      );
    } catch (e: any) {
      console.log(e);
      message.error(t("Organizations.error.failedGetSubs"));
    } finally {
      setIsLoading(false);
    }
  };

  const onDownload = async () => {
    try {
      setIsDownloadLoading(true);
      const response = await organizations.downloadOrganizationSubscriptions();
      getFile(response.data, `Report`);
    } catch (err) {
      message.error(t('Logs.managament.reportError'));
    } finally {
      setIsDownloadLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className={classes.loaderWrap}>
        <TableLoader 
          height={'calc(100vh - 280px)'}
        />
      </div>
    );
  }

  return (
    <div className={classes.scheduleWrap}>
      <Button onClick={onDownload} icon={<DownloadOutlined />} loading={isDownloadLoading} className={classes.export}>
        {t('Organizations.unloadButton')}
      </Button>

      <Table
        dataSource={dataTable}
        columns={columns}
        pagination={false}
        size='middle'
        className={classes.scheduleTable}
        scroll={{
          y: 'calc(100vh - 355px)',
        }}
      />
    </div>
  );
};

export default Subscriptions;

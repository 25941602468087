const blobErrorToObject = async (file: Blob) => {
  const reader = new FileReader();
  reader.readAsText(file);
  const result = await new Promise((resolve, reject) => {
    reader.onload = function(event) {
      resolve(reader.result)
    }
  })
  return JSON.parse(result as string)
};

export default blobErrorToObject;

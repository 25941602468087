import React from 'react'
import { Modal, Upload, Button, UploadProps } from 'antd'
import classes from './UploadModal.module.scss';
import { FileAddOutlined, FolderAddOutlined, FundViewOutlined, InboxOutlined, DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import Dragger from 'antd/es/upload/Dragger';
import { useTranslation } from 'react-i18next';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { IWithWebkitPath } from 'interfaces';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { setDocumentsType } from 'store/slices/dataDocumentsSlice';

type UploadModalProps = {
  open: boolean
  onCancel: () => void;
  entityName?: string;
  uploadProps: UploadProps<any>;
  fileList: IWithWebkitPath[];
  folderUploadProps: UploadProps<any>;
  handleUpload: () => void;
  setFileList: React.Dispatch<React.SetStateAction<IWithWebkitPath[]>>;
  title?: string;
  isAddedViaDragger?: boolean;
  setIsAddedViaDragger?: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function UploadModal({
  open,
  onCancel,
  entityName,
  uploadProps,
  fileList,
  handleUpload,
  setFileList,
  folderUploadProps,
  title,
  isAddedViaDragger,
  setIsAddedViaDragger,
}: UploadModalProps) {
  const unicNamesOfItems: string[] = [];
    
  const { isUploadingDocuments, displayedDragger } = useAppSelector((store) => store.windowState);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isNeedToChooseUploadMethod = isAddedViaDragger && dataRoom?.agreement_enable;

  const itemsForRender = fileList.map(elem => elem.webkitRelativePath 
    ? elem.webkitRelativePath.split('/').length === 1
      ? { name: elem.webkitRelativePath.split('/')[0], type: 'file' }
      : { name: elem.webkitRelativePath.split('/')[0], type: 'folder' }
    : { name: elem.name, type: 'file' }
  );
  
  const onDeleteItem = (item: string) => {
    const newFileList = fileList.filter(file => {
      const type =  file?.webkitRelativePath ? 'webkitRelativePath' : 'name';
      if (type === 'name') {
        return file.name !== item;
      } else if (type === 'webkitRelativePath') {
        return file?.webkitRelativePath!.split('/')[0] !== item
      } else {
        return true;
      }
    })
    setFileList(newFileList);
  };  

  const onStopPropagationDragger = (event: any) => event.stopPropagation();

  const IsSafari = () => {    
    const is_safari = navigator.vendor === 'Apple Computer, Inc.';
    return is_safari
      ? t('Documents.modals.upload.onlyFolders')
      : '';
  };

  const uploadSimple = () => {
    setIsAddedViaDragger && setIsAddedViaDragger(false);
    dispatch(setDocumentsType('files'));
  };

  const uploadConfident = () => {
    setIsAddedViaDragger && setIsAddedViaDragger(false)
    dispatch(setDocumentsType('confidentialFiles'));
  };
  
  return (
    <Modal 
      styles={{ body: {maxHeight: '85vh'} }}
      width={isNeedToChooseUploadMethod ? '60%' : '90%'}
      title={`${title || t('Documents.modals.upload.title')}: ${entityName}`}
      open={open}
      centered={true}
      onCancel={onCancel}
      footer={false}
      className={classes.modalUpload}

    >
      {
        isNeedToChooseUploadMethod
          ? (
            <div className={classes.uploadMethod}>
              <div className={classes.titleMethod}>{t('Documents.modals.upload.chooseUploadMethod')}</div>
              <div className={classes.buttonsMethod}>
                <Button
                  onClick={uploadSimple}
                  type='primary'
                  // className={classes.button}
                  icon={<UploadOutlined />}
                >
                  {t('Documents.modals.upload.uploadSimple')}
                </Button>

                <Button
                  onClick={uploadConfident}
                  type='primary'
                  // className={classes.button}
                  icon={<UploadOutlined />}
                >
                  {t('Documents.modals.upload.uploadConfident')}
                </Button>
              </div>
            </div>
          )
          : (
            <div className={classes.uploadWrap}>
              <div className={classes.menu}>
                <div className={classes.upload}>
                  {folderUploadProps.directory && (
                    <Upload
                      {...folderUploadProps}
                    >
                      <div className={classes.uploadButton}>
                        <FolderAddOutlined className={classes.uploadIcon} />
                        <span className={classes.uploadButtonText}>
                          {t('Documents.modals.upload.folder')}
                          <br/>
                          {IsSafari()}
                        </span>
                      </div>
                    </Upload>
                  )}
                  <Upload
                    showUploadList={false}
                    {...uploadProps}
                  >
                    <div className={classes.uploadButton}>
                      <FileAddOutlined className={classes.uploadIcon}/>
                      <span className={classes.uploadButtonText}>{t('Documents.modals.upload.select')}</span>
                    </div>
                  </Upload>
      
                
                </div>
      
                <div className={classes.uploadFooter}>
                  <Button
                    disabled={fileList?.length === 0}
                    type='primary'
                    onClick={handleUpload}
                  >
                    {t('Documents.management.upload')}
                  </Button>
                </div>
              </div>
              
              <div className={classes.uploadDraggerArea}>
                { !displayedDragger &&
                  <Dragger
                    style={{ backgroundColor: 'transparent' }}
                    // openFileDialogOnClick={false}
                    className={classes.dragger}
                    showUploadList={false}
                    accept='.doc, .docx, .xml, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf, image/*, video/*, audio/*, .txt'
                    {...uploadProps}
                    // accept='.doc,.xls,.xlsx,.docx,.pdf,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  >
                    <div className={classes.uploadFiles}>
                      {itemsForRender?.length === 0 ? (
                        <div className={classes.uploadScreen}>
                          <p className='ant-upload-drag-icon'>
                            <InboxOutlined />
                          </p>
                          <p className='ant-upload-text'>{t('Documents.modals.upload.dropText')}</p>
                        </div>
                      ) : (
                        itemsForRender.map((item, i) => {
                          const iconType = item.type === 'folder' ? 'folder' : item.name?.split('.').reverse()[0];
                          const icon = availableIconFormat.includes(iconType!) ? iconType : 'default';
      
                          if (unicNamesOfItems.includes(item.name)) {
                            return null;
                          } else {
                            unicNamesOfItems.push(item.name);
                            let countOfFilesInFolder;
                            if (item.type === 'folder') {
                              countOfFilesInFolder = fileList.reduce((acc, currnet) => {
                                if (currnet.webkitRelativePath?.split('/')[0] === item.name) {
                                  acc = acc + 1;
                                }
                                return acc;
                              }, 0);
                            }
      
                            return (
                              <div key={item.name + i} className={classes.oneItem} onClick={onStopPropagationDragger}>
                                <div className={classes.oneItemDiv}>
                                  <div className={classes.oneItemIcon}>
                                    {documnentIcons[icon as keyof typeof documnentIcons]}
                                  </div>
                                  <div className={classes.oneItemText}>
                                    {iconType === 'folder' ? `${item.name} (${countOfFilesInFolder})` : item.name}
                                  </div>
                                </div>
                                <div className={classes.oneItemDelete} onClick={() => onDeleteItem(item.name)}>
                                  {' '}
                                  <DeleteOutlined />{' '}
                                </div>
                              </div>
                            );
                          }
                        })
                      )}
                    </div>
                  </Dragger>
                }
              </div>
            </div>
          )
      }

      {isUploadingDocuments && 
        <UploadingComponent />
      }
    </Modal>
  );
}

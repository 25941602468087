import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './RoomsTab.module.scss';
import colors from '../../../scss/variables/colors.module.scss';
import RowLoader from 'components/Sceletons/RowLoader';

type Props = {
  setIsVisibleModal: Dispatch<SetStateAction<boolean>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  searchValue: string;
  isLoadingUserData: boolean;
};

export default function RoomsTabControl({ setIsVisibleModal, searchValue, setSearchValue, isLoadingUserData }: Props) {
  const { t } = useTranslation();
  const openNewRoomCreator = () => {
    setIsVisibleModal(true);
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  return (
    <div className={classes.management}>
      {isLoadingUserData ? (
        <RowLoader width={600} padding={'0 0 0 0'} height={32} borderradius={5}/>
      ) : (
        <>
          <Input
            prefix={<SearchOutlined className={classes.management_searchIcon} />}
            type='search'
            allowClear
            placeholder={t('Rooms.searchHolder')}
            value={searchValue}
            onChange={onSearch}
        />  
          <Button
            type='text'
            icon={<PlusOutlined style={{ color: colors.primary100, fontSize: 22 }}/>}
            onClick={openNewRoomCreator}
            className={classes.management_plus}
          />
        </>
      )}
    </div>
  );
}

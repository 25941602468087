import { ILogs } from 'interfaces';
import type { LatLngTuple } from 'leaflet';
import { useEffect } from 'react';

import { Marker, Popup, TileLayer, useMap } from 'react-leaflet';

type Props = {
  zoom: number;
  initialPosition: LatLngTuple;
  choosenLog: ILogs;
};

const LocationTile = ({ zoom, initialPosition, choosenLog }: Props) => {
  const map = useMap();

  useEffect(() => {
    map.flyTo(initialPosition, zoom);
  }, [zoom, initialPosition]);

  useEffect(() => {
    document.querySelector('.leaflet-bottom.leaflet-right')?.remove();
  }, []);

  return (
    <>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <Marker position={initialPosition}>
          <Popup>
            {choosenLog.details?.city} {choosenLog.details?.country}
          </Popup>
      </Marker>
    </>
  );
};

export default LocationTile;

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { IDocument } from 'interfaces';
import dayjs from 'dayjs';
import axios from 'axios';

export const fetchRoom = createAsyncThunk('dataRoom/fetchAll', async (props: { id: string }, thunkAPI) => {
  try {
    const rooms = await api.getRoom(props.id);
    const offset = new Date().getTimezoneOffset() * 60 * 1000;
    const inSeconds = dayjs(rooms.data.user_status_change_at).valueOf();
    const rightTime = rooms.data.user_status_change_at ? dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm') : '-';

    return {
      ...rooms.data,
      description: rooms.data.description ? rooms.data.description : '',
      user_status_change_at: rightTime,
    };
  } catch (e) {
    return thunkAPI.rejectWithValue('Download failed');
  }
});

export const fetchConfidentialDocuments = createAsyncThunk(
  'confidentialDocuments/fetchAll',
  async (props: { room_id: string }, thunkAPI) => {
    try {
      const documents = await api.getConfidentialDocuments(props.room_id);
      const offset = new Date().getTimezoneOffset() * 60 * 1000;

      const preparedDocuments = documents.data.map((document: IDocument) => {
        const inSeconds = dayjs(document.upload_at).valueOf();
        const rightTime = document.upload_at ? dayjs(inSeconds - offset).format('DD.MM.YYYY, HH:mm:ss') : '-';
        return {
          ...document,
          // clicked: false,
          // permissions: document.permissions ? document.permissions?.join(', ') : '',
          upload_at: rightTime,
          key: document.token,
        };
      });
      return preparedDocuments;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

export const fetchRoomNotifications = createAsyncThunk('notifications/fetchAll', async (payload: {locale: string}, thunkAPI) => {
  try {
    const source = axios.CancelToken.source();
    thunkAPI.signal.addEventListener('abort', () => source.cancel());
    const response = await api.getNotifications(source.token, payload.locale);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue(err);
  }
});

import { TFunction } from 'i18next';

export const getNDADocColumns = (t: TFunction) => [
  {
    title: t('Confirm.NDADoc.name'),
    dataIndex: 'name',
    key: 'name',
    width: '50%',
  },
  {
    title: t('Confirm.NDADoc.upload_at'),
    dataIndex: 'upload_at',
    key: 'upload_at',
    width: '50%',
  },
];

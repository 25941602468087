import { TFunction } from 'i18next';
import { RuleType } from 'rc-field-form/lib/interface';

// types 'date'
export const validateField = (fieldName: string, t: TFunction):{
  max?: number;
  message?:string;
  pattern?: RegExp;
  type?: RuleType;
} => {

const fieldsRules = [
  {
    fields: ['country', 'city'],
    rule: {
      pattern: new RegExp(/^[-a-zA-Zа-яА-ЯёЁ ]+$/i),
      message: t('Organizations.validate.onlyLetters'),
    }
  },{
    fields: ['biс', 'net_profit', 'postal_code', 'inn', 'sic_code', 'naics', 'checking_account', 'correspondent_account', 'capital', 'revenue', 'number_of_employees', 'okved', 'okato', 'okpo', 'kpp', 'reg_number'],
    rule: {
      pattern: new RegExp(/^[-0-9 ]+$/i),
      message: t('Organizations.validate.onlyNumbers'),
    }
  },{
    fields: ['phone_number', 'ceo_phone_number', 'accountant_phone_number'],
    rule: {
      pattern: new RegExp(/^[-+0-9()]+$/i),
      message: t('Organizations.validate.onlyPhone'),
    }
  },{
    fields: ['email', 'ceo_email', 'accountant_email'],
    rule: {
      type: 'email' as RuleType,
      message: t('Organizations.validate.emailRule'),
    }
  },{
    fields: ['link'],
    rule: {
      type: 'url' as RuleType,
      message: t('Organizations.validate.urlRule'),
    }
  },{
    fields: ['reg_date', 'ceo_expire_date'],
    rule: {
      pattern: new RegExp(/^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-([12][09][0-9][0-9])$/i),
      message: t('Organizations.validate.dateRule'),
    },
  },
]

  for (let i in fieldsRules) {
    const currentRule = fieldsRules[i];
    if (currentRule.fields.includes(fieldName)) {
      return currentRule.rule
    }      
  }
  return {}
}

import { DatePicker, Form, Input, Modal, Select, message, InputNumber } from 'antd'
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IDocument, IFolder } from 'interfaces';
import React from 'react'
import classes from './ShareModal.module.scss';
import { useTranslation } from 'react-i18next';
import { setIsOpenShareEntityModal } from 'store/slices/windowStateSlice';
import { useAppDispatch } from 'store/hook';
import dayjs from 'dayjs';
import api from 'api';
import { RangePickerProps } from 'antd/es/date-picker';

enum ExpireTypes { Count = 'count', Time = 'time' };
enum ModeTypes { OnlyView = 'only_view', All = 'all' };

type ShareModalProps = {
  open: boolean;
  onCancel: () => void;
  entity: IDocument | IFolder;
};

export default function ShareModal({ open, onCancel, entity }: ShareModalProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [expires, setExpires] = React.useState<ExpireTypes>(ExpireTypes.Count);
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const getExpiresValue = (values: any) => {
    switch(expires) {
      case ExpireTypes.Time:
        return `${dayjs(values[expires]).utc().format('YYYY-MM-DD HH:mm')}:59`;
      default: 
        return values[expires];
    };
  }

  const onFinish = async (values: any) => {
    const expires_value = getExpiresValue(values);    

    delete values[ExpireTypes.Time];
    delete values[ExpireTypes.Count];

    const payload = { ...values, document_token: entity.token, expires_value };

    setIsLoading(true);
    try {      
      await api.shareDocument(payload);
      message.success(`${t('Documents.modals.share.sharedSuccess')} ${values.email}`, 10);
    } catch (e) {
      message.error(`${t('Documents.modals.share.sharedError')} ${entity.name}`, 5);
    } finally {
      form.resetFields();
      setIsLoading(false);
      dispatch(setIsOpenShareEntityModal(false));
    }
  };

  const availableModes = [
    {value: ModeTypes.OnlyView, label: t('Documents.modals.share.viewMode')},
    {value: ModeTypes.All, label: t('Documents.modals.share.allMode')}
  ];

  const availableExpires = [
    {value: ExpireTypes.Count, label: t('Documents.modals.share.countExpires')},
    {value: ExpireTypes.Time, label: t('Documents.modals.share.timeExpires')}
  ];

  const defaultValues = { mode: ModeTypes.OnlyView, expires_option: ExpireTypes.Count };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // return current && current < dayjs().endOf('day');
    return current && current < dayjs().subtract(1, 'day').endOf('day'); // with today
  };

  const CountPicker = (
    <Form.Item
      rules={[{ required: true }]}
      label={t('Documents.modals.share.countLabel')}
      name={ExpireTypes.Count}
      style={{ display: 'inline-block', width: 'calc(40% - 15px)', margin: '0 0 0 15px' }}
    >
      <InputNumber
        min={1}
        max={1000}
        placeholder={t('Documents.modals.share.countPlacrholder')}
        style={{width: '100%'}}
      />
    </Form.Item>
  );

  const TimePicker = (
    <Form.Item
      rules={[{ required: true }]}
      label={t('Documents.modals.share.timeLabel')}
      name={ExpireTypes.Time}
      style={{ display: 'inline-block', width: 'calc(40% - 15px)', margin: '0 0 0 15px' }}
    >
      <DatePicker
        disabledDate={disabledDate}
        showTime
        format={'HH:mm DD-MM-YYYY'}
        placeholder={t('Documents.modals.share.timePlacrholder')}
        style={{width: '100%'}}
      />
    </Form.Item>
  );

  return (
    <Modal
    centered
      open={open}
      onCancel={onCancel}
      cancelText={t('main.cancelButton')}
      title={`${t('Documents.modals.share.shareTitle')}: ${entity.name}`}
      okText={t('Documents.modals.share.share')}
      onOk={form.submit}
      okButtonProps={{disabled: isLoading}}
      cancelButtonProps={{disabled: isLoading}}
      className={classes.shareModal}
    >
      <Form
        form={form}
        initialValues={defaultValues}
        layout='vertical'
        onFinish={onFinish}
        className={classes.form}
      >
        <Form.Item
          rules={[
            { whitespace: true },
            { required: true },
          ]}
          label={t('Documents.modals.share.userNameLabel')}
          name='user_name'
        >
          <Input placeholder={t('Documents.modals.share.userNamePlaceholder')}/>
        </Form.Item>

        <Form.Item
          rules={[
            { whitespace: true },
            { required: true },
            { type: 'email' }
          ]}
          label={t('Documents.modals.share.emailLabel')}
          name='email'
        >
          <Input placeholder={t('Documents.modals.share.emailPlaceholder')} />
        </Form.Item>

        <Form.Item
          name='mode'
          label={t('Documents.modals.share.chooseMode')}
        >
          <Select
            options={availableModes}
          />
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            label={t('Documents.modals.share.chooseExpires')}
            name='expires_option'
            style={{ display: 'inline-block', width: '60%' }}
          >
            <Select
              options={availableExpires}
              onChange={setExpires}
            />
          </Form.Item>

          {
            expires === ExpireTypes.Count 
              ? CountPicker
              : TimePicker
          }
        </Form.Item>

      </Form>

      { isLoading &&
        <UploadingComponent />
      }
    </Modal>
  )
}

import { TFunction } from 'i18next';
import { IColumn } from 'interfaces';

const getColumns = (t: TFunction): IColumn[] => {
  return [
    {
      title: t('Users.table.name'),
      dataIndex: 'first_name',
      key: 'first_name',
    },
    {
      title: t('login.email'),
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: t('Users.table.role'),
      dataIndex: 'role',
      key: 'role',
    },
  ];
};

export default getColumns;

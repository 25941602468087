import { Card } from 'antd';
import classes from '../FileDetailsModal.module.scss';
import { CloseCircleOutlined } from '@ant-design/icons';

type Props = {
  info: any;
};

export default function AgreementMessages({ info }: Props) {  
  return (
    <div className={classes.messages_overlay}>
      <Card className={classes.messages_card} key={`message-${info.id}`} bordered>
        <b><i><CloseCircleOutlined style={{ color: 'red', paddingRight: 5 }} />{info?.author.name}:</i></b>
        <br />
        {info?.comment}
      </Card>
    </div>
  );
}

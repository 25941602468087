import RowLoader from './RowLoader';
const OrganizationLoader = (props: any) => {

  return (
    <div>
      <RowLoader width={400} padding={'0 0 0 0'} height={32} borderradius={5} />
      <RowLoader width={800} padding={'15px 0 0 0'} height={42} borderradius={5} />
      <RowLoader width={800} padding={'30px 0 0 0'} height={400} borderradius={5} />
    </div>
  );
};
export default OrganizationLoader;

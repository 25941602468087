import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Space, Input, Tag, Tooltip, theme } from 'antd';
import { IInvitations, IUserData } from 'interfaces';
import { useTranslation } from 'react-i18next';
import classes from './Invitations.module.scss';
import { useAppSelector } from 'store/hook';
import { TFunction } from 'i18next';

type InvitationsProps = {
  tags: string[];
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setInvitations: React.Dispatch<React.SetStateAction<IInvitations[]>>;
  usersEmail?: string[];
  setEmailIsValid: React.Dispatch<React.SetStateAction<boolean>>;
  mode?: string
};

const emailTest = /[a-zа-яё0-9]+@[a-zа-яё_-]+\.[a-z]{2,3}/i;
const isMyEmail = (email: string, userData: IUserData | null): boolean => {
  return userData?.email?.toLowerCase() === email?.toLowerCase()
};

const getTooltipTitle = (t: TFunction, casesObj: {[key: string]: boolean | undefined}, currentValue: string): string => {
  const titles = {
    isLongTag: currentValue,
    isUserThere: t('Users.modals.invite.alreadyHere'),
    incorrectEmail: t('Users.modals.invite.incorrectEmail'),
    thisMyEmail: t('Users.modals.invite.yourEmail'),
  }

  for (let currentCase in casesObj) {
    if (casesObj[currentCase]) return titles[currentCase as keyof typeof titles]
  }

  return currentValue;
}

export default function Invitations({
  tags,
  setTags,
  setInvitations,
  usersEmail,
  setEmailIsValid,
  mode
}: InvitationsProps) {
  const [inputVisible, setInputVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [editInputIndex, setEditInputIndex] = React.useState(-1);
  const [editInputValue, setEditInputValue] = React.useState('');
  const [isEmailInputError, setIsEmailInputError] = React.useState(false);

  const inputRef = React.useRef<InputRef>(null);
  const editInputRef = React.useRef<InputRef>(null);
  
  const { t } = useTranslation();
  const { userData } = useAppSelector(store => store.userData);  
  const { token } = theme.useToken();

  const isUserAlreadyThere = (email: string, usersEmail: string[]): boolean => {
    return mode !== 'organization' && usersEmail?.includes(email);
  };

  React.useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  React.useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  React.useEffect(() => {
    for (const tag of tags) {
      if (!emailTest.test(tag) || isMyEmail(tag, userData) || isUserAlreadyThere(tag, usersEmail || [])) {
        setEmailIsValid(false);
        break;
      }else{
        setEmailIsValid(true);
      }
    }
  }, [tags]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    setInvitations((prev: IInvitations[]) =>
      prev.filter((invitation: IInvitations) => invitation.email !== removedTag)
    );
  };

  const showInput = () => {
    setInputVisible(true);
    setIsEmailInputError(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    setIsEmailInputError(!emailTest.test(e.target.value) || isMyEmail(e.target.value, userData));
    setEmailIsValid((prev) => {
      const prevValues = tags.map(tag => emailTest.test(tag) && !isUserAlreadyThere(tag, usersEmail || []));      
      const currentValue = emailTest.test(e.target.value) && !isMyEmail(e.target.value, userData) && !isUserAlreadyThere(e.target.value, usersEmail || []);      
      const returnValue = [...prevValues, currentValue].includes(false)
      return !returnValue;
    });
  };

  const handleInputConfirm = () => {
    const tagsToLower = tags.map((tag) => tag.toLocaleLowerCase());
    if (inputValue && tagsToLower.indexOf(inputValue.toLocaleLowerCase()) === -1) {
      setTags([...tags, inputValue.trim()]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setInputValue('');
  };

  const tagPlusStyle: React.CSSProperties = {
    background: token.colorBgContainer,
    borderStyle: 'dashed',
    height: 32,
    display: 'flex',
    alignItems: 'center',
  };

  const borderStyle: React.CSSProperties = !isEmailInputError ? {} : { border: '1px solid red' };

  return (
    <div style={{ height: 'calc(100% - 22px)' }}>
      <div className={classes.titleInvitations}>{t('Users.modals.invite.invitations')}</div>
      <Space size={[0, 8]} className={classes.invitationsWrap} wrap>
        <Space size={[0, 8]} wrap style={{borderRadius: 2}}>
          {tags?.map((tag, index) => {
            if (editInputIndex === index) {
              return (
                <Input
                  ref={editInputRef}
                  key={tag}
                  className={classes.tagInputStyle}
                  value={editInputValue}
                  onChange={handleEditInputChange}
                  onBlur={handleEditInputConfirm}
                  onPressEnter={handleEditInputConfirm}
                />
              );
            }
            const isLongTag = tag.length > 20;
            const isUserThere = mode !== 'organization' && usersEmail?.includes(tag.toLocaleLowerCase());
            const isCorrectEmail = emailTest.test(tag);
            const thisMyEmail = isMyEmail(tag, userData);
            const border = (isCorrectEmail && !thisMyEmail) ? {} : { border: '1px solid red' };
                        
            const tagElem = (
              <Tag
                key={tag}
                closable
                color={isUserThere ? 'orange' : 'green'}
                style={{
                  userSelect: 'none',
                  height: 32,
                  display: 'flex',
                  alignItems: 'center',
                  ...border,
                }}
                onClose={() => handleClose(tag)}
              >
                <span
                  onDoubleClick={(e) => {
                    if (index !== 0) {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }
                  }}
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </span>
              </Tag>
            );
            return isLongTag || isUserThere || !isCorrectEmail || thisMyEmail ? (
              <Tooltip
                title={getTooltipTitle(t, {isLongTag, isUserThere, incorrectEmail: !isCorrectEmail, thisMyEmail}, tag)}
                key={tag}
              >
                {tagElem}
              </Tooltip>
            ) : (
              tagElem
            );
          })}
          {inputVisible ? (
            <Input
              ref={inputRef}
              type='text'
              className={classes.tagInputStyle}
              value={inputValue}
              onChange={handleInputChange}
              onBlur={handleInputConfirm}
              onPressEnter={handleInputConfirm}
              style={{ ...borderStyle }}
            />
          ) : (
            <Tag style={tagPlusStyle} onClick={showInput}>
              <PlusOutlined /> {t('Users.modals.invite.enter')}
            </Tag>
          )}
        </Space>
      </Space>
    </div>
  );
}

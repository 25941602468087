import React from "react"
import ContentLoader from "react-content-loader"
import { useTranslation } from "react-i18next";

const OrganizationsSidesLoader = (props: any) => {

  const { t } = useTranslation();

  return <ContentLoader 
    speed={2}
    width={760}
    height={500}
    viewBox="0 0 760 500"
    backgroundColor="#f3f3f3"
    foregroundColor="white"
    title={t('Documents.table.loader')}
    {...props}
  >
    <rect x="0" y="0" rx="5" ry="5" width="750" height="205" /> 
    <rect x="0" y="230" rx="5" ry="5" width="750" height="205" /> 
    <rect x="0" y="460" rx="5" ry="5" width="60" height="30" />
  </ContentLoader>
}

export default OrganizationsSidesLoader

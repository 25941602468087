import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SigningState } from 'api/signing';
import { ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { fetchSigningState } from 'store/reducers/signingCreator';

type initialStateType = {
  signing: ISimpleGroupForAprove[];
  signingErorr: string;
  isSigningLoading: boolean;
  mySigning: ISimpleUserForAprove | null;
  needSigningFromUsers: ISimpleUserForAprove[];
  signingInfo: SigningState | null;
};

const initialState: initialStateType = {
  signing: [],
  signingErorr: '',
  isSigningLoading: false,
  mySigning: null,
  needSigningFromUsers: [],
  signingInfo: null,
};

const signingSlice = createSlice({
  name: 'signing',
  initialState,
  reducers: {
    resetSigningState: (state) => {
      state.signing = [];
      state.mySigning = null;
      state.needSigningFromUsers = [];
      state.signingInfo = null;
    },
    setSigningState: (state, action: PayloadAction<{
      signing: ISimpleGroupForAprove[],
      mySigning: ISimpleUserForAprove | null,
      needSigningFromUsers: ISimpleUserForAprove[]
    }>) => {
      state.signing = action.payload.signing;
      state.mySigning = action.payload.mySigning;
      state.needSigningFromUsers = action.payload.needSigningFromUsers;
    },
    setSigningInfo: (state, action: PayloadAction<SigningState>) => {
      state.signingInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSigningState.pending, (state) => {
      state.isSigningLoading = true;
      state.signingErorr = '';
    });

    builder.addCase(fetchSigningState.rejected, (state, action: any) => {
      if ( action?.error?.message !== 'Aborted' ) {
        state.isSigningLoading = false;
        state.signingErorr = action.payload;
      }
    });

    builder.addCase(fetchSigningState.fulfilled, (state, action: PayloadAction<{
      signing: ISimpleGroupForAprove[],
      mySigning: ISimpleUserForAprove | null,
      needSigningFromUsers: ISimpleUserForAprove[],
      signingInfo: SigningState
    }>) => {
      state.signingErorr = '';
      state.isSigningLoading = false;
      state.signing = action.payload.signing;
      state.mySigning = action.payload.mySigning;
      state.needSigningFromUsers = action.payload.needSigningFromUsers;
      state.signingInfo = action.payload.signingInfo;
    });
  },
});

const signingReducer = signingSlice.reducer;

export const { resetSigningState, setSigningState, setSigningInfo } = signingSlice.actions;
export default signingReducer;

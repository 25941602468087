import React from 'react'
import classes from './TempLink.module.scss';
import { useTranslation } from 'react-i18next';

export default function ErrorPage({error}: {error: string}) {
  const { t } = useTranslation();

  return (
    <div className={classes.errorWrap}>
      <div className={classes.errortext}>{error}.</div>
      <div>{t('templink.documentMissing')}.</div>
    </div>
  )
}

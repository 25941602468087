import React, { useState } from 'react';
import { Affix, Button, Layout, Tabs, TabsProps, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import classes from './SidesSider.module.scss';
import { setSelectedOrganization } from 'store/slices/dataSidesSlice';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import { IDocument } from 'interfaces';
import api from 'api';
import cn from 'classnames';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import OrganizationSiderInfo from 'components/OrganizationSider/components/OrganizationSiderInfo';

const { Sider } = Layout;

export default function SidesSider() {
  const [isDownloadingDocument, setIsDownloadingDocument] = useState(false);
  const [downloadingDocument, setDownloadingDocument] = useState<IDocument | null>(null);

  const { selectedOrganization, isOrgMenuCollapsed, organizationDocuments, isLoadingOrganizationDocuments, isSelectedOrganizationInfoLoading } =
    useAppSelector((store) => store.sides);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onCloseClick = () => dispatch(setSelectedOrganization(null));

  const downloadDocument = async (targetDocument: IDocument) => {
    setIsDownloadingDocument(true);
    setDownloadingDocument(targetDocument);
    await api
      .downloadFile({
        extension: targetDocument.extension,
        token: targetDocument.token,
        id: dataRoom?.id,
        entity: targetDocument.id,
        action: 'download',
        is_agreement: false,
        is_organization: true,
      })
      .then((res) => {
        const url = window.URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.href = url;
        a.download = targetDocument.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch(() => {
        message.error(`${t('Documents.error.downloadFailed')} '${targetDocument.name}'`);
      })
      .finally(() => {
        setIsDownloadingDocument(false);
        setDownloadingDocument(null);
      });
  };

  const TabsData: TabsProps['items'] = [
    {
      key: 'Info',
      label: t('Organizations.sider.nav.info'),
      children: (
        <OrganizationSiderInfo
          setIsAddingInfoModalOpen={() => {}}
          selectedOrganization={selectedOrganization}
          isEditable={false}
          isSelectedOrganizationInfoLoading={isSelectedOrganizationInfoLoading}
        />
      ),
    },
    {
      key: 'Documents',
      label: t('Sides.Sider.docsTitle'),
      children: (
        <div className={classes.contentArea}>
          {isLoadingOrganizationDocuments ? (
            <div className={classes.holder}>
              <Spinner size={60} />
            </div>
          ) : organizationDocuments[0] ? (
            <div>
              {organizationDocuments.map((doc) => {
                const icon = doc.extension && availableIconFormat.includes(doc.extension) ? doc.extension : 'default';

                return (
                  <div
                    key={doc.id}
                    className={classes.documentWrap}
                    onClick={() => !isDownloadingDocument && downloadDocument(doc)}
                  >
                    <div className={classes.oneDocument}>
                      <span style={{paddingRight: 10}}>{documnentIcons[icon as keyof typeof documnentIcons]}</span>
                      {doc.name}
                    </div>

                    <div className={classes.downloadIcon}>
                      {downloadingDocument?.id === doc.id ? <Spinner size={16} /> : <DownloadOutlined />}
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={classes.holder}>{t('Sides.Sider.emptyDocs')}</div>
          )}
        </div>
      ),
    },
  ];

  return (
    <Affix className={cn(classes.sider, isDownloadingDocument ? classes.downloadingProcess : 'none')}>
      <Sider theme='light' width={420} collapsedWidth={0} collapsed={isOrgMenuCollapsed} className={classes.siderMenu}>
        <div className={classes.aside}>
          <Button
            className={`${!isOrgMenuCollapsed && classes.aside_close}`}
            onClick={onCloseClick}
            icon={<CloseOutlined style={{ fontSize: 18, color: 'grey' }} />}
          />

          <Tabs className={classes.tabs} items={TabsData} />
        </div>
      </Sider>
    </Affix>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { RootState } from 'store';

const fetchAgreements = createAsyncThunk(
  'agreements/fetchAll',
  async (payload: {id: string, entity: string}, thunkApi) => {
    try {
      const response = await api.getAgreements(payload.id, payload.entity);
      const neededState: ISimpleGroupForAprove[] = response.data[0]?.agreement_state || [];
      const agreementInfo = {
        id: response.data[0].id,
        entity: response.data[0].entity,
        author: response.data[0].author,
        comment: response.data[0].comment!,
        status: response.data[0].status!,
      } 
      
      let needAgreementFromUsers: ISimpleUserForAprove[] = [];
      neededState?.forEach((group: any) => {
        needAgreementFromUsers = group.users ? [...needAgreementFromUsers, ...group.users] : needAgreementFromUsers;
      });      

      const rootState = thunkApi.getState() as RootState;
      const { userData } = rootState.userData;
      const myAgreementState = needAgreementFromUsers.find(user => user.user_id === userData?.id) || null;

      return { agreements: neededState, myAgreement: myAgreementState, needAgreementFromUsers, info: agreementInfo };
    } catch (err) {
      return thunkApi.rejectWithValue(`Failed to load agreements`);
    }
});

export { fetchAgreements };

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import axios from 'axios';
import { IDocument, IEmployee } from 'interfaces';
import dayjs from 'dayjs'

const fetchYourOrganization = createAsyncThunk(
  'organization/fetchRrganization',
  async (payload, thunkApi) => {
    try {
      const response = await api.getUserOrganizations();
      return response.data;
    } catch (err) {
      return thunkApi.rejectWithValue(err);
    }
  }
);

export const fetchDocumentsOfOrganization = createAsyncThunk(
  'organization/fetchAllDocuments',
  async (payload: string, thunkApi) => {
    try {
      const response = await api.getOrganizationDocuments(payload);
      const orgDocuments: IDocument[] = response.data.map((document) => {
        const modified_at = dayjs(document.modified_at, 'DD.MM.YYYY, HH:mm:ss').valueOf();
        return {...document, modified_at: modified_at,}
      });
      return orgDocuments
    } catch (err: any) {
      if (axios.isAxiosError(err)) {
        thunkApi.rejectWithValue(err.response?.data);
      } else {
        thunkApi.rejectWithValue(err.message);
      }
      return [];
    }
  }
);

export const fetchUsersOfOrganization = createAsyncThunk(
  'organization/fetchAllUsers',
  async (payload: string, thunkApi) => {
    try {
      const employees = await api.getOrganizationEmployees(payload);
      return employees.data;
    } catch (err: any) {
      return thunkApi.rejectWithValue(err);
    }
  }
);


export const setNewEmployeeRole = createAsyncThunk('organization/setNewEmployeeRole',
  async (props: {employee: IEmployee, role_name: string}, thunkApi) => {
  try {
    await api.changeEmployeeRole({
      user_id: props.employee.user_id,
      role_name: props.role_name,
    })
    return {
      ...props.employee,
      role_name: props.role_name,
    }
  } catch (e) {
    return thunkApi.rejectWithValue('Failed setting new role');
  }
});

export const deleteDocumentOfOrganization = createAsyncThunk(
  'organizations/deleteDocument',
  async (payload: {doc_id: string, dataRoomId: string }, thunkApi) => {
    try {
      await api.deleteDocuments([payload.doc_id], {
        room_id: payload.dataRoomId,
        details: {
          documents_names: 'document of organization',
          documents_ids: payload.doc_id
        }
      });
      return payload.doc_id
    } catch (err: any) {
      return thunkApi.rejectWithValue(payload.doc_id);
    }
  }
);

export default fetchYourOrganization;

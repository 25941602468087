import React from 'react';
import { useParams } from 'react-router-dom';
import classes from './OrganizationBook.module.scss';
import auth from 'api/auth';
import organizations from 'api/organizations'
// import { ArrowLeftOutlined } from '@ant-design/icons'

const OrganizationBook = () => {
  const [dataUrl, setDataUrl] = React.useState<string | null>(null)
  const [usersData, setUsersData] = React.useState<any>(null)
  const [specialist, setSpecialist] = React.useState<any>(null)
  const { encoded_jwt } = useParams();  

  React.useEffect(() => {
    encoded_jwt && decodeData(encoded_jwt)
  },[encoded_jwt]);

  const decodeData = async (encoded_jwt: string) => {
    try {
      const response = await auth.decodeDataFromLink(encoded_jwt);
      const organizationId = response.data.organization_id
      const organizationUsers = await organizations.getOrganizationUserOptions(organizationId)
      const filteredUsers = organizationUsers.data.filter(user => user.integrations && user.integrations.calendly)
      setUsersData(filteredUsers)
    } catch (e) {
      // console.log(e);
    }
  }

  const openOptions = (specialist_id: number) => setSpecialist(specialist_id)
  const openCalendar = (url: string) => setDataUrl(url)

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);
  },[dataUrl])

  return (
    <div className={classes.bookWrap}>
      {dataUrl && (
        <>
          {/* <button className={classes.backBtn}><ArrowLeftOutlined className={classes.back} /></button> */}
          <div className='calendly-inline-widget'
            data-url={dataUrl}
            style={{ minWidth: 320, height: '90vh', minHeight: 700}}
          />
        </>
      )}
      {!dataUrl && usersData && specialist === null && (
        <div className={classes.bookWrapper}>
          <div className={classes.bookTitle}>Choose a specialist</div>
          {usersData.map((user: any, index: number) => (
            <div key={user.user_id} onClick={() => openOptions(index)} className={classes.bookCard}>
              <div>
                <div className={classes.bookUser}>{`${user.first_name} ${user.last_name}`}</div>
                {/* <div className={classes.bookDescription}>Attorney</div> */}
              </div>
              <div className={classes.bookBtn}>Choose</div>
            </div>
          ))}
        </div>
      )}
      {!dataUrl && usersData && specialist !== null && (
        <div className={classes.bookWrapper}>
          <div className={classes.bookTitle}>Choose a option</div>
          {usersData[specialist].integrations.calendly.map((option: any) => (
            <div key={option.name} onClick={() => openCalendar(option.url)} className={classes.bookCard}>
              <div>
                <div className={classes.bookUser}>{option.name}</div>
                {option.description && <div className={classes.bookDescription}>{option.description}</div>}
              </div>
              <div className={classes.bookBtn}>Choose</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default OrganizationBook;

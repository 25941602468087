import { useEffect, useState } from 'react';
import { IPreparedLogs } from 'interfaces';
import api from 'api';
import classes from './DocumentsReport.module.scss';
import LogsTable from 'components/LogsTable/LogsTable';
import prepareLogs from 'helpers/prepareLogs';
import { useTranslation } from 'react-i18next';

type Props = {
  document_id: string;
};

export default function DocumentActions({ document_id }: Props) {
  const [documentLogs, setDocumentLogs] = useState<IPreparedLogs[]>([]);
  const [isLaodingLogs, setIsLoadingLogs] = useState(false);

  const { i18n } = useTranslation();
  const locale = i18n.language || 'en'

  useEffect(() => {
    setIsLoadingLogs(true);
    api
      .getDocumentLogs({ document_id, locale })
      .then((response) => {
        setDocumentLogs(prepareLogs(response.data));
      })
      .catch((err) => {})
      .finally(() => setIsLoadingLogs(false));
  }, [locale]);

  return (
    <div className={classes.action}>
      <LogsTable
        logs={documentLogs}
        height='calc(100vh - 219px)'
        scroll={{ y: 'calc(100vh - 285px)' }}
        isLoading={isLaodingLogs}
      />
    </div>
  );
}

export const createInfoLogDetails = (selectedOrganization?: any, newOrganization?: any) => {
  let details: any = {
    before: {},
    after: {},
  };

  for (let key in newOrganization?.info) {
    if (newOrganization && newOrganization?.info) {
      for (let field in newOrganization?.info[key]) {
        details.after[`${key}__${field}`] = newOrganization?.info[key][field];
        details.before[`${key}__${field}`] = ''
      }
    }

    if (selectedOrganization && selectedOrganization?.info) {
      for (let field in selectedOrganization?.info[key]) {
        details.before[`${key}__${field}`] = selectedOrganization?.info[key][field];

        if (!details.after[`${key}__${field}`]) details.after[`${key}__${field}`] = '';
      }
    }
  }

  for (let key in details.after) {
    if (details.after[key] === details.before[key]) {
      delete details.before[key]
      delete details.after[key]
    }
  }
  
  return details;
}

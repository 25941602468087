import { getCanvasID, getWebglID, getWebglInfo, getInfoIP, getDeviceType, getListFonts, getAccept } from "./getMoreInfoFromBrowser";
import { getUA, deviceDetect, isIOS, isAndroid } from "react-device-detect";
import getAudioFingerprint from "./getAudioFingerprint";
// import { getSystemFonts } from "./getSystemFonts";
// 27.02.2024 убраны значения appName, appCodeName, webGLParameters, systemFonts из состава fingerprint http://10.1.3.25/issues/4432

const getBrowserClientData = async () => {
  const {
    // appName,
    // appCodeName,
    appVersion,
    cookieEnabled,
    doNotTrack,
    hardwareConcurrency,
    language,
    languages,
    maxTouchPoints,
    platform,
    product,
    userAgent,
    vendor,
    plugins,
    mimeTypes,
  } = window.navigator;

  const {
    browserFullVersion,
    browserMajorVersion,
    browserName,
    engineName,
    engineVersion,
    isBrowser,
    osName,
    osVersion
  } = deviceDetect(getUA);

  const newVariable: any = window.navigator;
  const { deviceMemory, userAgentData  } = newVariable;
  let { devicePixelRatio }: any = window;
  // weird behaviour when getting value from localhost vs ip!!!
  const devPixelRatio = +parseInt(devicePixelRatio);
  
  const touchSupport = 'ontouchstart' in newVariable;
  const screenSizeColorDepth = `${window.screen.width}x${window.screen.height}x${window.screen.colorDepth}`;

  const audioCtx = new AudioContext();
  const oscillator = audioCtx.createOscillator()
  
  const audioContext = [
    oscillator.channelCount,
    oscillator.channelCountMode,
    oscillator.channelInterpretation,
    oscillator.context.destination.maxChannelCount,
    oscillator.context.destination.numberOfInputs,
    oscillator.context.destination.numberOfOutputs,
    oscillator.context.sampleRate
  ].join('/');

  const audioContextFingerprint = await getAudioFingerprint()
  const webGLID = getWebglID(false);
  const canvasID = getCanvasID(false);
  const webGL_info = getWebglInfo();    
  const InfoIP = await getInfoIP();
  const deviceType = getDeviceType();
  const accept = getAccept();
  const listOfFonts = getListFonts()?.join(', ');
  // const systemFonts = getSystemFonts().join(', ');  

  const {
    glVendor,
    glRenderer,
    VERSION,
    // SUPORTED_EXTENSIONS,
  }: any = webGL_info;

  const {
    ip,
    version,
    country_name,
    city,
    latitude,
    longitude,
    timezone,
  }: any = InfoIP;

  const clientDataFromBrowser = {
      userAgent,
      accept,
      language,
      languages: languages.join(', '),
      headersPlatformName: userAgentData?.platform,
      headersMobileName: userAgentData?.mobile,
      headersName: userAgentData?.brands?.map((el:any) => Object.values(el)).join(', '),
      platformOS: platform,
      listOfFonts,
      product,
      vendor,
      hardwareConcurrency,
      deviceMemory,
      plugins: Object.entries(plugins).map(([, plugin]) => plugin.name)?.join(', '),
      mimeTypes: Object.entries(mimeTypes).map(([, mimeType]) => mimeType.type)?.join(', '),
      screenSizeColorDepth,
      webGLVendor: glVendor,
      webGLRenderer: glRenderer,
      webGLData: VERSION,
      // webGLParameters: SUPORTED_EXTENSIONS?.join(', '),
      audioContext,
      canvasID,
      audioContextFingerprint,      
      webGLID,
      IP: `${ip} - ${version} `,
      hostName: window.location.hostname,
      location: { country: country_name, city, latitude, longitude, timezone },
      cookieEnabled,
      appVersion,
      // hash: window.location.hash, //всегда пустое значение
      host: window.location.host,
      touchSupport,
      // systemFonts,
      devPixelRatio,
      // appCodeName,
      // appName,
      doNotTrack: doNotTrack?.toString(),
      maxTouchPoints,
      deviceType,
      browserFullVersion,
      browserMajorVersion,
      browserName,
      engineName,
      engineVersion,
      isBrowser: isBrowser?.toString(),
      osName: osName ? osName : isIOS ? 'IOS' : isAndroid ? 'Android' : null,
      osVersion,
  };

  return clientDataFromBrowser;
}

export { getBrowserClientData }

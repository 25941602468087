import dayjs from 'dayjs';

export const dateChecker = (firstDay: string, secondDay: string) => {
  const firstDate = dayjs(firstDay).date();
  const firstMonth = dayjs(firstDay).month();
  const secondDate = dayjs(secondDay).date();
  const secondMonth = dayjs(secondDay).month();

  return firstDate === secondDate && firstMonth === secondMonth;
};

import { Modal } from 'antd';
import { TFunction } from 'i18next';
import { INotification } from 'interfaces';
import { getArrayObjectFromKeys } from 'helpers/getArrayFromObjectKeys';
import classes from './NotificationsModal.module.scss';

type Props = {
  isOpen: boolean;
  selectedNotification: INotification;
  onCancel: () => void;
  t: TFunction;
};

// const exceptions = ['details', 'user']

export default function NotificationsModal({ isOpen, onCancel, t, selectedNotification }: Props) {
  const notificationDetail = getArrayObjectFromKeys({
    ...selectedNotification.details,
    ...selectedNotification.user,
    ...selectedNotification,
  });
  // const filteredNotificationDetail = notificationDetail.filter((details) => !exceptions.includes(details.label));
  const filteredNotificationDetail = notificationDetail.filter((details) => typeof details.value !== 'object');

  return (
    <Modal
      onCancel={onCancel}
      title={t('Header.Notification.title')}
      open={isOpen}
      footer={null}
      centered
      className={classes.modal}
      classNames={{ 
        body: classes.body,
      }}
    >
      {filteredNotificationDetail.map((details) => (
        <div key={`row-${details.key}`} className={classes.row}>
            <div className={classes.column}>{details.label}</div>
            <div className={classes.column}>{details.value}</div>
        </div>
      ))}
    </Modal>
  );
}

import { TFunction } from 'i18next';

export const getInfoColumns = (t: TFunction) => [
  {
    title: t('Report.info.key'),
    dataIndex: 'key',
    key: 'key',
    width: '50%',
  },
  {
    title: t('Report.info.value'),
    dataIndex: 'current',
    key: 'current',
    width: '50%',
  },
];


export const getCompareColumns = (t: TFunction) => [
  {
    title: t('Report.info.key'),
    dataIndex: 'key',
    key: 'key',
    width: '33%',
  },
  {
    title: t('Report.info.previous'),
    dataIndex: 'previous',
    key: 'previous',
    width: '35%',
  },
  {
    title: t('Report.info.current'),
    dataIndex: 'current',
    key: 'current',
    width: '32%',
  },
];

import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hook';
import classes from './LogsManagement.module.scss';
import ManagementLoader from '../Sceletons/ManagementLoader';
import { Button, DatePicker, Dropdown, MenuProps, message } from 'antd';
import type { Dayjs } from 'dayjs';
import { ILogs, IPreparedLogs } from 'interfaces';
import { setLogs } from 'store/slices/dataLogsSlice';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { DownloadOutlined, EllipsisOutlined, PrinterOutlined } from '@ant-design/icons';
import api from 'api';
import { getFile } from 'helpers/downloadSomeFile';
import { getlogsColumnsForPrint } from 'components/LogsTable/columns/logsColumns';
import { pdfHelper } from 'helpers/pdfHelper';

const { RangePicker } = DatePicker;

export default function LogsManagement() {
  const [downloadProcess, setDownloadProcess] = React.useState<string[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = React.useState(false);
  
  const { isLoadingRooms, dataRoom } = useAppSelector((store) => store.dataRoom);
  const { allLogs, logs, logsForPrint } = useAppSelector((store) => store.logs);
  const { userAndRoomStatus } = useAppSelector((store) => store.userData);

  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en'

  const dispatch = useAppDispatch();

  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = [
    { label: t('Logs.presets.7'), value: [dayjs().add(-7, 'd'), dayjs()] },
    { label: t('Logs.presets.14'), value: [dayjs().add(-14, 'd'), dayjs()] },
    { label: t('Logs.presets.30'), value: [dayjs().add(-30, 'd'), dayjs()] },
    { label: t('Logs.presets.90'), value: [dayjs().add(-90, 'd'), dayjs()] },
  ];

  const onRangeChange = (dates: null | (Dayjs | null)[], dateStrings: string[]) => {
    if (dates) {
      // console.log('From: ', dates[0], ', to: ', dates[1]);
      // console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
      // where 86400000 is a day in milliseconds
      // const startPeriod = dates[0]?.toDate()?.getTime()! - 86400000;
      // const endPeriod = dates[1]?.toDate().getTime()! + 86400000;
      const startPeriod = dayjs(dateStrings[0])?.toDate()?.getTime()!; // - 86400000;
      const endPeriod = dayjs(dateStrings[1])?.toDate()?.getTime()! + 86400000;

      const filteredLogs = allLogs.filter((log: IPreparedLogs) => {    
        const inDateFormat = log?.date?.split('.')?.reverse()?.join('-');
        // const dateInMilliseconds = new Date(inDateFormat)?.getTime();
        const dateInMilliseconds = new Date(new Date(`${inDateFormat}T${log?.time}`))?.getTime();
        return dateInMilliseconds >= startPeriod! && dateInMilliseconds <= endPeriod!;
      });
      dispatch(setLogs(filteredLogs));
    } else {
      dispatch(setLogs(allLogs));
    }
  };

  const downloadReport = async () => {
    setDownloadProcess(prev => [...prev, 'download']);
    try {
      const res = await api.getLogsReport({room_id: dataRoom?.id!, locale});
      getFile(res.data, `${t('Logs.managament.report')} '${dataRoom?.name}'`);
    } catch (e) {
      message.error(t('Logs.managament.reportError'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === 'download'));
    }
  };

  const printReport = async () => {
    setDownloadProcess(prev => [...prev, 'print']);
    api.logPrint(dataRoom?.id!, false);
    try {
      const logsColumns = getlogsColumnsForPrint(t);   
      const arrOfLogs = logsForPrint && logsForPrint[0] ? logsForPrint : logs;
      pdfHelper<ILogs>(arrOfLogs, logsColumns, t('Logs.managament.report'))
    } catch (e) {
      message.error(t('Logs.managament.reportError'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el === 'print'));
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'download',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('download')}
          onClick={downloadReport}
          icon={<DownloadOutlined />}
          className={classes.liButton}
          disabled={Boolean(downloadProcess[0])}
        >
          {t('Logs.managament.download')}
        </Button>
      ),
    },
    {
      key: 'print',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('print')}
          onClick={printReport}
          icon={<PrinterOutlined />}
          className={classes.liButton}
          disabled={Boolean(downloadProcess[0])}
        >
          {t('Logs.managament.print')}
        </Button>
      ),
    },
  ];

  return (
    <div className={classes.managementArea}>
      { (isLoadingRooms || userAndRoomStatus !== '')
        ? <ManagementLoader/> 
        : <div className={classes.managementWrap}>
            <div className={classes.managementTitle}>
              {t('Logs.managament.title')}
            </div>
            <div className={classes.optionsSection}>
              <RangePicker
                className={classes.rangePicker}
                presets={rangePresets}
                onChange={onRangeChange}
              />
              <Dropdown
                // trigger={['click']}
                open={isOpenDropdown || Boolean(downloadProcess[0])}
                onOpenChange={(open) => setIsOpenDropdown(open)}
                className={classes.dropdown}
                menu={{ items }}
                placement='bottomLeft'
              >
                <Button icon={<EllipsisOutlined />} />
              </Dropdown>
            </div>
        </div>
      }
    </div>
  )
}

import { IWithWebkitPath } from 'interfaces';

const segregateToRootFolders = (fileList: IWithWebkitPath[]) => {
  const rootFolders = [];
  const foldersBreadCrumbs: string[][] = [];

  for (let file of fileList) {
    if (file.webkitRelativePath) {
      const breadCrumbs = file.webkitRelativePath.split('/');
      rootFolders.push(breadCrumbs[0]);
      foldersBreadCrumbs.push(breadCrumbs);
    }
  }

  const withoutDublicateRootFolders = new Set(rootFolders);
  const withTreeFolders: string[][][] = [];

  withoutDublicateRootFolders.forEach((folder) => {
    const filteredFolders = foldersBreadCrumbs
      .filter((breadCrumb) => breadCrumb[0] === folder)
      .sort((first, second) => second.length - first.length);
    withTreeFolders.push(filteredFolders);
  });

  return withTreeFolders;
};

export default segregateToRootFolders;

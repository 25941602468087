import { IOrganization } from 'interfaces';
import React from 'react';
import classes from './Organization.module.scss';
import { Button, Dropdown, MenuProps } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type OrganizationCardProps = {
  organization: IOrganization;
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleteModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};


const OrganizationTitle = ({organization, setIsEditModalOpen, setIsDeleteModalOpen}: OrganizationCardProps) => {
  const { t } = useTranslation();
  const canEditOrganizationSettings = organization?.permission?.can_edit_organization_settings;
  const canDeleteOrganization = organization?.permission?.can_delete_organization;
  const isNeedToDisplayEditIcon = canEditOrganizationSettings || canDeleteOrganization;

  const cardActions: MenuProps['items'] = [];

  canEditOrganizationSettings && cardActions?.push(
    {
      label: t('Organizations.card.edit'),
      key: 'onEditClick',
      icon: <EditOutlined style={{ fontSize: 16 }} />,
    }
  );

  canDeleteOrganization && cardActions?.push(
    {
      label: t('Organizations.card.delete'),
      key: 'onDeleteClick',
      icon: <DeleteOutlined style={{ fontSize: 16 }}/>,
    }
  );

  const onActionClick: MenuProps['onClick'] = ({ key }) => {
    const actions = {
      onEditClick: () => setIsEditModalOpen(true),
      onDeleteClick: () =>  setIsDeleteModalOpen(true)
    }

    return actions[key as keyof typeof actions]();
  };

  // const abbrevation = organization.name.replace(/["'`«»“„]/g,'')
  //   .split(' ')
  //   .map((str) => str[0])
  //   .join('')
  //   .slice(0, 3);

  return (
      <div className={classes.titleWrap}>
        <div className={classes.titleName}>
         {/* <Avatar
            className={classes.initials}
            size={40}
          >
            {abbrevation}
          </Avatar> */}
          {organization.name}
        </div>
        {isNeedToDisplayEditIcon && <Dropdown
          placement='bottom'
          trigger={['click']}
          // align={{offset: [-1, -40]}}
          menu={{ items: cardActions, onClick: onActionClick }}
        >
          <Button
            className={classes.editIcon}
            type='text'
            size='small'
            icon={<EditOutlined/>}
          />
        </Dropdown>}
      </div>
  );
}

export default OrganizationTitle;

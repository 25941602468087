import { requestTemplate } from 'api';
import { ILogs } from 'interfaces';

//LOGS
const getLogs = (id: string, locale: string) => requestTemplate.get<ILogs[]>(`/logs/?room_id=${id}&locale=${locale}&id=${id}&action=view`);

const getUserLogs = (
  user_id: string, room_id: string, locale: string
) => requestTemplate.get<ILogs[]>(`/logs/user/?user_id=${user_id}&room_id=${room_id}&locale=${locale}`);

const getDocumentLogs = (
  payload: { document_id: string, locale: string}
) => requestTemplate.get<ILogs[]>(`/logs/document/?document_id=${payload.document_id}&locale=${payload.locale}`);

const getLogsReport = (
  payload: {room_id: string, locale: string, filters?: {[key: string]: any}},
) => requestTemplate.post<Blob>(`/logs/report/?locale=${payload.locale}`, payload, {responseType: 'blob'});

const getConfidentLogs = (
  room_id: string, locale: string, logTypes?: string
) =>requestTemplate.get<ILogs[]>(`/logs/confident/?room_id=${room_id}&types=${logTypes}&locale=${locale}`);

const getConfidentLogsXLSX = (room_id: string, locale: string, logTypes?: string) => requestTemplate.post<Blob>(`/logs/confident/report/xlsx/?locale=${locale}`, {
  room_id,
  type: logTypes
}, { responseType: 'blob' });

const logPrint = (room_id: string, is_confidential: boolean) => requestTemplate.post(`/logs/print/?room_id=${room_id}&is_confidential=${is_confidential}`);

const logs = {
  getLogs,
  getUserLogs,
  getDocumentLogs,
  getLogsReport,
  getConfidentLogs,
  getConfidentLogsXLSX,
  logPrint,
};

export default logs;

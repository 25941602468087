import React, { useState } from 'react'
import { Modal, Form, Input, Button, message } from 'antd'
import classes from './AddingInfoModal.module.scss';
import { TFunction } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { getOrganizationFields, OrgFields } from 'helpers/organizationFieldsDictionary';
import { GroupOrgFields } from 'helpers/organizationFieldsDictionary';
import api from 'api';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { deleteEmptyFieldsFromObject } from 'helpers/deleteEmptyFieldsFromObject';
import { createInfoLogDetails } from './createInfoLogDetails';
import { validateField } from './validateField';
import { setSelectedOrganization } from 'store/slices/dataSidesSlice';
import { setOrganization } from 'store/slices/organization';

type AddingInfoModalType = {
  t: TFunction;
  open: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AddingInfoModal({t, open, setIsOpen}: AddingInfoModalType) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isAvailableToUpdate, setIsAvailableToUpdate] = React.useState(false);
  const [isAvailableClearButton, setIsAvailableClearButton] = React.useState(false);

  const { organization } = useAppSelector((state) => state.organization);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const dispatch = useAppDispatch();

  const fieldgroups = getOrganizationFields(t);
  
  React.useEffect(() => {
    for (let groupFields in organization?.info) {
      const currentGroupName = groupFields as GroupOrgFields;
      
      for (let field in organization?.info[currentGroupName]) {
        const currentField = field as OrgFields        
        form.setFieldValue([currentGroupName, currentField], organization?.info[currentGroupName]![currentField]);
      };
    };

    setIsAvailableClearButton(Boolean(organization?.info));
  }, [organization]);

  const checkAvailableOfControlButtons = () => {
    const fields = form.getFieldsValue();
    const currentValues = JSON.stringify(deleteEmptyFieldsFromObject(fields));
    const startValues = JSON.stringify(organization?.info || {});
    const isTheSameInfo = currentValues === startValues;
    setIsAvailableToUpdate(!isTheSameInfo);    
    setIsAvailableClearButton(currentValues !== '{}');
  };

  const clearAllFields = () => {
    form.resetFields();
    checkAvailableOfControlButtons();
  };

  const onChangeSomeField = () => {
    checkAvailableOfControlButtons();
  };

  const onChangeInput = ( e: any, group: string, field: string ) => {
    // console.log(e.target.value,"<<<<<e.target.value");
    // console.log(group,'<group');
    // console.log(field,'<field');
    const targetGroup = organization?.info && organization?.info[group as GroupOrgFields];
    const targetField = targetGroup && targetGroup[field as OrgFields];
    setIsAvailableToUpdate(Boolean(targetField) !== Boolean(e.target.value));
    const fields = form.getFieldsValue();
    const currentValues = JSON.stringify(deleteEmptyFieldsFromObject(fields));
    setIsAvailableClearButton(currentValues !== '{}');
  };

  const onFinish = async (values: any) => {
    for (const key in values) {
      for (const field in values[key]) {
        if (!values[key][field]) delete values[key][field];
      }
      if (JSON.stringify(values[key]) === '{}' ) delete values[key]
    }

    setIsLoading(true);
    try {
      const newOrganization = {
        ...organization,
        name: organization?.name!,
        id: organization?.id!,
        owner_id: organization?.owner_id!,
        info: values
      };

      let newOrganizationInfo: any = {
        id: organization?.id,
        owner_id: organization?.owner_id,
        info: values
      }

      const details = createInfoLogDetails(organization, newOrganization);

      await api.editOrganization({
        ...newOrganizationInfo,
        details,
      }, dataRoom?.id!);
      dispatch(setSelectedOrganization(newOrganization));
      dispatch(
        setOrganization(newOrganization)
      );

      message.success(t('Organizations.card.success'));
    } catch (err) {
      message.error(t('Organizations.card.error'));
    } finally {
      setIsOpen(false);
      setIsLoading(false);
    }
  };

  const controlButtons = (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <Button
        disabled={!isAvailableClearButton}
        onClick={clearAllFields}
      >
        {t('Users.HelperMenu.clear')}
      </Button>
      <div>
        <Button
          style={{ marginRight: 10 }}
          onClick={() => setIsOpen(false)}
        >
          {t('Users.HelperMenu.cancel')}
        </Button>
        <Button disabled={!isAvailableToUpdate} onClick={form.submit} type='primary'>
          {t('Users.HelperMenu.save')}
        </Button>
      </div>
    </div>
  )

  return (
    <Modal
      centered
      width={'75%'}
      onCancel={() => setIsOpen(false)}
      onOk={form.submit}
      open={open}
      // okButtonProps={{disabled: !isAvailableToUpdate}}
      okText={t('Users.HelperMenu.save')}
      title={`${t('Organizations.modals.addInfo')}`}
      className={classes.modal}
      confirmLoading={isLoading}
      footer={controlButtons}
    >
      <Form
        form={form}
        onFinish={onFinish}
        onChange={onChangeSomeField}
        className={classes.form}
        wrapperCol={{ span: 16 }}
        labelCol={{ span: 8 }}
        labelAlign='left'
        labelWrap
      >
        {fieldgroups.map((group) => {          
          return <div className={classes.wrap} key={group.fieldGroupValue}>
            <div className={classes.groupWrap}>{group.fieldGroupLabel}</div>
            <div>{group.fields.map(field => {              
              return (
                <Form.Item
                  className={classes.form_item}
                  name={[group.fieldGroupValue, field.value]}
                  // rules={[{required: field.value === 'name' ? true : false}]}
                  rules={[
                    { whitespace: true },
                    validateField(field.value, t)
                  ]}
                  label={field.label}
                  key={field.value}
                  // normalize={(value) => value.trim()}
                >
                  <Input
                    onChange={(e) => onChangeInput(e, group.fieldGroupValue, field.value)}
                    placeholder={`${t(`Organizations.fields.addValue`)} "${field.label}"`}
                    allowClear
                  />
                </Form.Item>
              )
            })}</div>
          </div> 
        })}
      </Form>
      {isLoading && <UploadingComponent />}
    </Modal>
  )
}
          

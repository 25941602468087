import React from 'react'
import { Avatar, Typography } from 'antd';
import classes from './SideOrganizationCard.module.scss';
import { IOrganization } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { setSelectedOrganization } from 'store/slices/dataSidesSlice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import cn from 'classnames';
import { fetchOrganizationDocuments, fetchOrganizationInfo } from 'store/reducers/sidesCreator';

const { Paragraph } = Typography;

type OrganizationCardProps = {
  organization: IOrganization;
}

export default function OrganizationCard({organization}: OrganizationCardProps) {
  const [ellipsis, setEllipsis] = React.useState(true);

  const { selectedOrganization, isOrgMenuCollapsed} = useAppSelector((store) => store.sides);
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const setOrganization = () => {
    dispatch(setSelectedOrganization(organization));
    dispatch(fetchOrganizationInfo(organization.id));
    dispatch(fetchOrganizationDocuments(organization.id));
  };

  const isThisSelectedOrganization = organization.id === selectedOrganization?.id;
  const abbrevation = organization.name
  .split(' ')
  .map((str) => str[0])
  .join('')
  .slice(0, 3);


  const toOrgSite = (orgLink: string) => orgLink 
    ? <div className={classes.link} onClick={() => navigateToSite(orgLink)}>
      {orgLink}
    </div>
    : '-'

  const navigateToSite = (orgLink: string) => {
    window.open(`http://${orgLink}`)
  };
  

  return (
    <div onClick={setOrganization} className={cn(classes.cardSide, isThisSelectedOrganization ? classes.selected : 'none', isOrgMenuCollapsed ? 'none' : classes.fullwidth)}>
      <div className={classes.cardHeader}>
        <Avatar
          style={{ backgroundColor: '#f7f1fe', color: '#7f56d8' }}
          size={40}
        >
          {abbrevation}
        </Avatar>
        <div className={classes.cardTitle}>{organization.name}</div>
      </div>
      
      <div className={classes.carfInfo}>
        <div className={classes.cardRow}>
          <div className={classes.rowTitle}>{t('Sides.about')}</div>
          <Paragraph ellipsis={ellipsis ? { rows: 2, expandable: true, symbol: 'more' } : false} className={classes.rowInfo}>
            { organization.description || `-` }
          </Paragraph>
        </div>

        <div className={classes.cardRow}>
          <div className={classes.rowTitle}>{t('Sides.website')}</div>
          <div className={classes.rowInfo}>
            {toOrgSite(organization.link || `-`)}
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { CSSProperties } from 'react'
import Spinner from 'components/Spinner/Spinner';
import classes from './UploadingComponent.module.scss';

type UploadingComponentProps = {
  style?: CSSProperties;
}

export default function UploadingComponent({style}: UploadingComponentProps) {
  return (
    <div className={classes.loading} style={style}>
      <Spinner size={96}/>
    </div>
  )
}

import { message, Modal } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setUserData } from 'store/slices/userDataSlice';
import { signatureRules } from './signatureRules';
import api from 'api';
import classes from '../SingingTab.module.scss';

type SignatureRulesModalProps = {
  isRulesModalOpen?: boolean;
  setIsRulesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isAlreadyAccept: boolean;
};

const SignatureRulesModal = ({
  isRulesModalOpen,
  setIsRulesModalOpen,
  isAlreadyAccept,
}: SignatureRulesModalProps) => {
  const [isRequesting, setIsRequesting] = React.useState(false);
  const [isAvailableAcceptButton, setIsAvailableAcceptButton] = React.useState<boolean>(false);

  const scrollElement = React.useRef<HTMLDivElement | null>(null);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { userData } = useAppSelector((store) => store.userData);

  React.useEffect(() => {    
    let localRef: HTMLDivElement | null = null;
    if (scrollElement.current) localRef = scrollElement.current;
    localRef?.addEventListener('scroll', scrollHandler);
    if ((localRef?.scrollHeight || 2) <= (localRef?.clientHeight || 1)) setIsAvailableAcceptButton(true);
    return () => {
      localRef?.removeEventListener('scroll', scrollHandler);
    };
  }, [isRulesModalOpen, scrollElement.current]);

  const scrollHandler = React.useCallback((e: Event) => {
    const borders = 2;
    const scrollHeight = scrollElement.current?.scrollHeight || 1;
    const scrollTop = scrollElement.current?.scrollTop || 0;
    const clientHeight = scrollElement.current?.clientHeight || 1;

    if ((scrollHeight - scrollTop - borders) <= clientHeight) {
      setIsAvailableAcceptButton(true);
    } else setIsAvailableAcceptButton(false);
  }, []);


  const createSignature = async () => {
    setIsRequesting(true);
    try {
      await api.createSignature();
      userData && dispatch(setUserData({...userData, has_sign: true}));
      message.success(t('Documents.details.signing.acceptedRules'), 10);
      setIsRulesModalOpen(false);
    } catch (e) {
      message.error(t('Documents.details.signing.errorAcceptingRules'));
    } finally {
      setIsRequesting(false);
    }
  }

  return (
    <Modal
      width={'85%'}
      open={isRulesModalOpen}
      centered={true}
      // loading={isRequesting}
      onCancel={() => setIsRulesModalOpen(false)}
      title={t('Documents.details.signing.rulesTitle')}
      // title={t('Documents.details.signing.rulesTitle')}
      okButtonProps={{loading: isRequesting, disabled: !isAvailableAcceptButton}}
      okText={t('Documents.details.signing.acceptButton')}
      onOk={createSignature}
      className={classes.modalRules}
      footer={ (params) => isAlreadyAccept ? null : params }
    >
      <div ref={scrollElement} className={classes.scrollElem}>
        {signatureRules[String(i18n.language === 'ru' || i18n.language === 'kg' ? i18n.language : 'en') as keyof typeof signatureRules]}

        {/* {rules[String(i18n.language) as keyof typeof rules]} */}
      </div>

      {isRequesting &&
        <UploadingComponent />
      }
    </Modal>
  );
}

export default SignatureRulesModal;

import { TFunction } from 'i18next';

export const getMockActions = (staticT: TFunction, t: TFunction) => ({
  ru: [
    staticT('Logs.none'),
  ],
  actual: [
    t('Logs.none'),
  ],
});

import * as pdfjsLib from 'pdfjs-dist';
import React, { FC, memo, KeyboardEvent } from 'react';
import Error from './Error';
import Spinner from './Spinner';
import PDFViewer from './Viewer/PDFViewer';
import classes from './Viewer.module.scss';
import { useAppSelector } from 'store/hook';
import XLSXViewer from './XLSXViewer/XLSXViewer';
import { imageExtensions, videoExtensions } from 'store/reducers/viewerCreator';
import ImageViewer from './ImageViewer/ImageViewer';
import VideoViewer from './VideoViewer/VideoViewer';

pdfjsLib.GlobalWorkerOptions.workerSrc =
  '/helpers/pdf.worker.js';

type WebViewerType = {
  url?: any;
  data64?: string;
  isLoading: boolean;
  errorMessage: string;
  onClose: () => void;
};

const WebViewer: FC<WebViewerType> = ({ url, isLoading, data64, errorMessage, onClose }) => {
  const { documentExtention } = useAppSelector(state => state.viewer);
  const onEscapeHandle = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Escape") {
      onClose();
    }
  };
  
  const chooseViewer = () => {
    let viewer = '';
    if (documentExtention === 'xlsx') {
      viewer = 'xlsx';
    } else if (imageExtensions.includes(documentExtention)) {
      viewer = 'image';
    } else if (videoExtensions.includes(documentExtention)) {
      viewer = 'video';
    }
    
    switch(viewer) {
      case 'xlsx':
        return <XLSXViewer data={url} onClose={onClose} />;
      case 'image':
        return <ImageViewer url={url} onClose={onClose} />;
      case 'video':
        return <VideoViewer url={url} onClose={onClose} />;
      default: 
        return <PDFViewer url={url} onClose={onClose} data64={data64} isModal={true} />;
    };
  }

  return (
    <>
      <div tabIndex={0} className={classes.overlay} onClick={onClose}></div>
      <div onKeyDown={onEscapeHandle} ref={(ref) => ref?.focus()} tabIndex={0} className={classes.WebViewer}>
        {isLoading
          ? <Spinner />
          : ( errorMessage
            ? <Error msg={errorMessage} onClose={onClose} />
            : chooseViewer()
          )
        }
      </div>
    </>
  
  );
};

export default memo(WebViewer);

import React from 'react';
import classes from '../SingingTab.module.scss'
import { WarningOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const NotPartisipant = () => {
  const { t } = useTranslation();

  return (
    <div className={classes.notPartisipant}>
      <WarningOutlined style={{color: 'orange'}}/>
      <span className={classes.text}>{t('Documents.details.signing.notPartisipant')}</span>
    </div>
  );
}

export default NotPartisipant;

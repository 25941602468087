import React from 'react'
import { useAppSelector } from 'store/hook';
import classes from '../FileDetailsModal.module.scss';
import Spinner from 'components/Spinner/Spinner';
import { useTranslation } from 'react-i18next';
import AgreementButtons from './AgreementButtons';

export default function AgreementActions({
  onDeclineOpen,
  documentPermissions,
}: {
  documentPermissions: string[];
  onDeclineOpen: () => void;
}) {
  const { isArgeementsLoading, myAgreement, agreements } = useAppSelector((store) => store.agreements);
  const { selectedDocument, selectedFolder } = useAppSelector((state) => state.documents);

  const { t } = useTranslation();

  return (
    <div>{
      isArgeementsLoading
        ? (
          <div className={classes.argeementsLoading}>
            <div>{t('Documents.details.agreement.loading')}</div>
            <div><Spinner size={24} /></div>
          </div> 
        )
        : (
          agreements[0]
            ? (
              myAgreement
              ? (
                <AgreementButtons
                  t={t}
                  documentPermissions={documentPermissions}
                  selectedDocument={selectedDocument}
                  selectedFolder={selectedFolder}
                  onDeclineOpen={onDeclineOpen}
                />
              )
              : <div className={classes.noAgreement}>{t('Documents.details.agreement.noYourAgreement')}</div>
            )
            : (
              <div className={classes.noAgreement}>{t('Documents.details.agreement.noAgreement')}</div>
            )
        )
    }</div>
  )
}

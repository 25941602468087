import React from 'react';
import classes from '../SingingTab.module.scss'
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

type CheckboxWithRulesProps = {
  openRulesModal: (e: React.MouseEvent<HTMLSpanElement>) => void;
  hasSign?: Boolean;
}

const CheckboxWithRules = ({
  openRulesModal,
  hasSign
}: CheckboxWithRulesProps) => {
  const { t } = useTranslation();

  return ( 
    <div onClick={openRulesModal} className={classes.checkBox}>
      <Checkbox
        checked={Boolean(hasSign)}
        disabled={Boolean(hasSign)}
      />
      <div>
        {t('Documents.details.signing.checkboxBefore')}{' '}
        <span className={classes.link}>
        {t('Documents.details.signing.checkboxLink')}
        </span>
        {' '}{t('Documents.details.signing.checkboxAfter')}
      </div>
    </div>
  );
}

export default CheckboxWithRules;

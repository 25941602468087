import { Form, Input, message, Modal } from 'antd';
import React from 'react';
import classes from './SupportModal.module.scss';
import { useTranslation } from 'react-i18next';
import TextArea from 'antd/es/input/TextArea';
import { useAppSelector } from 'store/hook';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import auth from 'api/auth';

type SupportModalProps = {
  isSupportModalOpen?: boolean;
  onCancel: () => void;
};

const SupportModal = ({
  isSupportModalOpen,
  onCancel,
}: SupportModalProps) => {
  // const [fileList, setFileList] = React.useState<UploadFile[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { userData } = useAppSelector(store => store.userData);

  const [form] = Form.useForm();
  const { t } = useTranslation();

  React.useEffect(() => {
    form.setFieldsValue({ email: userData?.email});
  }, [userData])

  const onFinish = async (values: any) => {
    setIsLoading(true);
    try {
      // const formData = new FormData();
      // formData.append('email', values.email);
      // formData.append('text', values.text);
      // fileList[0] && formData.append('file', fileList[0] as RcFile);

      await auth.sendMessageToSupport(values);
      message.success(t('Header.supportModal.successSend'), 10);
      onCancel()
    } catch (err) {
      message.error(t('Header.supportModal.errorSend'), 10);
    } finally {
      setIsLoading(false);
    }
  };

  // const uploadProps: UploadProps = {
  //   onRemove: (file) => {
  //     const index = fileList.indexOf(file);
  //     const newFileList = fileList.slice();
  //     newFileList.splice(index, 1);
  //     setFileList(newFileList);
  //   },
  //   beforeUpload: (file) => {
  //     setFileList([...fileList, file]);

  //     return false;
  //   },
  //   fileList,
  // };

  return (
    <Modal
      className={classes.modal}
      width={'60%'}
      onCancel={() => onCancel()}
      title={t('Header.supportModal.title')}
      open={isSupportModalOpen}
      centered={true}
      onOk={form.submit}
      okButtonProps={{loading: isLoading}}
      okText={t('Header.supportModal.okText')}
      styles={{
        body: { padding: '20px 20px 10px 20px' },
      }}
    >
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Form.Item
          name='email'
          label={t('Header.supportModal.emailLabel')}
          rules={[
            { whitespace: true },
            { required: true },
            { type: 'email' }
          ]}
        >
          <Input
            disabled={Boolean(userData?.email) || isLoading}
            placeholder={t('Header.supportModal.emailPlaceholder')}
          />
        </Form.Item>
        <Form.Item
          name='text'
          label={t('Header.supportModal.textLabel')}
          rules={[{ required: true }]}
        >
          <TextArea
            disabled={isLoading}
            autoSize={{minRows: 6, maxRows: 12}}
            className={classes.textarea}
            placeholder={t('Header.supportModal.textPlaceholder')}
          />
        </Form.Item>

        {/* <Form.Item
          name='file'
          label={'Прикрепить файл'}
        >
          <Upload {...uploadProps}>
            {!fileList[0] && <Button icon={<UploadOutlined />}>Select File</Button>}
          </Upload>
        </Form.Item> */}
      </Form>
      {isLoading && <UploadingComponent />}
    </Modal>
  );
}

export default SupportModal;

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import dayjs from 'dayjs'

const fetchAllNotes = createAsyncThunk('notes/fetchAll', async (payload: string, thunkApi) => {
  try {
    const response = await api.getDocumentNotes(payload);
    const offset = new Date().getTimezoneOffset() * 60 * 1000;    
    return response.data.map((note, i) => {
      const inSeconds = dayjs(note.timestamp, 'DD.MM.YYYY, hh:mm:ss').valueOf();      
      const rightTime = dayjs(inSeconds - offset);      
      return {
        ...note,
        timestamp: rightTime.format('DD.MM.YYYY, HH:mm:ss'),
        timestamp_in_seconds: inSeconds
      }
    }).sort((a,b) => a.timestamp_in_seconds - b.timestamp_in_seconds);
  } catch (err) {    
    return thunkApi.rejectWithValue(`Failed to load notes of №${payload} document`);
  }
});

export { fetchAllNotes };

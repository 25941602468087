
export const scheduleColumn = [
  {
    title: 'Event type',
    dataIndex: 'name',
    key: 'name',
    width: '15%',
  },
  {
    title: 'Start time',
    dataIndex: 'start_time',
    key: 'start_time',
    width: '20%',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '13%',
  },
  {
    title: 'Link',
    dataIndex: 'link',
    key: 'link',
    width: '15%',
  },
  {
    title: 'Member',
    dataIndex: 'invitee_name',
    key: 'invitee_name',
    width: '12%',
  },
  {
    title: 'Email',
    dataIndex: 'invitee_email',
    key: 'invitee_email',
    width: '15%',
  },
];

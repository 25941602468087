import { IOrganization } from 'interfaces';

export const createLogChangeOrg = (before: IOrganization, after: IOrganization) => {
  let details: any = {
    before: {},
    after: {},
  };

  for (let key in after) {
    if (after[key] !== before[key]) {
      details.before[`organization_${key}`] = before[key];
      details.after[`organization_${key}`] = after[key]
    }
  };

  return details;
}

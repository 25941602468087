import React from 'react';
import { Table } from 'antd';
import { useAppSelector } from 'store/hook';
import classes from './PermissionsTable.module.scss';
import { getPermissionsColumns } from './columns/permissionsColumns';
import TableLoader from 'components/Sceletons/TableLoader';
import { IColumn, IDocument, IFolder } from 'interfaces';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import ExistingGroups from 'components/PermissionsTable/ExistingGroup/ExistingGroup';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';

type PermissionsTableProps = {
  dataWithRootFolder: IFolder[];
  setDataWithRootFolder: React.Dispatch<React.SetStateAction<IFolder[]>>;
  heightTable?: string;
  scrollTable?: string;
  scrollLoader?: string;
  parent?: string;
  choosenGroup?: string | null;
  setChoosenGroup?: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function PermissionsTable({dataWithRootFolder, setDataWithRootFolder, heightTable, scrollTable, scrollLoader, parent, choosenGroup, setChoosenGroup}: PermissionsTableProps) {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const { isGroupLoading } = useAppSelector((state) => state.dataUsers);
  const { isLoadingPermissions, loadingFolders } = useAppSelector((state) => state.permissions);
  
  const { t } = useTranslation();
  
  const isLoadPermissionsIcons = isLoadingPermissions || isGroupLoading;

  React.useEffect(()=> {
    const permissionsColumns = getPermissionsColumns(t);
    const customColumns = permissionsColumns.map((col: IColumn) => ({
      ...col, 
      title: (col.key === 'name' && parent === 'assignPermissions') 
        ? <ExistingGroups choosenGroup={choosenGroup} setChoosenGroup={setChoosenGroup}/> 
        : col.title,
      render: (value: string, info: IFolder | IDocument) => renderCell({value, info, col}),
    }))
    setModifiedColumns(customColumns);
  },[isLoadingPermissions, isGroupLoading]);

  const onPermissionClick = (info: IFolder, colKey: string) => {
    if (colKey === 'none_column') {
      setDataWithRootFolder((prev: IFolder[]) => prev.map(folder => folder.key === info.key 
        ? {
          ...folder,
          permissions: [],
          children: folder.children ? folder.children.map(document => ({...document, permissions: []})) : null
        }
        : folder
      ));
    } else {
      setDataWithRootFolder((prev: IFolder[]) => prev.map(folder => folder.key === info.key 
        ? folder.permissions?.includes(colKey)
          ? {
            ...folder,
            permissions: (folder.permissions as string[])?.filter((permission: string) => permission !== colKey),
            children: folder.children 
              ? folder.children.map(document => ({...document, permissions: (folder.permissions as string[])?.filter((permission: string) => permission !== colKey)})) 
              : null
          }
          : {
            ...folder,
            permissions: [...(folder.permissions as string[]), colKey],
            children: folder.children
              ? folder.children.map(document => ({...document, permissions: [...(folder.permissions as string[]), colKey]}))
              : null
          }
        : folder
      ));
    }
  }
  
  const renderCell = (props: { value: string, info: IFolder | IDocument, col: IColumn}) => {
    const { value, info, col } = props;
    const icon = availableIconFormat.includes(info.type!) ? info.type : 'default';

    const isClickable = parent === 'assignPermissions' ? true : info.type?.includes('folder') 
      && info.can_edit_permissions;      

    if (col.key === 'name') {
      return info.key === 'rootFolder'
        ? <div className={classes.rootFolder}>{documnentIcons[icon as keyof typeof documnentIcons]} {value}</div>
        : <div>{documnentIcons[icon as keyof typeof documnentIcons]} {value}</div>;
    }    

    if (isLoadPermissionsIcons) return <div className={classes.iconWrap}> <Spinner size={28} /> </div>

    if (col.key === 'none_column') {
            
      return (
        <div
          onClick={() => onPermissionClick(info, col.key)}
          className={cn(classes.iconWrap, isClickable ? classes.hovered : 'nonHovered' )}
        >
          <CloseOutlined 
            className={classes.icon} 
            style={{
              color: info.type?.includes('folder')
                ? (info.permissions?.length === 0 ? 'red' : 'lightgrey' )
                : (info.permissions?.length === 0 ? 'grey' : 'transparent' )
            }}
          />
        </div>
      )
    }
   
    return (
      <div
        onClick={() => isClickable && onPermissionClick(info, col.key)}
        className={cn(classes.iconWrap, isClickable ? classes.hovered : 'nonHovered' )}
      >
        <CheckOutlined 
          className={classes.icon}
          style={{
            color: info.type?.includes('folder')
              ? (info.permissions?.includes(col.key) ? (isClickable ? 'green' : 'grey') : 'lightgray' )
              : (info.permissions?.includes(col.key) ? 'grey' : 'transparent' )
          }}
        />
      </div>
    );
  }

  if (loadingFolders) {   
    return <TableLoader height={scrollLoader}/>
  };  
  
  return (
    <div
      className={classes.tableArea}
      style={{ maxHeight: heightTable }}
    >
      <Table
        style={{backgroundColor: 'white'}}
        dataSource={dataWithRootFolder}
        columns={modifiedColumns}
        pagination={false}
        scroll={{y: scrollTable, x: '900px'}}
        size='large'
        rowClassName={classes.permisssionsRow}
      />
    </div>
  )
}

import React from 'react';
import classes from './UsersTab.module.scss';
import api from 'api';
import Spinner from 'components/Spinner/Spinner'
import { Button, Collapse, Dropdown, MenuProps, Modal } from 'antd';
import { StepBackwardOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { defaultGroups, fetchUserLogs } from 'store/reducers/usersGroupCreator';
import { setMoreInfoHidden } from 'store/slices/windowStateSlice';
import PersonInformation from 'components/SideBar/UsersTab/Settings/PersonInformation';
import GroupEditing from 'components/SideBar/UsersTab/Settings/GroupEditing';
import TimeLine from 'components/SidersTimeline/TimeLine';
import { setGroupChoosen, setUserChoosen } from 'store/slices/dataUsersSlice';

type Props = {
  currentSegment: 'main' | 'room'
}

const MoreInfoComponent = ({ currentSegment }: Props) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedService, setSelectedService] = React.useState('');
  const [services, setServices] = React.useState<MenuProps['items']>([{ key: 'loading', label: (<Spinner />), disabled: true}])
  const { isMoreInfoHide } = useAppSelector((state) => state.windowState);
  const { selectedGroup, selectedUser, userLogs, isLoadingUserLogs, errorUserLogs } = useAppSelector((state) => state.dataUsers);
  const { userPermissions } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const canViewLogs = userPermissions.can_view_logs

  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en'

  React.useEffect(() => {
    const getServices = async () => {
      const organizations = await api.getUserOrganizations();
      const services: MenuProps['items'] = [];      

      const organization_services = await api.getOrganizationServices(organizations.data.id)
      await organization_services.data.forEach((service: any) => {
        services.push({
          key: service.id,
          label: (
            <div className={classes.serviceWrapper}>
              <div className={classes.serviceTitles}>
                <div className={classes.serviceTitle}>{service.name}</div>
                <div className={classes.serviceDescription}>{organizations.data.name}</div>
              </div>
              <div className={classes.serviceCost}>{`${service.cost}$`}</div>
            </div>
          )
        })
      });
      
      setServices(services)
    }

    currentSegment !== 'main' && getServices()
  }, []);

  React.useEffect(() => {    
    currentSegment !== 'main' && dataRoom && selectedUser && canViewLogs && dispatch(fetchUserLogs({user_id: selectedUser.id, room_id: dataRoom.id, locale}));
  }, [dataRoom, selectedUser]);

  const onCloseClick = () => {
    dispatch(setMoreInfoHidden(true));
    dispatch(setUserChoosen(null));
    dispatch(setGroupChoosen(null));
  };

  const showModal: MenuProps['onClick'] = ({ key }) => {
    setIsModalOpen(true);
    setSelectedService(key)
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedService('')
  };

  const onClickBill = async () => {
    setIsModalOpen(false);
    const user = selectedUser?.id
    const service = selectedService
    await api.createUserBill({ organization_service_id: service, user_id: user })
  }

  const usersItems = [
    {
      key: 'information',
      label: t('Users.HelperMenu.personalInfo'),
      children: selectedUser ? <PersonInformation currentSegment={currentSegment} /> : null,
    },
  ]

  const groupItem = [
    {
      key: 'information',
      label: t('Users.HelperMenu.groupInfo'),
      children: selectedGroup ? <GroupEditing /> : null,
    },
  ]

  currentSegment !== 'main' && canViewLogs && usersItems.push(
    {
      key: 'timeline',
      label: t('Users.HelperMenu.timeline'),
      children: selectedUser ? <TimeLine logs={userLogs} logsError={errorUserLogs} loading={isLoadingUserLogs} /> : null,
    }
  );

  const collapseItems = selectedUser ? usersItems : groupItem;
  const nameOfEntity = selectedGroup
    ? (
      defaultGroups.includes(selectedGroup.name!)
        ? t(`Users.table.${selectedGroup.name}`)
        : selectedGroup.name
    )
    : (
      selectedUser
        ? `${selectedUser.first_name} ${selectedUser.last_name}`
        : ''
    );

  return (
    <div className={classes.aside}>
      <div className={classes.title}>{nameOfEntity}</div>
      <Button
        className={`${!isMoreInfoHide && classes.aside_close}`}
        onClick={onCloseClick}
        icon={<StepBackwardOutlined style={{ fontSize: 18, color: 'grey' }} />}
      />
     {currentSegment !== 'main' && (
        <Dropdown trigger={['click']} menu={{ items: services, onClick: showModal }} placement='bottomLeft'>
          <Button disabled={false} type='primary' className={classes.billButton}>
            {t('Users.HelperMenu.bill')}
          </Button>
        </Dropdown>
      )}
      <Modal
        centered
        title={t('Users.HelperMenu.billModalTitle')}
        open={isModalOpen}
        onOk={onClickBill}
        onCancel={handleCancel}
        cancelText={t('Users.HelperMenu.cancel')}
        okText={t('Users.HelperMenu.ok')}
      />
      <Collapse
        defaultActiveKey={'information'}
        accordion
        className={classes.accordion}
        // expandIconPosition={'end'}
        items={collapseItems}
      />
    </div>
  );
}

export default MoreInfoComponent;

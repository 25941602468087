import { CSSProperties, memo, useEffect, useRef } from 'react';
import classes from '../Viewer.module.scss';
import * as pdfjs from 'pdfjs-dist';

type PdfpageProps = {
  page: pdfjs.PDFPageProxy;
  scale: number;
  style: CSSProperties;
};

const PdfPage = memo<PdfpageProps>((props) => {
  const { page, scale } = props;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const textLayerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!page) return;

    const viewport = page.getViewport({ scale });
    textLayerRef.current?.style.setProperty('--scale-factor', `${scale}`);

    // Prepare canvas using PDF page dimensions
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext('2d') as CanvasRenderingContext2D;

      // canvas.height = viewport.height;
      // canvas.width = viewport.width;

      const resolution = 2; // for example

      canvas.height = resolution * viewport.height; //actual size
      canvas.width  = resolution * viewport.width;

      canvas.style.height = viewport.height + 'px'; //showing size will be smaller size
      canvas.style.width = viewport.width + 'px';

      context.scale(resolution, resolution); // Better to use context.scale than transform

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: context,
        viewport: viewport,
        // transform: [resolution, 0, 0, resolution, 0, 0] // instead use context.scale(resolution, resolution)
      };
      const renderTask = page.render(renderContext);
      renderTask.promise.then(function () {
        // console.log('Page rendered');
      });

      page.getTextContent().then((textContent) => {
        // console.log(textContent);
        if (!textLayerRef.current) {
          return;
        }

        // Pass the data to the method for rendering of text over the pdf canvas.
        pdfjs.renderTextLayer({
          container: textLayerRef.current,
          viewport: viewport,
          textDivs: [],
          textContentSource: textContent,
        });
      });
    }

    return () => {
      canvasRef.current = null;
    };
  }, [page, scale]);

  return (
    <div className={classes.PdfPage}>
      <canvas ref={canvasRef} />
      <div ref={textLayerRef} className={classes.PdfPage__textLayer} />
    </div>
  );
});

export default PdfPage;

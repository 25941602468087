
import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';

const RowLoader = ({ height, width, marginTop, marginBottom, padding, borderRadius, ...props }: any) => { 
  const currentHeight = height ? String(height).replace('px', '') : 24;  
  const currentWidth = width ? String(width).replace('px', '') : 140;
  const margintop = marginTop || 0;
  const marginbottom = marginBottom || 0;
  const currentPadding = padding || '12px 0 0 0';
  const borderradius = borderRadius || 0;
  const { t } = useTranslation();
  
  return (
  <div style={{ padding: currentPadding, overflow: 'hidden', height: currentHeight, marginTop: margintop, marginBottom: marginbottom, borderRadius: borderradius}}>
    <ContentLoader 
      speed={2}
      width={currentWidth}
      height={currentHeight}
      title={t('Documents.table.loader')}
      viewBox={`0 0 ${currentWidth} ${currentHeight}`}
      {...props}
    >
      <rect x='0' y='0' rx='3' ry='3' width={currentWidth} height={currentHeight} />
    </ContentLoader>
  </div>
  )
}
export default RowLoader

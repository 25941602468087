import { EllipsisOutlined, FileDoneOutlined, FileExcelOutlined, FilePdfOutlined, ReloadOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, MenuProps, Tabs, TabsProps, Typography, UploadFile, UploadProps, message } from 'antd';
import { RcFile } from 'antd/es/upload';
import { DocumentActions, DocumentInfo, DocumentResearchUpload } from 'components/DocumentsReport';
import { IDocData, ResearchStatusType } from 'interfaces';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from 'api';
import classes from './Report.module.scss';
import prepareDocumentInfo from 'components/DocumentsReport/helpers/prepareDocumentInfo';
import { getCompareColumns } from 'components/DocumentsReport/helpers/columns';
import { ICompareDataType } from 'components/DocumentsReport/interfaces';
import { pdfHelper } from 'helpers/pdfHelper';
import { exportHelper } from 'helpers/excelHelper';
import { useAppSelector } from 'store/hook';

export default function Report() {
  const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);
  const [reportStatus, setReportStatus] = useState<ResearchStatusType>('upload');
  const [documentID, setDocumentID] = useState<string>('');
  const [documentData, setDocumentData] = useState<IDocData | null>(null);
  const [isDocumentDataLaoding, setIsDocumentDataLoading] = useState(false);

  const [downloadProcess, setDownloadProcess] = useState<string[]>([]);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const { userData } = useAppSelector((state) => state.userData);

  const { t } = useTranslation();

  const tabsItems: TabsProps['items'] = [
    {
      label: (
        <div className={classes.tabs_tab}>
          <FileDoneOutlined /> {t('Report.tabs.info')}
        </div>
      ),
      key: 'DocumentInfo',
      children: <DocumentInfo documentData={documentData!} />,
    },
    {
      label: (
        <div className={classes.tabs_tab}>
          <UnorderedListOutlined /> {t('Report.tabs.action')}
        </div>
      ),
      key: 'DocumentActions',
      children: <DocumentActions document_id={documentID} />,
    },
  ];

  const handleUpload = async () => {
    try {
      setIsDocumentDataLoading(true);
      const formData = new FormData();
      formData.append('document', uploadFiles[0] as RcFile);
      const response = await api.researchDocument(formData);
      const infoForTables = prepareDocumentInfo(response.data, t, userData!);

      setDocumentID(response.data?.previous.document_id);
      setDocumentData(infoForTables);
      setReportStatus('result');
    } catch (Err) {
      message.error(t('Report.errors.dontExist'));
    } finally {
      setIsDocumentDataLoading(false);
    }
  };

  const onRestart = () => {
    setReportStatus('upload');
    setUploadFiles([]);
  };

  const uploadProps: UploadProps = {
    fileList: uploadFiles,
    multiple: false,
    beforeUpload: (file, fileList) => {
      const extension = file.name.match(/\.(\w+)$/)![1].toLowerCase();      
      if(extension === 'exe' || extension === 'bin'){
        message.error(t('Documents.error.notSupported'));
        return false;
      };
      setUploadFiles([file]);
      return false;
    },
    disabled: isDocumentDataLaoding,
  };

  const exportExcel = async () => {
    setDownloadProcess(prev => [...prev, 'exportExcel']);
    try {
      const reportColumns = getCompareColumns(t);
      const allData = documentData!.infoData.concat(documentData!.infoCompare);
      
      exportHelper(allData, reportColumns, `${t('Report.report.title')}: ${uploadFiles[0].name}`)
    } catch (e) {
      message.error(t('Report.errors.exportExcel'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el !== 'exportExcel'));
    }
  };

  const exportPdf = () => {
    setDownloadProcess(prev => [...prev, 'exportPdf']);
    try {
      const reportColumns = getCompareColumns(t);
      const allData = documentData!.infoData.concat(documentData!.infoCompare);
  
      pdfHelper<ICompareDataType>(
        allData,
        reportColumns,
        `${t('Report.report.title')}: ${uploadFiles[0].name}`,
        'export'
      )
    } catch (e) {
      message.error(t('Report.errors.exportPdf'));
    } finally {
      setDownloadProcess(downloadProcess.filter(el => el !== 'exportPdf'));
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'download',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('exportPdf')}
          onClick={exportPdf}
          icon={<FilePdfOutlined />}
          className={classes.liButton}
          disabled={Boolean(downloadProcess[0])}
        >
          {t('Report.report.download')} PDF
        </Button>
      ),
    },
    {
      key: 'print',
      className: classes.liItem,
      label: (
        <Button
          loading={downloadProcess.includes('exportExcel')}
          onClick={exportExcel}
          icon={<FileExcelOutlined />}
          className={classes.liButton}
          disabled={Boolean(downloadProcess[0])}
        >
          {t('Report.report.download')} XSLX
        </Button>
      ),
    },
  ];

  const onDelete = () => setUploadFiles([]);

  const reportCases = {
    upload: (
      <DocumentResearchUpload
        t={t}
        uploadFiles={uploadFiles}
        onDelete={onDelete}
        uploadProps={uploadProps}
        handleUpload={handleUpload}
      />
    ),
    result: (
      <Layout className={classes.container}>
        <Tabs
          tabBarExtraContent={<>
            <Button
              icon={<ReloadOutlined />}
              className={classes.resetButton}
              onClick={onRestart}
            >
              {t('Report.tabs.extra')}
            </Button>
            <Dropdown
              open={isOpenDropdown || Boolean(downloadProcess[0])}
              onOpenChange={(open) => setIsOpenDropdown(open)}
              menu={{ items }}
              placement='bottomLeft'
            >
              <Button icon={<EllipsisOutlined />} />
            </Dropdown>
          
          </>}
          items={tabsItems}
          className={classes.tabs}
        />
      </Layout>
    ),
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainSpace}>
        <Typography.Title level={3}>{t('sidebar.report')}</Typography.Title>

        {reportCases[reportStatus]}
      </div>
    </div>
  );
}

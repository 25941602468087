
export const billingColumn = [
    {
        title: 'Service name',
        dataIndex: 'service',
        key: 'name',
        width: '20%',
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        width: '25%',
    },
    {
        title: 'Cost',
        dataIndex: 'service',
        key: 'cost',
        width: '15%',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '20%',
    },
    {
        title: 'Payment Link',
        dataIndex: 'user_link',
        key: 'user_link',
        width: '20%',
    },
  ];
  
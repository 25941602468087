const checkIsSamePath = (
  deepPathList: string[],
  childrenPathList: string,
  withFile?: boolean
): boolean => {
  if (withFile) {
    const deepPath = deepPathList.join('/');
    const childrenPath = childrenPathList.split("/").slice(0, childrenPathList.length).join('/');

    return deepPath.startsWith(childrenPath);
  }

  const deepPath = deepPathList.join('/');
  const childrenPath = childrenPathList;

  return deepPath.startsWith(childrenPath);
};

export default checkIsSamePath;

import { Empty } from 'antd'
import React from 'react'
import classes from './RoomsTab.module.scss';
import { useTranslation } from 'react-i18next';

type Props = {}

export default function EmptyRoom({}: Props) {
  const { t } = useTranslation();

  return (
    <Empty
      className={classes.emptyCreate}
      description={
        <span>
          {t('Documents.management.noAvailable')}.
          <br />  
          {t('Rooms.prompt+')}
          <br />
        </span>
      }
    />
  )
}

import { Layout, Typography, Form, Input, Button, Select, message, Tooltip } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { CloseOutlined, LockFilled } from '@ant-design/icons';
import { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import auth from 'api/auth';
import classes from './Reset.module.scss';
import PasswordRule from 'routes/SignUp/passwordRule/PasswordRule';

const { Title, Paragraph } = Typography;

export default function ResetPassword() {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordTooltipOpen, setpasswordTooltipOpen] = useState(false);
  const [passwordRules, setPasswordRules] = useState<Record<string, 'error' | 'success'>>({
    min: 'error',
    latin: 'error',
    special: 'error',
    number: 'error',
    letter: 'error',
  });

  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();
  const defaultLanguage = localStorage.getItem('language') || 'en';
  const passwordRuleKeys = Object.keys(passwordRules);


  const onResetHandle = async (values: { password: string; confirm: string }) => {
    try {
      setIsLoading(true);
      const user_idBasex64 = params.email?.replace('user=', '')!;
      const user_id = atob(user_idBasex64);
      await auth.restorePassword({ user_id, password: values.password });
      message.success(t('resetPassword.success'));
      navigate('/login');
    } catch (err) {
      message.error(t('resetPassword.error'));
    } finally {
      setIsLoading(false);
    }
  };

  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const updatedRules: Record<string, 'error' | 'success'> = {
      min: value.length >= 8 ? 'success' : 'error',
      latin: /[a-zA-Z]/.test(value) ? 'success' : 'error',
      special: /[^\w\d\sа-яА-ЯёЁ]/.test(value) ? 'success' : 'error',
      number: /\d/.test(value) ? 'success' : 'error',
      //@ts-ignore
      letter: /\p{L}/u.test(value) ? 'success' : 'error',
    };

    setPasswordRules(updatedRules);
  };

  const onPasswordTooltipClose = () => setpasswordTooltipOpen(false);
  const onPasswordTooltipOpen = () => setpasswordTooltipOpen(true);

  const languagesValues = [
    {
      value: 'en',
      label: <Trans i18nKey='languages.en' />,
    },
    {
      value: 'ru',
      label: <Trans i18nKey='languages.ru' />,
    },
    {
      value: 'kg',
      label: <Trans i18nKey='languages.kg' />,
    },
    {
      value: 'es',
      label: <Trans i18nKey='languages.es' />,
    },
  ];

  return (
    <div className={classes.mainWrap}>
      <Layout className={classes.resetElem}>
        <Select
          className={classes.localizationSelect}
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />
        <div className={classes.resetCard}>
          <Typography>
            <Title level={2}>{t('resetPassword.title')}</Title>
            <Paragraph className={classes.subTitle}>{t('login.info')}</Paragraph>
          </Typography>
          <Form className={classes.resetForm} onFinish={onResetHandle} form={form}>
          <Tooltip
              color='#fff'
              trigger='focus'
              overlayClassName={classes.overlay}
              open={passwordTooltipOpen}
              title={
                <div className={classes.overlay_container}>
                  <CloseOutlined className={classes.overlay_close} onClick={onPasswordTooltipClose} />
                  {passwordRuleKeys.map((key) => (
                    <PasswordRule key={key} rule={key} type={passwordRules[key]} />
                  ))}
                </div>
              }
            >
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: `${t('personalInfo.input')}"${t('personalInfo.password')}"`,
                  },
                  {
                    pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W).{8,}$/,
                    message: '',
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('personalInfo.password')}
                  size='large'
                  prefix={<LockFilled className={classes.itemIcon} />}
                  className={classes.input}
                  onFocus={onPasswordTooltipOpen}
                  onBlur={onPasswordTooltipClose}
                  onChange={onPasswordChange}
                />
              </Form.Item>
            </Tooltip>

            <Form.Item
              name='confirm'
              rules={[
                { required: true, message: `${t('personalInfo.confirm')}` },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('personalInfo.confirmText')));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t('personalInfo.confirm')}
                size='large'
                prefix={<LockFilled className={classes.itemIcon} />}
                className={classes.input}
              />
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isLoading}
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {t('resetPassword.submit')}
                </Button>
              )}
            </Form.Item>
          </Form>
        </div>
      </Layout>
    </div>
  );
}

import { BellOutlined } from '@ant-design/icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Empty, Tooltip, Typography } from 'antd';
import api from 'api';
import classNames from 'classnames';
import { NotificationsModal } from 'components/Modals';
import RowLoader from 'components/Sceletons/RowLoader';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { TFunction } from 'i18next';
import { dateFormatType, INotification } from 'interfaces';
import { FC, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchRoomNotifications } from 'store/reducers/roomsCreator';
import { setIsNotificationsModalOpen, setNotifications, setSelectedNotification } from 'store/slices/dataRoomSlice';
import classes from './HeaderComponent.module.scss';

type NotificationRowProps = {
  items: INotification[];
  index: number;
  t: TFunction;
  style: any;
  onItemClick: () => void;
  dateFormat: dateFormatType;
};

function NotificationRow({ items, index, t, style, onItemClick, dateFormat }: NotificationRowProps) {
  const date = getDateWithTimeZone(items[index].created_at, dateFormat);
  return (
    <div style={style} className={classes.list_item} onClick={onItemClick}>
      <Tooltip title={t(`Header.Notification.${items[index].type}`)}>
        <Typography.Title level={5} className={classes.list_item_title}>
          <span className={classes.list_item_inner_title}>
            {!items[index].status && <FontAwesomeIcon className={classes.list_item_dot} icon={faCircle} />}{' '}
            {t(`Header.Notification.${items[index].type}`)}
          </span>
          <span className={classes.list_item_timestamp}>{date}</span>
        </Typography.Title>
      </Tooltip>
      <div className={classes.list_item_description}>
        {/* {items[index].user?.name}, {t(`Header.Notification.message.${items[index].type}`)} */}
        {items[index].message}
      </div>
    </div>
  );
}

type Props = {
  dateFormat: dateFormatType;
};

const Notifications: FC<Props> = ({ dateFormat }) => {
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isLoadingNotifications, setIsLoadingNotifications] = useState(false);
  const { notifications, isNotificationsModalOpen, selectedNotification, notificationStatus } = useAppSelector(
    (state) => state.dataRoom
  );
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const locale = i18n.language || 'en';

  const notificationRef = useRef<HTMLDivElement>(null);
  const notificationSignalRef = useRef<() => void>();

  const fetchNotifications = async (locale: string) => {
    setIsLoadingNotifications(true);
    const dispatchPromise = dispatch(fetchRoomNotifications({ locale }));
    notificationSignalRef.current = dispatchPromise.abort;
    await dispatchPromise;
    setIsLoadingNotifications(false);
  };

  useEffect(() => {
    const onOverLayoutClick = (event: globalThis.MouseEvent) => {
      const withinBoundaries = event.composedPath().includes(notificationRef.current!);
      if (!withinBoundaries) {
        setIsNotificationsOpen(false);
        notificationSignalRef.current!();
      }
    };
    if (notificationRef.current) {
      document.addEventListener('click', onOverLayoutClick);
    }

    return () => {
      notificationSignalRef.current && notificationSignalRef.current();
      document.removeEventListener('click', onOverLayoutClick);
    };
  }, [notificationRef]);

  useEffect(() => {
    fetchNotifications(locale);
  }, [locale]);

  const onNotificationOpen = () => {
    notificationSignalRef.current && notificationSignalRef.current();
    setIsNotificationsOpen((prev) => !prev);
  };

  const onViewBtnClick = () => {
    setIsLoadingNotifications(true);
    const newNotificationsIdxs: number[] = [];
    const newNotifications = notifications.filter((not, idx) => {
      if (not.status === 0) {
        newNotificationsIdxs.push(idx);
        return true;
      }

      return false;
    });

    Promise.all(
      newNotifications.map(async (not) => {
        return (await api.updateNotification({ notification_id: not.id, status: 1 })).data;
      })
    )
      .then((datas) => {
        let dataIdx = -1;
        const changedNotifications = notifications.map((not, idx) => {
          if (newNotificationsIdxs.includes(idx)) {
            dataIdx += 1;
            return datas[dataIdx];
          }

          return not;
        });

        dispatch(setNotifications(changedNotifications));
      })
      .finally(() => setIsLoadingNotifications(false));
  };

  const onCancelDetails = () => {
    dispatch(setIsNotificationsModalOpen(false));
    dispatch(setSelectedNotification(null));
  };

  const newNotificationsCount = useMemo(() => notifications.filter((not) => not.status === 0).length, [notifications]);
  const showNotificationsCount = newNotificationsCount === 0 || isLoadingNotifications || isNotificationsOpen;

  const notificationsCases = {
    pending: [1, 2, 3].map((_, idx) => <RowLoader marginTop={'20px'} width={350} height={102} key={`load-${idx}`} />),
    fulfilled: (
      <>
        <FixedSizeList
          itemCount={notifications.length}
          height={notifications.length > 3 ? 400 : notifications.length * 102}
          itemSize={102}
          itemData={notifications}
          width={'100%'}
        >
          {({ index, style }) => {
            const onItemClick = async () => {
              dispatch(setSelectedNotification(notifications[index]));
              dispatch(setIsNotificationsModalOpen(true));
              setIsNotificationsOpen(false);
              const { data } = await api.updateNotification({ notification_id: notifications[index].id, status: 1 });
              dispatch(
                setNotifications(
                  notifications.map((notification) => (notification.id === data.id ? data : notification))
                )
              );
            };

            return (
              <NotificationRow dateFormat={dateFormat} onItemClick={onItemClick} t={t} style={style} items={notifications} index={index} />
            );
          }}
        </FixedSizeList>

        <Button
          loading={isLoadingNotifications}
          type='default'
          onClick={onViewBtnClick}
          className={classes.list_button}
        >
          {t('Header.Notification.btn')}
        </Button>
      </>
    ),
    empty: <Empty description={t('Header.Notification.empty')} />,
  };

  return (
    <>
      <div className={classes.notification} ref={notificationRef}>
        <div className={classes.notificationWrap} onClick={onNotificationOpen}>
          <Badge
            dot={Boolean(newNotificationsCount)}
            count={!showNotificationsCount ? newNotificationsCount : undefined}
            overflowCount={99}
          >
            <BellOutlined className={classes.notificationBell} />
          </Badge>
        </div>

        <div className={classNames(classes.notification_container, isNotificationsOpen && classes.notification_open)}>
          {notificationsCases[notificationStatus]}
        </div>
      </div>

      {isNotificationsModalOpen && (
        <Suspense fallback={<div />}>
          <NotificationsModal
            t={t}
            selectedNotification={selectedNotification!}
            isOpen={isNotificationsModalOpen}
            onCancel={onCancelDetails}
          />
        </Suspense>
      )}
    </>
  );
};

export default Notifications;

import { TFunction } from 'i18next';

export type IGroupOrgField = {
  fieldGroupValue: string;
  fieldGroupLabel: string;
  fields: IOrgField[];
};

export type IOrgField = {
  value: string;
  label: string;
};

export enum GroupOrgFields {
  OrgData = 'org_data',
  RegData = 'reg_data',
  Structure = 'structure',
  BankDetails = 'bank_details',
  FinanceDetails = 'finance_details',
  Other = 'other'
}

export enum OrgFields {
  Description = 'description',
  Name = 'name',
  ShortName = 'short_name',
  InternationalName = 'international_name',
  EntityKind = 'entity_kind',
  Country = 'country',
  Address = 'address',
  City = 'city',
  PostalCode = 'postal_code',
  PhoneNumber = 'phone_number',
  Email = 'email',
  Link = 'link',
  RegDate = 'reg_date',
  RegNumber = 'reg_number',
  INN = 'inn',
  KPP = 'kpp',
  OKPO = 'okpo',
  OKATO = 'okato',
  VAT = 'vat',
  SICCode = 'sic_code',
  NAICS = 'naics',
  OKVED = 'okved',
  Sector = 'sector',
  NatureOfWork = 'nature_of_work',
  GoodsToExport = 'goods_to_export',
  GoodsToImport = 'goods_to_import',
  ServiceProducts = 'service_products',
  CEO = 'ceo',
  CEOExpireDate = 'ceo_expire_date',
  CEOReason = 'ceo_reason',
  CEOEmail = 'ceo_email',
  CEOPhoneNumber = 'ceo_phone_number',
  Accountant = 'accountant',
  AccountantEmail = 'accountant_email',
  AccountantPhoneNumber = 'accountant_phone_number',
  Branches = 'branches',
  BankName = 'bank_name',
  IBAN = 'iban',
  CheckingAccount = 'checking_account',
  Correspondentaccount = 'correspondent_account',
  BIC = 'biс',
  Capital = 'capital',
  Revenue = 'revenue',
  NumberOfEmployees = 'number_of_employees',
  NetProfit = 'net_profit',
  Licensies = 'licensies',
}

export const getOrganizationFields = (t: TFunction):  IGroupOrgField[] => [
  {
    fieldGroupValue: GroupOrgFields.OrgData,
    fieldGroupLabel: t(`Organizations.fields.${GroupOrgFields.OrgData}`),
    fields: [
      {
        value: OrgFields.Name,
        label:  t(`Organizations.fields.${OrgFields.Name}`),
      },
      {
        value: OrgFields.ShortName,
        label: t(`Organizations.fields.${OrgFields.ShortName}`),
      },
      {
        value: OrgFields.InternationalName,
        label: t(`Organizations.fields.${OrgFields.InternationalName}`),
      },
      {
        value: OrgFields.EntityKind,
        label: t(`Organizations.fields.${OrgFields.EntityKind}`),
      },
      {
        value: OrgFields.Country,
        label: t(`Organizations.fields.${OrgFields.Country}`),
      },
      {
        value: OrgFields.Address,
        label: t(`Organizations.fields.${OrgFields.Address}`),
      },
      {
        value: OrgFields.City,
        label: t(`Organizations.fields.${OrgFields.City}`),
      },
      {
        value: OrgFields.PostalCode,
        label: t(`Organizations.fields.${OrgFields.PostalCode}`),
      },
      {
        value: OrgFields.PhoneNumber,
        label: t(`Organizations.fields.${OrgFields.PhoneNumber}`),
      },
      {
        value: OrgFields.Email,
        label: t(`Organizations.fields.${OrgFields.Email}`),
      },
      // {
      //   value: OrgFields.Link,
      //   label: t(`Organizations.fields.${OrgFields.Link}`),
      // },
    ]
  },
  {
    fieldGroupValue: GroupOrgFields.RegData,
    fieldGroupLabel: t(`Organizations.fields.${GroupOrgFields.RegData}`),
    fields: [
      {
        value: OrgFields.RegDate,
        label: t(`Organizations.fields.${OrgFields.RegDate}`),
      },
      {
        value: OrgFields.RegNumber,
        label: t(`Organizations.fields.${OrgFields.RegNumber}`),
      },
      {
        value: OrgFields.INN,
        label: t(`Organizations.fields.${OrgFields.INN}`),
      },
      {
        value: OrgFields.KPP,
        label: t(`Organizations.fields.${OrgFields.KPP}`),
      },
      {
        value: OrgFields.OKPO,
        label: t(`Organizations.fields.${OrgFields.OKPO}`),
      },
      {
        value: OrgFields.OKATO,
        label: t(`Organizations.fields.${OrgFields.OKATO}`),
      },
      {
        value: OrgFields.VAT,
        label: t(`Organizations.fields.${OrgFields.VAT}`),
      },
      {
        value: OrgFields.SICCode,
        label: t(`Organizations.fields.${OrgFields.SICCode}`),
      },
      {
        value: OrgFields.NAICS,
        label: t(`Organizations.fields.${OrgFields.NAICS}`),
      },
      {
        value: OrgFields.OKVED,
        label: t(`Organizations.fields.${OrgFields.OKVED}`),
      },
      {
        value: OrgFields.Sector,
        label: t(`Organizations.fields.${OrgFields.Sector}`),
      },
      {
        value: OrgFields.NatureOfWork,
        label: t(`Organizations.fields.${OrgFields.NatureOfWork}`),
      },
      {
        value: OrgFields.GoodsToExport,
        label: t(`Organizations.fields.${OrgFields.GoodsToExport}`),
      },
      {
        value: OrgFields.GoodsToImport,
        label: t(`Organizations.fields.${OrgFields.GoodsToImport}`),
      },
      {
        value: OrgFields.ServiceProducts,
        label: t(`Organizations.fields.${OrgFields.ServiceProducts}`),
      },
    ]
  },
  {
    fieldGroupValue: GroupOrgFields.Structure,
    fieldGroupLabel: t(`Organizations.fields.${GroupOrgFields.Structure}`),
    fields: [
      {
        value: OrgFields.CEO,
        label: t(`Organizations.fields.${OrgFields.CEO}`),
      },
      {
        value: OrgFields.CEOExpireDate,
        label: t(`Organizations.fields.${OrgFields.CEOExpireDate}`),
      },
      {
        value: OrgFields.CEOReason,
        label: t(`Organizations.fields.${OrgFields.CEOReason}`),
      },
      {
        value: OrgFields.CEOEmail,
        label: t(`Organizations.fields.${OrgFields.CEOEmail}`),
      },
      {
        value: OrgFields.CEOPhoneNumber,
        label: t(`Organizations.fields.${OrgFields.CEOPhoneNumber}`),
      },
      {
        value: OrgFields.Accountant,
        label: t(`Organizations.fields.${OrgFields.Accountant}`),
      },
      {
        value: OrgFields.AccountantEmail,
        label: t(`Organizations.fields.${OrgFields.AccountantEmail}`),
      },
      {
        value: OrgFields.AccountantPhoneNumber,
        label: t(`Organizations.fields.${OrgFields.AccountantPhoneNumber}`),
      },
      {
        value: OrgFields.Branches,
        label: t(`Organizations.fields.${OrgFields.Branches}`),
      }
    ]
  },
  {
    fieldGroupValue: GroupOrgFields.BankDetails,
    fieldGroupLabel: t(`Organizations.fields.${GroupOrgFields.BankDetails}`),
    fields: [
      {
        value: OrgFields.BankName,
        label: t(`Organizations.fields.${OrgFields.BankName}`),
      },
      {
        value: OrgFields.IBAN,
        label: t(`Organizations.fields.${OrgFields.IBAN}`),
      },
      {
        value: OrgFields.CheckingAccount,
        label: t(`Organizations.fields.${OrgFields.CheckingAccount}`),
      },
      {
        value: OrgFields.Correspondentaccount,
        label: t(`Organizations.fields.${OrgFields.Correspondentaccount}`),
      },
      {
        value: OrgFields.BIC,
        label: t(`Organizations.fields.${OrgFields.BIC}`),
      }
    ]
  },

  {
    fieldGroupValue: GroupOrgFields.FinanceDetails,
    fieldGroupLabel: t(`Organizations.fields.${GroupOrgFields.FinanceDetails}`),
    fields: [
      {
        value: OrgFields.Capital,
        label: t(`Organizations.fields.${OrgFields.Capital}`),
      },
      {
        value: OrgFields.Revenue,
        label: t(`Organizations.fields.${OrgFields.Revenue}`),
      },
      {
        value: OrgFields.NumberOfEmployees,
        label: t(`Organizations.fields.${OrgFields.NumberOfEmployees}`),
      },
      {
        value: OrgFields.NetProfit,
        label: t(`Organizations.fields.${OrgFields.NetProfit}`),
      }
    ]
  },
  {
    fieldGroupValue: GroupOrgFields.Other,
    fieldGroupLabel: t(`Organizations.fields.${GroupOrgFields.Other}`),
    fields: [
      {
        value: OrgFields.Licensies,
        label: t(`Organizations.fields.${OrgFields.Licensies}`),
      },
    ]
  }
]

import api from 'api';
import convert from 'api/convert';
import { IDocumentInfo, ILocale } from 'interfaces';
import getFilenameFromResponse from './getFilenameFromResponse';

export const getPathToDocument = async (payload: IDocumentInfo & ILocale) => {
  const response = await convert.convertDocument({ ...payload, is_webview: true, locale: payload.locale });
  const filename = getFilenameFromResponse(response)  
  const fileURL = window.URL.createObjectURL(response.data as Blob);  
  return {fileURL, filename: filename === 'undefined' ? '' : filename};
};

export const getTableFile = async (payload: IDocumentInfo) => {
  const response = await api.viewFile({ ...payload, is_webview: true });
  const filename = getFilenameFromResponse(response)  
  const fileURL = new File([response.data], 'some.xlsx');
  return {fileURL, filename: filename === 'undefined' ? '' : filename};
};

export const getPathToImage = async (payload: IDocumentInfo) => {
  const response = await api.viewFile({ ...payload, is_webview: true });
  const filename = getFilenameFromResponse(response)  
  const fileURL = window.URL.createObjectURL(response.data as Blob);
  return {fileURL, filename: filename === 'undefined' ? '' : filename};
};

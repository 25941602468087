import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { FC } from 'react';

type SpinnerType = {
  size?: string | number;
  color?: string;
  width?: string;
};

const Spinner: FC<SpinnerType> = ({ size = 25, color = 'inherit', width = '100%' }) => {
  const antIcon = <LoadingOutlined style={{ fontSize: size, color }} spin />;
  return (
    <div
      style={{
        width,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Spin indicator={antIcon} />
    </div>
  );
};

export default Spinner;


import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import prepareLogs from 'helpers/prepareLogs';

interface IDocumentLogsThunk {
  document_id: string;
  locale: string;
}

export const fetchAvailableDocumentsLogs = createAsyncThunk(
  'documentsLogs/fetchAll',
  async (payload: IDocumentLogsThunk, thunkApi) => {
    try {
      const response = await api.getDocumentLogs(payload);
      const logs = response.data;
      
      return {
        logs: prepareLogs(logs),
        dampLogs: logs
      };
    } catch (err) {
      return thunkApi.rejectWithValue('Download Failed');
    }
  }
);

import { requestTemplate } from 'api';
import { IDetails, IRoomPermissions, IUserData, IUsers, IRestrictedUser } from 'interfaces';

export type NewInvitesResponse = {email: string, link: string | null}[];
type IFingerprint = {[key: string]: any};
type FingerprintResponce = {
  fingerprint_data: IFingerprint;
  id: string;
  user_id: string;
  created_at: string;
}

//USERS
const getUserInfo = () => requestTemplate.get<IUserData>('/users/me/?action=view');
const getUserPermissions = (payload: string) => requestTemplate.get<IRoomPermissions>(`/users/permissions/?room_id=${payload}`);

const uploadUserInfo = (
  payload: {userData: IUserData, details: IDetails}, room_id: string
) => requestTemplate.put<{ success: boolean; status: number}>(`/users/me/?action=update&id=${room_id}`, payload);

const getUsers = (payload: string) => requestTemplate.get<IUsers[]>(`/users/by_room/?room_id=${payload}&id=${payload}&action=view`);

const sendBrowserFingerprint = (
  payload: IFingerprint,
) => requestTemplate.post<FingerprintResponce>(`/users/fingerprint/`, payload, {
  headers: {'Accept': 'application/json', 'Content-Type': 'multipart/form-data'}
});

const setRoomStatus = (
  payload: {status: number | string, room_id: string, details: IDetails}
) => requestTemplate.post<{ message: string }>(`/users/status/?action=update&id=${payload.room_id}`, payload);

//INVITES
const getNewInvite = (payload: {emails: string[], room_id: string, group_id: string, type?: string, details: IDetails}) =>
  requestTemplate.post<NewInvitesResponse>(`/invites/invite/?action=create&id=${payload.room_id}`, payload);

const getGlobalUsers = () => requestTemplate.get<IRestrictedUser[]>('/users/global/');
const setTFAuth = (payload: boolean) => requestTemplate.put(`/users/auth/?tfa_enabled=${payload}`);

const users = {
  getUserInfo,
  getUserPermissions,
  uploadUserInfo,
  getUsers,
  sendBrowserFingerprint,
  setRoomStatus,
  getNewInvite,
  getGlobalUsers,
  setTFAuth,
};

export default users;

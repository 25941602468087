import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUserData } from "interfaces";
import { fetchProfileData } from "store/reducers/profileCreator";

type ProfileStates = {
  profile: IUserData | null;
  profileIsLoading: boolean;
  profileIsError: "";
};

const initialState: ProfileStates = {
  profile: null,
  profileIsError: "",
  profileIsLoading: false,
};

const profileSlice = createSlice({
  name: "profileData",
  initialState,
  reducers: {
    setProfileData(state, action: PayloadAction<IUserData | null>) {
      state.profile = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfileData.fulfilled, (state, action: PayloadAction<IUserData>) => {
      state.profileIsLoading = false;
      state.profileIsError = "";
      state.profile = action.payload;
    });

    builder.addCase(fetchProfileData.pending, (state) => {
      state.profileIsError = "";
      state.profileIsLoading = true;
    });

    builder.addCase(fetchProfileData.rejected, (state, action: any) => {
      state.profileIsLoading = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.profileIsError = action.payload.errorMessage
      } else {
        state.profileIsError = action.error.message
      }
    });
  },
});

const profileReducer = profileSlice.reducer;
export const { setProfileData } = profileSlice.actions;
export default profileReducer;

import { requestTemplate } from 'api';
import { IDetails, IGroup, IGroupByRole, IGroupsByRoom } from 'interfaces';

type SimpleResponse = {
  group_id?: string,
  success: boolean,
  message: string
}

//GROUPS
const addGroup = (
  payload: { details: IDetails, room_id: string, name?: string, role?: string, access?: string },
) => requestTemplate.post<SimpleResponse>(`/groups/create/?action=create&id=${payload.room_id}`, payload);

const getGroups = (payload: string) => requestTemplate.get<IGroupsByRoom[]>(`/groups/by_room/?room_id=${payload}`);

const getGroupsByRole = (
  payload: {roomId: string; role: string},
) => requestTemplate.get<IGroupByRole[]>(`/groups/by_room/?room_id=${payload.roomId}&role=${payload.role}`);

const deleteGroup = (
  payload: { group_id: string, room_id: string, details?: IDetails },
) => requestTemplate.delete<SimpleResponse>(`/groups/?action=delete&id=${payload.room_id}&group_id=${payload.group_id}`,  { data: payload });

// ЭНДПОИНТ НЕ ИСПОЛЬЗУЕТСЯ
const changeGroup = (
  payload: IGroup
) => requestTemplate.patch<SimpleResponse>(`/groups/edit/?group_id=${payload.id}`, payload);

// ЭНДПОИНТ НЕ ИСПОЛЬЗУЕТСЯ
const addUserToGroup = <T = any> (
  payload: {group_id: string, email: string, room_id: string, details: any}
) => requestTemplate.post<T>(`/groups/add_user/?action=update&id=${payload.room_id}`, payload);

const groups = {
  addGroup,
  getGroups,
  getGroupsByRole,
  deleteGroup,
  addUserToGroup,
  changeGroup,
};

export default groups;

import { io, Socket as SockerRef } from 'socket.io-client';

class Socket {
  socket: null | WebSocket;
  constructor() {
    this.socket = null;
  }

  connect(url: string) {
    if (!this.socket) {
      this.socket = new WebSocket(url);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  send(message: any) {
    if (this.socket) {
      this.socket.send(JSON.stringify(message));
    }
  }

  on(eventName: any, callback: any) {
    if (this.socket) {
      this.socket.addEventListener(eventName, callback);
    }
  }
}

class SocketIO {
  socket: null | SockerRef;
  constructor() {
    this.socket = null;
  }

  connect(url: string) {
    if (!this.socket) {
      this.socket = io(`${url}`, {
        withCredentials: true,
        path: "/ws/socket.io",
        extraHeaders: {
          'Access-Control-Allow-Origin': '*'
        }
      });
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
    }
  }

  send(senderEmit: string, message: any) {
    if (this.socket) {
      this.socket.emit(senderEmit, message);
    }
  }

  sendChatMessage(message: any) {
    if (this.socket) {
      this.socket.emit('chat_message', message);
    }
  }

  on(eventName: any, callback: any) {
    if (this.socket) {
      this.socket.on(eventName, callback);
    }
  }
}

export { Socket, SocketIO };

import { IWithWebkitPath } from 'interfaces';
import checkIsSamePath from './checkIsSamePath';

interface customFolder extends Record<string, any> {
  name: string;
  children: customFolder[];
  coordinates: number[];
  path: string;
  type: string;
}

// arrows [root array pointer, children arrays pointer]
// здесь описана функция построение папок из путей
// we cane use storage.coordinates and storage.path as id because it is unique value

function buildFoldersTree(
  fileList: IWithWebkitPath[],
  segregatedRootFolder: string[][],
  arrows: number[] = [0, 1],
  storage: customFolder = {
    name: segregatedRootFolder[0][0],
    children: [],
    type: 'folder',
    coordinates: [0, 0],
    path: segregatedRootFolder[0][0],
  }
): customFolder {
  if (arrows[0] === segregatedRootFolder.length && arrows[1] >= segregatedRootFolder[0].length) {
    return storage;
  }

  if (arrows[0] === segregatedRootFolder.length) {
    for (let key in storage.children) {
      storage.children[key] = buildFoldersTree(
        fileList,
        segregatedRootFolder,
        [0, arrows[1] + 1],
        storage.children[key] as customFolder
      );
    }

    return storage;
  }

  if (arrows[1] >= segregatedRootFolder[arrows[0]].length) {
    return buildFoldersTree(fileList, segregatedRootFolder, [arrows[0] + 1, arrows[1]], storage);
  }

  const isHaveSameChildren = storage.children?.find(
    (child) => child.name === segregatedRootFolder[arrows[0]][arrows[1]]
  );
  const isSamePath = checkIsSamePath(segregatedRootFolder[arrows[0]], storage.path);

  if (isHaveSameChildren || !isSamePath) {
    return buildFoldersTree(fileList, segregatedRootFolder, [arrows[0] + 1, arrows[1]], storage);
  }

  if (arrows[1] === segregatedRootFolder[arrows[0]].length - 1) {
    const webkitPath = segregatedRootFolder[arrows[0]].slice(0, arrows[1] + 1).join('/');

    const uploadedFile: any = {
      name: segregatedRootFolder[arrows[0]][arrows[1]],
      type: 'file',
      coordinates: arrows,
      path: webkitPath,
    };

    storage.children = [...storage.children, uploadedFile];
    return buildFoldersTree(fileList, segregatedRootFolder, [arrows[0] + 1, arrows[1]], storage);
  }

  const folder: customFolder = {
    name: segregatedRootFolder[arrows[0]][arrows[1]],
    type: 'folder',
    children: [],
    coordinates: arrows,
    path: segregatedRootFolder[arrows[0]].slice(0, arrows[1] + 1).join('/'),
  };

  storage.children = [...storage.children, folder];

  return buildFoldersTree(fileList, segregatedRootFolder, [arrows[0] + 1, arrows[1]], storage);
}

export default buildFoldersTree;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import emptyImage from '../../assets/img/org_empty.png';
import classes from './Organization.module.scss';
import { PlusOutlined } from '@ant-design/icons';


type NoOrganizationProps = {
  onAddClick: () => void;
};

const NoOrganization = ({onAddClick}: NoOrganizationProps) => {
  const { t } = useTranslation();  

  return (
    <div className={classes.emptyContainer}>
      <div>
        <img src={emptyImage} alt='No ogganization'/>
      </div>
      <div className={classes.text}>{t('Organizations.noOrganizationText')}</div>
      <Button onClick={onAddClick} icon={<PlusOutlined />} type='primary'>{t('Organizations.addButton')}</Button>
    </div>
  );
}

export default NoOrganization;

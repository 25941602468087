import React from 'react';
import { useAppSelector } from 'store/hook';
import ManagementLoader from '../../components/Sceletons/ManagementLoader';
import classes from './MainPageEmpty.module.scss'
import { useTranslation } from 'react-i18next';

export default function MainPageEmpty() {
  const { isLoadingUserData } = useAppSelector(store => store.userData);
  const { isLoadingRooms } = useAppSelector(state => state.dataRoom);
  const { t } = useTranslation();

  return (
    <div className={classes.emptyRooms}>
      {
        isLoadingUserData || isLoadingRooms 
          ? <ManagementLoader /> 
          : <div className={classes.centred}>{t('Documents.management.noAvailable')}</div>
      }
    </div>
  )
}

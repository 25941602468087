import { UploadOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Form, Space, Typography, Upload } from 'antd';
import classes from './ProfileInfo.module.scss';

const { Title, Link, Paragraph } = Typography;

const ProfileInformation = () => {
  return (
    <Card  title='Your Photo' bordered className={classes.card}>
      <Space wrap>
        <Avatar
          size={84}
          src='https://media.gq-magazine.co.uk/photos/5d13a048f7cb38e1e9112dcd/1:1/w_1169,h_1169,c_limit/image.jpg'
          className={classes.avatar}
        />
        <div className={classes.avatarItems}>
          <Title level={5}>Edit your photo</Title>
          <Paragraph>
            <Link type='secondary'>Delete</Link>{' '}
            <Link className={classes.buttons_delete}>Update</Link>
          </Paragraph>
        </div>
      </Space>

      <Form.Item>
        <br />
        <Upload.Dragger style={{ backgroundColor: '#7f56d80d' }}>
          <article className={classes.container}>
            <div>
              <div className={classes.container_circle}>
                <UploadOutlined />
              </div>
            </div>
            <span className={classes.buttons_delete}> Click to upload </span>
            or drag and drop
            <div>SVG, PNG, JPG or GIF</div> <span>(max, 800 X 800px)</span>
          </article>
        </Upload.Dragger>
      </Form.Item>

      <div className={classes.buttons}>
        <Space>
          <Button type='default'>Cancel</Button>
          <Button type='primary'>Save</Button>
        </Space>
      </div>
    </Card>
  );
};

export default ProfileInformation;

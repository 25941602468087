import { useTranslation } from 'react-i18next';
import React from 'react';
import { Button, Form, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setNotes } from 'store/slices/dataNotes';
import api from 'api';
import classes from './NotesTab.module.scss';
import { IDocument } from 'interfaces';
import { setDataDocumentTable } from 'store/slices/dataDocumentsSlice';
import TextArea from 'antd/es/input/TextArea';

type AddNotesInputProps = {
  documentId: string;
  documentName: string;
};

export default function AddNotesInput ({ documentId, documentName }: AddNotesInputProps) {
  const [form] = Form.useForm();
  const [isLaoding, setIsLoading] = React.useState<boolean>(false);
  const [noteText, setNoteText] = React.useState<string>('');

  const { notes } = useAppSelector((state) => state.notes);
  const { userData } = useAppSelector((state) => state.userData);
  const { dataDocumentTable } = useAppSelector(state => state.documents);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const addNote = async (values: { text: string }) => {
    setIsLoading(true);
    try {
      const timestamp = new Date().toLocaleString()
      const {
        data: { id },
      } = await api.createNotes({
        ...values,
        document_id: documentId,
        room_id: dataRoom?.id!,
        details: { note_text: values.text }
      });
      
      notes &&
        dispatch(setNotes([
          ...notes,
          {
            timestamp,
            author: `${userData?.first_name} ${userData?.last_name}`,
            text: values.text,
            can_share: true,
            id: id,
            visible_to: []
          },
        ]));
        message.success(`${t('Documents.success.notesAdd')} ${documentName}`);
        const changedHasNotes = dataDocumentTable.find((el) => el.id === documentId) as IDocument;
        if(changedHasNotes.has_notes) return;

        const changedData = dataDocumentTable.map((el) => el.id === documentId ? {...el, has_notes: true} : el);
        dispatch(setDataDocumentTable(changedData));
      } catch (err) {
        message.error(`${t('Documents.error.notesAdd')} ${documentName}`);
    } finally {
      setIsLoading(false);
      setNoteText('');
      form.setFieldValue('text', '')
    }
  };

  const onChangeNoteText = (event: React.ChangeEvent<HTMLTextAreaElement>) => setNoteText(event.target.value);

  return (
    <Form  layout='inline' form={form} onFinish={addNote} className={classes.addInput}>

      <Form.Item required name='text' className={classes.input}>
        <TextArea
          disabled={isLaoding}
          className={classes.textarea}
          value={noteText}
          onChange={onChangeNoteText}
          autoSize={{minRows: 4, maxRows: 4}}
          count={{
            show: true,
            max: 500,
            exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
          }}
          placeholder={t('Documents.modals.notes.placholder')}
          allowClear
        />
      </Form.Item>

      <Button htmlType='submit' type='primary' loading={isLaoding} disabled={!noteText || !noteText.trim()}>
        {t('Documents.management.add')}
      </Button>

    </Form>
  );
};

import { MailFilled } from '@ant-design/icons';
import { Button, Divider, Form, Input, Layout, Select, Typography, message } from 'antd';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './Reset.module.scss';
import auth from 'api/auth';

const { Title, Paragraph } = Typography;

export default function Reset() {
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const defaultLanguage = localStorage.getItem('language') || 'en';

  const onResetHandle = async (values: { email: string }) => {
    try {
      setIsLoading(true);
      await auth.sendEmailToRestore(values);
      message.success(t('resetPassword.successSend'));
    } catch (err) {
      message.error(t('resetPassword.failedSend'))
    } finally {
      setIsLoading(false);
    }
  };

  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  const languagesValues = [
    {
      value: 'en',
      label: <Trans i18nKey='languages.en' />,
    },
    {
      value: 'ru',
      label: <Trans i18nKey='languages.ru' />,
    },
    {
      value: 'kg',
      label: <Trans i18nKey='languages.kg' />,
    },
    {
      value: 'es',
      label: <Trans i18nKey='languages.es' />,
    },
  ];

  return (
    <div className={classes.mainWrap}>
      <Layout className={classes.resetElem}>
        <Select
          className={classes.localizationSelect}
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />
        <div className={classes.resetCard}>
          <Typography>
            <Title level={2}>{t('resetPassword.title')}</Title>
            <Paragraph className={classes.subTitle}>{t('login.info')}</Paragraph>
          </Typography>
          <Form className={classes.resetForm} onFinish={onResetHandle} form={form}>
            <Form.Item name={'email'} rules={[{ required: true, message: t('login.inputEmail') }]}>
              <Input
                placeholder={t('login.email')}
                size='large'
                prefix={<MailFilled style={{ fontSize: '90%' }} />}
                className={classes.input}
              />
            </Form.Item>

            <Form.Item shouldUpdate>
              <Button type='primary' htmlType='submit' loading={isLoading}>
                {t('resetPassword.reset')}
              </Button>
            </Form.Item>
          </Form>
        <Divider />

        <Typography style={{ textAlign: 'center' }}>
          <Typography.Link href='/login' className={classes.primary}>
            {t('login.returnToLogin')}
          </Typography.Link>
        </Typography>
        </div>
        
      </Layout>
    </div>
  );
}

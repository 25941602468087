import React from 'react';
import { IEmployee } from 'interfaces';
import classes from './OrganizationEmploees.module.scss';
import { UserDeleteOutlined, UserOutlined } from '@ant-design/icons';
import { Button, ConfigProvider, Select, Tooltip } from 'antd';
import { orgaizationRoles } from 'components/Modals/InviteEmployeesModal/InviteEmployeesModal';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../scss/variables/colors.module.scss';

type EmployeeCardProps = {
  employee: IEmployee;
  deleteEmployeeHandle: (employee: IEmployee) => void;
  changeRole: (employee: IEmployee, role_name: string) => Promise<void>;
  orgOwner: string;
  handleOpenEmployeeInfo: (employee: IEmployee) => void;
  permission?: {[key: string]: boolean} | undefined;
  userId?: string;
}

const EmployeeCard = ({
  employee,
  deleteEmployeeHandle,
  changeRole,
  orgOwner,
  handleOpenEmployeeInfo,
  permission,
  userId
}: EmployeeCardProps) => {
  const [installingNewRole, setInstallingNewRole] = React.useState(false);

  const { t } = useTranslation();

  const isOrgOwner = orgOwner === employee.user_id;  
  const thisIsFullAdministrator = permission?.can_edit_organization_settings && permission?.can_invite_organization_users;
  const isSelectAvailable = permission?.can_edit_organization_user 
    && (thisIsFullAdministrator || employee.role_name !== 'full_administrator') 
    && employee.user_id !== userId;
  
  const rolesToDisplay = thisIsFullAdministrator
    ? orgaizationRoles
    : orgaizationRoles.filter(role => role !== 'full_administrator');

  const roleOptions = rolesToDisplay.map((role) => ({
    label: t(`Organizations.invite.${role}`),
    value: role,
    key: role,
  }));
  
  const handleChangeRole = React.useCallback( async (value: any) => {
    setInstallingNewRole(true);
    try {
      await changeRole(employee, value)
    } catch (err) {      
    } finally {
      setInstallingNewRole(false);
    }
  }, []);

  const handleOpenDeleteModal = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    deleteEmployeeHandle(employee);
  };

  return (
    <div
      onClick={() => handleOpenEmployeeInfo(employee)}
      className={classes.employeeCard}
    >
      <div className={classes.employeeInfo}>
        <UserOutlined className={classes.employeeIcon}/>
        <div className={classes.employeeData}>
          <span className={classes.employeeName}>{`${employee.first_name} ${employee.last_name}`}</span>
          <span className={classes.employeeEmail}>{employee.email}</span>
        </div>
      </div>
      <div className={classes.employeeActions}>
        <div className={classes.roleSelect}>
          {isSelectAvailable
          ? (
            <ConfigProvider theme={{ token: { colorText: colors.textcolor } }}>
              <Select
                loading={installingNewRole}
                disabled={installingNewRole || isOrgOwner}
                value={employee.role_name}
                options={roleOptions}
                popupMatchSelectWidth={false}
                onClick={(e) => e.stopPropagation()}
                onChange={handleChangeRole}
              />
            </ConfigProvider>
          )
          : ( orgaizationRoles.includes(employee.role_name) ? t(`Organizations.invite.${employee.role_name}`) : employee.role_name)
          }
        </div>
        { permission?.can_exclude_organization_users && (isOrgOwner ? (
          <div className={classes.deleteBlock} >
            <Tooltip placement='topRight' title={t('Organizations.owner')}>
              <FontAwesomeIcon style={{paddingRight: 5}} color={'gold'} icon={faCrown} />
            </Tooltip>
          </div>
        )
        : (
          <Button
            className={classes.deleteButton}
            // loading={isDeletingEmployee}
            onClick={(e) => handleOpenDeleteModal(e)}
            type='text'
            icon={ <UserDeleteOutlined /> }
          />
        ))
        }
        
      </div>
    </div>
  );
}

export default EmployeeCard;

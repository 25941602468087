import { requestTemplate } from 'api';
import { ISimpleGroupForAprove } from 'interfaces';

export type ArgeementState = {
  id: string;
  entity: string;
  agreement_state?: ISimpleGroupForAprove[];
  comment?: string;
  author?: any;
  status?: 0 | 1 | 2 | null;
  initiator_id?: string;
};

//AGREEMENT
const addAgreement = (
  payload: ArgeementState
) => requestTemplate.post<string>(`/agreement/`, payload);

const deleteAgreement = (
  payload: { id: string, entity: string }
) => requestTemplate.delete<string>(`/agreement/`, { data: payload });

const getAgreements = (
  id: string, entity: string
) => requestTemplate.get<ArgeementState[]>(`/agreement/?id=${id}&entity=${entity}`);

const agreement = {
  addAgreement,
  deleteAgreement,
  getAgreements,
};

export default agreement;

import { Modal } from 'antd';
import React from 'react'
import { useTranslation } from 'react-i18next';
import classes from './TempLink.module.scss';

type ShareBrowserModalProps = {
  open: boolean;
  onCancel: () => void;
  recordConsent: () => void;
};

export default function ShareBrowserModal({open, onCancel, recordConsent}: ShareBrowserModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      centered
      closable={false}
      maskClosable={false}
      keyboard={false}
      onCancel={onCancel}
      title={t('templink.shareModalTitle')}
      okText={t('templink.agree')}
      cancelText={t('templink.refuse')}
      onOk={recordConsent}
      className={classes.shareBrowserModal}
    >
      {t('templink.shareModalText')}
    </Modal>
  )
}

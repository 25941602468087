import { IDataRoom, ISettings } from 'interfaces';

export const createSettingLog = (dataRoom: IDataRoom, settings: ISettings | IDataRoom) => {
  const detailsOfAction = {
    before: {
      agreement_enable: dataRoom?.agreement_enable,
      agreement_file: dataRoom?.agreement_file,
      name: dataRoom?.name || '',
      description: dataRoom?.description || '',
      custom_url: dataRoom?.custom_url || '',
    },
    after: {
      agreement_enable: settings?.agreement_enable,
      agreement_file: settings?.agreement_file?.name || settings?.agreement_file,
      name: settings?.name || '',
      description: settings?.description || '',
      custom_url: settings?.custom_url || '',
    },
  }

  for (let field in detailsOfAction.before) {
    const before = detailsOfAction.before[field as keyof typeof detailsOfAction.before];
    const after = detailsOfAction.after[field as keyof typeof detailsOfAction.after];

    if (before == after) {
      delete detailsOfAction.before[field as keyof typeof detailsOfAction.before];
      delete detailsOfAction.after[field as keyof typeof detailsOfAction.after];
    }
  };
  
  return detailsOfAction;
}

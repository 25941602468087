import React from 'react'
import organizations from 'api/organizations';
import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import classes from './OrganizationSchedule.module.scss'
import Schedule from './Schedule'
import Requests from './Requests';
import Subscriptions from './Subscriptions';
import RowLoader from 'components/Sceletons/RowLoader';

const OrganizationIntegrations = () => {
  const [uniqueUsers, setUniqueUsers] = React.useState<number>(0);
  const [isloadingNumberOfUnique, setIsloadingNumberOfUnique] = React.useState<boolean>(false);

  const { t } = useTranslation();
  React.useEffect(() => {
    // localStorage.setItem('previousPage', 'organization/clients')
    const getUniquesUsers = async () => {
      setIsloadingNumberOfUnique(true);
      try {
        const response = await organizations.getVisitors()
        setUniqueUsers(response.data);
      } finally {
        setIsloadingNumberOfUnique(false);
      }
    }
    getUniquesUsers()
  },[]);
  
  const TabsData: TabsProps['items'] = [
    {
      key: 'schedule',
      label: t('Organizations.tabs.schedule'),
      children: <Schedule />,
    },
    {
      key: 'requests',
      label: t('Organizations.tabs.requests'),
      children: <Requests />,
    },
    {
      key: 'subscriptions',
      label: t('Organizations.tabs.subscriptions'),
      children: <Subscriptions />,
    },
  ];

  return (
    <div className={classes.tabsWrap}>
      <div className={classes.userCount}>{t('Organizations.uniqueUsers')}:
        <span style={{padding: '0 0 0 3px'}}>
          {isloadingNumberOfUnique 
            ? <RowLoader padding={'3px 0 0 0'} width={12} height={16} />
            : uniqueUsers
          }
        </span>
      </div>

      <Tabs
        // tabBarExtraContent={<div className={classes.userCount}>{`${t('Organizations.uniqueUsers')}: ${uniqueUsers}`}</div>}
        className={classes.tabs}
        defaultActiveKey={'schedule'}
        items={TabsData}
        type='card'
      />
    </div>
  )
}

export default OrganizationIntegrations

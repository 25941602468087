import { message } from 'antd';
import api from 'api';
import auth from 'api/auth';
import { getBrowserClientData } from 'helpers/browserData/browserData';
import { deleteCookie, setCookie } from 'helpers/cookies';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

const Confirm = () => {
  const navigate = useNavigate();
  const { user_id } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    const controller = new AbortController();
    onAccept(controller.signal);

    return () => controller.abort();
  }, [user_id]);

  const onAccept = async (signal: AbortSignal) => {
    try {
      if (user_id) {
        let browserData: any = null;
        try {
          browserData = await getBrowserClientData();
        } catch (error) {
          console.log(error, 'error while collecting browser information');
        };

        const response = await auth.acceptRegistration(user_id, signal);
        setCookie('athena-token', response.data.access_token, 1);
        setCookie('refresh_token', response.data.refresh_token, 1);

        const form_data = new FormData();
        form_data.append('browser_data', JSON.stringify(browserData));
        navigate('/');
        message.success(t('confirm.success'));
        browserData && await api.sendBrowserFingerprint(form_data);
      }
    } catch (err) {
      deleteCookie('athena-token');
      deleteCookie('refresh_token');
      message.error(t('confirm.error'));
      navigate('/login');
    }
  };

  return <div></div>;
};

export default Confirm;

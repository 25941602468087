import { TabsProps } from 'antd';
import { TFunction } from 'i18next';

const getTimelineTabs = (t: TFunction): TabsProps['items'] => [
  {
    key: 'ALL',
    label: t('Documents.details.tabs.all'),
  },
  {
    key: 'create',
    label: t('Documents.details.tabs.create'),
  },
  {
    key: 'download',
    label: t('Documents.details.tabs.download'),
  },
  {
    key: 'view',
    label: t('Documents.details.tabs.view'),
  },
  {
    key: 'update',
    label: t('Documents.details.tabs.update'),
  },
  {
    key: 'trigger',
    label: 'Trigger',
  },
];

export { getTimelineTabs };

import { Button, Select, Table, message, Modal, Form, Input, FormProps } from 'antd';
import organizations from 'api/organizations';
import TableLoader from 'components/Sceletons/TableLoader';
import React, { useMemo, useState } from 'react';
import { requestsColumn } from './columns/requestsColumns';

import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/es/select';
import { getFile } from 'helpers/downloadSomeFile';
import { useTranslation } from 'react-i18next';
import classes from './OrganizationSchedule.module.scss';

const rowClasses = [classes.waiting, classes.inProgress, classes.processed];

type FieldType = {
  fullName?: string;
  email?: string;
  phoneNumber?: string;
  citizenship?: string;
  residence?: string;
  preferredMethod?: any;
  message?: string;
};

const Requests = () => {
  const [organizationId, setOrganizationId] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [dataTable, setDataTable] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const { i18n, t } = useTranslation('translation', { keyPrefix: 'Organizations' });

  const selecOptions: DefaultOptionType[] = [
    { label: t('statuses.waiting'), value: 0 },
    { label: t('statuses.inProgress'), value: 1 },
    { label: t('statuses.processed'), value: 2 },
  ];

  const customColumns = React.useMemo(
    () =>
      requestsColumn.map((col) => ({
        ...col,
        render: (value: any, info: any) => renderCell({ value, info, col }),
        title: t(`columns.requests.${col.dataIndex}`),
      })),
    [i18n.language, requestsColumn]
  );

  const sortedData = useMemo(
    () => dataTable.sort((first, second) => (first.status === 1 ? -1 : first.status - second.status)),
    [dataTable]
  );

  React.useEffect(() => {
    getRequests();
  }, []);

  const getRequests = async () => {
    setIsLoading(true);
    try {
      const response = await organizations.getOrganizationRequests();
      setOrganizationId(response.data[0]?.organization_id)
      setDataTable(response.data.map((request: any) => ({ ...request, loading: false, key: request.id })));
    } catch (e: any) {
      message.error(t("Organizations.error.failedGetRequests"));
    } finally {
      setIsLoading(false);
    }
  };

  const renderCell = ({ value, info, col }: any) => {
    if (col.key === 'status') {
      return (
        <div className={classes.center}>
          <Select
            value={value}
            options={selecOptions}
            loading={info.loading}
            disabled={info.loading}
            onChange={(option) => onStatusChange(option, info.id)}
            className={classes.select}
          />
        </div>
      );
    }

    return value;
  };

  const onStatusChange = async (value: number, id: string) => {
    try {
      setDataTable((prev) => prev.map((data) => (data.id === id ? { ...data, loading: true } : data)));
      await organizations.changeOrganizationRequestStatus({ status: value, object_id: id });
      setDataTable((prev) => prev.map((data) => (data.id === id ? { ...data, status: value } : data)));
    } catch (err) {
      console.log(err);
    } finally {
      setDataTable((prev) => prev.map((data) => (data.id === id ? { ...data, loading: false } : data)));
    }
  };

  const onDownload = async () => {
    try {
      setIsDownloadLoading(true);
      const response = await organizations.downloadOrganizationRequests();
      getFile(response.data, t('tabs.requests'));
    } catch (err) {
      message.error(t("Organizations.error.failedGetRequests"));
    } finally {
      setIsDownloadLoading(false);
    }
  };

  const onFinish: FormProps<FieldType>['onFinish'] = async (values: any) => {
    const form_data = {
      full_name: values.fullName,
      email: values.email,
      phone_number: values.phoneNumber,
      citizenship: values.citizenship,
      current_residence: values.residence,
      preferred_method: values.preferredMethod,
      message: values.message,
      organization_id: organizationId,
    }
    await organizations.createOrganizationRequests(form_data)
    await getRequests()
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };



  if (isLoading) {
    return (
      <div className={classes.loaderWrap}>
        <TableLoader 
          height={'calc(100vh - 280px)'}
        />
      </div>
    );
  }

  return (
    <div className={classes.scheduleWrap}>
      <Button onClick={onDownload} icon={<DownloadOutlined />} loading={isDownloadLoading} className={classes.export}>
        {t('unloadButton')}
      </Button>
      <Button type="primary" onClick={showModal} icon={<PlusOutlined />} disabled={!organizationId} className={classes.add}>
        {t('addButton')}
      </Button>
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        width={'60vw'}
        footer={[]}
      >
        <Form
          labelCol={{ span: 9 }}
          wrapperCol={{ span: 13 }}
          onFinish={onFinish}
          layout="horizontal"
          className={classes.addModal}
        >
          <Form.Item<FieldType> label={t('form.fullname')} name="fullName">
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label={t('form.email')} name="email">
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label={t('form.phoneNumber')} name="phoneNumber">
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label={t('form.citizenship')} name="citizenship">
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label={t('form.residence')} name="residence">
            <Input />
          </Form.Item>
          <Form.Item<FieldType> label={t('form.preferredMethod')} name="preferredMethod">
            <Select>
              <Select.Option value="Email">Email</Select.Option>
              <Select.Option value="Phone number">Phone number</Select.Option>
              <Select.Option value="Any">Any</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item<FieldType> label={t('form.message')} name="message">
            <Input.TextArea />
          </Form.Item>
          <Form.Item wrapperCol={{ xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 9 } }}>
            <Button type="primary" htmlType="submit">
              {t('form.submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Table
        dataSource={sortedData}
        rowClassName={(record) => rowClasses[record.status]}
        columns={customColumns}
        pagination={false}
        size='middle'
        className={classes.scheduleTable}
        scroll={{
          y: 'calc(100vh - 355px)',
        }}
      />
    </div>
  );
};

export default Requests;

import { Modal, message } from 'antd';
import api from 'api';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setOrganization} from 'store/slices/organization';

type DeleteOrganizationModalProps = {
  open: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DeleteOrganizationModal(
  {
    open,
    setIsOpen,
  }: DeleteOrganizationModalProps
) {
  const [isDeleting, setIsDeleting] = React.useState(false);
  
  const { organization } = useAppSelector(state => state.organization);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const deleteOrganization = async () => {
    setIsDeleting(true);
    try {
      await api.deleteOrganization(organization?.id!)
      message.success(`
        ${t('Organizations.name')}
        ${organization?.name}
        ${t('Organizations.modals.deleteSuccess')}
      `);
      dispatch(setOrganization(null));
    } catch (e) {
      message.error(`${t('Organizations.modals.deleteError')} ${organization?.name}`);
    } finally {
      setIsDeleting(false);
      setIsOpen(false)
    }
  }

  return (
    <Modal
      centered
      onCancel={() => setIsOpen(false)}
      open={open}
      cancelText={t('main.cancelButton')}
      okText={t('main.deleteButton')}
      onOk={deleteOrganization}
      confirmLoading={isDeleting}
      title={t('Organizations.modals.deleteTitle')}
    >
      <div style={{padding: 30, display: 'flex', justifyContent: 'center'}}>
        {t('Organizations.modals.deleteConfirmText')} - {organization?.name}?
      </div>
    </Modal>
  )
}

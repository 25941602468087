type ArrayKeysType = {
  label: string;
  value: any;
  key: string;
};

export const getArrayObjectFromKeys = (object: any) => {
  const data: ArrayKeysType[] = [];
  for (let key in object) {
    const item = {
      key: `${key} ${object.id}`,
      label: key,
      value: object[key as keyof typeof object],
    };

    data.push(item);
  }
  return data;
};

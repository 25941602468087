import { SendOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Form, Input, InputRef } from 'antd';
import { TextAreaRef } from 'antd/es/input/TextArea';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { dateFormatCase } from 'helpers/getDateWithTimeZone';
import { TFunction } from 'i18next';
import { IChat, IChatMessage } from 'interfaces';
import { CSSProperties, FC, KeyboardEvent, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchUnviewedAmount } from 'store/reducers/chatCreator';
import RecieveMessage from './Messages/RecieveMessage';
import SendMessage from './Messages/SendMessage';
import classes from './Messanger.module.scss';

type Props = {
  onSendMessage: (event: any) => void;
  onChatUsersOpen: () => void;
  chatGroups: IChatMessage[][];
  chatInfo: IChat | null;
  t: TFunction;
};

const Messanger: FC<Props> = ({ onChatUsersOpen, onSendMessage, t, chatGroups, chatInfo }) => {
  const { userData } = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchUnviewedAmount({ data: chatInfo, count: -1 }));
    form.setFieldValue('message', '');
  }, []);

  const scrollToBottom = () => bodyRef.current?.scrollTo({ top: bodyRef.current.scrollHeight + 150 });

  useEffect(() => {
    if (bodyRef.current) scrollToBottom();
  }, [form, bodyRef, chatGroups]);

  const onFinish = (values: any) => {
    onSendMessage(values.message);
    form.resetFields(['message']);
  };

  const submitOnEnterClick = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && event.shiftKey === false) {
      setTimeout(() => {
        form.resetFields(['message']);
      }, 1);
      onSendMessage((event.target as HTMLTextAreaElement).value);
    }
  };

  return (
    <div className={classes.messanger}>
      <div role='navigation' className={classes.messanger_header}>
        <div className={classes.messanger_header_items}>
          <div
            className={classes.messanger_avatars}
            style={{ '--length': chatInfo?.members.length } as CSSProperties}
            onClick={onChatUsersOpen}
          >
            {chatInfo?.members.map((member, idx) => (
              <Avatar
                key={`avatar-${idx}`}
                style={{ '--idx': idx } as CSSProperties}
                className={classes.messanger_avatar}
                icon={<UserOutlined />}
              />
            ))}
          </div>
        </div>
        <div className={classNames(classes.messanger_title, classes.messanger_header_items)}>
          <div className={classes.messanger_name}>
            {t(`Chats.types.${chatInfo?.entity_type}`)} {chatInfo?.entity_name}
          </div>
          {/* <span className={classes.messanger_date}>{t('Chats.lastSeen')}</span> */}
        </div>
      </div>
      <div className={classes.messanger_body} ref={bodyRef}>
        <div className={classes.messanger_bodyMessages}>
          {chatGroups.map((chats, index) => (
            <div className={classes.messanger_chatGroups} key={`group-${chats[0]?.timestamp}-${index}`}>
              {chats[0]?.timestamp && (
                <div className={classes.messanger_dateSection}>
                  {dayjs(chats[0]?.timestamp, dateFormatCase[userData?.date_format!]).format('DD/MM/YYYY')}
                </div>
              )}

              {chats.map((chatMessage, idx) => {
                const isFirst = chatMessage.user_name !== chatGroups[index][idx - 1]?.user_name;
                return chatMessage.email === userData?.email ? (
                  <SendMessage
                    key={`message-${chatMessage.timestamp + idx}-sender`}
                    isFirst={isFirst}
                    message={chatMessage.message}
                    date={`${dayjs(chatMessage.timestamp, dateFormatCase[userData?.date_format!]).format('HH:mm')}`}
                    status={chatMessage.status}
                  />
                ) : (
                  <RecieveMessage
                    key={`message-${chatMessage.timestamp + idx}-recieve`}
                    username={chatMessage.user_name}
                    isFirst={isFirst}
                    message={chatMessage.message}
                    date={`${dayjs(chatMessage.timestamp, dateFormatCase[userData?.date_format!]).format('HH:mm')}`}
                  />
                );
              })}
            </div>
          ))}
        </div>
      </div>
      <Form onFinish={onFinish} form={form} className={classes.messanger_footer_form}>
        <div className={classes.messanger_footer}>
          {/* <SmileOutlined className={classNames(classes.messanger_footerIcons, classes.messanger_left)} /> */}
          <Form.Item name='message' className={classes.messanger_footer_item}>
            <Input.TextArea
              ref={inputRef}
              autoFocus
              className={classes.messanger_textarea}
              placeholder={t('Chats.startTyping')}
              autoSize={{ maxRows: 10, minRows: 1 }}
              onKeyDown={submitOnEnterClick}
            />
          </Form.Item>
          <div className={classes.messanger_sendingIcons}>
            <SendOutlined onClick={form.submit} className={classes.messanger_footerIcons} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default Messanger;

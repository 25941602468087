import { Form, Input, InputRef, Modal, message } from 'antd';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import classes from './NewRoomModal.module.scss';
import dayjs from 'dayjs';
import api from 'api';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { adminPermissions, setDefaultUserPermissions, setUserAndRoomStatus, setUserData } from 'store/slices/userDataSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { createChat } from 'store/reducers/chatCreator';

type NewRoomModalProps = {
  open: boolean
  onCancel: () => void;
}

export default function NewRoomModal({open, onCancel}: NewRoomModalProps) {
  const [form] = Form.useForm();
  const nameInput = React.useRef<InputRef | null>(null);
  
  const [isCreatingRoom, setIsCreatingRoom] = React.useState(false);

  const { userData } = useAppSelector(store => store.userData);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleCreateNewRoom = async (value: any) => {
    setIsCreatingRoom(true);
    try {
      const formData = new FormData();

      for(const key in value){        
        value[key] && formData.append(key, value[key]);
      }

      const { data: newRoom } = await api.createRoom(formData);
      const UTCDate = dayjs().valueOf() + new Date().getTimezoneOffset() * 60 * 1000;
      const parsedDateRoom = {...newRoom, last_action_timestamp: UTCDate, created_at: Date.parse(String(newRoom.created_at)), permissions: adminPermissions}
      userData && dispatch(setUserData({...userData, available_rooms: userData?.available_rooms 
        ? [parsedDateRoom, ...userData?.available_rooms] 
        : [parsedDateRoom]
      }));
      localStorage.removeItem('previousFolderPath');
      localStorage.setItem('previousPage', 'documents');
      localStorage.setItem('previousRoomId', newRoom.id);
      dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      dispatch(setDefaultUserPermissions());
      dispatch(setDataRoom(newRoom));
      dispatch(
        createChat({
          data: {
            entity_type: 1,
            entity_id: newRoom.id,
            room_id: newRoom.id!,
            title: newRoom.name,
            members_ids: [userData?.id!],
          },
          ownerId: userData?.id!,
        })
      );
      navigate(`/room/${newRoom.id}/documents`);
      setIsCreatingRoom(false);
      onCancel();
      message.success(t('Header.success.created'));
    } catch (err: unknown) {
      setIsCreatingRoom(false);
      if (err instanceof AxiosError && err?.response?.data?.error === 'room already exists') message.warning(t('Header.error.alreadyExists'));
      else message.error(t('Header.error.create'));
    }
  };

  React.useEffect(() => nameInput.current?.focus(), [])

  return (
    <Modal 
      styles={{ body: {maxHeight: '85vh'} }}
      width={'60%'}
      title={t('Header.createModal.title')}
      open={open}
      centered={true}
      onCancel={onCancel}
      onOk={form.submit}
      okText={t('Header.createModal.createButton')}
      confirmLoading={isCreatingRoom}
      cancelText={t('Header.createModal.cancelButton')}
      className={classes.modalAddRoom}
    >
      <div className={classes.modalWrap}>
      <Form className={classes.form} layout='vertical' onFinish={handleCreateNewRoom} form={form}>
        
        <Form.Item className={classes.form_input} label={t('Header.createModal.nameLabel')} name='name' rules={[{ required: true, message: t('Header.createModal.roomNameValidate') }]}>
          <Input
            ref={nameInput}
            maxLength={100}
            placeholder={t('Header.createModal.namepPaceholder')}
          />
        </Form.Item>

        {/* <Form.Item className={classes.form_input} label={t('Header.createModal.descriptionLabel')} name='description'>
            <TextArea
              autoSize={{minRows: 4, maxRows: 5}}
              count={{
                show: true,
                max: 500,
                exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
              }}
            />
         </Form.Item> */}
      </Form>
      </div>
    </Modal>
  );
}

import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import classes from './PasswordRule.module.scss';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

type Props = {
  type: 'error' | 'success';
  rule: string;
};

const typeCases = {
  error: {
    icon: <CloseCircleOutlined />,
    class: classes.error,
  },
  success: {
    icon: <CheckCircleOutlined />,
    class: classes.success,
  },
};

function PasswordRule({ type, rule }: Props) {
  const { t } = useTranslation();

  return (
    <div className={classNames(classes.row, typeCases[type].class)}>
      {' '}
      {typeCases[type].icon} {t(`SignUp.rules.${rule}`)}
    </div>
  );
}

export default PasswordRule;

import { LockOutlined } from '@ant-design/icons';
import { Button, Tooltip, Tree, message } from 'antd';
import { DataNode } from 'antd/es/tree';
import api from 'api';
import { documnentIcons } from 'helpers/documentIcons';
import { TFunction } from 'i18next';
import { IDataRoom, IDocument, IFolder } from 'interfaces';
import { Key, useEffect, useMemo, useState } from 'react';
import classes from './RoomAction.module.scss';
import { DeleteModal } from 'components/Modals';

const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

const filterTreeData = (list: DataNode[], keys: Key[], filtered?: Key[]): DataNode[] => {
  const filteredKeys: Key[] = filtered || [];

  return list.filter((node) => {
    if (filteredKeys.length === keys.length) {
      return true;
    }

    if (keys.includes(node.key)) {
      filteredKeys.push(node.key);
      return false;
    }

    if (node.children) {
      node.children = filterTreeData(node.children, keys, filteredKeys);
      return true;
    }

    return true;
  });
};
type Props = {
  documents: IDocument[];
  folders: IFolder[];
  room: IDataRoom;
  clearData: (type: 'documents' | 'users') => void;
  t: TFunction;
};

const DeleteRoomTreeSelect = ({ documents, folders, room, clearData, t }: Props) => {
  const [treeData, setTreeData] = useState<DataNode[]>([]);
  const [dataSource, setDataSource] = useState<(IFolder | IDocument)[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<{ key: string; name: string; is_confidential?: boolean }[]>([]);
  const [isDeletingDocuments, setIsDeletingDocuments] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const onCancelConfirm = () => setIsConfirmModalOpen(false);
  const onOpenConfirm = () => setIsConfirmModalOpen(true);

  const { treeSelectedKeys, onlyDocs } = useMemo(() => {
    const onlyDocs = [...selectedKeys].filter(({ key }) => key !== 'All');

    return {
      treeSelectedKeys: selectedKeys.map(({ key }) => key),
      onlyDocs,
    };
  }, [selectedKeys]);

  useEffect(() => {
    const allCheckNode: DataNode = {
      key: 'All',
      title: t('Rooms.modals.delete.allSelect'),
      isLeaf: true,
    };

    const dataNode: DataNode[] = [...folders, ...documents].map(({ id, name, type, extension, is_confidential }) => ({
      id: id!,
      title: name,
      icon: (
        <div className={classes.tree_icon}>
          {documnentIcons[(extension || 'folder') as keyof typeof documnentIcons]}{' '}
          {is_confidential && (
            <Tooltip title={t('Documents.table.confidential')}>
              <LockOutlined style={{ color: 'red' }} />
            </Tooltip>
          )}
        </div>
      ),
      children: type === 'folder' ? [] : undefined,
      key: `${extension ? 'document' : 'folder'} ${id}`,
      isLeaf: type !== 'folder',
    }));

    dataNode.unshift(allCheckNode);

    setTreeData(dataNode);
    setDataSource([...folders, ...documents]);
  }, []);

  const onLoadData = async ({ key }: any) => {
    const targetId = key.split(' ')[1];
    const { data: childrenFolders } = await api.getFoldersOfFolder(targetId);
    const { data: childrenDocuments } = await api.getDocuments({ room_id: room.id, folder_id: targetId, action: '' });
    setDataSource((prev) => [...prev, ...childrenFolders, ...childrenDocuments]);

    const childrenElements = [...childrenFolders, ...childrenDocuments].map((item) => ({
      title: item.name,
      icon: (
        <div className={classes.tree_icon}>
          {documnentIcons[(item?.extension || 'folder') as keyof typeof documnentIcons]}{' '}
          {item.is_confidential && (
            <Tooltip title={t('Documents.table.confidential')}>
              {' '}
              <LockOutlined style={{ color: 'red' }} />{' '}
            </Tooltip>
          )}
        </div>
      ),
      key: `${item.extension ? 'document' : 'folder'} ${item.id}`,
      id: item.id!,
      isLeaf: Boolean(item.extension),
    }));

    setTreeData((origin) => updateTreeData(origin, key, childrenElements));
  };

  const onSelect = (treeSelectedKeysAndCheck: any, { node }: { node: DataNode }) => {
    if (node.key === 'All') {
      const isSelectAll = !treeData.map(({ key }) => treeSelectedKeys.includes(key as string)).includes(false);
      return isSelectAll
        ? setSelectedKeys([])
        : setSelectedKeys(treeData.map(({ key, title }) => ({ key: key as string, name: title as string })));
    }

    setSelectedKeys((prev) =>
      prev.find(({ key }) => key === node.key)
        ? prev.filter(({ key }) => key !== node.key)
        : [...prev, { key: node.key as string, name: node.title as string }]
    );
  };

  const onDelete = async () => {
    setIsDeletingDocuments(true);

    const arrFilesIds: string[] = [];
    const arrFilesNames: string[] = [];
    const arrFolderIds: string[] = [];
    const arrFolderNames: string[] = [];
    const arrFilesConfidentialIds: string[] = [];
    const arrFilesConfidentialNames: string[] = [];

    onlyDocs.forEach(({ key: document, name }) => {
      if (String(document).includes('folder')) {
        const folderId = String(document).split(' ')[1];
        arrFolderIds.push(folderId);
        arrFolderNames.push(name);
      } else {
        const documentId = String(document).split(' ')[1];
        const targetDocument = dataSource.find((doc) => doc.id === documentId);

        if (targetDocument?.is_confidential) {
          arrFilesConfidentialIds.push(targetDocument?.id!);
          arrFilesConfidentialNames.push(targetDocument?.name!);
        } else {
          arrFilesIds.push(targetDocument?.id!);
          arrFilesNames.push(targetDocument?.name!);
        }
      }
    });

    try {
      arrFilesIds.length &&
        (await api.deleteDocuments(arrFilesIds, {
          room_id: room.id,
          action: 'delete',
          details: {
            documents_names: arrFilesNames.join(', '),
            documents_ids: arrFilesIds.join(', '),
          },
        }));
      arrFilesConfidentialIds.length &&
        (await api.deleteDocuments(arrFilesConfidentialIds, {
          room_id: room.id,
          action: 'delete_confident',
          details: {
            documents_names: arrFilesConfidentialNames.join(', '),
            documents_ids: arrFilesConfidentialIds.join(', '),
          },
        }));

      await Promise.all(arrFolderIds.map(async (id) => await api.deleteFolder(id)));
      setTreeData((prev) => {
        const newTreeData = filterTreeData(prev, treeSelectedKeys);
        newTreeData.length <= 1 && clearData('documents');

        return newTreeData;
      });

      setSelectedKeys([]);
      message.success(
        `${t('Documents.success.deleteObjects')}: ${[
          ...arrFilesNames,
          ...arrFolderNames,
          ...arrFilesConfidentialNames,
        ].join(', ')}`
      );
    } catch (err) {
      message.error(
        `${t('Documents.error.deleteObjects')}: ${[
          ...arrFilesNames,
          ...arrFolderNames,
          ...arrFilesConfidentialNames,
        ].join(', ')}`
      );
    } finally {
      setIsDeletingDocuments(false);
      onCancelConfirm();
    }
  };

  return (
    <div>
      <Tree
        treeData={treeData}
        selectedKeys={treeSelectedKeys}
        checkedKeys={treeSelectedKeys}
        className={classes.tree}
        onSelect={onSelect}
        onCheck={onSelect}
        loadData={onLoadData}
        disabled={isDeletingDocuments}
        showLine
        showIcon
        checkable
        multiple
      />

      <Button loading={isDeletingDocuments} type='primary' disabled={!onlyDocs.length} onClick={onOpenConfirm}>
        {t('Documents.contextMenu.delete')}
      </Button>

      {isConfirmModalOpen && (
        <DeleteModal
          isOpen={isConfirmModalOpen}
          onCancel={onCancelConfirm}
          submit={onDelete}
          isLoading={isDeletingDocuments}
          title={t('Documents.confirm.delete')}
          text={`${t('Documents.confirm.confirmDelete')}: ${onlyDocs.map(({ name }) => name).join(', ')}?`}
          okText={t('Documents.confirm.delete')}
        />
      )}
    </div>
  );
};

export default DeleteRoomTreeSelect;

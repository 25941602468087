import { Card, Switch, Typography } from 'antd';
import { TFunction } from 'i18next';
import cn from 'classnames'
import classes from '../RoomSettings.module.scss';

type Props = {
  t: TFunction;
  watermark_enabled: boolean;
  setWaterMark: (watermark: boolean) => void;
};

export default function RoomMark({ t, watermark_enabled, setWaterMark }: Props) {
  return (
    <Card title={t('Settings.mark.title')} bordered className={cn(classes.card, classes.settingsTitle)}>
      <Typography className={classes.mb20}>{t('Settings.mark.body')}</Typography>
      <Switch
        onChange={setWaterMark}
        checked={watermark_enabled}
        checkedChildren={t('Settings.terms.enable')}
        unCheckedChildren={t('Settings.terms.disable')}
      />
    </Card>
  );
}

import { FC, ReactElement, ReactNode, CSSProperties } from 'react';

type PageProps = {
  children: ReactNode | ReactElement;
  style: CSSProperties;
};

const Page: FC<PageProps> = ({ children, style }) => <div style={style}>{children}</div>;

export default Page;

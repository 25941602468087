import React from 'react';
import { ConfigProvider, Segmented, UploadProps } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { useTranslation } from 'react-i18next';
import OrganizationEmploees from './OrganizationEmploees/OrganizationEmploees';
import OrganizationBills from './OrganizationBills/OrganizationBills';
import OrganizationIntegrations from 'components/OrganizationComponents/OrganizationIntegrations/OrganizationIntegrations';
import OrganizationDocumentsTable from './OrganizationDocumentsTable/OrganizationDocumentsTable';
import { InviteEmployeesModal } from 'components/Modals';
import classes from './Organization.module.scss';
import colors from '../../scss/variables/colors.module.scss';
import { useAppSelector } from 'store/hook';

type OrganizationInfoProps = {
  uploadProps: UploadProps;
  onOpen: () => void;
}

const OrganizationInfo = ({uploadProps, onOpen}: OrganizationInfoProps) => {
  const [isInviteEmployeesOpen, setIsInviteEmployeesOpen] = React.useState(false);
  const [currentSegment, setCurrentSegment] = React.useState<SegmentedValue>('employees');

  const { organization } = useAppSelector(state => state.organization);

  const { t } = useTranslation();

  const segmentOptions = [
    {
      label: t('Organizations.segments.employees'),
      value: 'employees'
    }
  ];

  organization?.permission?.can_view_organization_documents && segmentOptions.push({
    label: t('Organizations.segments.documents'),
    value: 'documents'
  });

  organization?.permission?.can_view_organization_integrations && segmentOptions.push({
    label: t('Organizations.segments.integrations'),
    value: 'integrations'
  });

  organization?.permission?.can_view_organization_bills && segmentOptions.push({
    label: t('Organizations.segments.bills'),
    value: 'bills'
  });

  const infoElements = {
    employees: <OrganizationEmploees
      setIsInviteEmployeesOpen={setIsInviteEmployeesOpen}
    />,
    integrations: <OrganizationIntegrations />,
    bills: <OrganizationBills />,
    documents: <OrganizationDocumentsTable
      uploadProps={uploadProps}
      onOpen={onOpen}
    />,
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorBgLayout: colors.borderColor,
          },
          components: {
            Segmented: {
              itemSelectedColor: colors.primary100,
              itemHoverColor: colors.primary100,
            },
          },
        }}
      >
        <Segmented
          className={classes.segment}
          options={segmentOptions}
          value={currentSegment}
          size='large'
          onChange={(value) => {
            setCurrentSegment(value);
          }}
        />
      </ConfigProvider>

      {infoElements[currentSegment as keyof typeof infoElements]}

      {isInviteEmployeesOpen && (
        <React.Suspense fallback={<div />}>
          <InviteEmployeesModal isOpen={isInviteEmployeesOpen} setIsInviteUserOpen={setIsInviteEmployeesOpen} />
        </React.Suspense>
      )}
    </div>
  );
}

export default OrganizationInfo;

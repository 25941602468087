import { DownloadOutlined, FileWordOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import api from 'api';
import { TFunction } from 'i18next';
import { IConfident, IDocument } from 'interfaces';
import { FC, useState } from 'react';
import { useAppDispatch } from 'store/hook';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import classes from './ConfidentModal.module.scss';
import { useTranslation } from 'react-i18next';

type ConfidentModalProps = {
  selectedDocument: IDocument;
  t: TFunction;
  open: boolean;
  onCancel: () => void;
  roomId: string;
};

export default function ConfidentModal ({
  selectedDocument,
  t,
  open,
  onCancel,
  roomId,
}: ConfidentModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const { i18n } = useTranslation();

  const onDownloadClick = async (confident: IConfident) => {
    if (isLoading) return;
    setIsLoading(true);
    const res = await api.getDocumentsConfident(confident.id);    
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([res.data]));
    link.download = `${confident.name}.${confident.extension}`;
    link.click();
    link.remove();
    setIsLoading(false);
  };

  const onOk = () => {
    dispatch(
      fetchDocumentToViewer({
        id: selectedDocument.id!,
        token: selectedDocument.token,
        roomId,
        extension: selectedDocument.extension,
        is_agreement: false,
        locale: i18n.language,
      })
    );

    onCancel();
  };

  return (
    <Modal
      centered
      title={'The document is confident. Are you sure to open this document?'}
      cancelText={'Cancel'}
      confirmLoading={isLoading}
      open={open}
      onCancel={onCancel}
      onOk={onOk}
    >
      {selectedDocument.confident_document!.map((confident) => (
        <Typography className={classes.confident} onClick={() => onDownloadClick(confident)}>
          <FileWordOutlined className={classes.word} /> {confident.name} <DownloadOutlined />
        </Typography>
      ))}
    </Modal>
  );
};

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import axios from 'axios';
import prepareDocuments from 'helpers/prepareDocuments';
import { IDocument } from 'interfaces';
import { RootState } from 'store';
import dayjs from 'dayjs'

export const fetchAvailableDocuments = createAsyncThunk(
  'dataDocuments/fetchAll',
  async (props: { roomId: string, folderId?: string | null }, thunkAPI) => {
    try {
      const { roomAllChats } = (thunkAPI.getState() as RootState).chat;
      const source = axios.CancelToken.source();
      thunkAPI.signal.addEventListener('abort', () => source.cancel());


      const rootState = thunkAPI.getState() as RootState;
      const { userFoldersPermissions } = rootState.permissions;
      const { currentFolder } = rootState.documents;

      const folders = props.folderId
        ? await api.getFoldersOfFolder(props.folderId, source.token)
        : await api.getFolders(props.roomId, source.token);        

      const documents = await api.getDocuments(
        { room_id: props.roomId, folder_id: props.folderId || undefined, action: 'view' },
        source.token
      );

      const foldersWithPermissions = folders.data.map(folder => {
        const targetFolderId = props.folderId ? currentFolder[0].id : folder.id;        
        return {
          ...folder,
          permissions: userFoldersPermissions?.find(folder => folder.id === targetFolderId)?.permissions
        }
      });      
              
      // if (!props.folderId) {
      //   thunkAPI.dispatch(setFoldersForPermissions(foldersWithPermissions.map(folder => ({...folder, key: `folder ${folder.id}`, type: 'folder'}))))
      // }

      const documentChats = roomAllChats
      .filter(({ entity_type, unviewed_amount }) => entity_type === 0 && unviewed_amount > 0)
      .map(({ entity_id }) => entity_id);

      const preparedDocuments = documents.data.map((document: IDocument) => ({
        ...document,
        upload_at: dayjs(document.upload_at).valueOf(),
        clicked: false,
        key: document.id,
        unviewed_amount: documentChats.includes(document.id) ? 1 : 0,
      }));
      const dataForDocumentTable = prepareDocuments(preparedDocuments, foldersWithPermissions);

      return {
        documents: preparedDocuments, dataForDocumentTable: dataForDocumentTable, folders: foldersWithPermissions || []
      };
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);


import { FC } from 'react';
import classNames from 'classnames';
import classes from '../Messanger.module.scss';
import { messageStatusType } from 'interfaces';
import Spinner from 'components/Spinner/Spinner';

type Props = {
  isFirst: boolean;
  message: string;
  date: string;
  status: messageStatusType;
};

const statusCases = {
  sended: (
    <div className={classes.messanger_checked}>
      <div className={classes.messanger_check}></div>
      <div className={classes.messanger_check}></div>
    </div>
  ),
  pending: <Spinner size={12} color='#fff' width='fit-content' />,
};

const SendMessage: FC<Props> = ({ isFirst, date, message, status }) => {
  return (
    <div className={classNames(classes.messanger_send, isFirst && classes.messanger_first)}>
      <p className={classes.messanger_messageText}>{message}</p>
      <div className={classes.messanger_messageTime}>
        <span>{date}</span>
        {statusCases[status]}
      </div>
    </div>
  );
};

export default SendMessage;

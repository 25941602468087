import React, { FC } from 'react';
import { Button, Empty, Table, Upload, UploadProps } from 'antd';
import { IColumn, IDocument } from 'interfaces';
import { getOrganizationColumns } from './columns/data';
import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import classes from './OrganizationDocumentsTable.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import { deleteDocumentOfOrganization, fetchDocumentsOfOrganization } from 'store/reducers/organizationCreator';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import TableLoader from 'components/Sceletons/TableLoader';

type OrganizationDocumentsTableProps = {
  uploadProps: UploadProps;
  onOpen: () => void;
};

const { Dragger } = Upload;

const OrganizationDocumentsTable: FC<OrganizationDocumentsTableProps> = ({
  uploadProps,
  onOpen,
}) => {
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);

  const { isDocumentDeleting, deletingDocumentID, documents, isDocumentsLoading, organization} = useAppSelector((state) => state.organization);

  const dataSource = documents?.map((doc) => ({ ...doc, key: doc.id }));
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    organization && dispatch(fetchDocumentsOfOrganization(organization.id));
  },[organization])

  React.useEffect(() => {
    const organizationColumns = getOrganizationColumns(t);
    const customColumn = organizationColumns.map((column) => ({
      ...column,
      render: (value: any, info: any) => renderCell({ value, info, column }),
    }));

    setModifiedColumns(customColumn);
  }, [documents, i18n.language, deletingDocumentID, userData]);

  const deleteHandle = React.useCallback((targetDocument: IDocument) => {
    dispatch(deleteDocumentOfOrganization({doc_id: targetDocument.id, dataRoomId: dataRoom?.id! }))
  },[dataRoom]);

  const renderCell = React.useCallback((props: { value: any; info: IDocument; column: IColumn }) => {
    const { column, value, info } = props;
    const icon = availableIconFormat.includes(info.extension) ? info.extension : 'default';    
    if (column.key === 'name') {
      return (
        <span>
          <span>{documnentIcons[icon as keyof typeof documnentIcons]}</span>
          {value}
        </span>
      );
    } 

    if (column.key === 'modified_at') {      
      return info.modified_at 
        ? getDateWithTimeZone(info.modified_at, userData?.date_format || 'international')
        : '-'
    }
    if (column.key === 'delete') {
      return ( isDocumentDeleting && deletingDocumentID.includes(info.id))
        ? <div className={classes.deleteWrap}>
          <LoadingOutlined/>
        </div>  
        : <div onClick={() => deleteHandle(info)} className={classes.deleteWrap}>
          <DeleteOutlined className={classes.delete}/>
        </div>;
    } 
    return value;
  }, [deletingDocumentID, userData]);

  if (isDocumentsLoading) {
    return (
      <div className={classes.loaderWrap}>
        <TableLoader
          height={'calc(100vh - 200px)'}
          padding={10}
        />
      </div>
    )
  };

  return (
    <div className={classes.mainWrap}>
      <Dragger
        {...uploadProps}
        openFileDialogOnClick={false}
        style={{
          width: '100%',
          borderRadius: 3,
          border: `1px solid white`,
          cursor: 'auto',
          backgroundColor: 'white'
        }}
        // accept='.doc,.xls,.xlsx,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      >
        <Table
          scroll={{ y: 'calc(100vh - 320px)'}}
          columns={modifiedColumns}
          pagination={false}
          size='middle'
          className={classes.tableStyles}
          dataSource={dataSource}
          locale={{
            emptyText: (<div className={classes.emptyText}>
              <Empty description={null}/>
              <div>{t('Organizations.emptyDocs')}</div>
            </div>),
          }}
          onRow={(record) => {
            const onRowClick = () => {
              dispatch(setIsOpenViewer(true));
              dispatch(
                fetchDocumentToViewer({
                  token: record.token,
                  extension: record.extension,
                  roomId: record.room_id!,
                  id: record.id,
                  is_agreement: false,
                  locale: i18n.language,
                  is_organization: true,
                })
              );
            };

            return {
              onDoubleClick: onRowClick,
            };
          }}
        />
      </Dragger>

      <Button className={classes.addButton} onClick={onOpen}>
        {t('main.add')}
      </Button>    
    </div>
  );
};

export default OrganizationDocumentsTable;

import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import RowLoader from 'components/Sceletons/RowLoader';
import { TFunction } from 'i18next';
import { ChangeEvent, Dispatch, SetStateAction } from 'react';
import classes from './ChatTab.module.scss';

type Props = {
  searchValue: string;
  isLaoding: boolean;
  onCreateModalOpen: () => void;
  setSearchValue: Dispatch<SetStateAction<string>>;
  t: TFunction;
};

function ChatTabControls({ t, searchValue, isLaoding, onCreateModalOpen, setSearchValue }: Props) {
  const onChange = (event: ChangeEvent<HTMLInputElement>) => setSearchValue(event.target.value);

  return (
    <div className={classes.management}>
      {isLaoding ? (
        <RowLoader width={600} padding={'0 0 0 0'} height={32} borderradius={5} />
      ) : (
        <>
          <Input
            prefix={<SearchOutlined className={classes.management_searchIcon} />}
            type='search'
            placeholder={t('Chats.searchChat')}
            value={searchValue}
            onChange={onChange}
          />
          <Tooltip title={t('Chats.createButton')}>
            {/* <Popover
              trigger='click'
              overlayClassName={classes.management_create}
            > */}
            <Button
              type='text'
              icon={<PlusOutlined className={classes.create} />}
              onClick={onCreateModalOpen}
              className={classes.management_plus}
            />
            {/* </Popover> */}
          </Tooltip>
        </>
      )}
    </div>
  );
}

export default ChatTabControls;

import React from 'react';
import { Form, message } from 'antd';
import { UploadProps } from 'antd/es/upload';
import api from 'api';
import { AddingInfoModal, AddOrganizationModal, EditOrganizationModal, UploadModal, DeleteOrganizationModal } from 'components/Modals';
import OrganizationLoader from 'components/Sceletons/OrganizationLoader';
import WebViewer from 'components/Webviewer/WebViewer';
import dayjs from 'dayjs';
import { IWithWebkitPath } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchYourOrganization from 'store/reducers/organizationCreator';
import { setIsOpenViewer } from 'store/slices/viewerSlice';
import { setIsUploadingDocuments } from 'store/slices/windowStateSlice';
import classes from './Organizations.module.scss';
import NoOrganization from 'components/OrganizationComponents/NoOrganization';
import { setOrganization, setOrganizationDocuments, setOrganizationEmployees } from 'store/slices/organization';
import OrganizationTitle from 'components/OrganizationComponents/OrganizationTitle';
import OrganizationInfo from 'components/OrganizationComponents/OrganizationInfo';

export const errorsOrganizationText = ['organization_by_name_exists'];
export const errorsOrganizationValue = ['create', 'alreadyExists'];

const Organization = () => {
  const [isEditModalOpen, setIsEditModalOpen] = React.useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const [isAddModalOpen, setIsAddModalOpen] = React.useState<boolean>(false);
  const [isAddingInfoModalOpen, setIsAddingInfoModalOpen] = React.useState<boolean>(false);
  const [isAddModalLoading, setIsAddModalLoading] = React.useState<boolean>(false);
  const [fileList, setFileList] = React.useState<IWithWebkitPath[]>([]);
  const [isUploadModalOpen, setIsUploadModalOpen] = React.useState(false);

  const [creatingForm] = Form.useForm();  

  const { dataRoom } = useAppSelector(state => state.dataRoom);
  const { isOpenViewer, documentUrl, isDocumentError, isDocumentLoading } = useAppSelector(state => state.viewer);
  const { organization, isLoadingOrganization, documents } = useAppSelector(state => state.organization);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();  

  React.useEffect(() => {
    dispatch(fetchYourOrganization());
    return () => {
      onViewerClose();
      dispatch(setOrganization(null));
      dispatch(setOrganizationEmployees([]));
      dispatch(setOrganizationDocuments([]));
    }
  }, []);

  const onCreateOrganization = async (values: any) => {
    try {
      setIsAddModalLoading(true);
      const response = await api.createOrganization({
        ...values,
        details: {
          organization_name: values.name,
          organization_description: values.description || null,
          organization_link: values.organization_link || null,
        },
      });      
      
      dispatch(setOrganization({
        ...response.data,
          permission: {
          can_edit_organization_roles: true,
          can_view_organization_logs: true,
          can_edit_organization_settings: true,
          can_invite_organization_users: true,
          can_exclude_organization_users: true,
          can_edit_organization_user: true,
          can_delete_organization: true,
          can_view_organization_documents: true,
          can_view_organization_integrations: true,
          can_view_organization_bills: true
        }
      }));
      setIsAddModalOpen(false);

      message.success(t('Organizations.success.create'));
    } catch (err: any) {
      const indexValue = errorsOrganizationText.findIndex(el => el === err?.response?.data?.message) + 1;
      message.error(t(`Organizations.error.${errorsOrganizationValue[indexValue]}`));
    } finally {
      setIsAddModalLoading(false);
    }
  };

  // const checkNameField = (_: any, value: string) => {
  //   if (error || (existsNames.includes(value) && selectedOrganization?.name !== value)) {
  //     return Promise.reject(existsNames);
  //   } else {
  //     return Promise.resolve();
  //   }
  // };

  const handleUpload = async () => {
    dispatch(setIsUploadingDocuments(true));

    await Promise.all(
      fileList.map((filePath) => {
        const formData = new FormData();
        formData.append('document', filePath as any);
        formData.append('room', dataRoom?.id!);
        formData.append('organization_id', organization?.id!);

        return api.uploadOrganizationDocument(formData, filePath.name, dataRoom?.id!);
      })
    )
      .then((values) => {
        const createdDocuments = values.map((response) => ({...response.data, upload_at: dayjs().format('HH:mm:ss DD.MM.YYYY')}));
        dispatch(setOrganizationDocuments([...documents, ...createdDocuments]));
        message.success(t('Documents.success.uploadDocument'));
        onCancel();
      })
      .catch((error) => {
        message.error(t('Documents.error.uploadDocument'));
      })
      .finally(() => {
        dispatch(setIsUploadingDocuments(false));
        setFileList([]);
      });
  };

  const uploadProps: UploadProps = {
    multiple: true,
    style: {borderRadius: 4},
    beforeUpload: (file) => {
      const extension = file.name.match(/\.(\w+)$/)![1].toLowerCase();      
      if(extension === 'exe' || extension === 'bin'){
        message.error(t('Documents.error.notSupported'));
        return false;
      };
      setFileList((prev) => [...prev, file]);
      return false;
    },
    onDrop(event) {
      setIsUploadModalOpen(true);
    },
    showUploadList: false,
    fileList,
  };

  // const onSelectOrganization = (organization: IOrganization) => {
  //   dispatch(setSelectedOrganization(organization));
  //   dispatch(fetchDocumentsOfOrganization(organization.id));
  // }

  const onAddClick = () => setIsAddModalOpen(true);

  const onCancel = () => {
    setIsUploadModalOpen(false);
    setFileList([]);
  };

  const onOpen = () => setIsUploadModalOpen(true);
  const onViewerClose = () => dispatch(setIsOpenViewer(false));

  return (
    <div className={classes.wrapper}>
      {isLoadingOrganization ? (
          <OrganizationLoader />
        ) : (
          <div className={classes.organization}>
            {organization 
              ? <div className={classes.orgWrap}>
                <OrganizationTitle
                  organization={organization}
                  setIsEditModalOpen={setIsEditModalOpen}
                  setIsDeleteModalOpen={setIsDeleteModalOpen}
                />
                <OrganizationInfo
                  uploadProps={uploadProps}
                  onOpen={onOpen}
                />
              </div>
              :  <NoOrganization onAddClick={onAddClick}/>
            }
          </div>
        )}
      
      

      {isEditModalOpen && (
        <React.Suspense fallback={<div />}>
          <EditOrganizationModal
            open={isEditModalOpen}
            setIsEditModalOpen={setIsEditModalOpen}
            t={t}
          />
        </React.Suspense>
      )} 

      {isDeleteModalOpen && (
        <React.Suspense fallback={<div />}>
          <DeleteOrganizationModal
            open={isDeleteModalOpen}
            setIsOpen={setIsDeleteModalOpen}
          />
        </React.Suspense>
      )} 

      {isAddingInfoModalOpen && (
        <React.Suspense fallback={<div />}>
          <AddingInfoModal
            open={isAddingInfoModalOpen}
            setIsOpen={setIsAddingInfoModalOpen}
            t={t}
          />
        </React.Suspense>
      )}

      {isAddModalOpen && (
        <React.Suspense fallback={<div />}>
          <AddOrganizationModal
            form={creatingForm}
            onSubmit={onCreateOrganization}
            open={isAddModalOpen}
            loading={isAddModalLoading}
            setIsAddModalOpen={setIsAddModalOpen}
            t={t}
          />
        </React.Suspense>
      )}

      {isUploadModalOpen && (
        <React.Suspense fallback={<div></div>}>
          <UploadModal
            uploadProps={uploadProps}
            folderUploadProps={uploadProps}
            fileList={fileList}
            setFileList={setFileList}
            open={isUploadModalOpen}
            onCancel={onCancel}
            handleUpload={handleUpload}
            entityName={organization?.name}
            title={t('Documents.modals.upload.organization')}
          />
        </React.Suspense>
      )}

      {isOpenViewer && (
        <React.Suspense>
          <WebViewer
            isLoading={isDocumentLoading}
            errorMessage={isDocumentError}
            url={documentUrl}
            onClose={onViewerClose}
          />
        </React.Suspense>
      )}
    </div>
  );
};

export default Organization;

import { MessengerWithTabs } from 'components/Messanger';
import MessangerLoader from 'components/Sceletons/MessangerLoader';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import fetchChatData from 'store/reducers/chatCreator';
import { setChatMessage, setChatStatus } from 'store/slices/chatsSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import classes from './Chat.module.scss';

dayjs.extend(utc);

const Chat = () => {
  const { isLoadedSession, roomChatLoading, selectedChat } = useAppSelector((state) => state.chat);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { userData } = useAppSelector((state) => state.userData);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSelectedKeys(['chat']));
  }, [])

  const onSendMessage = (message: string) => {
    if (!message || !message.trim()) return;
    const id = dayjs().utc().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
    dispatch(
      setChatMessage({
        message: message,
        id,
        status: 'pending',
      })
    );
    dispatch(
      fetchChatData({
        data: {
          status: 'pending',
          message: message,
          created_at: id,
          timestamp: id,
          email: userData?.email!,
          user_name: `${userData?.first_name} ${userData?.last_name}`,
          room_id: dataRoom?.id!,
          chat_id: selectedChat?.id!
        },
        type: 'add',
      })
    );
    dispatch(setChatStatus('send'));
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.mainSpace}>
        {dataRoom?.id && isLoadedSession && !roomChatLoading ? (
          <MessengerWithTabs onSendMessage={onSendMessage}/>
        ) : (
          <MessangerLoader />
        )}
      </div>
    </div>
  );
};

export default Chat;

import { Button, Card, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { DeleteOutlined, InboxOutlined, SearchOutlined } from '@ant-design/icons';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import classes from './DocumentsReport.module.scss';
import { TFunction } from 'i18next';

type Props = {
  uploadProps: UploadProps;
  uploadFiles: UploadFile[];
  onDelete: () => void;
  handleUpload: () => void;
  t: TFunction;
};

export default function DocumentResearchUpload({
  uploadProps,
  uploadFiles,
  onDelete,
  handleUpload,
  t,
}: Props) {
  return (
    <div className={classes.research}>
      <Upload.Dragger
        style={{ borderRadius: 4 }}
        className={classes.dragger}
        showUploadList={false}
        {...uploadProps}
      >
        <p className='ant-upload-drag-icon'>
          <InboxOutlined />
        </p>
        <p>{t('Documents.modals.upload.dropText')}</p>
      </Upload.Dragger>

      <Card
        className={classes.uplaodList}
        title={<Tooltip title={t('Report.upload.title')}>{t('Report.upload.title')}</Tooltip>}
        actions={[
          <Button
            onClick={handleUpload}
            type='primary'
            disabled={!uploadFiles.length}
            className={classes.researchButton}
            loading={uploadProps.disabled}
            icon={<SearchOutlined />}
          >
            {t('Report.upload.button')}
          </Button>,
        ]}
      >
        {uploadFiles.map((file) => {
          const type = file.name.match(/\.(\w+)$/)![1];
          const icon = availableIconFormat.includes(type!) ? type : 'default';
          return (
            <div key={`file-${file.uid}`} className={classes.fileItem}>
              <div className={classes.fileItem_icon}>{documnentIcons[icon as keyof typeof documnentIcons]}</div>
              <div className={classes.fileItem_name}>{file.name}</div>
              <div className={classes.fileItem_deleteIcon} onClick={onDelete}>
                <DeleteOutlined />
              </div>
            </div>
          );
        })}
      </Card>
    </div>
  );
}

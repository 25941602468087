import { PDFPageProxy } from 'pdfjs-dist';
import { FC, RefObject, useCallback, useEffect, useState } from 'react';
import { ListOnScrollProps, VariableSizeList } from 'react-window';
import Page from './Page';
import PdfPage from './PdfPage';
import '../Viewer.module.scss';

type ViewerProps = {
  pagesCount: number;
  handleGetPdfPage: (idx: any) => Promise<PDFPageProxy>;
  windowRef: RefObject<VariableSizeList>;
  width: string | number;
  height: string | number;
  gap?: number;
  scale: number;
  setIsAvailableAccept?: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentPage?:  React.Dispatch<React.SetStateAction<number>>;
};

const Viewer: FC<ViewerProps> = ({
  pagesCount,
  handleGetPdfPage,
  windowRef,
  scale,
  height = '400px',
  width,
  gap = 1,
  setIsAvailableAccept,
  setCurrentPage
}) => {
  const [pages, setPages] = useState<PDFPageProxy[]>([]);
  const [overallHeight, setOverallHeight] = useState(0);

  useEffect(() => {
    windowRef.current!.resetAfterIndex(0);
  }, [scale]);

  useEffect(() => {
    if (pages[0]) {      
      const heightOfparent = Number(windowRef.current?.props.height);
      const documentHeight = pages.reduce((accumulator, page) => accumulator + page.getViewport({ scale })?.height, 0);
      setOverallHeight && setOverallHeight(documentHeight);
      if( heightOfparent >= documentHeight) setIsAvailableAccept && setIsAvailableAccept(true);
    }
    return() => {
      setIsAvailableAccept && setIsAvailableAccept(false);
      setOverallHeight && setOverallHeight(0);
    }
  },[pages, scale])

  const fetchPage = useCallback(
    async (index: number) => {
      if (pages[index]) return;

      const page = await handleGetPdfPage(index);
      setPages((prev) => {
        const next = [...prev];
        next[index] = page;
        return next;
      });

      windowRef.current!.resetAfterIndex(index);
    },
    [handleGetPdfPage, pages]
  );

  const handleItemSize = (index: number) => {
    const page = pages[index];
    if (page) {
      const viewport = page.getViewport({ scale });
      const itemSize = viewport.height + gap;
      return itemSize;
    }
    return 50;
  };

  const scrollDocument = useCallback((e: ListOnScrollProps) => {
    const payloadHeight = Math.abs(overallHeight! - Number(height) - (pagesCount * 2));
    const pageHeight = overallHeight / pagesCount;  
    setCurrentPage && setCurrentPage(Math.ceil(e.scrollOffset/pageHeight) || 1);
    if (e.scrollOffset >= Math.abs(payloadHeight)) {
      setIsAvailableAccept && setIsAvailableAccept(true);
    }
  }, [height, overallHeight, pagesCount]);

  return (
    <VariableSizeList
      onScroll={scrollDocument}
      ref={windowRef}
      width={width}
      height={height}
      itemCount={pagesCount}
      itemSize={handleItemSize}
    >
      {({ index, style }) => {
        fetchPage(index);
        return (
          <Page style={style}>
            <PdfPage style={style} page={pages[index]} scale={scale} />
          </Page>
        );
      }}
    </VariableSizeList>
  );
};

export default Viewer;

import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import colors from './scss/variables/colors.module.scss';
import store from './store/index';
import "./i18t";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// To not display an error "findDOMNode is deprecated in StrictMode"
const consoleError = console.error.bind(console);
console.error = (errObj, ...args) => {
  if (args.includes('findDOMNode')) return;
  consoleError(errObj, ...args);

};

root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider theme={{
          token: {
            colorPrimary: colors.primary100,
            colorBgTextActive: colors.hover100,
            colorBgTextHover: colors.hover100,
            colorFillAlter: colors.hover100,
            // colorBgElevated: colors.verylightgrey,
            colorBgLayout: colors.verylightgrey,
          },
          components: {
            Tabs: {
              colorBgContainer: '#fff',
              colorBgTextActive: colors.verylightgrey,
              colorBgTextHover: colors.verylightgrey,
              colorFillAlter: colors.verylightgrey,
            },
          },
        }}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  // </React.StrictMode>
);

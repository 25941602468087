import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { fetchAgreements } from 'store/reducers/agreementsCreator';

type infoType = {
  status: 0 | 1 | 2;
  id: string;
  comment: string;
  entity: string;
  author: any;
}

type initialStateType = {
  agreements: ISimpleGroupForAprove[];
  argeementsErorr: string;
  isArgeementsLoading: boolean;
  myAgreement: ISimpleUserForAprove | null;
  needAgreementFromUsers: ISimpleUserForAprove[];
  agreementInfo: infoType | null;
  defaultActiveAgreementKey: string;
};

const initialState: initialStateType = {
  agreements: [],
  argeementsErorr: '',
  isArgeementsLoading: false,
  myAgreement: null,
  needAgreementFromUsers: [],
  agreementInfo: null,
  defaultActiveAgreementKey: 'documents_notes',
};

const agreementsSlice = createSlice({
  name: 'agreements',
  initialState,
  reducers: {
    resetAgreementState: (state) => {
      state.agreements = [];
      state.myAgreement = null;
      state.needAgreementFromUsers = [];
      state.agreementInfo = null;
    },
    setAgreementState: (state, action: PayloadAction<{
      agreements: ISimpleGroupForAprove[],
      myAgreement: ISimpleUserForAprove | null,
      needAgreementFromUsers: ISimpleUserForAprove[]
    }>) => {
      state.agreements = action.payload.agreements;
      state.myAgreement = action.payload.myAgreement;
      state.needAgreementFromUsers = action.payload.needAgreementFromUsers;
    },
    setAgreementInfo: (state, action: PayloadAction<infoType>) => {
      state.agreementInfo = action.payload;
    },
    
    setDefaultActiveKey: (state, action: PayloadAction<string>) => {
      state.defaultActiveAgreementKey = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAgreements.pending, (state) => {
      state.isArgeementsLoading = true;
      state.argeementsErorr = '';
    });

    builder.addCase(fetchAgreements.rejected, (state, action: any) => {
      if ( action?.error?.message !== 'Aborted' ) {
        state.isArgeementsLoading = false;
        state.argeementsErorr = action.payload;
      }
    });

    builder.addCase(fetchAgreements.fulfilled, (state, action: PayloadAction<{
      agreements: ISimpleGroupForAprove[],
      myAgreement: ISimpleUserForAprove | null,
      needAgreementFromUsers: ISimpleUserForAprove[],
      info: infoType
    }>) => {
      state.argeementsErorr = '';
      state.isArgeementsLoading = false;
      state.agreements = action.payload.agreements;
      state.myAgreement = action.payload.myAgreement;
      state.needAgreementFromUsers = action.payload.needAgreementFromUsers;
      state.agreementInfo = action.payload.info;
    });
  },
});

const agreementsReducer = agreementsSlice.reducer;

export const { resetAgreementState, setAgreementState, setAgreementInfo, setDefaultActiveKey } = agreementsSlice.actions;
export default agreementsReducer;

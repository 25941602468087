import React from 'react';
import { Form, Input, Modal, message } from 'antd';
import { TFunction } from 'i18next';
import { getArrayObjectFromKeys } from 'helpers/getArrayFromObjectKeys'; // этот хелпер нужен чтобы из динамических ключей сделать массив из объектов чтобы можно было по нему проходиться и генерить инпуты
import { exceptionOrganizationKeys } from 'helpers/exceptionKeys'; // Это массив исключений полей в организации. Так как поля будут добавляться динамически это те поля которые не нужно будет изменять или переводить в массив значений
import classes from './EditOrganizationModal.module.scss';
import { useAppDispatch, useAppSelector } from 'store/hook';
import api from 'api';
import { createLogChangeOrg } from 'components/Modals/EditOrganizationModal/createLogChangeOrg';
import { errorsOrganizationText, errorsOrganizationValue } from 'routes/Organization/Organization';
import { OrgFields } from 'helpers/organizationFieldsDictionary';
import { validateField } from '../AddingInfoModal/validateField';
import { setOrganization } from 'store/slices/organization';

type EditOrganizationModalType = {
  t: TFunction;
  open: boolean;
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function EditOrganizationModal ({
  t,
  open,
  setIsEditModalOpen,
}: EditOrganizationModalType) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [submittable, setSubmittable] = React.useState(false);
  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const { organization } = useAppSelector((state) => state.organization);
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const organizationForInputs: any = {
    ...organization,
    description: organization?.description || '',
    link: organization?.link || '',
  }

  const fields = getArrayObjectFromKeys(organizationForInputs);
  const filteredFields = fields.filter((field) => !exceptionOrganizationKeys.includes(field.label));
  const dispatch = useAppDispatch();
  
  React.useEffect(() => {
    let countOfNewValues = 0;
    for(let field in values) {
      countOfNewValues++;
      if (values[field] === organizationForInputs[field]) countOfNewValues--;
    }
    if (Boolean(countOfNewValues)) setSubmittable(true);
    else setSubmittable(false);

    if (Boolean(countOfNewValues)) {
      values?.[OrgFields.Name]
        ? form.validateFields([OrgFields.Name]).then(
          () => setSubmittable(true),
          () => setSubmittable(false),
        )
        : setSubmittable(false)
    }
  }, [values, organization]);

  React.useEffect(() => {
    filteredFields?.forEach((field) => {
      form.setFieldValue(field.label, field.value);
    });
  }, []);

  const onFinish = async (values: any) => {    
    setIsLoading(true);
    try {
      const newOrganization = { ...organization, ...values };
      let details = organization && createLogChangeOrg(organization, newOrganization);
      let newOrganizationInfo: any = {
        id: organization?.id,
        user_id: organization?.user_id,
      }

      for (let field in values) {
        const after = values[field];
        const before = organization && organization[field];
        if ( before !== after ) {
          newOrganizationInfo = { ...newOrganizationInfo, [field]: after }
        }
      }

      await api.editOrganization({
        ...newOrganizationInfo,
        details,
      }, dataRoom?.id!);
      dispatch(setOrganization(newOrganization));
      setIsEditModalOpen(false);
      message.success(t('Organizations.card.success'));
    } catch (err: any) {
      const indexValue = errorsOrganizationText.findIndex(el => el === err?.response?.data?.error) + 1;
      const errorValue = errorsOrganizationValue[indexValue];
      message.error(t(`Organizations.card.${errorValue}`));
    } finally {
      setIsLoading(false);
    }
  };

  const fieldValidadors = {
    name: [
      { required: true },
    ],
    link: [
      { whitespace: true },
      validateField(OrgFields.Link, t)
    ],
    description: []
  }

  return (
    <Modal
      centered
      onCancel={() => setIsEditModalOpen(false)}
      onOk={form.submit}
      okButtonProps={{disabled: !submittable}}
      open={open}
      cancelText={t('main.cancelButton')}
      okText={t('Users.HelperMenu.edit')}
      title={`${t('Users.HelperMenu.edit')} ${organization?.name}`}
      className={classes.modal}
      confirmLoading={isLoading}
    >
      <Form form={form} layout='vertical' onFinish={onFinish} className={classes.form}>
        {filteredFields.map((field) => (
          <Form.Item
            rules={fieldValidadors[field.label as keyof typeof fieldValidadors]}
            className={classes.form_item}
            name={field.label}
            label={ field.label === 'name' ? t(`Organizations.modals.add.${field.label}`) : t(`Organizations.fields.${field.label}`)}
            key={field.key}
          >
            { field.label === 'description' 
              ? <Input.TextArea autoSize={{ minRows: 5, maxRows: 15 }} placeholder={t(`Organizations.modals.add.placeholders.${field.label}`)}/>
              : <Input placeholder={t(`Organizations.modals.add.placeholders.${field.label}`)}/>
            }
          </Form.Item>
        ))}
      </Form>
    </Modal>
  );
};

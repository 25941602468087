import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INotes } from "interfaces";
import { fetchAllNotes } from "store/reducers/notesCreator";

type initialStateType = {
  isNotesLoading: boolean;
  isNotesErorr: string;
  notes: INotes[] | null;
  isOpenNotesDeleteModal: boolean;
  selectedNote: string;
};

const initialState: initialStateType = {
  isNotesErorr: "",
  isNotesLoading: false,
  notes: null,
  isOpenNotesDeleteModal: false,
  selectedNote: "",
};

const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setIsOpenNotesDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenNotesDeleteModal = action.payload;
    },

    setSelectedNote: (state, action: PayloadAction<string>) => {
      state.selectedNote = action.payload;
    },

    setNotes: (state, action: PayloadAction<INotes[]>) => {
      state.notes = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllNotes.pending, (state) => {
      state.isNotesLoading = true;
      state.isNotesErorr = "";
    });

    builder.addCase(fetchAllNotes.rejected, (state, action: any) => {
      state.isNotesErorr = action.payload;
      state.isNotesLoading = false;
    });

    builder.addCase(fetchAllNotes.fulfilled, (state, action: PayloadAction<INotes[]>) => {
      state.isNotesErorr = "";
      state.isNotesLoading = false;
      state.notes = action.payload;
    });
  },
});

const notesReducer = notesSlice.reducer;

export const { setIsOpenNotesDeleteModal, setSelectedNote, setNotes } =
  notesSlice.actions;
export default notesReducer;

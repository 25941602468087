import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';

const ManagementLoader = (props: any) => {

  const { t } = useTranslation();

  return <ContentLoader 
    speed={2}
    width={800}
    height={160}
    title={t('Documents.table.loader')}
    viewBox='0 0 800 160'
    backgroundColor='#f3f3f3'
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='15' y='15' rx='4' ry='4' width='600' height='32' /> 
    <rect x='15' y='71' rx='4' ry='4' width='200' height='16' /> 
    <rect x='15' y='110' rx='4' ry='4' width='600' height='32' />
  </ContentLoader>
}

export default ManagementLoader

import { AxiosResponse } from 'axios';

const getFilenameFromResponse = (response: AxiosResponse) => {
  const disposition = response.headers['content-disposition']
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  const matches = filenameRegex.exec(disposition) || [];
  const filename = decodeURI(matches[1]);  
  return filename
}

export default getFilenameFromResponse;

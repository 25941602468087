import { requestTemplate } from 'api';
import { IDocument, IOrganization, OrganizationInfo, IEmployee } from 'interfaces';
import { NewInvitesResponse } from './users';

type SimpleResponse = {
  success: boolean;
  message: string;
};

//SIDES
const getRoomOrganizations = (roomId: string) => requestTemplate.get<IOrganization[]>(`/organizations/by_room/?room_id=${roomId}`);
const getOrganizationDocs = (organization_id: string) => requestTemplate.get<IDocument[]>(`/organizations/documents/?organization_id=${organization_id}`);

//ORGANIZATIONS
const getUserOrganizations = () => requestTemplate.get<IOrganization>(`/organizations/by_user/`);
const inviteEmployees = (payload: {type: string, emails: string[], role_name: string, organization_id: string}) =>
  requestTemplate.post<NewInvitesResponse>(`/organizations/invite/`, payload);

const createOrganization = (
  organization: IOrganization
) => requestTemplate.post<IOrganization>(`/organizations/?action=create`, organization);

const editOrganization = (
  organization: IOrganization, room_id: string
) => requestTemplate.put<SimpleResponse>(`/organizations/?action=update&id=${room_id}&organization_id=${organization.id}`, organization);

const deleteOrganization = (organization_id: string) => requestTemplate.delete<SimpleResponse>(`/organizations/?action=delete&organization_id=${organization_id}`);

const uploadOrganizationDocument = (
  payload: FormData, document_name: string, roomId: string
) => requestTemplate.post<IDocument>(`/organizations/upload/?action=create&document_name=${document_name}&id=${roomId}`, payload, {
  headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'application/json'},
});

const getOrganizationDocuments = (organization_id: string) => requestTemplate.get<IDocument[]>(`/organizations/documents/?organization_id=${organization_id }`);
const getOrganizationInfo = (organization_id: string) => requestTemplate.get<OrganizationInfo>(`/organizations/info/?organization_id=${organization_id}`);

const getOrganizationEmployees = (organization_id: string) => 
  requestTemplate.get<IEmployee[]>(`/organizations/employee/?organization_id=${organization_id}`);

const changeEmployeeRole = (payload: {user_id: string, role_name: string}) =>
  requestTemplate.put<string>('/organizations/employee/', payload);

const deleteEmployee = (user_id: string) =>
  requestTemplate.delete<string>(`/organizations/employee/?user_id=${user_id}`);

const getScheduleData = () => requestTemplate.get<any>(`/bookings/all_events/`);

const getOrganizationSubscriptions = () => requestTemplate.get<any>(`/organizations/subscriptions/`);
const createOrganizationSubscriptions = (payload: any) => requestTemplate.post<any>(`/organizations/subscriptions/`, payload);
const downloadOrganizationSubscriptions = () => requestTemplate.post<Blob>(`/organizations/subscriptions/report/`, undefined, {responseType: 'blob'});

const getOrganizationRequests = () => requestTemplate.get<any>(`/organizations/requests/`);
const createOrganizationRequests = (payload: any) => requestTemplate.post<any>(`/organizations/requests/`, payload);
const changeOrganizationRequestStatus = (payload: any) => requestTemplate.put(`/organizations/requests/`, payload);
const downloadOrganizationRequests = () =>
  requestTemplate.post<Blob>(`/organizations/requests/report/`, undefined, {responseType: 'blob'});

const getOrganizationServices = (organization_id: string) => requestTemplate.get(`/organizations/services/?organization_id=${organization_id}`);
const getUserBills = () => requestTemplate.get(`/organizations/billing/`);
const getOrganizationBills = (organization_id: string) => requestTemplate.get(`/organizations/invoice_billing/?organization_id=${organization_id}`);

const createUserBill = (payload: any) => requestTemplate.post(`/organizations/billing/`, payload)

const getVisitors = () => requestTemplate.get(`/organizations/visitors/`)
const createVisit = (payload: any) => requestTemplate.post(`/organizations/visitors/`, payload)
const getOrganizationUserOptions = (organization_id: string) => 
  requestTemplate.get<IEmployee[]>(`/bookings/options/?organization_id=${organization_id}`);

const organizations = {
  getRoomOrganizations,
  getOrganizationDocs,
  getUserOrganizations,
  inviteEmployees,
  createOrganization,
  editOrganization,
  deleteOrganization,
  uploadOrganizationDocument,
  getOrganizationDocuments,
  getOrganizationInfo,
  getOrganizationEmployees,
  changeEmployeeRole,
  deleteEmployee,
  getOrganizationRequests,
  createOrganizationRequests,
  getOrganizationSubscriptions,
  createOrganizationSubscriptions,
  getScheduleData,
  changeOrganizationRequestStatus,
  downloadOrganizationRequests,
  downloadOrganizationSubscriptions,
  getOrganizationServices,
  getUserBills,
  getOrganizationBills,
  createUserBill,
  getVisitors,
  createVisit,
  getOrganizationUserOptions,
};

export default organizations;

import axios from 'axios'
import { murmurhash3_32_gc } from "./hasher";

export const getWebglID = (debug: boolean) => {
  try {
      const canvas = document.createElement('canvas');
      const ctx: any = canvas.getContext('webgl');
      canvas.width = 256;
      canvas.height = 128;

      const f = 'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}';
      const g = 'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}';
      const h = ctx.createBuffer();

      ctx.bindBuffer(ctx.ARRAY_BUFFER, h);

      const i = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.7321, 0]);

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ctx.bufferData(ctx.ARRAY_BUFFER, i, ctx.STATIC_DRAW), (h.itemSize = 3), (h.numItems = 3);

      const j = ctx.createProgram();
      const k = ctx.createShader(ctx.VERTEX_SHADER);

      ctx.shaderSource(k, f);
      ctx.compileShader(k);

      const l = ctx.createShader(ctx.FRAGMENT_SHADER);

      ctx.shaderSource(l, g);
      ctx.compileShader(l);
      ctx.attachShader(j, k);
      ctx.attachShader(j, l);
      ctx.linkProgram(j);
      ctx.useProgram(j);

      j.vertexPosAttrib = ctx.getAttribLocation(j, 'attrVertex');
      j.offsetUniform = ctx.getUniformLocation(j, 'uniformOffset');

      ctx.enableVertexAttribArray(j.vertexPosArray);
      ctx.vertexAttribPointer(j.vertexPosAttrib, h.itemSize, ctx.FLOAT, !1, 0, 0);
      ctx.uniform2f(j.offsetUniform, 1, 1);
      ctx.drawArrays(ctx.TRIANGLE_STRIP, 0, h.numItems);

      const n = new Uint8Array(canvas.width * canvas.height * 4);
      ctx.readPixels(0, 0, canvas.width, canvas.height, ctx.RGBA, ctx.UNSIGNED_BYTE, n);

      const result = JSON.stringify(n).replace(/,?"[0-9]+":/g, '');

      if (debug) {
          document.body.appendChild(canvas);
      } else {
          ctx.clear(ctx.COLOR_BUFFER_BIT | ctx.DEPTH_BUFFER_BIT | ctx.STENCIL_BUFFER_BIT);
      }     
      return murmurhash3_32_gc(result);
  } catch {
      return null;
  }
};

export const getCanvasID = (debug: boolean) => {
  try {
      const canvas = document.createElement('canvas');
      const ctx: any = canvas.getContext('2d');
      const text = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ`~1!2@3#4$5%6^7&8*9(0)-_=+[{]}|;:',<.>/?";
      ctx.textBaseline = 'top';
      ctx.font = "14px 'Arial'";
      ctx.textBaseline = 'alphabetic';
      ctx.fillStyle = '#f60';
      ctx.fillRect(125, 1, 62, 20);
      ctx.fillStyle = '#069';
      ctx.fillText(text, 2, 15);
      ctx.fillStyle = 'rgba(102, 204, 0, 0.7)';
      ctx.fillText(text, 4, 17);

      const result = canvas.toDataURL();

      if (debug) {
          document.body.appendChild(canvas);
      } else {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
      }

      return murmurhash3_32_gc(result);
  } catch {
      return null;
  }
};

export const getWebglInfo = () => {
  try {
      const ctx: any = document.createElement('canvas').getContext('webgl');
      const ext = ctx?.getExtension("WEBGL_debug_renderer_info");

      const result = {
          VERSION: ctx.getParameter(ctx.VERSION),
          // SHADING_LANGUAGE_VERSION: ctx.getParameter(ctx.SHADING_LANGUAGE_VERSION),
          // VENDOR: ctx.getParameter(ctx.VENDOR),
          SUPORTED_EXTENSIONS: ctx.getSupportedExtensions(),
          glVendor: ext && ctx?.getParameter(ext.UNMASKED_VENDOR_WEBGL),
          glRenderer: ext && ctx?.getParameter(ext.UNMASKED_RENDERER_WEBGL),
      };

      return result;
  } catch {
      return null;
  }
};

export const getInfoIP = async () => {
  try {
    const res = await axios.get('https://ipapi.co/json/')  
    return res.data;
  } catch {
    return null;
  }
}

export const getDeviceType = () => {
  const ua = navigator.userAgent;
  const tabletRegex = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i;
  const mobRegex = /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/;

  if (tabletRegex.test(ua)) {return "tablet"};
  if (mobRegex.test(ua)) {return "mobile"};
  return "desktop";
}

export const getListFonts = () => {
  let { fonts } = document;
  const it = fonts.entries();

  let arr = [];
  let done = false;

  while (!done) {
      const font = it?.next();
      if (!font?.done) {
          arr.push(font?.value[0]?.family);
      } else {
          done = font?.done;
      }
  }
  return Array.from(new Set(arr));
}

export const getAccept = () => {
  const req = new XMLHttpRequest();
  req.open('GET', String(document.location), false);
  req.send(null);  
  const headers = req.getAllResponseHeaders().toLowerCase().split('\r\n');
  const headersWithoutDate: any = []
  headers.forEach(el => el.slice(0,4)==='date' ? null : headersWithoutDate.push(el))
  const response = headersWithoutDate?.join(', ')  
  
  return response
}

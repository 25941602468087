import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';

type ViewerStateType = {
  isOpenViewer: boolean;
  documentUrl: any;
  documentName: string;
  documentExtention: string;
  isDocumentLoading: boolean;
  isDocumentError: string;
};

const initialState: ViewerStateType = {
  isOpenViewer: false,
  documentUrl: '',
  documentName: '',
  documentExtention: '',
  isDocumentLoading: false,
  isDocumentError: '',
};

const viewerSlice = createSlice({
  name: 'webviewer',
  initialState,
  reducers: {
    setIsOpenViewer: (state, action: PayloadAction<boolean>) => {
      state.isOpenViewer = action.payload;
    },

    setIsDocumentError: (state, action: PayloadAction<string>) => {
      state.isDocumentError = action.payload;
    },

    resetViewerSlice: (state) => {
      state.isDocumentLoading = false;
      state.isDocumentError = '';
      state.documentExtention = '';
      state.documentUrl = '';
      state.documentName = '';
    }
  },

  extraReducers: (builder) => {
    builder.addCase(fetchDocumentToViewer.pending, (state) => {
      state.isDocumentLoading = true;
      state.isDocumentError = '';
      state.documentUrl = '';
    });

    builder.addCase(fetchDocumentToViewer.rejected, (state, action: any) => {
      state.isDocumentLoading = false;
      state.isDocumentError = action.payload;
      state.documentUrl = '';
    });

    builder.addCase(fetchDocumentToViewer.fulfilled, (state, action: PayloadAction<{fileURL: any, extension: string, filename: string}>) => {      
      state.isDocumentLoading = false;
      state.isDocumentError = '';
      state.documentExtention = action.payload.extension;
      state.documentUrl = action.payload.fileURL;
      state.documentName = action.payload.filename;
    });
  },
});

const viewerReducer = viewerSlice.reducer;

export const { setIsOpenViewer, setIsDocumentError, resetViewerSlice } = viewerSlice.actions;
export default viewerReducer;

import { Button, Table, message } from 'antd';
import { TableRowSelection } from 'antd/es/table/interface';
import api from 'api';
import { DeleteModal } from 'components/Modals';
import { TFunction } from 'i18next';
import { IDataRoom, IUsers } from 'interfaces';
import { Key, useEffect, useMemo, useState } from 'react';
import classes from './RoomAction.module.scss';
import getColumns from './columns';

type Props = {
  room: IDataRoom;
  users: IUsers[];
  clearData: (type: 'documents' | 'users') => void;
  t: TFunction;
};

type dataSourceType = {
  id: string;
  email: string;
  key: Key;
  role: string;
  first_name: string;
};

export default function DeleteRoomUsersTable({ room, users, t, clearData }: Props) {
  const [selectedUsers, setSelectedUsers] = useState<{ key: string; first_name: string }[]>([]);
  const [isDeletingDocuments, setIsDeletingDocuments] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [dataSource, setDataSource] = useState<dataSourceType[]>([]);

  const onCancelConfirm = () => setIsConfirmModalOpen(false);
  const onOpenConfirm = () => setIsConfirmModalOpen(true);

  const columns = getColumns(t);

  useEffect(() => {
    setDataSource(
      users.map(({ role, first_name, last_name, id, email }) => ({
        id,
        email,
        key: id,
        role: t(`Users.modals.invite.roles.${role}`),
        first_name: `${first_name} ${last_name}`,
      }))
    );
  }, []);

  const { keys, names } = useMemo(() => {
    const keys = selectedUsers.map(({ key }) => key);
    const names = selectedUsers.map(({ first_name }) => first_name);

    return {
      keys,
      names,
    };
  }, [selectedUsers]);

  const rowSelection: TableRowSelection<(typeof dataSource)[0]> = {
    selectedRowKeys: keys,
    onChange: (selectedRowsKeys, selectedRows) => {
      setSelectedUsers(selectedRows.map(({ key, first_name }) => ({ key: key as string, first_name })));
    },
  };

  const onDelete = async () => {
    try {
      setIsDeletingDocuments(true);
      const responses = await Promise.all(keys.map((selectedUser) => api.deleteSelectedUser(selectedUser, room.id)));
      setDataSource((prev) => {
        const filteredData = prev.filter(({ key }) => !keys.includes(key as string));
        !filteredData.length && clearData('users');

        return filteredData;
      });

      setSelectedUsers([]);
      message.success(t('Users.confirm.userExcludeSuccess'));
    } catch (Err) {
      message.error(t('Users.confirm.userExcludeError'));
    } finally {
      setIsDeletingDocuments(false);
      onCancelConfirm();
    }
  };

  return (
    <div>
      <Table
        className={classes.table}
        dataSource={dataSource}
        columns={columns}
        rowSelection={rowSelection}
        pagination={false}
        rowClassName={classes.pointer}
        scroll={{ y: '400' }}
        onRow={(record) => {
          const onRowCLick = () => {
            setSelectedUsers((prev) => {
              return prev.find(({ key }) => key === record.key)
                ? prev.filter(({ key }) => key !== record.key)
                : [...prev, { key: record.key as string, first_name: record.first_name }];
            });
          };

          return {
            onClick: onRowCLick,
          };
        }}
      />

      <Button loading={isDeletingDocuments} type='primary' disabled={!selectedUsers.length} onClick={onOpenConfirm}>
        {t('Documents.contextMenu.delete')}
      </Button>

      {isConfirmModalOpen && (
        <DeleteModal
          isOpen={isConfirmModalOpen}
          onCancel={onCancelConfirm}
          submit={onDelete}
          isLoading={isDeletingDocuments}
          title={t('Users.confirm.excludeTitle')}
          text={`${t('Users.confirm.confirmUserExclude')}: ${names.map((name) => name).join(', ')}?`}
          okText={t('Users.confirm.yes')}
        />
      )}
    </div>
  );
}

import React from 'react'
import SidesComponent from 'components/SidesComponent/SidesComponent';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useParams } from 'react-router';
import { fetchSidesOfRoom } from 'store/reducers/sidesCreator';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { setIsOrgMenuCollapsed, setSelectedOrganization } from 'store/slices/dataSidesSlice';

export default function Sides() {
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { sideOrganisations } = useAppSelector((store) => store.sides);

  const dispatch = useAppDispatch();
  const params = useParams();
  const roomId = params.room?.replace('room=', '');

  React.useEffect(() => {
    dispatch(setSelectedKeys(['sides']));
    if (dataRoom && sideOrganisations.length === 0 && dataRoom?.id === roomId) {
      dispatch(fetchSidesOfRoom(dataRoom?.id!));
    }
    window.scroll(0,0);
    return () => {
      dispatch(setIsOrgMenuCollapsed(true));
      dispatch(setSelectedOrganization(null));
    }
  }, [dataRoom?.id])

  return (
    <SidesComponent />
  )
}

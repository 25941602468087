import React from 'react';
import classes from './UsersTab.module.scss';
import { IUsers } from 'interfaces';
import { Button, Popconfirm, Select, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { defaultGroups } from 'store/reducers/usersGroupCreator';
import classNames from 'classnames';
import { MessageOutlined, QuestionCircleOutlined, UserDeleteOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { createChat } from 'store/reducers/chatCreator';
import { useNavigate } from 'react-router-dom';

type UserCardProps = {
  user: IUsers;
  currentSegment: 'main' | 'room'
  changeRole: (user: IUsers, group_id: string, targetGroupName?: string) => Promise<void>;
  deleteUser: (selectedUser: IUsers) => Promise<void>;
  markText: (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => void;
  searchValue: string;
  onUserClick: (event: any, userId: string) => void;
};

const UserCard = ({ user, changeRole, deleteUser, onUserClick, markText, searchValue, currentSegment }: UserCardProps) => {
  const markerTitle = React.useRef<HTMLDivElement>(null);
  const markerDesc = React.useRef<HTMLDivElement>(null);
  const [selectValue, setSelectValue] = React.useState('');
  const [openPopconfirm, setOpenPopconfirm] = React.useState<boolean>(false);
  const [isDeletingUser, setIsDeletingUser] = React.useState<boolean>(false);
  const [installingNewRole, setInstallingNewRole] = React.useState(false);

  const { userPermissions, userData } = useAppSelector((state) => state.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { groups } = useAppSelector((state) => state.dataUsers);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    currentSegment === 'room' && setSelectValue(user?.group[0]);
  }, [currentSegment])

  const onChatClick = async () => {
    const { payload } = (await dispatch(
      createChat({
        data: {
          members_ids: [userData?.id!, user.id],
          entity_type: 2,
          room_id: dataRoom?.id!,
          title: user.email,
        },
        ownerId: userData?.id!,
      })
    )) as any;
    const pathTypes = [`document/${payload.chat.id}`, ``, `user/${payload.chat.id}`];
    navigate(`/room/${dataRoom?.custom_url || dataRoom?.id}/chat/${pathTypes[payload.chat.entity_type]}`);
  };

  const { t } = useTranslation();
  const isRoomOwnerCard = user?.id === dataRoom?.owner_id;

  React.useEffect(() => {
    if (markText) {
      const text = searchValue || '';
      markText(text, markerTitle);
      markText(text, markerDesc);
    }
  }, [searchValue]);

  const fullName = `${user.first_name} ${user.last_name}`;
  
  const groupsSelectOptions = groups.map((group) => ({
    label: defaultGroups.includes(group.name) ? t(`Users.table.${group.name}`) : group.name,
    value: group.id,
    key: group.key,
  }));

  const isAvailableEditUserGroup = userPermissions?.can_edit_user_group;
  const targetGroupName = groupsSelectOptions.find((el) => el.value === selectValue)?.label;

  const handleChangeGroup = async (value: any) => {
    setInstallingNewRole(true);
    try {
      await changeRole(user, value, targetGroupName);
    } catch (err) {
      setSelectValue(user?.group[0]);
    } finally {
      setInstallingNewRole(false);
    }
  };

  const deleteSelectedUser = async (e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => {
    setIsDeletingUser(true);
    setOpenPopconfirm(false);
    e?.stopPropagation();
    try {
      await deleteUser(user);
    } catch (e) {
    } finally {
      setIsDeletingUser(false);
    }
  };

  const handleOpen = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setOpenPopconfirm(true);
  };

  const hidePopconfirm = () => {
    setOpenPopconfirm(false);
  };

  return (
    <div
      className={classNames(classes.userCard, installingNewRole ? classes.loading : null)}
      onClick={(event) => onUserClick(event, user.id)}
    >
      <div className={classes.jusctifySpace}>
        <div ref={markerTitle} className={classes.userTitle}>
          {isRoomOwnerCard && (
            <Tooltip title={t('Users.table.owner')}>
              <FontAwesomeIcon style={{ paddingRight: 5 }} color={'gold'} icon={faCrown} />
            </Tooltip>
          )}
          <Tooltip title={fullName} mouseEnterDelay={1}>
            {fullName}
          </Tooltip>
        </div>

        <div>
          {userData?.email !== user.email && (
            <Tooltip title={"Go to user's chat"} zIndex={1000}>
              <MessageOutlined onClick={onChatClick} />
            </Tooltip>
          )}

          {currentSegment !== 'main' && userPermissions?.can_exclude_users && !isRoomOwnerCard && (
            <Popconfirm
              title={t('Users.confirm.excludeTitle')}
              onPopupClick={(e) => e.stopPropagation()}
              open={openPopconfirm}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              cancelText={t('Users.confirm.cancel')}
              onCancel={hidePopconfirm}
              okText={t('Users.confirm.yes')}
              onConfirm={deleteSelectedUser}
              zIndex={1100}
              description={`${t('Users.confirm.confirmUserExclude')}
              ${fullName} (${user?.email})
              ${t('Users.confirm.fromRoom')}`}
            >
              <Tooltip title={t('Users.managment.exclude')} zIndex={1000} mouseEnterDelay={0.4}>
                <Button
                  loading={isDeletingUser}
                  onClick={handleOpen}
                  size='small'
                  type='text'
                  icon={<UserDeleteOutlined className={classes.deleteIcon} />}
                />
              </Tooltip>
            </Popconfirm>
          )}
        </div>
      </div>
      <div className={classes.jusctifySpace}>
        <div ref={markerDesc} className={classes.userDescription}>
          <Tooltip title={user.email} mouseEnterDelay={1}>
            {user.email}
          </Tooltip>
        </div>

        {currentSegment !== 'main' && isAvailableEditUserGroup && !isRoomOwnerCard ? (
          <Select
            placeholder={t('personalInfo.none')}
            size='small'
            loading={installingNewRole}
            disabled={user?.id === dataRoom?.owner_id || installingNewRole}
            options={groupsSelectOptions}
            value={selectValue}
            popupMatchSelectWidth={false}
            onClick={(e) => e.stopPropagation()}
            onChange={handleChangeGroup}
          />
        ) : (
          currentSegment !== 'main' && <div className={classes.userDescription}>{targetGroupName}</div>
        )}
      </div>
    </div>
  );
};

export default UserCard;

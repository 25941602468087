import { TFunction } from "i18next";

export const getlogsColumns = (t: TFunction) => [
  {
    title: t('Logs.table.columns.username'),
    dataIndex: 'username',
    key: 'username',
    width: '18%',
  },
  {
    title: t('Logs.table.columns.email'),
    dataIndex: 'email',
    key: 'email',
    width: '12%',
  },
  {
    title: t('Logs.table.columns.action'),
    dataIndex: 'action',
    key: 'action',
    width: '22%',
  },
  {
    title: t('Logs.table.columns.document'),
    dataIndex: 'entityname',
    key: 'entityname',
    width: '18%',
  },
  {
    title: t('Logs.table.columns.date'),
    dataIndex: 'date',
    key: 'date',
    width: '12%',
  },
  {
    title: t('Logs.table.columns.time'),
    dataIndex: 'time',
    key: 'time',
    width: '12%',
  },
  {
    title: t('Logs.table.columns.details'),
    dataIndex: 'details',
    key: 'details',
    width: '6%',
  },
];

export const getlogsColumnsForPrint = (t: TFunction) => [
  ...getlogsColumns(t).filter(el => el.dataIndex !== 'details'),
  {
    title: t('Logs.logsModal.document_size'),
    dataIndex: 'size',
    key: 'size',
    width: '6%',
  },
];




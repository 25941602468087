import { TFunction } from "i18next";

export const getSubscriptionsColumn = (t: TFunction) => [
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    width: '70%',
  },
  {
    title: t("Logs.table.columns.date"),
    dataIndex: 'created_at',
    key: 'created_at',
    width: '30%',
  },
];
  
import { Modal, Row } from 'antd';
import React from 'react';
import classes from './EmployeeInfoModal.module.scss';
import { IEmployee } from 'interfaces';
import { useTranslation } from 'react-i18next';

type EmployeeInfoModalType = {
  open: boolean;
  onCancel: () => void;
  selectedEmployee: IEmployee;
};

const EmployeeInfoModal = ({open, onCancel, selectedEmployee}: EmployeeInfoModalType) => {
  const { t } = useTranslation();
  const targetKeys = ['first_name', 'last_name', 'email', 'phone_number', 'role_name'];
  const employeeData = selectedEmployee && Object.entries(selectedEmployee);

  return (
    <Modal
      title={`${t('Organizations.infoModal.title')} ${selectedEmployee?.first_name} ${selectedEmployee?.last_name}`}
      centered
      width={'60%'}
      open={open}
      onCancel={onCancel}
      okButtonProps={{ style: { display: 'none' } }}
      cancelText={t('Organizations.infoModal.close')}
      className={classes.infoModal}
    >
      <Row className={classes.infoWrap}>
        {employeeData?.map((row) => {
          return (
            targetKeys.includes(row[0]) && (
              <div className={classes.rowDetails} key={row[0]}>
                <div className={classes.key}>{t(`Organizations.infoModal.${row[0]}`)}</div>
                <div className={classes.value}>
                  { row[0] === 'role_name'
                    ? t(`Organizations.invite.${row[1]}`)
                    : row[1]
                  }
                </div>
              </div>
            )
          );
        })}
      </Row>
    </Modal>
  );
}

export default EmployeeInfoModal;

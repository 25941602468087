import dayjs from 'dayjs';
import { dateFormatType } from 'interfaces';

export const dateFormatCase = {
  international: 'DD-MM-YYYY HH:mm:ss',
  usa: 'MM-DD-YYYY hh:mm a',
};

const getDateWithTimeZone = (date: string, type: dateFormatType) => {
  const timeOffset = dayjs().utcOffset();
  const unixTimeWithTimeZone = dayjs(date).valueOf() + timeOffset * 60 * 1000;
  return dayjs(unixTimeWithTimeZone).format(dateFormatCase[type]);
};


export default getDateWithTimeZone;

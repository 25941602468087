import React from 'react'
import { IInvitations } from 'interfaces'
import classes from './Invitations.module.scss';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';

type InvitationsSettingsProps = {
  invitations: IInvitations[];
  setInvitations: React.Dispatch<React.SetStateAction<IInvitations[]>>;
}

export default function InvitationsSettings({invitations, setInvitations}: InvitationsSettingsProps) {
  const { t } = useTranslation();

  const onChangeSettings = (value: React.ChangeEvent<HTMLInputElement>, field: keyof IInvitations, index: number) => {
    setInvitations((prev: IInvitations[]) => prev.map((invite: IInvitations, i: number) => i === index
      ? {...invite, [field]: value.target.value}
      : invite
    ))
  }

  return (
    
    <div style={{height: 'calc(100% - 22px)'}}>
      <div className={classes.titleInvitations}>{t('Users.modals.invite.invitations')}</div>
      <div className={classes.settingsWrap}>
        <div className={classes.columnsSettings}>
          <div>{t('Users.modals.invite.settings.email')}*</div>
          <div>{t('Users.modals.invite.settings.name')}</div>
          <div>{t('Users.modals.invite.settings.organization')}</div>
          <div>{t('Users.modals.invite.settings.position')}</div>
          <div>{t('Users.modals.invite.settings.phone')}</div>
        </div>
        <div className={classes.subWrap}>{invitations?.map((invitation: IInvitations, index: number) => {
          return (
          <div key={`settings_${index}`} className={classes.columnsSettings}>
            <Input
              disabled
              value={invitation.email}
            />
            <Input
              onChange={value => onChangeSettings(value, 'fullName', index)}
              value={invitation.fullName}
            />
            <Input
              onChange={value => onChangeSettings(value, 'organization', index)}
              value={invitation.organization}
            />
            <Input
              onChange={value => onChangeSettings(value, 'position', index)}
              value={invitation.position}
            />
            <Input
              onChange={value => onChangeSettings(value, 'phone', index)}
              value={invitation.phone}
            />
          </div>
        )})}</div>
      </div>
    </div>
  )
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import prepareDocuments from 'helpers/prepareDocuments';

export const fetchFoldersForPermissions = createAsyncThunk(
  'dataPermissions/fetchAll',
  async (props: { roomId: string }, thunkAPI) => {
    try {

      const folders = await api.getFolders(props.roomId);
        
      const foldersForPermissions = prepareDocuments([], folders.data);
      
      return foldersForPermissions;
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

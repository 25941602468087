import React from 'react';
import { Modal, Select, Form, message } from 'antd';
import classes from './PermissionsModal.module.scss';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import PermissionsTable from 'components/PermissionsTable/PermissionsTable';
import { IDataUserTable, IFolder } from 'interfaces';
import { fetchAvailableDocuments } from 'store/reducers/documentsCreator';
import { useTranslation } from 'react-i18next';
import { defaultGroups, fetchOnlyGroups } from 'store/reducers/usersGroupCreator';
import createPermissionTableData from 'helpers/createPermissionTableData';
import { setCurrentFolder } from 'store/slices/dataDocumentsSlice';
import { setDataForPermissionTable, setIsLoadingPermissions } from 'store/slices/dataPermissionsSlice'
import { setIsEmptyGroupResponce } from 'store/slices/dataUsersSlice';
import { fetchFoldersForPermissions } from 'store/reducers/permissionsCreator';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { setUserAndRoomStatus } from 'store/slices/userDataSlice';

type PermissionsModalProps = {
  open: boolean
  onCancel: () => void;
  reloadSelected?: () => void;
  choosenRole?: IDataUserTable | null;
}

export default function PermissionsModal({open, onCancel, reloadSelected}: PermissionsModalProps) {
  const [form] = Form.useForm();

  const [dataWithRootFolder, setDataWithRootFolder] = React.useState<IFolder[]>([]);
  const [firstRenderData, setFirstRenderData] = React.useState<IFolder[]>([]);
  const [isPermissionsUpdating ,setIsPermissionsUpdating] = React.useState(false);
  const [choosenGroup, setChoosenGroup] = React.useState<any>();

  const { groups, isGroupLoading, isEmptyGroupResponce } = useAppSelector((state) => state.dataUsers);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { currentFolder } = useAppSelector(store => store.documents);
  const { foldersForPermissions, dataForPermissionTable, rootFolderForPermissions, isLoadingPermissions } = useAppSelector((store) => store.permissions); 

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  
  const dataSourceForPermissionTable = dataForPermissionTable[0] ? dataForPermissionTable : foldersForPermissions;  

  const filteredPermissions = dataWithRootFolder.filter((folder => {
    const sameFolder = firstRenderData.find(el => el.key === folder.key);
    return String([...(sameFolder?.permissions as string[])].sort()) !== String([...(folder?.permissions as string[])].sort());
  }));

  React.useEffect(()=> {
    !foldersForPermissions[0] && dataRoom && dispatch(fetchFoldersForPermissions({roomId: dataRoom?.id}));
  },[]);

  React.useEffect(()=> {
    choosenGroup && getGroupPermissions()
  },[choosenGroup]);

  React.useEffect(() => {
    getGroups();
    return () => {
      dispatch(setIsEmptyGroupResponce(false))
    }
  }, [groups]);

  React.useEffect(()=> {    
    setFirstRenderData(createPermissionTableData(dataSourceForPermissionTable, t, rootFolderForPermissions));
    setDataWithRootFolder(createPermissionTableData(dataSourceForPermissionTable, t, rootFolderForPermissions));
  },[foldersForPermissions, dataForPermissionTable]);

  const getGroupPermissions = async () => {
    dispatch(setIsLoadingPermissions(true));
    try {
      const { data: foldersPermissionsReq } = await api.getFoldersPermissions({room_id: dataRoom?.id!, group_id: choosenGroup});      
      const onlyFolders = foldersPermissionsReq.filter((el: any) => el.id !== 0);
      const rootFolder = foldersPermissionsReq.find((el: any) => el.id === 0);      
      dispatch(setDataForPermissionTable({documents: [], folders: onlyFolders[0] ? onlyFolders : dataRoom?.folders, rootFolder}))
    } catch (e) {
      message.error(t('Documents.error.gettingPermissions'));
    } finally {
      dispatch(setIsLoadingPermissions(false));
    }
  }  

  const getGroups = async () => {
    if ( groups[0] ) {
      form.setFieldsValue({ group: groups[0]?.id});
      setChoosenGroup(groups[0]?.id);
    } else if (isEmptyGroupResponce) {
      return;
    } else {
      dispatch(fetchOnlyGroups(dataRoom?.id!));
    }
  };

  const navigatToRoot = () => {
    reloadSelected && reloadSelected();
    dispatch(setCurrentFolder(currentFolder.slice(0, 0)))
  };

  const sendPermissions = async () => {
    form.submit();
    const group = form.getFieldValue('group');

    if (group) {
      setIsPermissionsUpdating(true)
      await Promise.all(filteredPermissions.map((folder) => {
        const permissionsBefore = firstRenderData.find(el => el.key === folder.key)?.permissions;
        const targetGroup = groups.find(somegroup => somegroup.id === group);
        
        return api.setFolderPermission({
          permission_types: folder.permissions as string[],
          folder_id: folder.id!,
          room_id: dataRoom?.id!,
          group_id: group,
          details: {
            before: permissionsBefore,
            after: folder.permissions,
            folder_id: folder.id,
            folder_name: folder.name,
            group_name: targetGroup?.name,
            group_role: targetGroup?.role?.name || '-',
            group_id: targetGroup?.id,
          }
        })
        .then(value => {
          message.success(t('Documents.error.permissionsSuccess'))
        })
        .catch(e => {
          message.warning(`${t('Documents.error.permissionsFailed')}' ${dataRoom?.folders.find(someFolder => folder.id === someFolder.id)?.name || 'ROOT_FOLDER'}'`);
        })
      }))
      .finally(() => {
        setIsPermissionsUpdating(false);
        // navigatToRoot()
        onCancel()
        dispatch(fetchAvailableDocuments({ roomId: dataRoom?.id!, folderId: currentFolder[currentFolder.length - 1]?.id }));
        dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      })
    }
  };  

  return (
    <Modal
      styles={{ body: {maxHeight: '96vh'} }}
      width={'98%'}
      title={`${t('Documents.modals.permissions.title')} '${dataRoom?.name}'`}
      open={open}
      centered={true}
      onCancel={onCancel}
      okButtonProps={{ disabled: !Boolean(filteredPermissions[0]) }}
      okText={t('Documents.modals.permissions.saveButton')}
      onOk={sendPermissions}
      confirmLoading={isPermissionsUpdating}
      cancelText={t('main.cancelButton')}
      className={classes.modalPermissions}
    >
      <Form className={classes.modalWrapPermissions} form={form}>

        <Form.Item
          className={classes.title}
          label={t('Documents.modals.permissions.selectGroup')}
          name='group'
          rules={[{ required: true, message: t('Documents.error.permissionsValidate') }]}
        >
          <Select
            loading={isGroupLoading}
            className={classes.select}
            placeholder={t('Documents.modals.permissions.select')}
            options={groups?.map((group: {name: string, id: string}) => ({
              value: group.id,
              label: defaultGroups.includes(group.name)
                ? t(`Users.table.${group.name}`)
                : group.name,
            }))}
            disabled={isLoadingPermissions}
            onChange={setChoosenGroup}
          /> 
        </Form.Item>
          
        <PermissionsTable
          dataWithRootFolder={dataWithRootFolder}
          setDataWithRootFolder={setDataWithRootFolder}
          heightTable={`calc(100vh - 220px)`}
          scrollTable={`calc(100vh - 325px)`}
          scrollLoader={'calc(100vh - 550px)'}
          parent={'changePermissions'}
        />
        
      </Form>

      {isPermissionsUpdating &&
        <UploadingComponent />
      }
    </Modal>
  )
}


import React from 'react'
import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';
import colors from '../../scss/variables/colors.module.scss';

const SimpleLoader = (props: any) => { 
  
  const { t } = useTranslation();

  return <ContentLoader 
    speed={2}
    width={80}
    height={24}
    title={t('Documents.table.loader')}
    viewBox='0 0 80 24'
    backgroundColor={colors.verylightgrey}
    foregroundColor='#ecebeb'
    {...props}
  >
    <rect x='0' y='0' rx='3' ry='3' width='80' height='24' />
  </ContentLoader>
}
export default SimpleLoader

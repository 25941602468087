import { TFunction } from 'i18next';

export type IRoleType = {
  value?: string;
  label?: string;
  name?: string;
  id?: string;
  permissions?: string[];
};

export const getRolesDictionary = (t: TFunction):  IRoleType[] => [
  {
    value: 'full_administrator',
    label: t('Users.modals.invite.roles.full_administrator'),
  },
  {
    value: 'restricted_administrator',
    label: t('Users.modals.invite.roles.restricted_administrator'),
  },
  {
    value: 'service_administrator',
    label: t('Users.modals.invite.roles.service_administrator'),
  },
  {
    value: 'user',
    label: t('Users.modals.invite.roles.user'),
  },
]

import { DeleteOutlined, FontColorsOutlined, SwapOutlined, InfoCircleOutlined, ShareAltOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import cn from 'classnames'
import { TFunction } from 'i18next'
import { IDocument, IFolder } from 'interfaces'
import React from 'react'
import classes from './ContextMenu.module.scss'
import { useAppSelector } from 'store/hook'

type ContextMenuProps = {
  entity: IDocument | IFolder;
  t: TFunction;
  deleteModalHandle: () => void;
  moveModalOpenHandle: () => void;
  renameModalHandle: () => void;
  shareModalHandle?: () => void;
  detailsModalHandle?: () => void;
}

export default function ContextMenu({entity, t, deleteModalHandle, moveModalOpenHandle, renameModalHandle, shareModalHandle, detailsModalHandle}: ContextMenuProps) {
  const { currentFolder, folders } = useAppSelector(store => store.documents);
  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);
  const { userPermissions } = useAppSelector(store => store.userData);

  const isRootFolder = !currentFolder[0];
  const targetFolderPermissions = isRootFolder
    ? userRootPermissions.permissions
    : userFoldersPermissions?.find(folder => folder.id === currentFolder[0].id)?.permissions;
    
  const isEntityForbiddenToDelete = !entity.permissions?.includes('can_delete');
  const isEntityForbiddenToShare = !entity.permissions?.includes('can_sharing') || entity.is_confidential;
  const isAvailableMoveDocuments = targetFolderPermissions?.includes('can_delete');
  const isAvailableFoldersToMove = Boolean(currentFolder[0] || folders[0])
  const isAvailableEditingFolders = userPermissions.can_edit_folder_permissions;
  
  return (
    <>
      {entity.type === 'folder' ?
        <div>
          <Button
            onClick={deleteModalHandle}
            icon={<DeleteOutlined />}
            className={classes.button}
            disabled={isEntityForbiddenToDelete}
          >
            {t('Documents.contextMenu.delete')}
          </Button>
          <Button
            icon={<FontColorsOutlined />}
            className={classes.button}
            onClick={renameModalHandle}
            disabled={!isAvailableEditingFolders}
          >
            {t('Documents.contextMenu.rename')}
          </Button>
          {/* <Button
            onClick={detailsModalHandle}
            icon={<InfoCircleOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.details')}
          </Button> */}
        </div>
        : <div>
          {/* <Button
            disabled
            icon={<FontColorsOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.rename')}
          </Button> */}
          <Button
            onClick={moveModalOpenHandle}
            icon={<SwapOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={!isAvailableMoveDocuments || !isAvailableFoldersToMove}
          >
            {t('Documents.contextMenu.move')}
          </Button>
          <Button
            onClick={deleteModalHandle}
            icon={<DeleteOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={isEntityForbiddenToDelete}
          >
            {t('Documents.contextMenu.delete')}
          </Button>
          <Button
            onClick={shareModalHandle}
            icon={<ShareAltOutlined />}
            className={cn(classes.button, classes.hovered)}
            disabled={isEntityForbiddenToShare}
          >
            {t('Documents.contextMenu.sharing')}
          </Button>
          <Button
            onClick={detailsModalHandle}
            icon={<InfoCircleOutlined />}
            className={classes.button}
          >
            {t('Documents.contextMenu.details')}
          </Button>
        </div>
      }
    </>
  )
}

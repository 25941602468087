import React from 'react';
import { Button, Input, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { PlusOutlined, SearchOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { setIsConfigureRolesOpen } from 'store/slices/dataUsersSlice';
import classes from './UsersTab.module.scss';
import RowLoader from 'components/Sceletons/RowLoader';
import colors from '../../../scss/variables/colors.module.scss';

type UsersTabControlProps = {
  setIsInviteUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoadingUsersInfo: boolean;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
};

const UsersTabControl = ({setIsInviteUserOpen, isLoadingUsersInfo, onSearch, searchValue}: UsersTabControlProps) => {
  const { isGroupLoading } = useAppSelector((state) => state.dataUsers);
  const { userPermissions } = useAppSelector((state) => state.userData);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const isAvailableInviteUsers = userPermissions?.can_invite_users;
  const isAvailableToEditRoles = userPermissions?.can_edit_roles;

  const onConfigureRolesClick = () => dispatch(setIsConfigureRolesOpen(true));

  return (
    <div className={classes.conrolWrap}>
      {isLoadingUsersInfo ? (
        <RowLoader width={600} padding={'0 0 0 0'} height={32} borderradius={5}/>
      ) : (
        <>
          <Input  
            placeholder={t('Users.Tabs.searchUser')}
            onChange={onSearch}
            allowClear
            value={searchValue}
            prefix={<SearchOutlined style={{color: 'grey'}}/>}
          />

          {isAvailableToEditRoles &&
            <Tooltip title={t('Users.managment.roles')}>
              <Button
                onClick={onConfigureRolesClick}
                type='text'
                icon={<UsergroupAddOutlined style={{ color: colors.primary100, fontSize: 22 }}/>}
              />
            </Tooltip>
          }

          {isAvailableInviteUsers &&
            <Tooltip title={t('Users.managment.invite')}>
              <Button
                onClick={() => !isGroupLoading && setIsInviteUserOpen(true)}
                style={{marginRight: 1}}
                type='text'
                icon={<PlusOutlined style={{ color: colors.primary100, fontSize: 22 }}/>}
              />
            </Tooltip>
          }
        </>
      )}
    </div>
  );
}

export default UsersTabControl;

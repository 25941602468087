import { EyeOutlined, DownloadOutlined, CloseOutlined, DeleteOutlined, UploadOutlined, ShareAltOutlined } from '@ant-design/icons';
import classes from '../PermissionsTable.module.scss'
import { TFunction } from 'i18next';

export const getPermissionsColumns = (t: TFunction) => [
  {
    title: t('Documents.modals.permissions.titleShort'),
    dataIndex: 'name',
    key: 'name',
    width: '25%',
  },
  {
    title: <div className={classes.iconWrap}>
      <UploadOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.upload')}</div>
    </div>,
    dataIndex: 'can_upload',
    key: 'can_upload',
  },
  {
    title: <div className={classes.iconWrap}>
      <DeleteOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.delete')}</div>
    </div>,
    dataIndex: 'can_delete',
    key: 'can_delete',
  },

  {
    title: <div className={classes.iconWrap}>
      <ShareAltOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.can_sharing')}</div>
    </div>,
    dataIndex: 'can_sharing',
    key: 'can_sharing',
  },
  {
    title: <div className={classes.iconWrap}>
      <DownloadOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.download')}</div>
    </div>,
    dataIndex: 'can_download',
    key: 'can_download',
  },
  {
    title: <div className={classes.iconWrap}>
      <DownloadOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.download_pdf')}</div>
    </div>,
    dataIndex: 'can_download_pdf',
    key: 'can_download_pdf',
  },
  {
    title: <div className={classes.iconWrap}>
      <EyeOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.view')}</div>
    </div>,
    dataIndex: 'can_view',
    key: 'can_view',
  },
  {
    title: <div className={classes.iconWrap}>
      <CloseOutlined className={classes.icon}/>
      <div>{t('Documents.modals.permissions.columns.none')}</div>
    </div>,
    dataIndex: 'none_column',
    key: 'none_column',
  },
];

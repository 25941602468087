import React, { useState, ChangeEvent } from 'react';
import { Input, Modal, message, Form, InputRef } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setIsFolderCreateOpen } from 'store/slices/dataRoomSlice';
import classes from './NewFolderModal.module.scss';
import { useTranslation } from 'react-i18next';
import api from 'api';
import { setUserPermissions } from 'store/slices/dataPermissionsSlice';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';

type NewFolderModalType = {
  roomId: string;
  open: boolean;
  resetNotes: () => void;
  reloadSelected: () => void;
};

export default function NewFolderModal ({ roomId, open, resetNotes, reloadSelected }: NewFolderModalType) {
  const [form] = Form.useForm();
  const textInput = React.useRef<InputRef | null>(null);

  const [folderName, setFolderName] = useState<string>('');
  const [isFolderCreating, setIsfolderCreating] = useState<boolean>(false);

  const { userFoldersPermissions, userRootPermissions } = useAppSelector((store) => store.permissions);
  const { currentFolder } = useAppSelector((store) => store.documents);

  const currentFolderId = currentFolder[0] ? currentFolder[currentFolder.length - 1]?.id : null;

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  React.useEffect(() => textInput.current?.focus(), [])

  const onFolderCreate = async () => {
    setIsfolderCreating(true);
    try {
      const { data: { id, name }} = await api.createFolder({
        name: folderName,
        room: roomId,
        folder_id: currentFolderId!,
        details: {folder_name: folderName}
      });
      dispatch(setIsFolderCreateOpen(false));

      const newFolderWithAllPermissions = {
        id,
        name,
        can_edit_permissions: true,
        type: 'folder',
        key: `folder ${id} ${(userFoldersPermissions?.length || 0) + 1}`,
        children: null,
        clicked: false,
        permissions: ['can_view', 'can_download','can_download_pdf', 'can_delete', 'can_share', 'can_upload']
      }

      // добавляем все права пользователю на только что созданную папку
      dispatch(setUserPermissions({
        documents: [],
        rootFolder: userRootPermissions,
        folders: [
          ...userFoldersPermissions || [],
          newFolderWithAllPermissions
        ],
      }));

      message.success(t('Documents.success.createFolder'));
      reloadSelected();
      resetNotes();
    } catch (err) {
      const error = err as any;
      if (error?.response?.data?.message === 'Folder with this name already exists') {
        message.error(t('Documents.error.existsFolder'));
        return;
      }
      message.error(error.message || error.response?.detail[0].msg || t('Documents.error.newFolder'));
    } finally {
      // setTimeout(() => {
      //   dispatch(fetchAvailableDocuments({ roomId: dataRoom?.id!, folderId: currentFolderId! }));
      // }, 1000);
      setIsfolderCreating(false);
    }
  };
  const onCancelClick = () => dispatch(setIsFolderCreateOpen(false));
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => setFolderName(event.target.value);

  return (
    <Modal
      title={t('Documents.modals.newFolder.title')}
      centered
      open={open}
      onOk={form.submit}
      onCancel={onCancelClick}
      className={classes.createFolderModal}
      okButtonProps={{ loading: isFolderCreating, disabled: !(folderName && folderName.trim()) }}
      okText={t('Documents.modals.newFolder.create')}
      cancelText={t('main.cancelButton')}
    >
      <Form form={form} onFinish={onFolderCreate}>
        <Form.Item className={classes.folderName} rules={[{ required: true }]}>
          <Input
            ref={textInput}
            value={folderName}
            onChange={onInputChange}
            placeholder={t('Documents.modals.newFolder.placeholder')}
          />
        </Form.Item>
      </Form>

      
      {isFolderCreating &&
        <UploadingComponent />
      }
    </Modal>
  );
};

import React from 'react';
import { Button, Modal, message } from 'antd';
import classes from './PermissionsModal.module.scss';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import PermissionsTable from 'components/PermissionsTable/PermissionsTable';
import { IDataUserTable, IFolder } from 'interfaces';
import { fetchAvailableDocuments } from 'store/reducers/documentsCreator';
import { useTranslation } from 'react-i18next';
import createPermissionTableData, { createPermissionForGroup } from 'helpers/createPermissionTableData';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { setDataForPermissionTable, setIsLoadingPermissions } from 'store/slices/dataPermissionsSlice';
import { setGroupChoosen } from 'store/slices/dataUsersSlice';

type NewRolePermissionsProps = {
  open: boolean
  onCancel: () => void;
  choosenRole: IDataUserTable | null;
}

export default function NewRolePermissions({open, onCancel, choosenRole}: NewRolePermissionsProps) {
  const [isPermissionsUpdating ,setIsPermissionsUpdating] = React.useState(false);
  const [dataWithRootFolder, setDataWithRootFolder] = React.useState<IFolder[]>([]);
  const [choosenGroup, setChoosenGroup] = React.useState<string | null>(null);

  const someChanges = dataWithRootFolder.map(el => (el.permissions ? Boolean(el.permissions[0]) : false))

  const { groups } = useAppSelector((state) => state.dataUsers);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { currentFolder } = useAppSelector(store => store.documents);
  const { foldersForPermissions, dataForPermissionTable, rootFolderForPermissions } = useAppSelector((store) => store.permissions); 

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  
  const dataSourceForPermissionTable = dataForPermissionTable[0] ? dataForPermissionTable : foldersForPermissions;

  React.useEffect(() => {
    choosenGroup
      ? setDataWithRootFolder(createPermissionTableData(dataSourceForPermissionTable, t, rootFolderForPermissions))
      : setDataWithRootFolder(createPermissionForGroup(foldersForPermissions, t, choosenRole?.role!.name!));
  }, [dataForPermissionTable, foldersForPermissions, choosenRole]);

  React.useEffect(() => {
    choosenGroup && getGroupPermissions();
  }, [choosenGroup]);

  const getGroupPermissions = async () => {
    dispatch(setIsLoadingPermissions(true));
    try {
      const { data: foldersPermissionsReq } = await api.getFoldersPermissions({
        room_id: dataRoom?.id!,
        group_id: choosenGroup!,
      });
      const onlyFolders = foldersPermissionsReq.filter((el: any) => el.id !== 0);
      const rootFolder = foldersPermissionsReq.find((el: any) => el.id === 0);
      dispatch(
        setDataForPermissionTable({
          documents: [],
          folders: onlyFolders[0] ? onlyFolders : dataRoom?.folders,
          rootFolder,
        })
      );
    } catch (e) {
      setDataWithRootFolder(createPermissionForGroup(foldersForPermissions, t, choosenRole?.role!.name!));
      message.error(t('Documents.error.gettingPermissions'));
    } finally {
      dispatch(setIsLoadingPermissions(false));
    }
  };

  const sendPermissions = async () => {
    setIsPermissionsUpdating(true)
    await Promise.all(dataWithRootFolder.map((folder) => {
      const targetGroup = groups.find(somegroup => somegroup.id === choosenRole?.id);
      
      return api.setFolderPermission({
        permission_types: folder.permissions as string[],
        folder_id: folder.id!,
        room_id: dataRoom?.id!,
        group_id: choosenRole?.id!,
        details: {
          before: [],
          after: folder.permissions,
          folder_id: folder.id,
          folder_name: folder.name,
          group_name: targetGroup?.name,
          group_role: targetGroup?.role?.name || '-',
          group_id: targetGroup?.id,
        }
      })
      .then(value => {
        message.success(t('Documents.error.permissionsSuccess'))
      })
      .catch(e => {
        message.warning(`${t('Documents.error.permissionsFailed')}' ${dataRoom?.folders.find(someFolder => folder.id === someFolder.id)?.name || 'ROOT_FOLDER'}'`);
      })
    }))
    .finally(() => {
      dispatch(setGroupChoosen(null));
      setIsPermissionsUpdating(false);
      onCancel()
      dispatch(fetchAvailableDocuments({ roomId: dataRoom?.id!, folderId: currentFolder[currentFolder.length - 1]?.id }));
      dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
    })
  };
  
  const footer = (
    <div>
      <Button
        type='primary'
        disabled={!someChanges.includes(true)}
        onClick={sendPermissions}
        loading={isPermissionsUpdating}
      >
        {t('Documents.modals.permissions.saveButton')}
      </Button>
    </div>
  )

  return (
    <Modal
      styles={{ body: {maxHeight: '96vh'} }}
      width={'98%'}
      title={`${t('Users.modals.configureRoles.permissionsTitle')} - ${choosenRole?.name}`}
      open={open}
      centered={true}
      closable={false}
      closeIcon={null}
      footer={footer}
      className={classes.modalPermissions}
    >
      
      <PermissionsTable
        dataWithRootFolder={dataWithRootFolder}
        setDataWithRootFolder={setDataWithRootFolder}
        heightTable={`calc(100vh - 220px)`}
        scrollTable={`calc(100vh - 325px)`}
        scrollLoader={'calc(100vh - 550px)'}
        parent={'assignPermissions'}
        choosenGroup={choosenGroup}
        setChoosenGroup={setChoosenGroup}
      />

      {isPermissionsUpdating &&
        <UploadingComponent />
      }
    </Modal>
  )
}

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import classes from './Viewer.module.scss';

const Spinner = () => {
  return (
    <div className={classes.spinner}>
      <Spin>
        <LoadingOutlined style={{ fontSize: 40 }} />
      </Spin>
    </div>
  );
};

export default Spinner;

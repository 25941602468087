import { requestTemplate } from 'api';
import { IRoleType } from 'helpers/rolesDictionary';
import { IPermission, INewRole, IRole } from 'interfaces';

//ROLES
const getActualRoles = (room_id: string) => requestTemplate.get<IRoleType[]>(`/roles/?room_id=${room_id}`);
const getPermissions = (entityType: string) => requestTemplate.get<IPermission[]>(`/roles/permissions/?entity_type=${entityType}`);
const createRole = (newRole: INewRole) => requestTemplate.post<INewRole>(`/roles/`, newRole);
const updateRole = (newRole: IRole) => requestTemplate.put<IRole>(`/roles/?role_id=${newRole.id}`, newRole);
const deleteRole = (roleId: string) => requestTemplate.delete<any>(`/roles/?role_id=${roleId}`);

const roles = {
  getActualRoles,
  getPermissions,
  createRole,
  updateRole,
  deleteRole
};

export default roles;

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import { ISimpleGroupForAprove, ISimpleUserForAprove } from 'interfaces';
import { RootState } from 'store';

const fetchSigningState = createAsyncThunk(
  'signing/fetchAll',
  async (payload: {id: string}, thunkApi) => {
    try {
      const response = await api.getSigningState(payload.id); // api.getAgreements(payload.id, 'document');
      const signData = response.data?.message[0]      
      const signingState: ISimpleGroupForAprove[] = signData?.signing_state || [];

      const signingInfo = {
        id: signData.id,
        author: signData.author,
        comment: signData.decline_comment!,
        status: signData.status!,
        initiator_id: signData.initiator_id
      };
      
      let needSigningFromUsers: ISimpleUserForAprove[] = [];
      signingState?.forEach((group: any) => {
        needSigningFromUsers = group.users ? [...needSigningFromUsers, ...group.users] : needSigningFromUsers;
      });      

      const rootState = thunkApi.getState() as RootState;
      const { userData } = rootState.userData;
      const mySigningState = needSigningFromUsers.find(user => user.user_id === userData?.id) || null;

      return { signing: signingState, mySigning: mySigningState, needSigningFromUsers, signingInfo };
    } catch (err) {
      return thunkApi.rejectWithValue(`Failed to load agreements`);
    }
});

export { fetchSigningState };

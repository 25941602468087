import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import axios from 'axios';

const fetchSidesOfRoom = createAsyncThunk('sides/fetchSides', async (roomId: string, thunkAPI) => {
  try {
    const response = await api.getRoomOrganizations(roomId);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Download failed');
  }
});

const fetchUsersOrganizations = createAsyncThunk('sides/fetchUsersOrganizations', async(_, thunkAPI)  => {
  try {    
    const response = await api.getUserOrganizations();
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Download failed');
  }
});

const fetchOrganizationDocuments = createAsyncThunk('sides/fetchOrganizationDocuments', async(organization_id: string, thunkAPI)  => {
  try {    
    const response = await api.getOrganizationDocs(organization_id);
    return response.data;
  } catch (err) {
    return thunkAPI.rejectWithValue('Download failed');
  }
});

export const fetchOrganizationInfo = createAsyncThunk('organization/fetchInfo', async (organization_id: string, thunkApi) => {
    try{
      const response = await api.getOrganizationInfo(organization_id);      
      return response.data;
    }catch (err: any) {
      if (axios.isAxiosError(err)) {
        thunkApi.rejectWithValue(err.response?.data);
      } else {
        thunkApi.rejectWithValue(err.message);
      }
      return {};
    }
  }
)

export { fetchSidesOfRoom, fetchUsersOrganizations, fetchOrganizationDocuments };

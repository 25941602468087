import type { LatLngTuple } from 'leaflet';
import { MapContainer } from 'react-leaflet';

import LocationTile from './LocationTile';
import Leaflet from 'leaflet';
import { Card } from 'antd';
import { ILogs } from 'interfaces';
import { useEffect, useState } from 'react';
import retinaMarker from 'leaflet/dist/images/marker-icon-2x.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import shadowMarker from 'leaflet/dist/images/marker-shadow.png';
import classes from './Map.module.scss';

type Props = {
  zoom: number;
  position?: LatLngTuple;
  choosenLog: ILogs;
};

const MapView = ({ zoom, position, choosenLog }: Props) => {
  const [showMap, setShowMap] = useState(false);

  useEffect(() => {
    // @ts-ignore
    delete L.Icon.Default.prototype._getIconUrl;
    Leaflet.Icon.Default.mergeOptions({
      iconRetinaUrl: retinaMarker,
      iconUrl: icon,
      shadowUrl: shadowMarker,
    });

    setShowMap(true);
  }, []);

  const initialPosition = choosenLog.details?.location.split(',') || position;

  return (
    <Card bordered={false}>
      {showMap && (
        <MapContainer
          className={classes.mapContainer}
          center={initialPosition}
          zoom={zoom}
          scrollWheelZoom={false}
          minZoom={1}
          maxZoom={18}
        >
          <LocationTile choosenLog={choosenLog} zoom={zoom} initialPosition={initialPosition} />
        </MapContainer>
      )}
    </Card>
  );
};

export default MapView;


export const requestsColumn = [
    {
        title: 'Full name',
        dataIndex: 'full_name',
        key: 'full_name',
        width: '15%',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        width: '20%',
    },
    {
        title: 'Phone number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        width: '15%',
    },
    {
        title: 'Citizenship',
        dataIndex: 'citizenship',
        key: 'citizenship',
        width: '8%',
    },
    {
        title: 'Residence',
        dataIndex: 'current_residence',
        key: 'current_residence',
        width: '8%',
    },
    {
        title: 'Method',
        dataIndex: 'preferred_method',
        key: 'preferred_method',
        width: '8%',
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        width: '13%',
    },

    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '13%',
    },
  ];
  
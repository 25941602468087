import React from 'react';
import { Tabs, TabsProps, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import fetchDocumentToViewer from 'store/reducers/viewerCreator';
import { setIsDocumentError } from 'store/slices/viewerSlice';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import RowLoader from 'components/Sceletons/RowLoader';
import cn from 'classnames';
import NDAAgreement from 'components/NDAElements/NDAAgreement';
import NDADocuments from 'components/NDAElements/NDADocuments';
import NDAReport from 'components/NDAElements/NDAReport';
import classes from './NDASection.module.scss';
import { setLogsForPrint } from 'store/slices/dataLogsSlice';

const NDASection = () => {
  const { dataRoom, isLoadingRooms } = useAppSelector((state) => state.dataRoom);
  const { isDocumentError } = useAppSelector((state) => state.viewer);
  const { userPermissions } = useAppSelector((state) => state.userData);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const userAcceptNDA = dataRoom?.user_status === 2;
  const canViewLogs = userPermissions.can_view_logs;

  React.useEffect(() => {
    dispatch(setSelectedKeys(['nda']));

    if (dataRoom?.agreement_file) {
      const fileInfo = dataRoom?.agreement_file!.split(/\.\b/); 
      const fileID = dataRoom.agreement_id;
      dispatch(
        fetchDocumentToViewer({
          token: fileID,
          id: dataRoom?.id!,
          entity: fileID,
          extension: fileInfo[1],
          is_agreement: true,
          action: 'nda_view',
          locale: i18n.language,
        })
      );
    }
  }, [dataRoom?.agreement_file]);

  React.useEffect(() => {
    if (isDocumentError) message.error(t('Confirm.errorNDADownload'));
    return () => {
      dispatch(setIsDocumentError(''));
      dispatch(setLogsForPrint([]))
    };
  }, [isDocumentError]);

  const TabsData: TabsProps['items'] = [
    {
      key: 'agreement',
      label: t('Confirm.agreement'),
      children: <NDAAgreement userAcceptNDA={userAcceptNDA}/>,
    },
    {
      key: 'documents',
      label: t('Confirm.documents'),
      disabled: !userAcceptNDA,
      children: <NDADocuments />,
    },
  ];

  canViewLogs && TabsData.push(
    {
      key: 'reports',
      label:  t('Confirm.reports'),
      disabled: !userAcceptNDA,
      children: <NDAReport />,
    }
  );

  return (
    <div className={classes.confirm}>

      <div className={classes.confirm_titleWrap}>
        <div className={classes.statusWrap}>
          <div className={classes.title}>
            {t('Confirm.ndaTitle')} 
          </div>
          {
            isLoadingRooms || !dataRoom?.user_status
              ? <RowLoader width={30} padding={'0 0 0 15px'} height={28}/>
              : (
                userAcceptNDA
                  ? <CheckOutlined className={classes.confirm_acceptIcon}/>
                  : <CloseOutlined className={classes.confirm_notAcceptIcon}/>
              )
          }
        </div>
        
        {
          isLoadingRooms || !dataRoom?.user_status
            ? <RowLoader width={220} padding={'0 0 0 30px'} height={28}/>
            : <div className={cn(classes.confirm_dateWrap, userAcceptNDA ? classes.confirm_accept : classes.confirm_nonAccept)}>
              { userAcceptNDA 
                ? (<>
                  <div>{t('Confirm.signedByMe')}</div>
                  <div className={classes.confirm_date}>{t('Confirm.date')}</div>
                  <div className={classes.confirm_dateValue}>{dataRoom?.user_status_change_at}</div>
                </>)
                : <div>{t('Confirm.notSigned')}</div>
              }
            </div>
        }
      </div>
    
      <Tabs className={classes.tabs} defaultActiveKey={'agreement'} items={TabsData} type='card' />

    </div>
  );
};

export default NDASection;

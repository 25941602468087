import { Button } from 'antd';
import { GroupOrgFields } from 'helpers/organizationFieldsDictionary';
import React from 'react'
import { useTranslation } from 'react-i18next';
import classes from '../OrganizationSider.module.scss';
import Spinner from 'components/Spinner/Spinner';
import { IOrganization } from 'interfaces';

type OrganizationSiderInfoProps = {
  setIsAddingInfoModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrganization: IOrganization | null;
  isEditable: boolean;
  isSelectedOrganizationInfoLoading: boolean;
};

export default function OrganizationSiderInfo({ setIsAddingInfoModalOpen, selectedOrganization, isEditable, isSelectedOrganizationInfoLoading }:OrganizationSiderInfoProps) {
  const { t } = useTranslation();

  const groupsOfOrganizationData = selectedOrganization?.info ? Object.keys(selectedOrganization?.info) : null;  

  return (
    <div className={classes.infoWrap}>
      {isSelectedOrganizationInfoLoading ? <Spinner /> : groupsOfOrganizationData && groupsOfOrganizationData[0]
        ? groupsOfOrganizationData.map(groupName => {
          const fieldsOfGroup = selectedOrganization?.info && Object.entries(selectedOrganization?.info[groupName as GroupOrgFields]!);

          return <div key={groupName} className={classes.groupName}>
            <div className={classes.groupTitle}>{t(`Organizations.fields.${groupName}`)}</div>
            {fieldsOfGroup && fieldsOfGroup.map(field => (
              <div key={field[0]} className={classes.fieldWrap}>
                <div className={classes.fieldTitle}>{t(`Organizations.fields.${field[0]}`)}</div>
                <div className={classes.fieldValue}>{field[1]}</div>
              </div>
            ))}
          </div>
        })
        : <div className={classes.groupName} style={{ padding: '30px 0' }}>{t(`Organizations.emptyData`)}</div>
      }

      {isEditable && (
        <div className={classes.buttonWrap}>
          <Button onClick={() => setIsAddingInfoModalOpen(true)}>{t(`Organizations.card.edit`)}</Button>
        </div>
      )}
    </div>
  );
}

export const privacy = {
  en: <>
    <p style={{paddingBottom: 15}}><b>Privacy Policy</b></p>

    <p>Effective: July 01, 2024 (“Effective Date“)</p>
    <p>This privacy policy ("Privacy Policy") applies to Taza Digital Technologies and our related entities (“Taza”, “us”, “we” and “our”) and details our commitment to protecting the privacy of our customers and users Taza Deal.VDR.</p>
    <p>This Privacy Policy describes how and why we collect Personal Information about you, how we use, manage, store, and disclose that information, and how you can exercise your privacy rights. This Privacy Policy also outlines how you can contact us to access and update your Personal Information and/or how you can raise any privacy concerns.</p>
    <p>We recommend that you read this Privacy Policy in full to ensure you are fully informed. However, if you wish to only access a particular section of this Privacy Policy, then you can click on the relevant link below to jump to that section.</p>

    <p style={{paddingTop: 15}}><b>Updates to our Privacy Policy</b></p>

    <p>We may update this Privacy Policy from time to time by posting a new version on our website.</p>
    <p>When an updated Privacy Policy is posted, we will change the “Effective Starting” date at the top of the policy accordingly.</p>
    <p>If the changes are material, you will be notified on our website on the Taza Deal.VDR service login screen. We may not notify you if the way we use your Personal Information has not changed or if any changes are not material.</p>
    <p>If you do not agree to its terms or any changes we make, you should either stop using the Taza Deal.VDR Services or exercise any rights you have as described in this Privacy Policy to opt out of our use of your Personal Information.</p>

    <p style={{paddingTop: 15}}><b>Why do we collect your Personal Information?</b></p>

    <p>We collect your personal information to provide you with Taza Deal.VDR services, primarily to protect the service for further development. We may also use your personal information to:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Provide and administer Taza Deal.VDR services;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>understand how you use Taza Deal.VDR services;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>support of forecasting functions and provision of recommendations, comments and tips that are part of the Taza Deal.VDR services;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Processing and responding to customer support requests;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Transaction processing;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Identify, eliminate and prevent any technical or security issues affecting Taza Deal.VDR services;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>send you information by e-mail or other communication channels;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>ensure compliance with our agreement with you;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>check the accuracy of the information;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>perform any other functions described in our terms and conditions or this Privacy Policy.</li>
    </ul>
    <p>If we do not collect your personal information, you may not be able to use Taza Deal.VDR's services or otherwise create an account or profile with us. In addition, we will not be able to contact you (including through marketing communications).</p>

    <p style={{paddingTop: 15}}><b>Other ways we use your information</b></p>

    <p>We may also collect, use, store and disclose your Personal Information for purposes and for recipients additional to those that are set out in this Privacy Policy. Where we do so, we will disclose those purposes and recipients to you when you provide your information to us.</p>
    
    <p style={{paddingTop: 15}}><b>What Personal Information do we collect?</b></p>

    <p>The Personal Information we may collect includes information that you provide voluntarily to us, information that we collect automatically and information that we obtain from third party sources. This may include, but is not limited to:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>your contact details, including your name, mailing and email addresses, and phone numbers;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>any usernames you may hold, any social media profiles you may hold, job title(s), company name, photographs, and any additional information connected with a profile or account that you create or that is created for you;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>your billing information, including your payment details and billing address;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>preferences about the way you would like us to communicate with you, including in respect of marketing communications;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>information about the way you access and use the Taza Deal.VDR Services, the content you post, your interactions with other users, and your use of features, links and third-party integrations;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>log information such as your IP address, the date and time when you used the Taza Deal.VDR Services, the details of the webpage you visited before accessing the Taza Deal.VDR Services, your browser type, configuration and plug-ins, your language preferences, and cookie data. For more information on our use of cookies, please see our Cookie Policy;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>information about the device on which you are using the Taza Deal.VDR Services, including the device type and settings, operating system, device identifiers, application IDs, and crash information. We may use your Wi-Fi and IP address from your device or browser to ascertain your general location, but we will only collect GPS location data from your mobile device with your consent;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>analytics information collected when you use the Taza Deal.VDR Services, or information that we generate or derive, including through queries we run in respect of use of the services and content contained in the services. This data, which could incorporate Personal Information, may include the username and IP address of the person using the Taza Deal.VDR Services, the parts of the Taza Deal.VDR Services being accessed and used, any relevant domain names and identifiers, and data about attachments (including their original filenames and sizes). We may also collect aggregated analytics data about the use of the Taza Deal.VDR Services, which will not contain Personal Information;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>information about third-party services you integrate when using the Taza Deal.VDR Services. We will connect any such services to the Taza Deal.VDR Services, and we may receive information about your account (including Personal Information) from the third-party service provider. We will not collect or hold your passwords for any third-party services; and</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>information about our user base and the performance of marketing campaigns. This might include Personal Information and/or aggregated information that does not identify individuals.</li>
    </ul>
    <p>We may also process Personal Information incorporated in the content that users create, provide, post, host, upload, store, communicate or display when you use the Taza Deal.VDR Services (“User Content”). This may include sensitive information, such as personal data revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, or trade union membership, and the processing of genetic data, biometric data for the purpose of uniquely identifying a natural person, data concerning health or data concerning a natural person’s sex life or sexual orientation. Where we process Personal Information in content, we do so on behalf of our customers and users and it is their responsibility to have lawful grounds to use that Personal Information. We will not be responsible for obtaining consent for the use of any sensitive information that is incorporated in any User Content.</p>

    <p style={{paddingTop: 15}}><b>How do we collect Personal Information?</b></p>

    <p>We collect Personal Information in a number of ways, including:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>when you establish an account with us, or when another user (for instance, a user from your company) creates an account for you;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>when you create or amend your profile;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>when you use the Taza Deal.VDR Services;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>through your device or browser, as outlined above;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>when you submit Personal Information directly to us, including through the use of our websites and the other Taza Deal.VDR Services, where you submit online forms, and where you send emails or other communications to us;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>from third parties. To ensure we are providing you with information, marketing, offers and opportunities that are relevant to you, we may collect information about you from sources including our marketing partners, publicly-accessible databases and social media. We may also collect information about our user base, the Taza Deal.VDR Services and our marketing campaigns from our related bodies corporate, our service partners, or others;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>from your third-party service providers. We may collect information from the providers of third-party services you integrate when using the Taza Deal.VDR Services, as described above; and</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>by running analytics or generating analytics data in connection with the Taza Deal.VDR Services, including through queries we run in respect of use of the services and content contained in the services.</li>
    </ul>

    <p style={{paddingTop: 15}}><b>How do we disclose Personal Information?</b></p>

    <p>We may disclose the Personal Information we collect:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>the administrator of the room in which you are a member. The room administrator can access and manage your Taza Deal.VDR account, as well as extract, transfer or delete your personal information;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>with other users of Taza Deal.VDR services. For example, your name, photo, and contact information may be shown to other users, including on your profile, in messages, or notifications.</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>in accordance with legal requirements and our legal rights. For example, when it is necessary to comply with legal requirements, prevent fraud, prevent death or serious injury, or protect our property rights. If you use our Q&A feature, we may provide certain organizations whose registered users view or post information about questions and answers with a separate copy of this information so that they comply with the rules of the U.S. Securities and Exchange Commission (SEC). These organizations (and related companies) may use and disclose such information to the extent necessary to comply with these SEC rules and any other legislation to which they are subject (and may transfer and store this information abroad).</li>
    </ul>
    <p>Some of the recipients described above, including our service providers, your team administrator, other users of the Taza Deal.VDR Services, any new owner of Taza Deal.VDR, and the SEC, are or may be located offshore.</p>
    <p>We may disclose your Personal Information to third parties to allow them to market to you (including through direct marketing) if we have first obtained your consent or if we have other lawful grounds to do so.</p>

    <p style={{paddingTop: 15}}><b>Data retention, access, correction, and deletion</b></p>

    <p>We retain Personal Information we collect from you where we have an ongoing legitimate business need to do so, and where you have not requested us to delete your Personal information, pursuant to any privacy laws that apply to Your Content.  Examples of legitimate business needs include, but are not limited to continuing to provide you access to the Taza Deal.VDR Services or to comply with applicable legal, tax or accounting requirements.</p>
    <p>You have a right to request a copy of your Personal Information, to object to our usage of your Personal Information, to request the correction of Your Personal Information, or to request the deletion or restriction of your Personal Information.   Your requests and choices may be limited in certain cases such as, but not limited to situations where your request would reveal information pertaining to another person, or where you ask us to delete Your Personal Data, and we are permitted by law to retain Your Personal Data, or have a compelling legitimate reason for doing so.</p>
    <p>Subject to any privacy laws that apply to your content, when we have no ongoing legitimate business need to process your Personal Information, we will either delete or anonymise it or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.</p>
    <p>We only retain Personal Information for such time as permitted by our contracts with our customers, or for such time as those customers otherwise instruct or permit us to do so, or pursuant to any privacy laws that apply to Your Content.</p>

    <p style={{paddingTop: 15}}><b>Cookies and similar tracking technology</b></p>

    <p>We use cookies and similar tracking technology (collectively, “Cookies”) to collect and use Personal Information about you, and to otherwise make interfaces more user-friendly, to provide you a better experience, and to target advertising to you that may be of interest. You can control or reset your cookies through your web browser, which allows you to customise your cookies preferences, to refuse all cookies, or to indicate when a cookie is being sent.  Note that some elements of the Taza Deal.VDR Services may not function properly if the ability to accept cookies is disabled.</p>
    <p>For further information about the types of Cookies we use and why, as well as further information on how you can control Cookies, please see our Cookie Policy.</p>

    <p style={{paddingTop: 15}}><b>Security</b></p>

    <p>In storing your Personal Information, we use a number of security and organisational measures and technologies to safeguard your Personal Information from unauthorised access, modification or disclosure and misuse, interference or loss. We have personnel tasked with protecting your information, developing new security features, and identifying and mitigating vulnerabilities. Our existing security measures include encryption, two-factor authentication, and notifications when new devices and apps are connected with your user account. We also have in place security measures and policies focused on restricting access to sensitive information to authorised personnel, and we consistently review our security procedures and architecture with a consideration of new technologies and updated methods. Despite our reasonable efforts however, no system is ever perfect.</p>
    <p>Whilst we hold your information on a secured server behind a firewall and we encrypt data transfer using 256bit SSL encryption, please be aware that there are inherent risks in transmitting information using the internet.</p>

    <p style={{paddingTop: 15}}><b>Legal basis for processing Personal Information (EEA and UK visitors)</b></p>

    <p>If you are a visitor from the European Economic Area or the United Kingdom, our legal basis for collecting and using the Personal Information described above will depend on the Personal Information concerned and the specific context in which we collect it.</p>
    <p>However, we will normally collect Personal Information from you only where we have your consent to do so, where we need the Personal Information to perform a contract with you, or where the processing is in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect Personal Information from you or may otherwise need the Personal Information to protect your vital interests or those of another person (e.g. other users).</p>
    <p>If we ask you to provide Personal Information to comply with a legal requirement or to perform a contact with you, we will make this clear at the relevant time and advise you whether the provision of your Personal Information is mandatory or not (as well as of the possible consequences if you do not provide your Personal Information).</p>
    <p>Similarly, if we collect and use your Personal Information in reliance on our legitimate interests (or those of any third party), we will make clear to you at the relevant time what those legitimate interests are.</p>
    <p>Most of the ways in which we use your personal data are based on our legitimate interests in:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>providing and administering the Taza Deal.VDR Services;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>keeping our website and the Taza Deal.VDR Services secure;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>keeping the Taza Deal.VDR Services up to date and enhancing them, both generally and for your use of them; and</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>marketing our products and services.</li>
    </ul>
    <p>When we rely on our legitimate interests as a lawful ground to process your Personal Information, we do so taking into account the potential impact on your privacy and we offer the right to object to or opt out from processing as described below in the “Your privacy rights” section below.</p>
    <p>If you have questions about or need further information concerning the legal basis on which we collect and use your Personal Information, please contact us using the contact details provided under the “Contacting Taza Deal.VDR” heading below.</p>

    <p style={{paddingTop: 15}}><b>International data transfers (EEA and UK visitors)</b></p>

    <p>Your Personal Information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have privacy laws that are different to the laws of your country (and, in some cases, may not be as protective).</p>
    <p>In particular, third party service providers and partners operate throughout the world. This means that when we collect your Personal Information, we may process it in any of these countries in accordance with any privacy laws that apply to your Content.</p>

    <p style={{paddingTop: 15}}><b>Where will my personal Data be processed</b></p>

    <p>We currently host our servers for the Taza Deal.VDR Services using a third-party hosting provider, DigitalOcean, Germany.</p>
    
    <p style={{paddingTop: 15}}><b>California user requests</b></p>

    <p>Californian users of the Taza Deal.VDR Services will have additional rights afforded to them under the California Consumer Privacy Act (CCPA) and the California Privacy Rights Act (CPRA, effective 1 January 2023).  </p>
    <p>The CCPA and the CPRA provide California users various rights with respect to the personal information we collect, including the right to, with certain limitations:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>request to access the personal information we hold about you;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>request that we delete any or all of your personal information;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>opt out of the “sale” of your personal information;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>opt out of the “sharing” of your personal information for cross-context behavioural advertising.</li>
    </ul>
    <p>California users of the Taza Deal.VDR Services may make a request with respect to any of these rights by contacting us at <a href="mailto:info@taza-digital.com">info@taza-digital.com.</a></p>

    <p style={{paddingTop: 15}}><b>Your privacy rights</b></p>

    <p>You have the following privacy rights, regardless of the legal jurisdiction of Your Content:</p>
    <p>You have various rights in relation to your personal data including:</p>


    <p><b>Right of access.</b> You have the right to access information we hold about you, including:</p>        
    <p> — the categories of data we’re processing</p>
    <p> — the purposes of data processing</p>
    <p> — the categories of third parties to whom the data may be disclosed</p>
    <p> — how long the data will be stored</p>
    <p> — your other rights regarding our use of your data</p>

    <p><b>Right to update your personal data.</b> You have the right to request we update incomplete, incorrect, unnecessary or outdated personal data about you.</p>

    <p><b>Right to be forgotten.</b> If you no longer want to use Taza Deal.VDR, you have the right to ask us to erase all personal and user data we hold about you.</p>

    <p><b>Right to object to data processing.</b> You have the right to request that we stop processing your personal data for marketing purposes, communication purposes or on other compelling legal ground.</p>

    <p><b>Right to data portability.</b> You have the right to request that your data be exported. If you are part of an account with other users, we will not do so to the extent that this involves disclosing data about any other user.</p>

    <p><b>Right to withdraw consent.</b> ou have the right to withdraw your consent at any time.</p>

    <p><b>Right to lodge a complaint.</b> If you believe that Taza Deal.VDR is not processing your Personal Data in accordance with the requirements set out herein or applicable data protection laws, you can at any time lodge a complaint with the appropriate data protection authority.</p>

    <p><b>Use of this website by children.</b> This website is not intended for anyone under the age of 16 years. If you are younger than 16, you may not register with or use this website. If we learn that we have collected Personal Information from a child under age 16, we will delete that information as quickly as possible. If you believe that a child under 16 may have provided us Personal Information, please contact us at <a href="mailto:info@taza-digital.com">info@taza-digital.com</a>. We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable privacy laws.</p>
    <p>Please note that we do not offer any of the rights described above with respect to any Personal Information that is incorporated in any User Content. We process such content on behalf of our customers and if your Personal Information is contained in any such content, you should contact the customer on whose behalf we have stored the information.</p>

    <p style={{paddingTop: 15}}><b>Cookie Policy</b></p>

    <p>Taza Deal.VDR uses cookies to operate the site and services, for example, to log you into the system and ensure the security of your account.</p>
    <p>If you do not accept our cookie policy, you will not be able to use the Taza Deal.VDR service due to the impossibility of authorization in the service.</p>

    <p style={{paddingTop: 15}}><b>Contacting Taza Deal.VDR</b></p>

    <p>Please contact us using the below details if you have queries about our Privacy Policy and privacy practices, or the way we deal with your Personal Information. You may also contact us using these details if you wish to exercise any of your privacy rights described in the section entitled “Your privacy rights” above: <a href="mailto:info@taza-digital.com">info@taza-digital.com</a> </p>
    <p>When you request that we access or correct your Personal Information, we will need to locate the relevant information, so it would greatly assist us if you could supply as much supporting detail as possible. Where we refuse any access or correction request, we will comply with any requirements under applicable laws to notify you of our reasons for doing so and the mechanisms through which you may complain. Where we deny a request to correct information after you have made a request to us, we will comply with any applicable legal requirements to advise you of any inaccuracies or lack of necessary detail within your request.   </p>
    <p>We take your privacy complaints seriously at all times. Where you inform us that you have a complaint about our handling of your Personal Information, we will contact you to let you know which of our team members will investigate your matter and the timeframe within which they will aim to respond to you.</p>
  </>,
  ////
  ////
  ////
  ////
  ru: <>
    <p style={{paddingBottom: 15}}><b>Политика конфиденциальности</b></p>

    <p>Дата вступления в силу: 1 июля 2024 г.</p>
    <p>Настоящая политика конфиденциальности («Политика конфиденциальности») распространяется на компанию Taza Digital Technologies и связанные с нами организации (далее «Taza») и подробно описывает, как мы защищаем личные данные наших клиентов и пользователей Taza Deal.VDR.</p>
    <p>Настоящая Политика конфиденциальности описывает, как и почему мы собираем Персональную информацию о вас, как мы используем, управляем, храним и раскрываем эту информацию, а также как вы можете реализовать свои права на конфиденциальность. Настоящая Политика конфиденциальности также содержит информацию о том, как вы можете связаться с нами для доступа и обновления вашей Персональной информации и/или как вы можете выразить свои опасения по поводу конфиденциальности. Мы рекомендуем вам полностью прочитать настоящую Политику конфиденциальности, чтобы быть полностью информированным.</p>

    <p style={{paddingTop: 15}}><b>Обновления нашей Политики конфиденциальности</b></p>

    <p>Мы можем время от времени обновлять настоящую Политику конфиденциальности, размещая новую версию на нашем веб-сайте. Когда обновленная Политика конфиденциальности будет размещена, мы изменим дату «Дата вступления в силу» в верхней части политики соответственно. Если изменения будут значительными, вы будете уведомлены об этом на экране входа в сервис Taza Deal.VDR на нашем веб-сайте. Мы можем не уведомлять вас, если способ использования вашей Личной информации не изменился или если изменения не являются значительными. Если вы не согласны с условиями или с любыми изменениями, которые мы вносим, вы должны либо прекратить использование сервисов Taza Deal.VDR, либо воспользоваться любыми правами, описанными в этой Политике конфиденциальности, чтобы отказаться от использования вашей Личной информации.</p>

    <p style={{paddingTop: 15}}><b>Почему мы собираем вашу Персональную информацию?</b></p>

    <p>Мы собираем вашу личную информацию, чтобы предоставлять вам услуги Taza Deal.VDR, главным образом для защиты сервиса и его дальнейшего развития. Мы также можем использовать вашу личную информацию для следующих целей:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Предоставление и администрирование услуг Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Понимание того, как вы используете услуги Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Поддержка функций прогнозирования и предоставление рекомендаций, комментариев и советов, которые являются частью услуг Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Обработка и ответы на запросы в службу поддержки клиентов;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Обработка транзакций;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Выявление, устранение и предотвращение любых технических или вопросов безопасности, влияющих на услуги Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Отправка вам информации по электронной почте или другим каналам связи;;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Обеспечение соблюдения нашего соглашения с вами;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Проверка точности информации;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Выполнение любых других функций, описанных в наших условиях использования или в настоящей Политике конфиденциальности.</li>
    </ul>
    <p>Если мы не будем собирать вашу личную информацию, вы не сможете пользоваться услугами Taza Deal.VDR или создавать у нас аккаунт или профиль. Кроме того, мы не сможем связаться с вами (включая маркетинговые коммуникации).</p>

    <p style={{paddingTop: 15}}><b>Другие способы использования вашей информации</b></p>

    <p>Мы также можем собирать, использовать, хранить и раскрывать вашу Персональную информацию для других целей и другим получателям, не указанным в настоящей Политике конфиденциальности. В таких случаях мы сообщим вам об этих целях в момент предоставления вами вашей информации.</p>
    
    <p style={{paddingTop: 15}}><b>Какие Персональные данные мы собираем?</b></p>

    <p>Личная информация, которую мы можем собирать, включает данные, которые вы предоставляете нам добровольно, данные, которые мы собираем автоматически, и данные, которые мы получаем от сторонних источников. Это может включать, но не ограничивается:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Ваши контактные данные, включая имя, почтовый и электронный адреса, а также номера телефонов;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Любые имена пользователей и профили в социальных сетях, должности, название компании, фотографии и любую дополнительную информацию, связанную с профилем или учетной записью, которую вы создаете или которая создается для вас;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Платежные данные, включая реквизиты оплаты и платежный адрес;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Предпочтения относительно способов связи с вами, включая маркетинговые коммуникации;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Информацию о том, как вы получаете доступ и используете услуги Taza Deal.VDR, контент, который вы публикуете, ваши взаимодействия с другими пользователями и использование функций, ссылок и интеграций с третьими сторонами;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Журнальные данные, такие как ваш IP-адрес, дата и время использования услуг Taza Deal.VDR, детали веб-страницы, которую вы посетили перед доступом к услугам Taza Deal.VDR, тип браузера, настройки и плагины, языковые предпочтения и данные файлов cookie. Для получения дополнительной информации о нашем использовании файлов cookie, пожалуйста, ознакомьтесь с нашей Политикой использования файлов cookie;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Информацию об устройстве, на котором вы используете услуги Taza Deal.VDR, включая тип устройства и настройки, операционную систему, идентификаторы устройства, идентификаторы приложений и данные о сбоях. Мы можем использовать ваш Wi-Fi и IP-адрес для определения вашего общего местоположения, но будем собирать данные о GPS-местоположении с вашего мобильного устройства только с вашего согласия;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Аналитическую информацию, собранную при использовании вами услуг Taza Deal.VDR, или информацию, которую мы генерируем или извлекаем, включая запросы в отношении использования услуг и контента. Эти данные, которые могут включать Личную информацию, могут включать имя пользователя и IP-адрес, части услуг Taza Deal.VDR, к которым осуществляется доступ и использование, доменные имена и идентификаторы, данные о вложениях (включая оригинальные имена файлов и размеры). Мы также можем собирать агрегированные аналитические данные об использовании услуг Taza Deal.VDR, которые не будут содержать Личную информацию;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Информацию о сторонних услугах, которые вы интегрируете при использовании услуг Taza Deal.VDR. Мы будем подключать любые такие услуги к услугам Taza Deal.VDR и можем получать информацию о вашей учетной записи (включая Личную информацию) от поставщика сторонних услуг. Мы не будем собирать или хранить ваши пароли для сторонних услуг;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Информацию о нашей базе пользователей и эффективности маркетинговых кампаний. Это может включать Личную информацию и/или агрегированную информацию, которая не идентифицирует отдельных лиц.</li>
    </ul>
    <p>Мы также можем обрабатывать Личную информацию, включенную в данные, который пользователи создают, предоставляют, публикуют, размещают, загружают, хранят, передают или отображают при использовании услуг Taza Deal.VDR («Пользовательский контент»). Это может включать чувствительную информацию, такую как личные данные, раскрывающие расовое или этническое происхождение, политические взгляды, религиозные или философские убеждения, членство в профсоюзах, генетические данные, биометрические данные с целью уникальной идентификации физического лица, данные о здоровье или данные о сексуальной жизни или сексуальной ориентации физического лица. Когда мы обрабатываем Личную информацию в контенте, мы делаем это от имени наших клиентов и пользователей, и это их ответственность — иметь законные основания для использования этой Личной информации. Мы не несем ответственности за получение согласия на использование любой чувствительной информации, включенной в Пользовательский контент.</p>
    
    <p style={{paddingTop: 15}}><b>Как мы собираем Персональную информацию?</b></p>

    <p>Мы собираем Персональную информацию различными способами, включая:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Когда вы создаете учетную запись у нас или когда другой пользователь (например, сотрудник вашей компании) создает учетную запись для вас;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Когда вы создаете или изменяете свой профиль;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Когда вы используете услуги Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Через ваше устройство или браузер, как описано выше;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Когда вы предоставляете Личную информацию непосредственно нам, включая использование наших веб-сайтов и других услуг Taza Deal.VDR, при заполнении онлайн-форм, отправке нам электронных писем или других сообщений;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>От третьих лиц. Чтобы предоставлять вам информацию, маркетинговые материалы, предложения и возможности, которые могут быть вам интересны, мы можем собирать информацию о вас из источников, включая наших маркетинговых партнеров, общедоступные базы данных и социальные сети. Мы также можем собирать информацию о нашей базе пользователей, услугах Taza Deal.VDR и наших маркетинговых кампаниях от наших связанных организаций, наших партнеров по обслуживанию и других сторонних источников;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>От ваших поставщиков сторонних услуг. Мы можем собирать информацию от поставщиков сторонних услуг, которые вы интегрируете при использовании услуг Taza Deal.VDR, как описано выше;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Путем проведения аналитики или генерации аналитических данных в связи с услугами Taza Deal.VDR, включая запросы, которые мы выполняем в отношении использования услуг и содержимого, содержащегося в услугах.</li>
    </ul>

    <p style={{paddingTop: 15}}><b>Как мы раскрываем Персональную информацию?</b></p>

    <p>Мы можем раскрывать Персональную информацию, которую мы собираем, следующим образом:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Администратору комнаты, в которой вы являетесь участником. Администратор комнаты может получить доступ к вашей учетной записи Taza Deal.VDR, управлять ей, а также извлекать, передавать или удалять вашу личную информацию;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Другим пользователям услуг Taza Deal.VDR. Например, ваше имя, фото и контактная информация могут быть показаны другим пользователям, включая на вашем профиле, в сообщениях или уведомлениях;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>В соответствии с юридическими требованиями и нашими законными правами. Например, когда это необходимо для соблюдения юридических требований, предотвращения мошенничества, предотвращения смерти или серьезных травм, или защиты наших имущественных прав. Если вы используете нашу функцию "Вопросы и ответы", мы можем предоставить определенным организациям, чьи зарегистрированные пользователи просматривают или публикуют информацию о вопросах и ответах, отдельную копию этой информации, чтобы они соответствовали правилам Комиссии по ценным бумагам и биржам США (SEC). Эти организации (и связанные с ними компании) могут использовать и раскрывать такую информацию в той мере, в какой это необходимо для соблюдения этих правил SEC и любого другого законодательства, которому они подчиняются (и могут передавать и хранить эту информацию за границей).</li>
    </ul>
    <p>Некоторые из описанных выше получателей, включая наших поставщиков услуг, вашего администратора команды, других пользователей услуг Taza Deal.VDR, любого нового владельца Taza Deal.VDR и SEC, находятся или могут находиться за границей.</p>
    <p>Мы можем раскрывать вашу Персональную информацию третьим лицам, чтобы они могли предлагать вам свои услуги (включая прямой маркетинг), если мы предварительно получили ваше согласие или если у нас есть другие законные основания для этого.</p>

    <p style={{paddingTop: 15}}><b>Сохранение данных, доступ, исправление и удаление</b></p>

    <p>Мы сохраняем Персональную информацию, которую собираем у вас, если у нас есть законные деловые основания для этого, и если вы не запросили у нас удаление вашей Персональной информации в соответствии с любыми законами о конфиденциальности, применимыми к вашему контенту. Примеры законных деловых оснований включают, но не ограничиваются, продолжением предоставления вам доступа к услугам Taza Deal.VDR или соблюдением применимых юридических, налоговых или бухгалтерских требований.</p>
    <p>Вы имеете право запросить копию вашей Персональной информации, возразить против использования нами вашей Персональной информации, запросить исправление вашей Личной информации или запросить удаление или ограничение обработки вашей Персональной информации. Ваши запросы и выбор могут быть ограничены в некоторых случаях, например, когда ваш запрос может раскрыть информацию, касающуюся другого лица, или когда вы просите нас удалить вашу Персональную информацию, и мы имеем законное право сохранить вашу Персональную информацию или имеем вескую законную причину для этого.</p>
    <p>В соответствии с любыми законами о конфиденциальности, применимыми к вашим данным, когда у нас больше нет законных деловых оснований для обработки вашей Персональной информации, мы либо удалим, либо анонимизируем её. Если это невозможно (например, потому что ваша Персональная информация хранится в резервных архивах), мы будем надежно хранить вашу Персональную информацию и изолировать её от дальнейшей обработки до тех пор, пока удаление станет возможным.</p>
    <p>Мы сохраняем Персональную информацию только в течение времени, разрешенного нашими контрактами с нашими клиентами, или в течение времени, которое клиенты иначе укажут или разрешат, или в соответствии с любыми законами о конфиденциальности, применимыми к вашему контенту.</p>

    <p style={{paddingTop: 15}}><b>Cookies и аналогичные технологии отслеживания</b></p>

    <p>Мы используем файлы cookie и аналогичные технологии отслеживания (совокупно «Cookies») для сбора и использования Персональной информации о вас, а также для улучшения пользовательского интерфейса, предоставления вам лучшего опыта и для целевой рекламы, которая может быть вам интересна. Вы можете управлять или сбросить настройки файлов cookie через ваш веб-браузер, что позволяет настроить предпочтения по файлам cookie, отказаться от всех файлов cookie или указать, когда файл cookie отправляется. Обратите внимание, что некоторые элементы услуг Taza Deal.VDR могут работать неправильно, если возможность принимать файлы cookie отключена.</p>
    <p>Для получения дополнительной информации о типах файлов cookie, которые мы используем и почему, а также дополнительной информации о том, как вы можете управлять файлами cookie, пожалуйста, ознакомьтесь с нашей Политикой использования файлов cookie.</p>

    <p style={{paddingTop: 15}}><b>Безопасность</b></p>

    <p>При хранении вашей Личной информации мы используем ряд мер безопасности и организационных технологий для защиты вашей Персональной информации от несанкционированного доступа, изменения или раскрытия, а также от неправильного использования, вмешательства или потери. У нас есть сотрудники, ответственные за защиту вашей информации, разработку новых функций безопасности и выявление и устранение уязвимостей. Наши существующие меры безопасности включают шифрование, двухфакторную аутентификацию и уведомления при подключении новых устройств и приложений к вашей учетной записи. У нас также есть меры безопасности и политики, направленные на ограничение доступа к конфиденциальной информации только авторизованным лицам, и мы постоянно пересматриваем наши процедуры безопасности и архитектуру с учетом новых технологий и обновленных методов. Несмотря на наши разумные усилия, ни одна система не является совершенной.</p>
    <p>Хотя мы храним вашу информацию на защищенном сервере за брандмауэром и шифруем передачу данных с использованием 256-битного SSL-шифрования, обратите внимание, что существуют риски, присущие передаче информации через интернет.</p>

    <p style={{paddingTop: 15}}><b>Правовые основания для обработки Персональной информации (посетители из ЕЭЗ и Великобритании)</b></p>

    <p>Если вы являетесь посетителем из Европейской экономической зоны или Великобритании, наши правовые основания для сбора и использования Персональной информации, описанной выше, будут зависеть от конкретной Персональной информации и контекста, в котором мы её собираем.</p>
    <p>Обычно мы собираем Персональную информацию от вас только при наличии вашего согласия на это, когда нам необходима Персональная информация для выполнения контракта с вами, или когда обработка осуществляется в наших законных интересах и не превышает ваших интересов по защите данных или основных прав и свобод. В некоторых случаях мы также можем иметь юридическое обязательство собирать Персональную информацию от вас или иным образом нуждаться в Персональной информации для защиты ваших жизненных интересов или интересов другого лица (например, других пользователей).</p>
    <p>Если мы запрашиваем у вас Персональную информацию для соблюдения юридического требования или выполнения контракта с вами, мы сообщим вам об этом в соответствующий момент и укажем, является ли предоставление вашей Персональной информации обязательным или нет (а также возможные последствия, если вы не предоставите свою Персональную информацию).</p>
    <p>Аналогично, если мы собираем и используем вашу Персональную информацию, опираясь на наши законные интересы (или интересы любой третьей стороны), мы ясно укажем вам в соответствующий момент, какие именно это законные интересы.</p>
    <p>Большинство способов использования ваших персональных данных основаны на наших законных интересах в:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>предоставлении и администрировании услуг Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>обеспечении безопасности нашего веб-сайта и услуг Taza Deal.VDR;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>поддержании актуальности и улучшении услуг Taza Deal.VDR, как в общем, так и для вашего использования;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>маркетинге наших продуктов и услуг.</li>
    </ul>
    <p>Когда мы полагаемся на наши законные интересы как на законное основание для обработки вашей Персональной информации, мы учитываем возможное влияние на вашу конфиденциальность и предлагаем вам право возражать против обработки или отказаться от неё, как описано ниже в разделе «Ваши права на конфиденциальность».</p>
    <p>Если у вас есть вопросы или вам нужна дополнительная информация о правовых основаниях, на которых мы собираем и используем вашу Персональную информацию, пожалуйста, свяжитесь с нами, используя контактные данные, указанные в разделе «Связь с Taza Deal.VDR» ниже.</p>

    <p style={{paddingTop: 15}}><b>Международные передачи данных (посетители из ЕЭЗ и Великобритании)</b></p>

    <p>Ваша Персональная информация может быть передана и обработана в странах, отличных от страны вашего проживания. Эти страны могут иметь законы о конфиденциальности, отличные от законов вашей страны (и, в некоторых случаях, менее защитные).</p>
    <p>В частности, сторонние поставщики услуг и партнеры работают по всему миру. Это означает, что когда мы собираем вашу Персональную информацию, мы можем обрабатывать её в любой из этих стран в соответствии с любыми законами о конфиденциальности, применимыми к вашему контенту.</p>
    
    <p style={{paddingTop: 15}}><b>Где будет обрабатываться моя Персональная информация</b></p>

    <p>В настоящее время мы размещаем наши серверы для услуг Taza Deal.VDR, используя стороннего хостинг-провайдера, DigitalOcean, Германия.</p>
    
    <p style={{paddingTop: 15}}><b>Запросы пользователей из Калифорнии</b></p>

    <p>Пользователям из Калифорнии, использующим услуги Taza Deal.VDR, предоставляются дополнительные права в соответствии с Законом Калифорнии о защите прав потребителей (CCPA) и Законом Калифорнии о правах на конфиденциальность (CPRA, вступает в силу с 1 января 2023 года)</p>
    <p>CCPA и CPRA предоставляют пользователям из Калифорнии различные права в отношении персональной информации, которую мы собираем, включая право, с определенными ограничениями:</p>
    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Запросить доступ к персональной информации, которую мы храним о вас;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Запросить удаление любой или всей вашей персональной информации;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Отказаться от «продажи» вашей персональной информации;</li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>Отказаться от «обмена» вашей персональной информацией для межконтекстной поведенческой рекламы.</li>
    </ul>
    <p>Пользователи из Калифорнии, использующие услуги Taza Deal.VDR, могут подать запрос в отношении любого из этих прав, связавшись с нами по адресу <a href="mailto:info@taza-digital.com">info@taza-digital.com</a>.</p>

    <p style={{paddingTop: 15}}><b>Ваши права на конфиденциальность</b></p>

    <p>У вас есть следующие права на конфиденциальность, независимо от юрисдикции, применимой к вашему контенту:</p>

    <ul>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право на доступ.</b> Вы имеете право на доступ к информации, которую мы храним о вас, включая:<br/>
          <span> — категории данных, которые мы обрабатываем;</span><br/>
          <span> — цели обработки данных;</span><br/>
          <span> — категории третьих лиц, которым могут быть раскрыты данные;</span><br/>
          <span> — срок хранения данных;</span><br/>
          <span> — ваши другие права в отношении нашего использования ваших данных.</span>
        </p>
        
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право на обновление ваших персональных данных.</b>  Вы имеете право запросить обновление неполных, некорректных, ненужных или устаревших персональных данных о вас.</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право на забвение.</b> Если вы больше не хотите использовать Taza Deal.VDR, вы имеете право попросить нас удалить все персональные и пользовательские данные, которые мы храним о вас.</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право возражать против обработки данных.</b> Вы имеете право запросить прекращение обработки ваших персональных данных для маркетинговых целей, целей связи или по другим веским юридическим основаниям.</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право на переносимость данных.</b> Вы имеете право запросить экспорт ваших данных. Если вы являетесь частью учетной записи с другими пользователями, мы не будем это делать, если это включает раскрытие данных о любом другом пользователе.</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право отозвать согласие.</b> Вы имеете право отозвать свое согласие в любое время.</p>
      </li>
      <li style={{marginLeft: 40, listStyleType: 'disc'}}>
        <p><b>Право подать жалобу.</b> Если вы считаете, что Taza Deal.VDR не обрабатывает ваши Персональные данные в соответствии с требованиями, изложенными здесь или применимыми законами о защите данных, вы можете в любое время подать жалобу в соответствующий орган по защите данных.</p>
      </li>
    </ul>

    <p><b>Использование этого веб-сайта детьми.</b> Этот веб-сайт не предназначен для лиц младше 16 лет. Если вам меньше 16 лет, вы не можете зарегистрироваться или использовать этот веб-сайт. Если мы узнаем, что мы собрали Персональную информацию от ребенка младше 16 лет, мы как можно быстрее удалим эту информацию. Если вы считаете, что ребенок младше 16 лет мог предоставить нам Персональную информацию, пожалуйста, свяжитесь с нами по адресу <a href="mailto:info@taza-digital.com">info@taza-digital.com</a>. Мы отвечаем на все запросы от лиц, желающих воспользоваться своими правами на защиту данных в соответствии с применимыми законами о конфиденциальности.</p>
    <p>Пожалуйста, обратите внимание, что мы не предлагаем ни одно из вышеописанных прав в отношении любой Персональной информации, включенной в любой Пользовательский контент. Мы обрабатываем такой контент от имени наших клиентов, и если ваша Персональная информация содержится в таком контенте, вам следует связаться с клиентом, от имени которого мы храним эту информацию.</p>

    <p style={{paddingTop: 15}}><b>Политика использования файлов cookie</b></p>

    <p>Taza Deal.VDR использует файлы cookie для работы сайта и предоставления услуг, например, для входа в систему и обеспечения безопасности вашей учетной записи. Если вы не принимаете нашу политику использования файлов cookie, вы не сможете использовать сервис Taza Deal.VDR из-за невозможности авторизации в системе.</p>

    <p style={{paddingTop: 15}}><b>Свяжитесь с Taza Deal.VDR</b></p>

    <p>Пожалуйста, свяжитесь с нами, используя указанные ниже контактные данные, если у вас есть вопросы о нашей Политике конфиденциальности и практиках конфиденциальности или о том, как мы обращаемся с вашей Персональной информацией. Вы также можете связаться с нами, если хотите воспользоваться любым из ваших прав на конфиденциальность, описанных в разделе «Ваши права на конфиденциальность» выше: <a href="mailto:info@taza-digital.com">info@taza-digital.com</a>. </p>
    <p>Когда вы запрашиваете у нас доступ или исправление вашей Персональной информации, нам потребуется найти соответствующую информацию, поэтому нам будет значительно легче, если вы сможете предоставить как можно больше подробностей. Если мы откажем в доступе или исправлении, мы будем соблюдать все требования применимых законов, чтобы уведомить вас о причинах нашего отказа и механизмах подачи жалобы. Если мы откажем в запросе на исправление информации после вашего обращения к нам, мы будем соблюдать все применимые юридические требования, чтобы сообщить вам о любых неточностях или недостатке необходимых данных в вашем запросе.</p>
    <p>Мы всегда серьезно относимся к вашим жалобам на конфиденциальность. Если вы сообщите нам о своей жалобе на наше обращение с вашей Персональной информацией, мы свяжемся с вами, чтобы сообщить, кто из членов нашей команды будет расследовать ваше дело, и в какие сроки они планируют ответить вам.</p>
  </>,
}

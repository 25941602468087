
export const billingColumn = [
    {
        title: 'Room name',
        dataIndex: 'room',
        key: 'room',
        width: '10%',
    },
    {
        title: 'User name',
        dataIndex: 'user',
        key: 'user',
        width: '15%',
    },
    {
        title: 'Service name',
        dataIndex: 'service',
        key: 'name',
        width: '20%',
    },
    {
        title: 'Created at',
        dataIndex: 'created_at',
        key: 'created_at',
        width: '20%',
    },
    {
        title: 'Cost',
        dataIndex: 'service',
        key: 'cost',
        width: '10%',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: '10%',
    },
    {
        title: 'Payment Link',
        dataIndex: 'user_link',
        key: 'user_link',
        width: '15%',
    },
  ];
  
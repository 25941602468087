import { Form, Modal, Select, message, Typography } from 'antd';
import Invitations from 'components/Invitations/Invitations';
import { IInvitations } from 'interfaces';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { useTranslation } from 'react-i18next';
import classes from './InviteEmployeesModal.module.scss';
import api from 'api';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { NewInvitesResponse } from 'api/users';
import { fetchUsersOfOrganization } from 'store/reducers/organizationCreator';
import classNames from 'classnames';
import { WarningOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

type InviteEmployeesModalProps = {
  isOpen: boolean;
  setIsInviteUserOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const orgaizationRoles = ['user', 'full_administrator', 'moderator'];

type valuesType = {
  role_name: string;
  group: string;
};

const InviteEmployeesModal = ({isOpen, setIsInviteUserOpen}: InviteEmployeesModalProps) => {
  const [invitations, setInvitations] = React.useState<IInvitations[]>([]);
  const [isLaoding, setIsLoading] = React.useState(false);
  const [choosenRole, setChoosenRole] = React.useState<string>();
  const [availableRoles, setAvailableRoles] = React.useState<{value: string; label: string, key: string}[]>([]);
  const [invatationsResponceAvailable, setInvatationsResponceAvailable] = React.useState(false);
  const [invatationsResponce, setInvatationsResponce] = React.useState<NewInvitesResponse>();
  const [tags, setTags] = React.useState<string[]>([]);
  const [emailIsValid, setEmailIsValid] = React.useState(false);
  
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { dataRoom } = useAppSelector((state) => state.dataRoom);
  const { organization } = useAppSelector((state) => state.organization);

  const usersEmail = dataRoom?.available_users?.map((user => user.email.toLocaleLowerCase()));

  const thisIsFullAdministrator = organization?.permission?.can_edit_organization_settings
    && organization?.permission?.can_invite_organization_users;

  const rolesToDisplay = thisIsFullAdministrator
    ? orgaizationRoles
    : orgaizationRoles.filter(role => role !== 'full_administrator');
    
  const rolesOfOrganization = rolesToDisplay?.map(role => ({
    value: role,
    key: role,
    label: t(`Organizations.invite.${role}`),
  }));

  React.useEffect(() => {
    setAvailableRoles(rolesOfOrganization);
    setChoosenRole(rolesOfOrganization[0].value);
  }, []);

  React.useEffect(() => {
    const newTags = tags.filter(
      (tag: string) => !Boolean(invitations.find((invitation: IInvitations) => invitation.email === tag))
    );
    setInvitations([
      ...invitations,
      ...newTags.map((tag: string) => ({email: tag})),
    ]);
  }, [tags]);
  
  const defaultValues = {
    role_name: rolesOfOrganization[0].value,
  };

  const onCancel = () => {
    setIsInviteUserOpen(false);
    setInvitations([]);
  };

  const onFinish = async (values: valuesType) => {
    if (invitations.length >= 1) {
      try {
        setIsLoading(true);
        const emails = invitations.map(({ email }) => email.toLocaleLowerCase());
        const response = await api.inviteEmployees({
          type: 'master',
          emails,
          organization_id: organization?.id!,
          role_name: values.role_name,
        });

        setInvatationsResponce(response.data);
        const successMessage = emails.length === 1 ? t('Organizations.invite.successInviteUser') : t('Organizations.invite.successInviteUsers');
        message.success(`${successMessage}: ${emails.join(', ')}`, 10);
        setInvatationsResponceAvailable(true);
        organization && dispatch(fetchUsersOfOrganization(organization?.id!))
      } catch (err) {
        message.error(t('Organizations.invite.errorInviteUsers'));
      } finally {
        setIsLoading(false);
      }
    } else {
      message.warning(t('Organizations.invite.warningInviteUsersEmpty'))
    }
  };
  
  const getCorrectLinkField = (link: string | null) => {
    switch(link) {
      case null:
        return (
          <div className={classes.textValue}>
            {`${t('Organizations.invite.employeesIsAdded')} '${t(`Organizations.invite.${choosenRole}`)}'`}
          </div>
        );
      case 'user_exist':
        return (<>
          <div className={classNames(classes.textValue, classes.warning)}>
            {t('Organizations.invite.user_exist')}
          </div>
          <WarningOutlined style={{color: 'orange', fontSize: 18}}/>
        </>);
      default: 
        return (<>
          <div className={classes.textValue}>
            {link}
          </div>
          <Paragraph
            className={classes.copyIcon}
            copyable={{
              text: link,
              tooltips: [t('Users.modals.invite.tooltip.copy'), t('Users.modals.invite.tooltip.copied')],
            }}
          />
        </>);
    };
  }
  
  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      className={classes.modal}
      okText={t('Organizations.invite.submitText')}
      title={invatationsResponceAvailable ? t('Organizations.invite.title2') : t('Organizations.invite.title')}
      width={'75%'}
      confirmLoading={isLaoding}
      onOk={form.submit}
      cancelText={invatationsResponceAvailable ? t('main.closeButton') : t('main.cancelButton')}
      okButtonProps={{className: invatationsResponceAvailable ? classes.none : 'simple', disabled: !emailIsValid}}
      centered
    >
      {invatationsResponceAvailable 
        ? <div>
          <div className={classes.invatationsWrap}>
            <div className={classes.columnsInvatations}>
              <div>{t('Users.modals.invite.settings.email')}</div>
              <div>{t('Users.modals.invite.invitationLink')}</div>
              <div />
            </div>
            <div className={classes.subWrap}>{invatationsResponce?.map((invitation: any, index: number) => {
                return (
                <div key={`settings_${index}`} className={classes.columnsInvatations}>
                  <div className={classes.emailValue}>
                    {invitation.email} :
                  </div>
                  {getCorrectLinkField(invitation.link)}
                </div>
              )})
            }</div>
          </div>
        </div>

        : <div className={classes.modal_invite}>
          <Form
            form={form}
            initialValues={defaultValues}
            layout='vertical'
            onFinish={onFinish}
          >
            <div className={classes.form_row}>

              <Form.Item style={{width: '100%'}} name='role_name' label={t('Users.modals.invite.labels.role')} required>
                <Select
                  className={classes.select}
                  options={availableRoles}
                  onChange={setChoosenRole}
                />
              </Form.Item>
              
            </div>

            <Invitations
              setEmailIsValid={setEmailIsValid}
              tags={tags}
              setTags={setTags}
              setInvitations={setInvitations}
              usersEmail={usersEmail}
              mode={'organization'}
            />

          </Form>
        </div>
      }

      { isLaoding &&
        <UploadingComponent />
      }
    </Modal>
  );
}

export default InviteEmployeesModal;

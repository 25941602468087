import { Layout, Dropdown, Avatar, MenuProps, Space, Select, Button, ConfigProvider } from 'antd';
import React from 'react';
import classes from './HeaderComponent.module.scss';
import {
  DownOutlined,
  LogoutOutlined,
  UserOutlined,
  ScheduleOutlined,
  CalculatorOutlined,
  CustomerServiceOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from 'helpers/cookies';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import SimpleLoader from '../Sceletons/SimpleLoader';
import { setCurrentFolder, setDocuments } from 'store/slices/dataDocumentsSlice';
import { setDataRoom } from 'store/slices/dataRoomSlice';
import { resetUserPermissions, setUserData } from 'store/slices/userDataSlice';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import { useTranslation, Trans } from 'react-i18next';
import { setDataForPermissionTable, setUserPermissions } from 'store/slices/dataPermissionsSlice';
import { resetOrgsAndSides } from 'store/slices/dataSidesSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSuitcase } from '@fortawesome/free-solid-svg-icons';
import Notifications from './Notifications';
import { clearAllChatData } from 'store/slices/chatsSlice';
import colors from '../../scss/variables/colors.module.scss';
import SupportModal from 'components/Modals/SupportModal/SupportModal';

const { Header } = Layout;

export default function HeaderComponent() {
  const [isSupportModalOpen, setIsSupportModalOpen] = React.useState(false);

  const { userData, isLoadingUserData } = useAppSelector((store) => store.userData);
  const { selectedKeys } = useAppSelector((store) => store.windowState);

  const { i18n, t } = useTranslation();
  const defaultLanguage = localStorage.getItem('language') || 'en';

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const clearRedux = () => {
    dispatch(setDataRoom(null));
    dispatch(clearAllChatData());
    dispatch(setDocuments({ documents: [] }));
    dispatch(resetUserPermissions());
    dispatch(setUserAndData({ users: [], dataUserTable: [] }));
    dispatch(setGroups([]));
    dispatch(setGroupChoosen(null));
    dispatch(setCurrentFolder([]));
    dispatch(setDataForPermissionTable({ documents: [] }));
    dispatch(setUserPermissions(null));
    dispatch(setUserData(null));
    dispatch(resetOrgsAndSides());
    localStorage.removeItem('previousRoomId');
    localStorage.removeItem('previousFolderPath');
    localStorage.removeItem('prevTab');
    localStorage.removeItem('previousPage');
  };

  const logoutHandle = () => {
    deleteCookie('athena-token');
    deleteCookie('refresh_token');
    clearRedux();
    navigate('/login');
    dispatch(setSelectedKeys(['/login']));
  };

  const onClick: MenuProps['onClick'] = async ({ key }) => {
    localStorage.setItem('previousRoomId', '');
    if (key === '/logout') return logoutHandle();
    localStorage.setItem('previousPage', key);
    navigate(key);
    dispatch(setSelectedKeys([key]));
  };

  const profileItems: MenuProps['items'] = [
    {
      label: t('Header.profile.profile'),
      key: 'profile',
      icon: <UserOutlined style={{ fontSize: 16 }} />,
      className: classes.labelPoint,
    },
    {
      label: t('Header.profile.organization'),
      key: 'organization',
      icon: <FontAwesomeIcon icon={faSuitcase} />,
      className: classes.labelPoint,
    },
    // {
    //   label: t('Header.profile.clients'),
    //   key: 'organization/clients',
    //   icon: <ScheduleOutlined style={{ fontSize: 16 }} />,
    //   className: classes.labelPoint,
    // },
    {
      label: t('Header.profile.billing'),
      key: 'billing',
      icon: <ScheduleOutlined style={{ fontSize: 16 }} />,
      className: classes.labelPoint,
    },
    {
      label: t('sidebar.report'),
      key: 'report',
      icon: <CalculatorOutlined style={{ fontSize: 16 }} />,
      className: classes.labelPoint,
    },
    {
      type: 'divider',
    },
    {
      label: t('Header.profile.logout'),
      key: '/logout',
      icon: <LogoutOutlined style={{ fontSize: 16 }} />,
      className: classes.labelPoint,
    },
  ];

  const openSupportModal = () => setIsSupportModalOpen(true);
  const closeSupportModal = () => setIsSupportModalOpen(false);

  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
  };

  const languagesValues = [
    {
      value: 'en',
      label: <Trans i18nKey='languages.en' />,
    },
    {
      value: 'ru',
      label: <Trans i18nKey='languages.ru' />,
    },
    {
      value: 'kg',
      label: <Trans i18nKey='languages.kg' />,
    },
    {
      value: 'es',
      label: <Trans i18nKey='languages.es' />,
    },
  ];

  return (
    <Header className={classes.headerWrapper}>
      <div className={classes.buttonAndName}>
        <div className={classes.title}>TazaDeal</div>
      </div>

      <div className={classes.profile}>
        <Button
          shape='round'
          onClick={openSupportModal}
          icon={<CustomerServiceOutlined style={{ fontSize: 16, color: colors.primary100 }} />}
        >
          {t('Header.support')}
        </Button>

        <ConfigProvider theme={{ token: { borderRadius: 16 } }}>
        <Select
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />
        </ConfigProvider>

        <Notifications dateFormat={userData?.date_format || 'international'} />

        <Dropdown
          // align={{offset: [1, 0]}}
          menu={{
            items: profileItems,
            onClick,
            selectable: true,
            selectedKeys: selectedKeys,
          }}
          className={classes.userBadge}
        >
          <Space>
            <Avatar size='large' icon={<UserOutlined />} />
            <div className={classes.userText}>
              {isLoadingUserData ? <SimpleLoader /> : userData ? userData.first_name : ''}
            </div>
            <DownOutlined />
          </Space>
        </Dropdown>
      </div>

      {isSupportModalOpen && (
        <React.Suspense fallback={<div />}>
          <SupportModal
            isSupportModalOpen={isSupportModalOpen}
            onCancel={closeSupportModal}
          />
        </React.Suspense>
      )}

    </Header>
  );
}

import { EditOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Select, Switch, message } from 'antd';
import api from 'api';
import { AxiosError } from 'axios';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IUserData } from 'interfaces';
import { FieldData } from 'rc-field-form/lib/interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setProfileData } from 'store/slices/profileSlice';
import { setUserData } from 'store/slices/userDataSlice';
import classes from './ProfileInfo.module.scss';

const { TextArea } = Input;

const PersonalInforamtion = () => {
  const [form] = Form.useForm();
  const [isDataUpdating, setIsDataUpdating] = useState<boolean>(false);
  const [haveChanges, setHaveChanges] = useState<boolean>(false);

  const { profile } = useAppSelector((state) => state.profile);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scroll(0, 0);
    setFormData();
  }, [profile]);

  useEffect(() => {}, []);

  const setFormData = () => {
    form.setFieldsValue({
      email: profile?.email,
      first_name: profile?.first_name,
      last_name: profile?.last_name,
      phone_number: profile?.phone_number,
      date_format: profile?.date_format,
      description: profile?.description,
      tfa_enabled: profile?.tfa_enabled,
    });
    setHaveChanges(false);
  };

  const onFieldsChange = (values: FieldData[], allValues: FieldData[]) => {
    const fieldsCompare = allValues.map(
      (field) => profile && profile[field.name as string[0] as keyof IUserData] === field.value
    );
    setHaveChanges(fieldsCompare.includes(false));
  };

  const onFinishSubmit = async (values: any) => {
    setIsDataUpdating(true);
    let isUpdateProfileData = false;

    let previousProfile: any = {};
    profile &&
      Object.keys(values).forEach((field) => {
        if (profile[field as keyof typeof profile] !== values[field] && field !== 'tfa_enabled') isUpdateProfileData = true;
        previousProfile[field] = profile[field as keyof typeof profile];
      });

    try {
      values.tfa_enabled !== profile?.tfa_enabled && (await api.setTFAuth(values.tfa_enabled));
      isUpdateProfileData &&
        (await api.uploadUserInfo(
          {
            ...values,
            details: {
              before: previousProfile,
              after: values,
            },
          },
          dataRoom?.id!
        ));
      message.success(t('personalInfo.successUpdate'));
      dispatch(setUserData({ ...profile!, ...values }));
      dispatch(setProfileData({ ...profile!, ...values }));
    } catch (err) {
      setFormData();
      const error = err as AxiosError<any>;
      message.error(error.response?.data.detail || t('personalInfo.notValidEmail'));
    } finally {
      setIsDataUpdating(false);
    }
  };

  const color = { Default: '#7f56d80d' };
  return (
    <>
      <Card title={t('Users.HelperMenu.personalInfo')} bordered className={classes.card}>
        <Form onFieldsChange={onFieldsChange} layout='vertical' onFinish={onFinishSubmit} form={form}>
          <Row justify={'space-between'}>
            <Col span={11}>
              <Form.Item
                name='first_name'
                className={classes.form_item}
                label={t('personalInfo.firstname')}
                rules={[
                  { required: true },
                  {
                    pattern: new RegExp(/^[-a-zA-Zа-яА-ЯёЁ ]+$/i),
                    message: t('personalInfo.onlyLetters'),
                  },
                ]}
              >
                <Input className={classes.input} style={{ backgroundColor: color.Default }} prefix={<UserOutlined />} />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                name='last_name'
                className={classes.form_item}
                label={t('personalInfo.lastname')}
                rules={[
                  { required: true, message: t('personalInfo.lastnameValidate') },
                  {
                    pattern: new RegExp(/^[-a-zA-Zа-яА-ЯёЁ ]+$/i),
                    message: t('personalInfo.onlyLetters'),
                  },
                ]}
              >
                <Input className={classes.input} style={{ backgroundColor: color.Default }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            className={classes.form_item}
            name='phone_number'
            label={t('personalInfo.phone')}
            rules={[
              { required: true },
              {
                pattern: new RegExp(/^[-+0-9]+$/i),
                message: t('personalInfo.onlyNumbers'),
              },
            ]}
          >
            <Input className={classes.input} style={{ backgroundColor: color.Default }} />
          </Form.Item>

          <Form.Item
            className={classes.form_item}
            name='email'
            label={t('personalInfo.email')}
            rules={[{ type: 'email' }, { required: true }]}
          >
            <Input
              disabled
              className={classes.input}
              style={{ backgroundColor: color.Default }}
              prefix={<MailOutlined />}
            />
          </Form.Item>

          <Row justify={'space-between'}>
            <Col span={11}>
              <Form.Item
                className={classes.form_item}
                name='tfa_enabled'
                label={t('personalInfo.tfa_enabled')}
                rules={[{ required: true }]}
                valuePropName='checked'
              >
                <Switch checkedChildren={t('Settings.terms.enable')} unCheckedChildren={t('Settings.terms.disable')} />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                className={classes.form_item}
                name='date_format'
                label={t('personalInfo.date_format.title')}
                rules={[{ required: true }]}
              >
                <Select
                  className={classes.select}
                  style={{ backgroundColor: color.Default }}
                  options={[
                    { label: t('personalInfo.date_format.usa'), value: 'usa' },
                    { label: t('personalInfo.date_format.international'), value: 'international' },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ position: 'relative' }}>
            <Form.Item className={classes.form_item} name='description' label={t('personalInfo.about')}>
              <TextArea
                autoSize={{ minRows: 5 }}
                className={classes.input}
                style={{ backgroundColor: color.Default, textIndent: 30 }}
              />
            </Form.Item>
            <EditOutlined className={classes.ico} style={{ fontSize: 16 }} />
          </div>

          <div className={classes.buttons}>
            <Button disabled={!haveChanges} onClick={setFormData} type='default'>
              {t('Users.HelperMenu.cancel')}
            </Button>
            <Form.Item style={{ margin: 0, paddingLeft: 15 }} shouldUpdate>
              {() => (
                <Button
                  disabled={!haveChanges || !!form.getFieldsError().filter(({ errors }) => errors.length).length}
                  type='primary'
                  htmlType='submit'
                >
                  {t('Users.HelperMenu.save')}
                </Button>
              )}
            </Form.Item>
          </div>
        </Form>

        {isDataUpdating && <UploadingComponent />}
      </Card>
    </>
  );
};

export default PersonalInforamtion;

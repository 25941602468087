import { Button, Image } from 'antd';
import React from 'react';
import classes from '../Viewer.module.scss';
import { CloseCircleOutlined, DownCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import { useAppDispatch } from 'store/hook';
import { resetViewerSlice } from 'store/slices/viewerSlice';
import { useTranslation } from 'react-i18next';

type ImageViewerProps = {
  url: any;
  onClose?: () => void;
  downloadDocument?: () => void;
}

const ImageViewer = ({url, onClose, downloadDocument}: ImageViewerProps) => {
  const [visible, setVisible] = React.useState(onClose ? true : false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (<div>
    { downloadDocument &&
      <Button onClick={downloadDocument} className={classes.download} icon={<DownloadOutlined />}>
        {t('templink.download')}
      </Button>
    }
    <Image
      rootClassName={classes.previewer}
      style={{ display: (onClose || visible) ? 'none' : 'flex'}}
      src={url}
      preview={{
        closeIcon: <CloseCircleOutlined style={{fontSize: 32, color: 'red'}}/>,
        visible,
        src: url,
        onVisibleChange: (value) => {
          if (!value) {
            onClose && onClose();
            onClose && dispatch(resetViewerSlice())
          }
          setVisible(value);
        },
      }}
    />
  </div>
  );
}

export default ImageViewer;


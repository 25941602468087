import { Tabs, TabsProps, message } from 'antd';
import Spinner from 'components/Spinner/Spinner';
import { ILogs } from 'interfaces';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { prepareTabs } from './data/prepareTabs';
import { useTranslation } from 'react-i18next';
import classes from './TimeLine.module.scss';

type TimeLineProps = {
  logs: ILogs[];
  logsError: ReactNode;
  loading: boolean;
  mode?: 'left' | 'alternate' | 'right';
};

const TimeLine: FC<TimeLineProps> = ({ logs, logsError, loading, mode }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (logsError) {
      message.error(logsError);
    }
  }, [logsError]);
  
  const tabs: TabsProps['items'] = useMemo(() => prepareTabs(logs, t, i18n, mode), [logs, i18n.language]);

  return (
    <div className={classes.timeline}>
      {loading ? (
        <div>
          <Spinner size={40} />
        </div>
      ) : (
        <Tabs
          items={tabs}
        />
      )}
    </div>
  );
};

export default TimeLine;

import { requestTemplate } from 'api';
import { ISimpleGroupForAprove } from 'interfaces';

export type SigningState = {
  id?: string;
  document_id?: string;
  signing_state?: ISimpleGroupForAprove[];
  comment?: string;
  decline_comment?: string;
  author?: any;
  status?: 0 | 1 | 2 | null;
  initiator_id?: string;
};

//SIGNING

const getSigningState = (
  id: string
) => requestTemplate.get<{message: SigningState[]}>(`/signatures/?id=${id}`);

const declineSigning = (
  payload: { comment: string; document_id: string }
) => requestTemplate.post('/signatures/decline/', payload);

const deleteSigning = (
  payload: { document_id: string }
) => requestTemplate.delete<string>(`/signatures/`, { data: payload.document_id });

const createSignature = <T = any> () => requestTemplate.post<T>(`/signatures/users/add_sign/`);

const signing = {
  getSigningState,
  declineSigning,
  deleteSigning,
  createSignature,
};

export default signing;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IDocument, IOrganization, OrganizationInfo } from 'interfaces';
import { fetchOrganizationDocuments, fetchOrganizationInfo, fetchSidesOfRoom, fetchUsersOrganizations } from 'store/reducers/sidesCreator';

interface DataState {
  sideOrganisations: IOrganization[];
  isLoadingSides: boolean;
  errorSides: string;

  usersOrganizations: IOrganization[];
  isLoadingUserOrganizations: boolean;
  errorUserOrganizations: string

  isAddOrgModalOpen: boolean;

  selectedOrganization: IOrganization | null;
  isOrgMenuCollapsed: boolean;

  organizationDocuments: IDocument[];
  isLoadingOrganizationDocuments: boolean;
  errorOrganizationDocuments: string;
  isSelectedOrganizationInfoLoading: boolean;
}

const initialState: DataState = {
  sideOrganisations: [],
  isLoadingSides: false,
  errorSides: '',

  usersOrganizations: [],
  isLoadingUserOrganizations: false,
  errorUserOrganizations: '',

  isAddOrgModalOpen: false,

  selectedOrganization: null,
  isOrgMenuCollapsed: true,

  organizationDocuments: [],
  isLoadingOrganizationDocuments: false,
  errorOrganizationDocuments: '',
  isSelectedOrganizationInfoLoading: false,
};

const dataSidesSlice = createSlice({
  name: 'dataSides',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchSidesOfRoom.fulfilled, (state, action: PayloadAction<IOrganization[]>) => {
      state.isLoadingSides = false;
      state.errorSides = '';
      state.sideOrganisations = action.payload;
    })
    builder.addCase(fetchSidesOfRoom.pending, (state) => {
      state.isLoadingSides = true;
    })
    builder.addCase(fetchSidesOfRoom.rejected, (state, action: any) => {
      state.isLoadingSides = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorSides = action.payload.errorMessage
      } else {
        state.errorSides = action.error.message
      }
    })

    builder.addCase(fetchUsersOrganizations.fulfilled, (state, action: PayloadAction<IOrganization>) => {
      state.isLoadingUserOrganizations = false;
      state.errorSides = '';
      state.usersOrganizations = [action.payload];
    })
    builder.addCase(fetchUsersOrganizations.pending, (state) => {
      state.isLoadingUserOrganizations = true;
    })
    builder.addCase(fetchUsersOrganizations.rejected, (state, action: any) => {
      state.isLoadingUserOrganizations = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorSides = action.payload.errorMessage
      } else {
        state.errorSides = action.error.message
      }
    })

    builder.addCase(fetchOrganizationDocuments.fulfilled, (state, action: PayloadAction<IDocument[]>) => {
      state.isLoadingOrganizationDocuments = false;
      state.errorOrganizationDocuments = '';
      state.organizationDocuments = action.payload;
    })
    builder.addCase(fetchOrganizationDocuments.pending, (state) => {
      state.isLoadingOrganizationDocuments = true;
    })
    builder.addCase(fetchOrganizationDocuments.rejected, (state, action: any) => {
      state.isLoadingOrganizationDocuments = false;
      if (action.payload) {
        state.errorOrganizationDocuments = action.payload.errorMessage
      } else {
        state.errorOrganizationDocuments = action.error.message
      }
    });

    
    builder.addCase(fetchOrganizationInfo.pending, (state) => {
      state.isSelectedOrganizationInfoLoading = true;
    });
    builder.addCase(fetchOrganizationInfo.fulfilled, (state, action: PayloadAction<OrganizationInfo>) => {
      state.isSelectedOrganizationInfoLoading = false;
      state.selectedOrganization!.info = action.payload;
    })
    builder.addCase(fetchOrganizationInfo.rejected,  (state, action) => {
      state.isSelectedOrganizationInfoLoading = false;
    })
  },

  reducers: {
    resetOrgsAndSides(state) {
      state.sideOrganisations = [];
      state.usersOrganizations = [];
    },
    setIsAddOrgModalOpen(state, action: PayloadAction<boolean>) {
      state.isAddOrgModalOpen = action.payload;
    },
    setSideOrganisations(state, action: PayloadAction<IOrganization[]>) {
      state.sideOrganisations = action.payload;
    },
    setSelectedOrganization(state, action: PayloadAction<IOrganization | null>) {
      state.selectedOrganization = action.payload;
    },
    setIsOrgMenuCollapsed(state, action: PayloadAction<boolean>) {
      state.isOrgMenuCollapsed = action.payload;
    },
  },
});

const dataSidesReducer = dataSidesSlice.reducer;

export const { resetOrgsAndSides, setIsAddOrgModalOpen, setSideOrganisations, setSelectedOrganization, setIsOrgMenuCollapsed } = dataSidesSlice.actions;
export default dataSidesReducer;

import { TFunction } from 'i18next';
import { ISimpleUserForAprove } from 'interfaces';
import classes from '../SingingTab.module.scss';

export interface IUserSigning extends ISimpleUserForAprove {
  user_group?: string;
  document_name?: string;
  signing_date?: string;
  room?: string;
  key?: string
}

export const getColumnsCertificate = (t: TFunction) => [
  {
    title: t('Documents.details.signing.userName'),
    dataIndex: 'user_name',
    key: 'user_name',
    width: '30%',
  },
  {
    title: t('Documents.details.signing.documentName'),
    dataIndex: 'document_name',
    key: 'document_name',
    width: '30%',
  },
  {
    title: t('Documents.details.signing.date'),
    dataIndex: 'signing_date',
    key: 'signing_date',
    width: '20%',
  },
  {
    title: t('Documents.details.signing.room'),
    dataIndex: 'room',
    key: 'room',
    width: '20%',
  },
  {
    title: t('Documents.details.signing.state'),
    dataIndex: 'signing_status',
    key: 'signing_status',
    width: '20%',
    className: classes.textToCenter,
  },
  // {
  //   title: t('nts.details.signing.email'),
  //   dataIndex: 'user_email',
  //   key: 'user_email',
  //   width: '25%',
  // },
  // {
  //   title: t('nts.details.signing.group'),
  //   dataIndex: 'user_group',
  //   key: 'user_group',
  //   width: '25%',
  // },
];

import {
  CloseCircleOutlined,
  CloseOutlined,
  LockFilled,
  MailFilled,
  PhoneOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Checkbox, Divider, Form, Input, Layout, Select, Tooltip, Typography, message } from 'antd';
import auth from 'api/auth';
import { AxiosError } from 'axios';
import { ISignUpData } from 'interfaces';
import React, { ChangeEvent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './SignUp.module.scss';
import LicenseModal from './license/LicenseModal';
import PasswordRule from './passwordRule/PasswordRule';
import PrivacyAndCookie from './license/PrivacyAndCookie';

const { Title, Paragraph, Link } = Typography;

type SignUpErrorDeatails = {
  message?: string;
  detail?: {
    message: string;
    status: string;
  };
};

type InviteSignup = {
  email: string;
  room_id: null | string;
  group_id: null | string;
  type: 'common';
};

export default function SignUp() {
  const [isLaoding, setIsLoading] = React.useState<boolean>(false);
  const [isLicenseModalOpen, setIsLicenseModalOpen] = React.useState<boolean>(false);
  const [isPrivacyAndCookielOpen, setIsPrivacyAndCookieOpen] = React.useState<boolean>(false);
  const [inviteData, setIniteData] = React.useState<InviteSignup | null>(null);
  const [passwordTooltipOpen, setpasswordTooltipOpen] = React.useState(false);
  const [passwordRules, setPasswordRules] = React.useState<Record<string, 'error' | 'success'>>({
    min: 'error',
    latin: 'error',
    special: 'error',
    number: 'error',
    letter: 'error',
  });

  const { hash } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();
  const defaultLanguage = localStorage.getItem('language') || 'en';

  const passwordRuleKeys = Object.keys(passwordRules);
  const arrErrorTexts = ['Invite has expired', 'Email already registered', 'Ошибка регистрации. Соглашение не принято'];
  const arrErrorLocalizationsTexts = [
    t('login.someError'),
    t('login.inviteExpire'),
    t('login.emailAlreadyRegistered'),
    t('login.agreementNotAccepted'),
  ];

  const languagesValues = [
    {
      value: 'en',
      label: <Trans i18nKey='languages.en' />,
    },
    {
      value: 'ru',
      label: <Trans i18nKey='languages.ru' />,
    },
    {
      value: 'kg',
      label: <Trans i18nKey='languages.kg' />,
    },
    {
      value: 'es',
      label: <Trans i18nKey='languages.es' />,
    },
  ];

  const onLanguageChange = (value: string) => {
    localStorage.setItem('language', value);
    i18n.changeLanguage(value);
    //to translate touched fields when switching
    const allFormFields = form.getFieldsValue(true);
    const namesOfFilledFields = Object.keys(allFormFields);
    form.validateFields(namesOfFilledFields);
  };

  React.useEffect(() => {
    parseHashInvite();
  }, []);

  const parseHashInvite = async () => {
    if (hash) {
      const response = await auth.inviteSignup(hash);
      form.setFieldsValue({ email: response.data.email });
      setIniteData(response.data);
    }
  };

  const onMessageClose: React.MouseEventHandler = (event) => message.destroy();

  const signUpHandle = async (values: ISignUpData) => {
    setIsLoading(true);
    try {
      const usersData: ISignUpData = inviteData
        ? {
            ...values,
            invite_type: inviteData.type,
            room_id: inviteData.room_id,
            group_id: inviteData.group_id,
          }
        : values;

      await auth.signup({
        ...usersData,
        email: usersData.email.toLocaleLowerCase(),
        details: {
          email: usersData.email.toLocaleLowerCase(),
          phone: usersData.phone_number,
        },
      });
      navigate('/login');
      message.success({
        content: (
          <span>
            {t('SignUp.success')} - {form.getFieldValue('email')}{' '}
            <CloseCircleOutlined className={classes.closeIcon} onClick={onMessageClose} />
          </span>
        ),
        duration: 0,
      });
    } catch (err) {
      const error = err as AxiosError<SignUpErrorDeatails>;
      const errorText = error.response?.data.message || error.response?.data.detail?.message || '';
      const indexError = arrErrorTexts.findIndex((text) => text === errorText) + 1;
      message.error(arrErrorLocalizationsTexts[indexError]);
    } finally {
      setIsLoading(false);
    }
  };

  const openLicenseModal = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setIsLicenseModalOpen(true);
  };

  const openPrivacyAndCookie = (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    setIsPrivacyAndCookieOpen(true);
  };

  const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const updatedRules: Record<string, 'error' | 'success'> = {
      min: value.length >= 8 ? 'success' : 'error',
      latin: /[a-zA-Z]/.test(value) ? 'success' : 'error',
      special: /[^\w\d\sа-яА-ЯёЁ]/.test(value) ? 'success' : 'error',
      number: /\d/.test(value) ? 'success' : 'error',
      //@ts-ignore
      letter: /\p{L}/u.test(value) ? 'success' : 'error',
    };

    setPasswordRules(updatedRules);
  };

  const onPasswordTooltipClose = () => setpasswordTooltipOpen(false);
  const onPasswordTooltipOpen = () => setpasswordTooltipOpen(true);

  return (
    <div className={classes.mainWrap}>
      <Layout className={classes.signUpElem}>
        <Select
          className={classes.localizationSelect}
          defaultValue={defaultLanguage}
          onChange={onLanguageChange}
          style={{ minWidth: 130 }}
          options={languagesValues}
          optionLabelProp={'label'}
        />
        <div className={classes.signUpCard}>
          <Typography>
            <Title level={2}>{t('personalInfo.signup')}</Title>
            <Paragraph className={classes.subTitle}>{t('personalInfo.fillInfo')}</Paragraph>
          </Typography>

          <Form form={form} name='signUp' className={classes.signUpForm} onFinish={signUpHandle}>
            <Form.Item
              name='first_name'
              rules={[
                {
                  required: true,
                  message: `${t('personalInfo.input')}"${t('personalInfo.firstname')}"`,
                },
                {
                  pattern: new RegExp(/^[a-zA-Zа-яА-ЯёЁ ]+$/i),
                  message: t('personalInfo.onlyLetters'),
                },
              ]}
            >
              <Input
                placeholder={t('personalInfo.firstname')}
                size='large'
                prefix={<UserOutlined className={classes.itemIcon} />}
                className={classes.input}
              />
            </Form.Item>

            <Form.Item
              name='last_name'
              rules={[
                {
                  required: true,
                  message: `${t('personalInfo.input')}"${t('personalInfo.lastname')}"`,
                },
                {
                  pattern: new RegExp(/^[a-zA-Zа-яА-ЯёЁ ]+$/i),
                  message: t('personalInfo.onlyLetters'),
                },
              ]}
            >
              <Input
                placeholder={t('personalInfo.lastname')}
                size='large'
                prefix={<UserOutlined className={classes.itemIcon} />}
                className={classes.input}
              />
            </Form.Item>

            <Form.Item
              name='phone_number'
              rules={[
                {
                  required: true,
                  message: `${t('personalInfo.input')}"${t('personalInfo.phone')}"`,
                },
                {
                  pattern: new RegExp(/^[-+0-9]+$/i),
                  message: t('personalInfo.onlyNumbers'),
                },
              ]}
            >
              <Input
                prefix={<PhoneOutlined className={classes.itemIcon} />}
                className={classes.input}
                placeholder={t('personalInfo.phone')}
              />
            </Form.Item>

            <Form.Item
              name='email'
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: t('personalInfo.emailRule'),
                },
              ]}
            >
              <Input
                placeholder={t('personalInfo.email')}
                size='large'
                disabled={Boolean(hash)}
                prefix={<MailFilled className={classes.itemIcon} />}
                className={classes.input}
              />
            </Form.Item>

            <Tooltip
              color='#fff'
              trigger='click'
              overlayClassName={classes.overlay}
              open={passwordTooltipOpen}
              title={
                <div className={classes.overlay_container}>
                  <CloseOutlined className={classes.overlay_close} onClick={onPasswordTooltipClose} />
                  {passwordRuleKeys.map((key) => (
                    <PasswordRule key={key} rule={key} type={passwordRules[key]} />
                  ))}
                </div>
              }
            >
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    message: `${t('personalInfo.input')}"${t('personalInfo.password')}"`,
                  },
                  {
                    pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*\W).{8,}$/,
                    message: '',
                  },
                ]}
              >
                <Input.Password
                  placeholder={t('personalInfo.password')}
                  size='large'
                  prefix={<LockFilled className={classes.itemIcon} />}
                  className={classes.input}
                  onFocus={onPasswordTooltipOpen}
                  onBlur={onPasswordTooltipClose}
                  onChange={onPasswordChange}
                />
              </Form.Item>
            </Tooltip>

            <Form.Item
              name='confirm'
              rules={[
                { required: true, message: `${t('personalInfo.confirm')}` },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(t('personalInfo.confirmText')));
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t('personalInfo.confirm')}
                size='large'
                prefix={<LockFilled className={classes.itemIcon} />}
                className={classes.input}
              />
            </Form.Item>

            <Form.Item
              name='is_accepted_agreement'
              valuePropName='checked'
              getValueFromEvent={(e) => {
                if (e.target.checked) openLicenseModal(e);
              }}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error(t('personalInfo.acceptedAgreementError'))),
                },
              ]}
            >
              <Checkbox>
                <span className={classes.licenseButton}>
                  {t('personalInfo.acceptAgreement')}{' '}
                  <span className={classes.link} onClick={(e) => openLicenseModal(e)}>
                    {t('personalInfo.license')}
                  </span>{' '}
                  {t('personalInfo.platform')}
                </span>
              </Checkbox>
            </Form.Item>


            <Form.Item
              name='is_accepted_privacy_cookie'
              valuePropName='checked'
              style={{marginTop: -20}}
              getValueFromEvent={(e) => {
                if (e.target.checked) openPrivacyAndCookie(e);
              }}
              rules={[
                {
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error(t('personalInfo.acceptedPolicyError'))),
                },
              ]}
            >
              <Checkbox>
                <span className={classes.licenseButton}>
                  {t('personalInfo.acceptAgreement')}{' '}
                  <span className={classes.link} onClick={(e) => openPrivacyAndCookie(e)}>
                    {t('personalInfo.privacyAndCookie')}
                  </span>{' '}
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item shouldUpdate>
              {() => (
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isLaoding}
                  disabled={
                    !form.isFieldsTouched(true) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
                  }
                >
                  {t('personalInfo.sign')}
                </Button>
              )}
            </Form.Item>
          </Form>

          <Divider />

          <Typography>
            {t('personalInfo.haveAcc')}{' '}
            <Link href='/login' className={classes.primary}>
              {t('personalInfo.logIn')}
            </Link>
          </Typography>
        </div>
      </Layout>

      {isLicenseModalOpen && (
        <React.Suspense fallback={<div />}>
          <LicenseModal
            isLicenseModalOpen={isLicenseModalOpen}
            setIsLicenseModalOpen={setIsLicenseModalOpen}
            t={t}
            form={form}
          />
        </React.Suspense>
      )}


      {isPrivacyAndCookielOpen && (
        <React.Suspense fallback={<div />}>
          <PrivacyAndCookie
            isPrivacyAndCookielOpen={isPrivacyAndCookielOpen}
            setIsPrivacyAndCookieOpen={setIsPrivacyAndCookieOpen}
            t={t}
            form={form}
          />
        </React.Suspense>
      )}
    </div>
  );
}

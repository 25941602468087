import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILogs, IPreparedLogs } from 'interfaces';
import { fetchAvailableDocumentsLogs } from 'store/reducers/documentsLogsCreator';
import { fetchAvailableLogs, fetchConfidentLogs } from 'store/reducers/logsCreator';

interface DataState {
  logs: IPreparedLogs[];
  isLoadingLogs: boolean;
  errorLogs: string;
  allLogs: IPreparedLogs[];
  logsForPrint: IPreparedLogs[] | null;

  choosenLog: ILogs | null;
  document: {
    logs: IPreparedLogs[];
    damp: ILogs[];
    error: string;
    laoding: boolean;
  },

  confidentLogs: IPreparedLogs[];
  isLoadingConfidentLogs: boolean;
  errorConfident: string;
}

const initialState: DataState = {
  logs: [],
  isLoadingLogs: false,
  errorLogs: '',
  allLogs: [],
  logsForPrint: null,

  choosenLog: null,
  document: {
    logs: [],
    damp: [],
    error: '',
    laoding: false,
  },

  confidentLogs: [],
  isLoadingConfidentLogs: false,
  errorConfident: '',
};

const dataLogsSlice = createSlice({
  name: 'datalogs',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAvailableLogs.fulfilled, (state, action: PayloadAction<{logs: IPreparedLogs[]}>) => {
      state.isLoadingLogs = false;
      state.errorLogs = '';
      state.logs = action.payload.logs;
      state.allLogs = action.payload.logs;
    })
    builder.addCase(fetchAvailableLogs.pending, (state) => {
      state.isLoadingLogs = true;
    })
    builder.addCase(fetchAvailableLogs.rejected, (state, action: any) => {
      state.isLoadingLogs = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorLogs = action.payload.errorMessage
      } else {
        state.errorLogs = action.error.message
      }
    })


    builder.addCase(fetchConfidentLogs.fulfilled, (state, action: PayloadAction<IPreparedLogs[]>) => {
      state.isLoadingConfidentLogs = false;
      state.errorConfident = '';
      state.confidentLogs = action.payload;
    })
    builder.addCase(fetchConfidentLogs.pending, (state) => {
      state.isLoadingConfidentLogs = true;
    })
    builder.addCase(fetchConfidentLogs.rejected, (state, action: any) => {
      state.isLoadingConfidentLogs = false;
      state.errorConfident = action.payload;
    })

    builder.addCase(fetchAvailableDocumentsLogs.fulfilled, (state, action: PayloadAction<{logs: IPreparedLogs[], dampLogs: ILogs[]}>) => {
      state.document.logs = action.payload.logs;
      state.document.damp = action.payload.dampLogs;

      state.document.error = '';
      state.document.laoding = false;
    });
    builder.addCase(fetchAvailableDocumentsLogs.rejected, (state, action: any) => {
      state.document.laoding = false;
      if (action.payload) {
        state.document.error = action.payload.errorMessage
      } else {
        state.document.error = action.error.message
      }
    });
    builder.addCase(fetchAvailableDocumentsLogs.pending, (state) => {
      state.document.laoding = true;
    })
  },
  reducers: {
    setLogs(state, action: PayloadAction<IPreparedLogs[]>) {
      state.logs = action.payload;
    },
    setChoosenLog(state, action: PayloadAction<ILogs | null>) {
      state.choosenLog = action.payload;
    },
    setLogsForPrint(state, action: PayloadAction<IPreparedLogs[]>) {
      state.logsForPrint = action.payload;
    },
    setConfidentLogs(state, action:  PayloadAction<IPreparedLogs[]>) {
      state.confidentLogs = action.payload;
    }
  },
});

const dataLogsReducer = dataLogsSlice.reducer;

export const { setLogs, setChoosenLog, setLogsForPrint, setConfidentLogs} = dataLogsSlice.actions;
export default dataLogsReducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IDocument, IEmployee, IOrganization } from 'interfaces';
import fetchYourOrganization, { deleteDocumentOfOrganization, fetchDocumentsOfOrganization, fetchUsersOfOrganization, setNewEmployeeRole } from 'store/reducers/organizationCreator';

type OrganizationsState = {
  organization: IOrganization | null;
  isLoadingOrganization: boolean;
  isErrorOrganization: string;
  documents: IDocument[];
  isDocumentsLoading: boolean;
  isDocumentDeleting: boolean;
  deletingDocumentID: string[];
  isSelectedOrganizationInfoLoading: boolean;

  organizationEmployees: IEmployee[]
  isLoadingOrganizationEmployees: boolean;
  isErrorOrganizationEmployees: string;
};

const initialState: OrganizationsState = {
  organization: null,
  isLoadingOrganization: false,
  isErrorOrganization: '',
  documents: [],
  isDocumentsLoading: false,
  isDocumentDeleting: false,
  deletingDocumentID: [],
  isSelectedOrganizationInfoLoading: false,

  organizationEmployees: [],
  isLoadingOrganizationEmployees: false,
  isErrorOrganizationEmployees: '',
};


const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<IOrganization | null>) => {
      state.organization = action.payload;
    },

    setOrganizationDocuments: (state, action: PayloadAction<IDocument[]>) => {
      state.documents = action.payload;
    },

    setOrganizationEmployees: (state, action: PayloadAction<any[]>) => {
      state.organizationEmployees = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchYourOrganization.pending, (state) => {
      state.isLoadingOrganization = true;
      state.organization = null;
    });

    builder.addCase(
      fetchYourOrganization.fulfilled,
      (state, action: PayloadAction<IOrganization>) => {
        state.organization = action.payload;
        state.isLoadingOrganization = false;
      }
    );

    builder.addCase(fetchYourOrganization.rejected, (state, action: PayloadAction<any>) => {
      state.isLoadingOrganization = false;
      state.isErrorOrganization = '';
    });

    builder.addCase(
      fetchDocumentsOfOrganization.fulfilled,
      (state, action: PayloadAction<IDocument[]>) => {
        state.documents = action.payload;
        state.isDocumentsLoading = false;
      }
    );
    builder.addCase(fetchDocumentsOfOrganization.pending, (state) => {
      state.isDocumentsLoading = true;
    });
    builder.addCase(fetchDocumentsOfOrganization.rejected, (state) => {
      state.isDocumentsLoading = false;
    });

    builder.addCase(fetchUsersOfOrganization.fulfilled, (state, action: PayloadAction<IEmployee[]>) => {
        state.organizationEmployees = action.payload;
        state.isLoadingOrganizationEmployees = false;
    });
    builder.addCase(fetchUsersOfOrganization.pending, (state) => {
      state.isLoadingOrganizationEmployees = true;
    });
    builder.addCase(fetchUsersOfOrganization.rejected, (state, action) => {
      state.isLoadingOrganizationEmployees = false;
      state.isErrorOrganizationEmployees = String(action.payload)
    });

    builder.addCase(
      deleteDocumentOfOrganization.fulfilled,
      (state, action: PayloadAction<string | undefined>) => {
        state.documents = state.documents.filter(doc => doc.id !== action.payload);
        state.deletingDocumentID = state.deletingDocumentID.filter(id => id !== action.payload);        
        state.isDocumentDeleting = state.deletingDocumentID.length === 0 ? false : true;
      }
    );
    builder.addCase(deleteDocumentOfOrganization.pending, (state, action) => {
      state.deletingDocumentID.push(action.meta.arg.doc_id)
      state.isDocumentDeleting = true;
    });
    builder.addCase(deleteDocumentOfOrganization.rejected, (state, action: any) => {
      state.deletingDocumentID = state.deletingDocumentID.filter(id => id !== action.payload);
      state.isDocumentDeleting = state.deletingDocumentID.length === 0 ? false : true;
    });

    builder.addCase(setNewEmployeeRole.fulfilled, (state, action: PayloadAction<IEmployee>) => {      
      const newOrganizationEmployees = state.organizationEmployees?.map(employee => 
        employee.user_id === action.payload.user_id
          ? action.payload
          : employee
      )
      state.organizationEmployees = newOrganizationEmployees;
    });
  },
});

const organizationReducer = organizationSlice.reducer;

export const { setOrganization, setOrganizationDocuments, setOrganizationEmployees } =
  organizationSlice.actions;
export default organizationReducer;

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';
import prepareLogs from 'helpers/prepareLogs';

export const fetchAvailableLogs = createAsyncThunk(
  'dataLogs/fetchAll',
  async (props: {roomId: string, locale: string}, thunkAPI) => {
    try {
      const logs = await api.getLogs(props.roomId, props.locale);      
      return {
        logs: prepareLogs(logs.data)
      }
    } catch (e) {
      return thunkAPI.rejectWithValue('Download failed');
    }
  }
);

const availableLogsTypes = [
  'nda_disable', 'nda_enable', 'nda_view', 'nda_signing',
  'download_confident', 'delete_confident', 'view_confident'
];

export const fetchConfidentLogs = createAsyncThunk(
  'dataConfidentLogs/fetchAll',
  async (props: {roomId: string, locale: string}, thunkAPI) => {
    try {
      const confidentLogs = await api.getConfidentLogs(props.roomId, props.locale, availableLogsTypes.join(','));      
      return prepareLogs(confidentLogs.data)
    } catch (e) {
      return thunkAPI.rejectWithValue('Download confident logs failed');
    }
  }
);

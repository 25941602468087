import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Avatar, Space, message } from 'antd'
import { Header } from 'antd/es/layout/layout'
import { LoginOutlined } from '@ant-design/icons'
import fetchDocumentToViewer, { imageExtensions, videoExtensions } from 'store/reducers/viewerCreator'
import { useAppDispatch, useAppSelector } from 'store/hook'
import UploadingComponent from 'components/UploadingComponent/UploadingComponent'
import PDFViewer from 'components/Webviewer/Viewer/PDFViewer'
import getFilenameFromResponse from 'helpers/getFilenameFromResponse'
import ErrorPage from './ErrorPage'
import ShareBrowserModal from './ShareBrowserModal'
import convert from 'api/convert'
import classes from './TempLink.module.scss'
import XLSXViewer from 'components/Webviewer/XLSXViewer/XLSXViewer'
import ImageViewer from 'components/Webviewer/ImageViewer/ImageViewer'
import VideoViewer from 'components/Webviewer/VideoViewer/VideoViewer'
import cn from 'classnames'
import api from 'api'

export default function TempLink() {
  const [isDownloadingDocument, setIsDownloadingDocument] = React.useState(false);
  const [isOpenShareModal, setIsOpenShareModal] = React.useState(false);
  const [isSharedInfo, setIsSharedInfo] = React.useState(false);
  const { documentUrl, documentName, isDocumentLoading, isDocumentError } = useAppSelector(state => state.viewer);

  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { document_token } = useParams();
  const [searchParams] = useSearchParams();
  const agreeToShareIpAndBrowser = localStorage.getItem('agreeToShareIpAndBrowser');

  const documentExtension = searchParams.get('ext') || '';
  const documentPermissions = searchParams.get('permissions')?.split(',');
  const downloadAvailability = documentPermissions?.includes('can_download') && documentPermissions?.includes('can_download_pdf');
  
  React.useEffect(() => {
    setIsSharedInfo(Boolean(agreeToShareIpAndBrowser));
    !agreeToShareIpAndBrowser && setIsOpenShareModal(true);
  }, []);

  React.useEffect(() => {
    incrementCounter(Boolean(documentUrl));
  }, [documentUrl]);

  React.useEffect(() => {
    isSharedInfo && document_token && documentExtension && fetchDocument(documentExtension)
  }, [document_token, isSharedInfo, searchParams]);
  
  const fetchDocument = async (extension: string) => {
    await dispatch(fetchDocumentToViewer({
      action: 'view',
      token: document_token!,
      is_agreement: false,
      is_temp_links: true,
      extension,
      locale: i18n.language,
    }));
  };

  const incrementCounter = async (needToIncremen: boolean) => {
    needToIncremen && await api.incrementCounter(document_token!);
  }

  const downloadDocument = async () => {
    setIsDownloadingDocument(true)
    try {
      let response: any;
      const payload = {
        action: 'download',
        token: document_token!,
        is_agreement: false,
        is_temp_links: true,
        locale: i18n.language,
      }
      
      if ([...imageExtensions, ...videoExtensions, 'xlsx'].includes(documentExtension!)) {
        response = await api.downloadFile({ ...payload, is_webview: true, extension: documentExtension });
      } else {
        response = await convert.convertDocument(payload);
      }
     
      const filename = getFilenameFromResponse(response);

      const url = window.URL.createObjectURL(response.data as Blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename || 'temp_document.pdf';
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (e) {
      message.error(t('templink.errorDownload'), 5);
    } finally {
      setIsDownloadingDocument(false);
    }
  }

  const toLogin = () => navigate('/login');
  const closeShareModal = () => {
    setIsOpenShareModal(false);
    toLogin();
  };
  const recordConsent = () => {
    setIsOpenShareModal(false);
    localStorage.setItem('agreeToShareIpAndBrowser', 'true');
    setIsSharedInfo(true);
  };

  const chooseViewer = () => {
    let viewer = '';
    if (documentExtension === 'xlsx') {
      viewer = 'xlsx';
    } else if (imageExtensions.includes(documentExtension)) {
      viewer = 'image';
    } else if (videoExtensions.includes(documentExtension)) {
      viewer = 'video';
    }
    
    switch(viewer) {
      case 'xlsx':
        return <XLSXViewer data={documentUrl} downloadDocument={downloadAvailability ? downloadDocument : undefined}/>;
      case 'image':
        return <ImageViewer url={documentUrl} downloadDocument={downloadAvailability ? downloadDocument : undefined}/>;
      case 'video':
        return <VideoViewer url={documentUrl} downloadDocument={downloadAvailability ? downloadDocument : undefined}/>;
      default: 
        return <PDFViewer url={documentUrl} isModal={false} downloadDocument={downloadAvailability ? downloadDocument : undefined} />;
    };
  }
  return (
    <div className={classes.viewerWrap}>
      <Header className={classes.headerWrapper}>
        <div className={classes.title}>
          TazaDeal
        </div>

        <div className={classes.filename}>
          <div style={{fontWeight: 600}}>{t('templink.viewdocument')}</div>
          {documentName && <div>{documentName}</div>}
        </div>

        <div className={classes.badge} onClick={toLogin}>
          <Space>
            <Avatar size='large' icon={<LoginOutlined />} />
            <div className={classes.loginText}>
              {t('login.logIn')}
            </div>
          </Space>
        </div>
      </Header>

      <div className={cn(classes.viewerBody, imageExtensions.includes(documentExtension) ? classes.flex : null)}>
        {(isDocumentLoading || !documentUrl)
          ? (
            isDocumentError
              ? <ErrorPage error={isDocumentError}/>
              : ( agreeToShareIpAndBrowser === 'true' && <UploadingComponent /> )
          )
          : chooseViewer()
          // : <PDFViewer url={documentUrl} isModal={false} downloadDocument={downloadDocument}/>
        }
      </div>

      {
        isDownloadingDocument && <UploadingComponent />
      }

      <React.Suspense fallback={<div />}>
        {isOpenShareModal && (
          <ShareBrowserModal open={isOpenShareModal} onCancel={closeShareModal} recordConsent={recordConsent}/>
        )}
      </React.Suspense>
    </div>
  );
}

import { MenuProps, Popover } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setSelectedDataRoom, setUserAndRoomStatus } from 'store/slices/userDataSlice';
import { fetchRoom } from 'store/reducers/roomsCreator';
import { fetchUserPermissions } from 'store/reducers/userDataCreator';
import { setSelectedKeys } from 'store/slices/windowStateSlice';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import classes from './RoomsTab.module.scss';
import React from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';
import { setFoldersForPermissions } from 'store/slices/dataPermissionsSlice';
import { DeleteRoomModal, EditRoomModal, NewRoomModal } from 'components/Modals';
import RoomsTabControl from './RoomsTabControl';
import { IDataRoom } from 'interfaces';
import EmptyRoom from './EmptyRoom';
import RowLoader from 'components/Sceletons/RowLoader';
import { onlyUserPages } from 'routes/Main/Main';
import Mark from 'mark.js';
import RoomName from './RoomCard';

type RoomsTabProps = {
  clearRedux: () => void;
};

const RoomsTab = ({clearRedux}: RoomsTabProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = React.useState(false);
  const [isNewRoomModal, setIsNewRoomModal] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');

  const { userData, selectedRoom, isLoadingUserData, userPermissions } = useAppSelector((store) => store.userData);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);
  const { selectedKeys } = useAppSelector((store) => store.windowState);

  const previousRoomId = localStorage.getItem('previousRoomId');

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const searchedRooms = React.useMemo(
    () => userData?.available_rooms.filter((room) => room.name.toLowerCase().includes(searchValue.toLocaleLowerCase())),
    [searchValue, userData?.available_rooms]
  );  

  const onEditRoomCancel = () => setIsEditModalOpen(false);
  const onDeleteRoomCancel = () => setIsDeleteModalOpen(false);

  const onOpenClick = async (_: any, room: IDataRoom, isLoadingRoom: boolean) => {    
    if ((dataRoom?.id !== room.id || onlyUserPages.includes(selectedKeys[0])) && !isLoadingRoom) {
      clearRedux();
      localStorage.setItem('previousRoomId', room.id);
      localStorage.setItem('previousPage', 'documents');
      dispatch(setFoldersForPermissions([]));
      navigate(`/room/${room.custom_url || room.id}/documents`);
      dispatch(setSelectedKeys(['/documents']));
      await dispatch(fetchUserPermissions({ idRoom: room.id }));
      await dispatch(fetchRoom({ id: room.id }));
      dispatch(setUserAndRoomStatus('fulfilledDataRoom'));
      dispatch(setSelectedDataRoom(room));
    }
  };

  const onEditClick = (e: MenuInfo, room: IDataRoom) => {
    e.domEvent.stopPropagation();
    setIsEditModalOpen(true);    
    dispatch(setSelectedDataRoom(room));
  };
    
  const onDeleteClick = (e: MenuInfo, room: IDataRoom) => {
    e.domEvent.stopPropagation();
    dispatch(setSelectedDataRoom(room));
    setIsDeleteModalOpen(true);
  };

  const toRoomSettings = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, room: IDataRoom) => {
    e?.stopPropagation();
    navigate(`/room/${room.custom_url || room?.id}/settings`);
  }

  const markText = (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number ) => {    
    const markInstance = new Mark(markerRef.current as HTMLElement);
    
    setTimeout(() => {
      markInstance.unmark({
        className: classes.mark,
        done: () => {
          markInstance.mark(text, {
            className: classes.mark,
          });
        }
      });
    }, timeout || 100);
  }

  const controlItems = (room: IDataRoom): MenuProps['items'] => [
    {
      key: 'edit',
      label: (
        <span>
          <EditOutlined /> {t('Users.HelperMenu.edit')}
        </span>
        ),
      onClick: (e) => onEditClick(e, room),
      disabled: !room.permissions?.can_edit_room_settings,
    },
    {
      key: 'delete',
      label:
      userData?.id === room.owner_id ? (
          <span>
            <DeleteOutlined /> {t('Users.HelperMenu.delete')}
          </span>
        ) : (
          <Popover title={t('Rooms.popovers.delete')}>
            <span>
              <DeleteOutlined /> {t('Users.HelperMenu.delete')}
            </span>
          </Popover>
        ),
      onClick: (e) => onDeleteClick(e, room),
      disabled: userData?.id !== room.owner_id,
    },
    {
      key: 'settings',
      label:
        previousRoomId === room.id && userPermissions?.can_edit_room_settings ? (
          <span>
            <SettingOutlined onClick={(e) => toRoomSettings(e, room)} className={classes.iconBtn} /> {' '}
            {t('sidebar.settings')}
          </span>
        ) : null,
      onClick: (e) => onDeleteClick(e, room),
      disabled: userData?.id !== room.owner_id,
    },
  ];

  return (
    <div>
      <RoomsTabControl
        setIsVisibleModal={setIsNewRoomModal}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        isLoadingUserData={isLoadingUserData}
      />
      {
        isLoadingUserData
          ? <div style={{marginRight: 10}}>
            <RowLoader width={600} padding={'0 0 0 10px'} height={70} borderradius={5}/>
          </div>
          : <div className={classes.roomCardsWrap}>
            {!userData?.available_rooms[0]
              ? <EmptyRoom />
              : searchedRooms?.map((room) => {
                return(
                  <div key={room.id}>
                    <RoomName
                      room={room}
                      onOpenClick={onOpenClick}
                      controlItems={controlItems}
                      toRoomSettings={toRoomSettings}
                      searchValue={searchValue}
                      markText={markText}
                      dateFormat={userData?.date_format || 'international'}
                    />
                  </div>
                )
              })
            }
          </div>
      }

      {isDeleteModalOpen && (
        <React.Suspense fallback={<div />}>
          <DeleteRoomModal t={t} room={selectedRoom!} onCancel={onDeleteRoomCancel} isOpen={isDeleteModalOpen} />
        </React.Suspense>
      )}

      {isEditModalOpen && (
        <React.Suspense fallback={<div />}>
          <EditRoomModal t={t} room={selectedRoom!} onCancel={onEditRoomCancel}  isOpen={isEditModalOpen} />
        </React.Suspense>
      )}

      {isNewRoomModal && (
        <React.Suspense fallback={<div />}>
          <NewRoomModal open={isNewRoomModal} onCancel={() => setIsNewRoomModal(false)} />
        </React.Suspense>
      )}
    </div>
  );
}

export default RoomsTab;

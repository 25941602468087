import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import prepareDocuments from 'helpers/prepareDocuments';
import { IFolder, IDocument } from 'interfaces';
import { fetchFoldersForPermissions } from 'store/reducers/permissionsCreator';

interface DataState {
  foldersForPermissions: IFolder[];
  loadingFolders: boolean;
  errorFolders: string;
  dataForPermissionTable: (IDocument | IFolder)[];
  rootFolderForPermissions: any;
  isLoadingPermissions: boolean;

  userFoldersPermissions: (IDocument | IFolder)[] | null;
  userRootPermissions: any;
}

const initialState: DataState = {
  foldersForPermissions: [],
  loadingFolders: false,
  errorFolders: '',
  rootFolderForPermissions: null,
  dataForPermissionTable: [],
  isLoadingPermissions: false,

  userFoldersPermissions: null,
  userRootPermissions: null,
};

const dataPermissionsSlice = createSlice({
  name: 'dataPermissions',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchFoldersForPermissions.fulfilled, (state, action: PayloadAction<IFolder[]>) => {
      state.loadingFolders = false;
      state.errorFolders = '';
      state.foldersForPermissions = action.payload;
    })
    builder.addCase(fetchFoldersForPermissions.pending, (state) => {
      state.loadingFolders = true;
    })
    builder.addCase(fetchFoldersForPermissions.rejected, (state, action: any) => {
      state.loadingFolders = false;
      if (action.payload) {
        // Since we passed in `MyKnownError` to `rejectValue` in `updateUser`, the type information will be available here.
        state.errorFolders = action.payload.errorMessage
      } else {
        state.errorFolders = action.error.message
      }
    })
    
  },
  reducers: {
    setFoldersForPermissions(state, action: PayloadAction<IFolder[]>) {
      state.foldersForPermissions = action.payload;
    },
    setIsLoadingPermissions(state, action: PayloadAction<boolean>) {
      state.isLoadingPermissions = action.payload;
    },
    setDataForPermissionTable(state, action: PayloadAction<{documents: IDocument[], folders?: IFolder[], rootFolder?: any}>) {
      state.dataForPermissionTable = prepareDocuments(action.payload.documents, action.payload.folders || []);
      state.rootFolderForPermissions = action.payload.rootFolder || null;
    },
    resetDataForPermissionTable(state) {
      state.dataForPermissionTable = [];
      state.rootFolderForPermissions = null;
    },
    setUserPermissions(state, action: PayloadAction<{documents: IDocument[], folders?: IFolder[], rootFolder?: any} | null>) {
      state.userFoldersPermissions = action.payload ? prepareDocuments(action.payload.documents, action.payload.folders || []) : null;
      state.userRootPermissions = action.payload ? action.payload.rootFolder : null;
    },
  },
});

const dataPermissionsReducer = dataPermissionsSlice.reducer;

export const {
  setFoldersForPermissions,
  setIsLoadingPermissions,
  setDataForPermissionTable,
  resetDataForPermissionTable,
  setUserPermissions,
} = dataPermissionsSlice.actions;
export default dataPermissionsReducer;

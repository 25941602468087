import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import colors from '../../scss/variables/colors.module.scss';

const MessangerLoader = (props: any) => {
  const { t } = useTranslation();

  return (
    <div style={{ borderRadius: 3, backgroundColor: 'white', margin: '0', width: '100%', height: '100%' }}>
      <ContentLoader
        speed={2}
        width='100%'
        title={t('Documents.table.loader')}
        height='100%'
        // viewBox={`0 0 1400 ${props.height}`}
        backgroundColor={colors.verylightgrey}
        foregroundColor='#ecebeb'
        {...props}
      >
        <rect x='0' y='0' rx='5' ry='5' width='100%' height='900' />
      </ContentLoader>
    </div>
  );
};
export default MessangerLoader;

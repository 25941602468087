import { Empty, Table } from 'antd';
import classes from './DocumentsReport.module.scss';
import { getCompareColumns, getInfoColumns } from './helpers/columns';
import { IColumn, IDocData } from 'interfaces';
import { useTranslation } from 'react-i18next';
import colors from '../../scss/variables/colors.module.scss';
import React from 'react';

type DocumentInfoProps = {
  documentData: IDocData;
};

export default function DocumentInfo({ documentData }: DocumentInfoProps) {
  const [modifiedCompareColumns, setModifiedCompareColumns] = React.useState<IColumn[]>([]);

  const { t } = useTranslation();
  const infoColumns = getInfoColumns(t);
  const compareColumns = getCompareColumns(t);

  React.useEffect(()=> {
    const modifiedCompareColumns = compareColumns.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
    }));
        
    setModifiedCompareColumns(modifiedCompareColumns);
  },[documentData]);

  const renderCell = (props: any) => {
    const { value, info, col } = props;
    const arrExceptionKeys = [t(`Report.info.uploaded_at`), t(`Report.info.opened_at`)]
    
    const isCurrentColumn = col.key === 'current' && !arrExceptionKeys.includes(info.key);
    const isAttributeChanged = info.current !== info.previous;
    const cellClass = (isCurrentColumn && isAttributeChanged) ? classes.redCell : 'simple';
    
    return (
      <div className={cellClass}>
        {(value || arrExceptionKeys.includes(info.key)) ? value : `<${(t('personalInfo.none'))}>`}
      </div>
    );
  }

  return (
    <div className={classes.info}>
      <Table
        dataSource={documentData.infoData}
        columns={infoColumns}
        className={classes.infoTable}
        pagination={false}
        size='small'
        style={{ borderBottom: `1px solid ${colors.hovergrey}`, marginBottom: 10 }}
        scroll={{ y: 'calc((100vh - 340px) / 2)' }}
        locale={{
          emptyText: <div className={classes.emptyText}><Empty /></div>,
        }}
      />

      <Table
        dataSource={documentData.infoCompare}
        columns={modifiedCompareColumns}
        className={classes.compareTable}
        pagination={false}
        size='small'
        style={{ borderBottom: `1px solid ${colors.hovergrey}` }}
        scroll={{ y: 'calc((100vh - 340px) / 2)' }}
        locale={{
          emptyText: <div className={classes.emptyText}><Empty /></div>,
        }}
      />
    </div>
  );
}

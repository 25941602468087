
import React from 'react'
import ContentLoader from 'react-content-loader'
import colors from '../../scss/variables/colors.module.scss';
import { useTranslation } from 'react-i18next';

const DocumentsDasboardLoader = (props: any) => { 
  
  const { t } = useTranslation();

  return (
    <div style={{borderRadius: 3, backgroundColor: 'white'}}>
      <ContentLoader 
        speed={2}
        width={1400}
        title={t('Documents.table.loader')}
        height={props.height || 500}
        // viewBox={`0 0 1400 ${props.height}`}
        backgroundColor={colors.verylightgrey}
        foregroundColor="#ecebeb"
        {...props}
      >
        <rect x="20" y="20" rx="5" ry="5" width="100" height="110" /> 
        <rect x="160" y="20" rx="5" ry="5" width="100" height="110" /> 
        <rect x="300" y="20" rx="5" ry="5" width="100" height="110" /> 
        <rect x="440" y="20" rx="5" ry="5" width="100" height="110" />
      </ContentLoader>
    </div>
  )
}
export default DocumentsDasboardLoader

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setChoosenDocuments, setDataDocumentTable } from 'store/slices/dataDocumentsSlice';
import DocumentsDasboardLoader from '../Sceletons/DocumentsDasboardLoader';
import DashboardItem from './DashboardItem';
import classes from './DocumentDashboard.module.scss';
import cn from 'classnames';
import { IDocument, IFolder } from 'interfaces';

type TableProps = {
  setSelectedRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>;
  isLoadingRooms: boolean;
  selectedRowIds: string[];
  setSelectedRowIds: React.Dispatch<React.SetStateAction<string[]>>;
  handleDropDocuments: (selectedFiles: string[], folder_id: string[]) => void;
  emptyText: string;
  openContextModal: (entity: IFolder | IDocument, openTargetModal: () => void) => void;
  resetContextMenuField: () => void;
  openContextMenu: (event: React.MouseEvent<HTMLTableRowElement | HTMLDivElement>, entity: IDocument | IFolder) => void;
};

export default function DocumentDashboard({
  setSelectedRowKeys,
  isLoadingRooms,
  handleDropDocuments,
  selectedRowIds,
  setSelectedRowIds,
  emptyText,
  openContextModal,
  resetContextMenuField,
  openContextMenu
}: TableProps) {
  const { dataDocumentTable, isLoadingDocuments } = useAppSelector((store) => store.documents);
  const { isLoadingProcess, isDeletingDocuments, isUploadingDocuments, isDownloadingDocuments} = useAppSelector((store) => store.windowState);
  const { userFoldersPermissions } = useAppSelector((store) => store.permissions);
  const { isLoadingUserPermissions } = useAppSelector((store) => store.userData);

  const params = useParams();
  const roomId = params.room?.replace('room=', '');

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const labelHandle = (e: React.MouseEvent<HTMLDivElement>, infoRow: any) => {
    e.stopPropagation();
    dispatch(setDataDocumentTable(
      dataDocumentTable?.map((doc: any) => (doc.id === infoRow.id ? { ...doc, clicked: !doc.clicked } : doc))
    ));    
  };
  
  const onSelectChange = (document_id: string) => {
    const selectedIds = selectedRowIds.includes(document_id)
      ? [...selectedRowIds.filter((id) => id !== document_id)]
      : [...selectedRowIds, document_id];

    const selectedRows = dataDocumentTable!.filter((documentItem) =>
      selectedIds.includes(documentItem.id!)
    );
    const newSelectedRowKeys = selectedRows
      // .filter(({ type }) => type !== 'folder')
      .map((documentItem) => documentItem.key!);

      const newSelectedRowPermissionsAndKeys = selectedRows
      // .filter(({ type }) => type !== 'folder')
      .map((documentItem) => ({ key: documentItem.key!, permissions: documentItem.permissions! }));

    const selectedFilesIds = selectedRows
      // .filter(({ type }) => type !== 'folder')
      .map(({ id }: any) => id);

    setSelectedRowIds(selectedFilesIds);
    dispatch(setChoosenDocuments(newSelectedRowPermissionsAndKeys));
    setSelectedRowKeys(newSelectedRowKeys);
  };

  if (isLoadingDocuments || isLoadingUserPermissions || isLoadingRooms || !userFoldersPermissions) {
    return <DocumentsDasboardLoader height={'calc(100vh - 261px)'} />;
  };
    
  return (
    <div className={cn(classes.tableArea,
      (isLoadingProcess || isDeletingDocuments || isUploadingDocuments || isDownloadingDocuments) ? classes.processLoading : classes.simple)
    }>
        {dataDocumentTable?.length 
          ? (
            <div className={classes.dashboard}>
              {dataDocumentTable?.map((file) => (
                <DashboardItem
                  dataSource={dataDocumentTable}
                  fileItem={file}
                  roomId={roomId!}
                  t={t}
                  selectedRowIds={selectedRowIds}
                  handleDropDocuments={handleDropDocuments}
                  onSelectChange={onSelectChange}
                  labelHandle={labelHandle}
                  key={file.key}
                  openContextModal={openContextModal}
                  resetContextMenuField={resetContextMenuField}
                  openContextMenu={openContextMenu}
                />
              ))}
            </div>
          ) : (
            <div
              style={{
                fontSize: 18,
                height: 'calc(100vh - 370px)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {t(`Documents.table.${emptyText}`)}
            </div>
          )
        }
    </div>
  );
}

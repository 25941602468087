import { TFunction } from "i18next";

export const getOrganizationColumns = (t: TFunction) => [
  {
    title: t('Documents.table.columns.name'),
    dataIndex: 'name',
    key: 'name',
    width: '65%',
  },
  {
    title: t('Documents.table.columns.changedAt'),
    dataIndex: 'modified_at',
    key: 'modified_at',
    width: '27%',
  },
  {
    title: '',
    dataIndex: 'delete',
    key: 'delete',
    width: '8%',
  },
];

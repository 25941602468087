import { FC, Dispatch, SetStateAction, useMemo } from 'react';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import { RefObject } from 'react';
import { Card, Switch, Upload, UploadFile } from 'antd';
import { TFunction } from 'i18next';
import classes from '../RoomSettings.module.scss';
import cn from 'classnames';
import { ItemRender } from 'antd/es/upload/interface';
import { settingsAction } from 'interfaces/ISettings';
import { useAppSelector } from 'store/hook';
import { availableIconFormat, documnentIcons } from 'helpers/documentIcons';
import { useNavigate } from 'react-router-dom';

const { Dragger } = Upload;

type RoomAccessType = {
  file?: UploadFile;
  setFile: (file: UploadFile[]) => void;
  setAgreement: () => void;
  agreement_enable: boolean;
  agreementRefs: RefObject<HTMLDivElement>;
  hasFile: boolean;
  setHasFile: Dispatch<SetStateAction<boolean>>;
  action: settingsAction;
  t: TFunction;
};

const RoomAccess: FC<RoomAccessType> = ({
  file,
  setFile,
  setAgreement,
  agreement_enable,
  agreementRefs,
  hasFile,
  setHasFile,
  action,
  t,
}) => {
  const { dataRoom } = useAppSelector((state) => state.dataRoom);

  const navigate = useNavigate();

  const currentRoomPath = dataRoom?.custom_url || dataRoom?.id!;

  const formatNDA = dataRoom?.agreement_file?.split('.')?.reverse()[0];  
  const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';

  const onBeforeUpload = (file: UploadFile) => {
    setFile([file]);
    setHasFile(true);
    return false;
  };

  const onRemove = () => setFile([]);
  const itemRender: ItemRender = useMemo(
    () =>
      (originNode, doc, fileList, { remove }) => (
        <div className={classes.file}>
          {originNode} <DeleteOutlined className={classes.deleteIcon} onClick={remove} />
        </div>
      ),
    []
  );

  const ndaCases = useMemo(
    () => ({
      nda_enable: file ? [file] : undefined,
      nda_disable: undefined,
    }),
    [file]
  );

  const uloadedNDA = (
    <div className={classes.uploadedWrap}>
      <div onClick={() => navigate(`/room/${currentRoomPath}/nda`)} className={classes.cardNDA}>
        <div style={{ fontSize: 32 }}>{documnentIcons[icon as keyof typeof documnentIcons]}</div>
        <div style={{ paddingLeft: 16 }}>{file?.name}</div>
      </div>
    </div>
  );

  const iconRender = (file: UploadFile) => {
    const formatNDA = file?.name?.split('.')?.reverse()[0];  
    const icon = availableIconFormat.includes(formatNDA) ? formatNDA : 'default';
    return documnentIcons[icon as keyof typeof documnentIcons];
  }

  return (
    <Card className={cn(classes.card, classes.settingsTitle)} title={t('Settings.terms.title')} bordered ref={agreementRefs}>
      <Switch
        checked={agreement_enable}
        checkedChildren={t('Settings.terms.enable')}
        unCheckedChildren={t('Settings.terms.disable')}
        onChange={setAgreement}
        disabled={dataRoom?.agreement_enable}
      />

      {
        dataRoom?.agreement_enable
          ? uloadedNDA
          : <Dragger
            name='file'
            listType='picture'
            multiple={false}
            className={cn(classes.dragger, !hasFile && classes.notFile)}
            disabled={!agreement_enable || !!file}
            fileList={ndaCases[action]}
            beforeUpload={onBeforeUpload}
            onRemove={onRemove}
            iconRender={iconRender}
            itemRender={itemRender}
            accept='.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf'
          >
            <p className='ant-upload-drag-icon'>
              <InboxOutlined />
            </p>
            <p className='ant-upload-text'>{t('Settings.terms.uploadFile.select')}</p>
            <p className='ant-upload-hint'>{t('Settings.terms.uploadFile.subtitle')}</p>
          </Dragger>
      }
      
    </Card>
  );
};

export default RoomAccess;

import React from 'react';
import { Modal } from 'antd';
import classes from './DeleteModal.module.scss';
import { useTranslation } from 'react-i18next';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';

type DeleteModalPops = {
  isOpen: boolean;
  onCancel: () => void;
  isLoading: boolean;
  submit: () => void;
  text: string;
  title: string;
  okText?: string;
  cancelText?: string;
};

const DeleteModal = ({ isOpen, onCancel, isLoading, submit, text, title, okText, cancelText }: DeleteModalPops) => {
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      centered
      okText={okText || t('Documents.management.delete')}
      cancelText={t('Documents.management.cancel')}
      confirmLoading={isLoading}
      title={title}
      onOk={submit}
      className={classes.modalDelete}
    >
      <div className={classes.delete}>{text}</div>

      {isLoading && <UploadingComponent />}
    </Modal>
  );
};

export default DeleteModal;

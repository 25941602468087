export const license = {
  en: <>
    <p style={{paddingBottom: 15}}> <b>Terms of Use</b></p>

    <p>Effective: July 01, 2024 (“Effective Date“)</p>
    <p>By accepting the Terms of Use by clicking on the corresponding "I accept the Terms of Use" button below or by downloading, installing, activating or using the software, you confirm that you have read this Terms of Use, understood its contents or agree to comply with its terms.</p>
    
    <p style={{paddingBottom: 15}}><b>Terms:</b></p>

    <p>- Client - a legal entity or individual who has acquired the right to use the Software and performs its use;</p>
    <p>- Users - The Client (including its employees), its contractors who are authorized by the Client to use the Software and to whom the Client or the Software provided the User's email address and password at the Client's request to enable these persons to use the Software and / or access it;</p>
    <p>- Copyright holder-Taza Digital Technology Limited Liability Company, TIN 02106202210224, ID 207099-3310-OOO dated 21.06.2022, legal address: Kyrgyzstan, Osh, 422 Lenina.</p>
    <p>- Software-service for managing the exchange of documents when concluding transactions Taza Deal.VDR;</p>
    <p>- Certificate – a document sent to the Client confirming their rights to use the Software.</p>

    <p style={{paddingBottom: 15}}><b>General provisions:</b></p>

    <p>This Terms of Use is a legal document concluded between the User and By the Copyright Holder in relation to the procedure for using the Software.</p>
    <p>By performing actions to install and use the Software, the User confirms his full and unconditional consent to all Terms of Use and undertakes to comply with them in accordance with the terms and requirements of Kyrgyzstan. The person who accepts this Terms of Use confirms to the Copyright Holder that he has the necessary authority to accept the provisions of this Terms of Use.</p>
    <p>In case of disagreement with the terms of this Terms of Use, the User must stop using it.</p>

    <p style={{paddingBottom: 15}}><b>User rights</b></p>

    <p>The User has the right to use the Software under the rights of a simple (non-exclusive) license from the moment the Client receives the Certificate and activates the Software purchased from the Copyright Holder (or from an official partner, distributor) during the validity period of the purchased license.</p>
    <p>Under this Terms of Use, the rights to new versions of the Software are not granted to the User under the purchased license, except in cases where updates and changes to the Software are carried out on the initiative of the Copyright Holder in order to eliminate errors in the operation of the purchased Software.</p>
    <p>The law does not provide for the unilateral refusal of the User to whom the rights were transferred from the acquired right to use the Software, in connection with which the purchased licenses are not subject to refund, a refund for them is not provided.</p>
    <p>The Copyright Holder provides warranty support for the software for a period of 365 calendar days from the date of transfer of rights to the Software.</p>

    <p style={{paddingBottom: 15}}><b>Proper use</b></p>

    <p>This Terms of Use grants the User the right to install (install), run and use a single instance of the Software, within the scope of its functionality, in accordance with the purpose and rules for proper use of the Software specified in this Terms of Use.</p>
    <p>The user undertakes not to modify, adapt, reverse engineer, decompile, disassemble, decrypt, copy, create derivative products, translate the Software or documentation, or otherwise attempt to extract the source code of the Software, or make any changes to the object code of the Software, including instructing other persons to perform the following operations: these actions are performed without the Copyright Holder's consent. </p>
    <p>The user does not have the right to use the Software to provide services to third parties (including consultations), including on a free basis, rent it out, transfer it to third parties, replicateуthe U Software, or useе it for fraudulent or other illegal purposes. </p>
    <p>If the user violates the terms of the Terms of Use, the procedure for proper use of the Software, the Copyright Holder has the right to terminate and / or refuse to grant the rights to use the Software without compensation for the cost of the acquired right to use the Software and any losses, monetary compensation.</p>

    <p style={{paddingBottom: 15}}><b>Copyright Notice</b></p>
    
    <p>The exclusive rights to the Softwareу belong to the Copyright Holder and are protected by the current legislation of the Russian Federation.</p>
    <p>If the Software includes third-party software that is not subject to intellectual property rights or other rights of the Copyright holder, the use of such third-party software is regulated by the relevant license agreements of the Copyright holder of such software. By agreeing to the terms of this Terms of Use, the User automatically agrees to all the terms of the License Agreements for third-party software included in the Software, the text of which is posted on the Copyright Holder's website. The copyright Holder guarantees that, subject to the terms of third-party licenses, the user will be able to use the third-party software as part of the Software freely, without additional payments, and with the amount of rights in respect of such software not less than the amount of rights granted in respect of the software.</p>
    <p>The User undertakes not to perform any actions that violate the intellectual rights of the Copyright Holder.</p>
    <p>The User does not have the right to delete or changeе the copyright information available in the Software.</p>

    <p style={{paddingBottom: 15}}><b>Limited Warranty</b></p>
    
    <p>Software is provided on an "as is" basis. The Copyright Holder does not provide any guarantees regarding the error-free and uninterrupted operation of the Software, as well as the compliance of the Software with the specific goals and expectations of the User.</p>
    <p>Under no circumstances shall the Copyright Holder be liable to the Users for any actual, indirect or incidental damages, including but not limited to losses resulting from: loss of profits, loss of income, loss and access to confidential information, personal data and other information, business interruption, or any other material or other damages arising from:  in connection with the use or inability to use the Software or documentation, as well as when using third-party software provided by third parties.</p>
    <p>The Copyright Holder is not responsible for the operation of operating systems, browsers, and third-party system components that are not included in the Software package. The user independently supports the operation of operating systems, browsers, and third-party system components, having the appropriate rights to use them, organizing updates, and providing security measures, as well as using other necessary methods. </p>
    <p>The Copyright holder does not guarantee the operability of the Software and its individual components, if the Software was installed independently by the user in its information system or its components that have outdated or unsupported operating systems, system drivers, components and/or technologies by the relevant third-party vendors (other third parties), as well as in the event of an inconsistent installation of the Software. with the appropriate third-party vendors for modifying (upgrading) the system software.</p>
    <p>The user guarantees that he / she is the owner of any documents, copies of documents and information processed during the use of the Software or has the right to such use, and is fully responsible for their processing, use and storage.</p>

    <p style={{paddingBottom: 15}}><b>Responsibility</b></p>
    
    <p>The User is responsible for violating the Terms of Use, as well as for illegal use of the Software in accordance with the current legislation of the Russian Federation.</p>
    <p>The User is solely responsible for the compliance of the content posted by the User with the requirements of the current legislation, including liability to third parties in cases where the User's placement of a particular content or content violates the rights and legitimate interests of third parties, including the personal non-property rights of authors, other intellectual rights of third parties, and/or encroaches on they receive intangible benefits.</p>
    <p>The Copyright holder reserves the right to immediately unilaterally terminate the User's license without notice in case of violation of the terms of the Terms of Use, as well as in case of detection of illegal use of the User's Software.</p>

    <p style={{paddingBottom: 15}}><b>Other provisions</b></p>
    
    <p>The Copyright Holder reserves the right to change the terms of the Terms of Use by notifying the User. For the purposes of this clause, the proper notification is the posting of the amended terms of the Terms of Use on the Copyright Holder's website. These changes to the terms of the Terms of Use come into force from the date of their publication on the site and apply to all Users. </p>
    <p>By accepting this Terms of Use, the User confirms its consent that in the course of its work, the Software may send the Copyright holder information (including, but not limited to) about errors, conflicts, and also includes the possibility of collecting statistical information carried out by the Right holder in order to analyze the use, as well as improve the Software.</p>
    <p>By accepting this Terms of Use, the User confirms its consent that the Copyright Holder has the right to publish and use information about the user for marketing purposes, which includes information about the fact of using the Copyright Holder's software, the name of the person and the logo (trademark) free of charge.</p>
    <p>In case of disputes and disagreements between the Copyright Holder and the User, the mandatory claim procedure for dispute settlement is applied. The deadline for responding to a claim is 14 (fourteen) business days from the date when the claim was received. In case of complete or partial refusal to satisfy the claim or failure of the Party submitting the claim to receive a response to the claim within the time limit, the claim dispute settlement procedure is considered to be observed. Disputes and disagreements are subject to consideration by the law of Kyrgyzstan.</p>
    <p>This Terms of Use applies to the entire period of use of the Software provided for in the Certificate.</p>
  </>,
  ////
  ////
  ////
  ////
  ru: <>
    <div style={{ textAlign: 'center' }}>
      <b>Лицензионное соглашение</b><br/>
      <b>на предоставление прав использования программы Taza Deal.VDR</b>
    </div>

    <div style={{ textIndent: 40, padding: '10px 0 0 20px' }}>
      <i >Принимая условия лицензионного соглашения путем нажатия на соответствующую кнопку «Я принимаю условия соглашения» ниже или путем загрузки, инсталляции, активации или использования программного обеспечения, вы подтверждаете, что прочитали настоящее соглашение, поняли его содержание или обязуетесь выполнять его условия.</i>
    </div>

    <div style={{ padding: '0 0 0 20px', textAlign: 'justify' }}>
      <ol>
        <li>Термины:
          <ul>
            <li>- Клиент - юридическое или физическое лицо, которое приобрело право использования Программы и осуществляющее ее использование;</li>
            <li>- Пользователи - Клиент (в том числе, его сотрудники) его контрагенты, которые уполномочены Клиентом использовать Программу и которым Клиент или Программа по запросу Клиента предоставили адрес электронной почты пользователя и пароль для предоставления этим лицам возможности использовать Программу и / или доступа к ней;</li>
            <li>- Правообладатель - Общество с ограниченной ответственностью "Таза Диджитал Текнолоджи", ИНН 02106202210224, Регистрационный номер 207099-3310-ООО, Дата первичной регистрации 21.06.2022, юридический адрес: Кыргызская Республика, г.Ош, ул.Ленина, д.422</li>
            <li>- Программа - сервис управления обменом документами при заключении сделок Taza Deal.VDR;</li>
            <li>- Сертификат – документ, передаваемый Клиенту в подтверждении его прав на использование Программы.</li>
          </ul>
        </li>
        
        <li>
          Общие положения:
          <ol>
            <li>Настоящее Соглашение является юридическим документом, заключаемым между Пользователем и Правообладателем в отношении порядка использования Программы.</li>
            <li>Осуществляя действия по установке и использованию Программы, Пользователь подтверждает свое полное и безоговорочное согласие со всеми условиями настоящего Соглашения и обязуется их соблюдать в соответствии с условиями и требованиям ст. 428 Гражданского кодекса РФ. Лицо, осуществляющее акцепт настоящего Соглашения, подтверждает Правообладателю, что обладает необходимыми полномочиями по акцептованию положений настоящего Соглашения.</li>
            <li>В случае несогласия с условиями настоящего Соглашения, Пользователь должен прекратить ее использование.</li>
          </ol>
        </li>

        <li>
          Права использования
          <ol>
            <li>Пользователь имеет право использовать Программу на правах простой (неисключительной) лицензии с момента получения Сертификата Клиентом и активации Программы, приобретенной у Правообладателя (или у официального партнера, дистрибьютора) в течение срока действия приобретенной лицензии.</li>
            <li>По настоящему Соглашению права на новые версии Программы не предоставляются Пользователю в рамках приобретённой лицензии, кроме случаев если обновления и изменения Программы осуществляются по инициативе Правообладателя в целях устранения ошибок функционирования приобретенной Программы.</li>
            <li>Односторонний отказ Пользователя, которому были переданы права, от приобретенного права использования Программы законом не предусмотрен, в связи с чем не подлежат возврату приобретенные лицензии, возврат денежных средств за них не предусмотрен.</li>
            <li>Правообладатель предоставляет гарантийную поддержку программного обеспечения на срок 365 календарных дней с даты передачи прав на Программу.</li>
          </ol>
        </li>

        <li>
          Надлежащее использование
          <ol>
            <li>Настоящее Соглашение предоставляет Пользователю право установки (инсталляции), запуска и использования одного экземпляра Программы, в рамках функциональных возможностей, в соответствии с назначением и правилами надлежащего использования Программы, указанными в настоящем Соглашении.</li>
            <li>Пользователь обязуется не модифицировать, адаптировать, выполнять обратное проектирование, декомпилировать, дизассемблировать, расшифровывать, копировать, создавать производные продукты, транслировать Программу или документацию, или в ином порядке пытаться извлечь исходный код Программы, не вносить какие-либо изменения в объектный код Программы, в том числе не поручать иным лицам осуществлять указанные действия без согласия Правообладателя.</li>
            <li>Пользователь не имеет права использовать Программу для предоставления услуг третьим лицам (включая консультации), в том числе на безвозмездной основе, сдавать в аренду, передавать третьим лицам, тиражировать Программу, а также использовать ее в мошеннических или иных противозаконных целях.</li>
            <li>При нарушении Пользователем условий Соглашения, порядка надлежащего использования Программы, Правообладатель вправе прекратить и/или отказать в предоставлении прав на использование Программу без возмещения стоимости приобретенного права использования Программы и каких-либо убытков, денежных компенсаций.</li>
          </ol>
        </li>

        <li>
          Авторское право
          <ol>
            <li>Исключительные права на Программу принадлежат Правообладателю и защищены действующим законодательством Российской Федерации.</li>
            <li>В случае, если в состав Программы входит программное обеспечение третьих лиц, которое не является объектом права интеллектуальной собственности или иного права Правообладателя, то использование такого программного обеспечения третьих лиц регламентируется соответствующими лицензионными соглашениями правообладателей такого программного обеспечения. Соглашаясь с условиями настоящего Соглашения, Пользователь автоматически соглашается со всеми условиями Лицензионных соглашений на программное обеспечение третьих лиц, входящее в состав Программы, текст которых размещен на сайте Правообладателя. Правообладатель гарантирует, что при соблюдении условий лицензий третьих лиц, Пользователь сможет использовать программное обеспечение третьих лиц в составе Программы свободно, без дополнительных выплат, и с объёмом прав в отношении такого программного обеспечения не меньшим, чем объем прав, предоставляемых в отношении программного обеспечения.</li>
            <li>Пользователь обязуется не совершать действия, нарушающие интеллектуальные права Правообладателя.</li>
            <li>Пользователь не вправе удалять или изменять имеющиеся в Программе сведения об авторских правах Правообладателя.</li>
          </ol>
        </li>

        <li>
          Ограниченная гарантия
          <ol>
            <li>Программа предоставляется на условиях «как есть». Правообладатель не предоставляет никаких гарантий в отношении безошибочной и бесперебойной работы Программы, а также соответствия Программы конкретным целям и ожиданиям Пользователя.</li>
            <li>Правообладатель ни при каких обстоятельствах не несет ответственности перед Пользователями за фактические, косвенные и второстепенные убытки, включая в числе прочего убытки в результате: потери прибыли, потери доходов, потери и получения доступа к конфиденциальной информации, персональных данных и иной информации, прерывания деятельности, в результате любых других материальных или иных убытков, возникающих в связи с использованием или невозможностью использования Программы или документации, а также при использовании стороннего программного обеспечения, предоставляемого третьими лицами.</li>
            <li>Правообладатель не несет ответственность за функционирование операционных систем, браузеров и сторонних системных компонентов, не включенных в комплект поставки Программы. Пользователь самостоятельно осуществляет поддержку функционирования операционных систем, браузеров и сторонних системных компонентов, обладая соответствующими правами на их использование, выполняя организацию обновления и обеспечивая мероприятия безопасности, а также используя иные необходимые для этого способы.</li>
            <li>Правообладатель не гарантирует работоспособность Программы и его отдельных компонентов, в случае, если установка Программа была самостоятельно осуществлена Пользователем в свою информационную систему или ее компоненты, имеющую устаревшие или неподдерживаемые соответствующими сторонними вендорами (иными третьими лицами) операционные системы, системные драйвера, компоненты и/или технологии, а также в случае несогласованной с соответствующими сторонними вендорами модификации (модернизации) системного программного обеспечения.</li>
            <li>Пользователь гарантирует, что является владельцем любых документов, копий документов и информации, обрабатываемых при использовании Программы или имеет право на такое использование, и несет полную ответственность за их обработку, использование и хранение.</li>
          </ol>
        </li>

        <li>
          Ответственность
          <ol>
            <li>Пользователь несет ответственность за нарушение условий Соглашения, а также за неправомерное использование Программы в соответствии с действующим законодательством Российской Федерации.</li>
            <li>Пользователь самостоятельно несет ответственность за соответствие содержания размещаемого Пользователем контента требованиям действующего законодательства, включая ответственность перед третьими лицами в случаях, когда размещение Пользователем того или иного контента или содержание контента нарушает права и законные интересы третьих лиц, в том числе личные неимущественные права авторов, иные интеллектуальные права третьих лиц, и/или посягает на принадлежащие им нематериальные блага.</li>
            <li>Правообладатель оставляет за собой право незамедлительно в одностороннем порядке без уведомления прекратить действие лицензии Пользователя в случае нарушения условий Соглашения, а также в случае выявления неправомерного использования Программы.</li>
          </ol>
        </li>

        <li>
          Иные положения
          <ol>
            <li>Правообладатель оставляет за собой право изменять условия Соглашения, уведомляя при этом Пользователя. Для целей настоящего пункта надлежащим уведомлением является размещение изменённых условий Соглашения на сайте Правообладателя. Указанные изменения в условиях Соглашения вступают в силу с даты их публикации на сайте и распространяются на всех Пользователей.</li>
            <li>Принимая настоящее Соглашение, Пользователь подтверждает свое согласие на то, что в ходе своей работы Программа может отправлять Правообладателю информацию (в том числе, но не ограничиваясь) об ошибках, конфликтах, а также включает в себя возможность сбора статистической информации, осуществляемого Правообладателем с целью анализа использования, а также совершенствования Программы.</li>
            <li>Принимая настоящее Соглашение, Пользователь подтверждает свое согласие на то, что Правообладатель вправе публиковать и использовать информацию о Пользователе в маркетинговых целях, которая включает в себя информацию о факте использования программного обеспечения Правообладателя, наименование лица и логотип (товарный знак) на безвозмездной основе.</li>
            <li>В случае возникновения споров и разногласии между Правообладателем и Пользователем, применяется обязательный претензионный порядок урегулирования спора. Срок ответа на претензию составляет 14 (четырнадцать) рабочих дней с момента, когда претензия была получена. В случае полного или частичного отказа в удовлетворении претензии или неполучения направившей претензию Стороной в срок ответа на претензию, претензионный порядок урегулирования споров считается соблюденным. Споры и разногласия подлежат рассмотрению Арбитражным судом г. Москвы.</li>
            <li>Настоящее Соглашение распространяет свое действие на весь период использования Программы, предусмотренный в Сертификате.</li>
          </ol>
        </li>

      </ol>
    </div>
  </>,
}

import React from 'react'
import { Button, Form, Input, message, Popconfirm } from 'antd';
import { FontSizeOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setMoreInfoHidden } from 'store/slices/windowStateSlice';
import { setGroupChoosen, setGroups, setUserAndData } from 'store/slices/dataUsersSlice';
import api from 'api';
import { IUsers } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { defaultGroups } from 'store/reducers/usersGroupCreator';

const { Item, useForm } = Form;

const defaultRoles = ['user', 'full_administrator'];

export default function GroupEditing() {
  const [form] = useForm();
  const [groupName, setGroupName] = React.useState('')
  const [isChanged, setIsChanged] = React.useState(false)
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [isAvailableEditUserGroup, setIsAvailableEditUserGroup] = React.useState<boolean>(false)

  const { selectedGroup, groups, users, dataUserTable } = useAppSelector((state) => state.dataUsers);
  const { userPermissions } = useAppSelector((state) => state.userData);

  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const localizedGroupName =  selectedGroup && defaultGroups.includes(selectedGroup?.name)
    ? t(`Users.table.${selectedGroup?.name}`)
    : selectedGroup?.name;

  React.useEffect(() => {    
    selectedGroup && form.setFieldsValue({
      group_name: localizedGroupName,
    });
    localizedGroupName && setGroupName(localizedGroupName)
    if (defaultRoles.includes(selectedGroup?.emailOrRole!)) setIsAvailableEditUserGroup(false)
    else if (userPermissions?.can_edit_roles) setIsAvailableEditUserGroup(true)
    else setIsAvailableEditUserGroup(false)
  }, [selectedGroup]);

  const setGroupSettings = (e: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(e.target.value);
    setIsChanged(e.target.value !== selectedGroup?.name)
  };

  const onCancelClick = () => {
    selectedGroup && form.setFieldsValue({
      group_name: localizedGroupName,
    });
    setIsChanged(false);
  }

  const onSaveEdit = async () => {
    setIsUpdating(true)
    try {      
      selectedGroup && await api.updateRole({...selectedGroup.role!, name: groupName});
      const apdatedGroup = {
        ...selectedGroup!,
        name: groupName,
        first_name: groupName,
        emailOrRole: groupName,
        group_name: groupName, 
        role: {
          ...selectedGroup!.role!,
          name: groupName
        }
      }
      dispatch(setGroupChoosen(apdatedGroup));
      dispatch(setGroups(groups.map(group => group.id === selectedGroup?.id 
        ? apdatedGroup
        : group
      )));
      const usersOfThisGroup = users.filter((user: IUsers) => user.group.includes(selectedGroup?.id!));
      dispatch(setUserAndData(
        {
          dataUserTable: dataUserTable.map(elem => elem.id === selectedGroup?.id
            ? {
              ...elem,
              name: groupName,
              first_name: ` ${groupName} (${usersOfThisGroup.length})`,
              emailOrRole: groupName,
              group_name: groupName,
              role: {
                ...elem.role!,
                name: groupName
              }
            }
            : elem
          ),
          users: users
        }
      ));
      setIsChanged(false);
      message.success(`${t('Users.HelperMenu.group')} '${groupName}' ${t('Users.HelperMenu.changed')}!`);
    } catch (e) {
      message.error(t('Users.HelperMenu.changeError'));
      onCancelClick();
    } finally {
      setIsUpdating(false);
    }
  }

  const onDeleteClick = async () => {
    setConfirmLoading(true);
    try {
      selectedGroup?.role && await api.deleteRole(selectedGroup?.role?.id);

      const usersOfDeletingGroup = dataUserTable.find(elem => elem.id === selectedGroup?.id)?.children || [];

      dispatch(setGroups(groups.filter(group => group.id !== selectedGroup?.id!)));
      const userInDefaultGroup = dataUserTable
        .filter(element => element.id !== selectedGroup?.id!)
        .map(group => (group.name === 'user'
          ? {
            ...group,
            children: [...group.children!, ...usersOfDeletingGroup]
          }
          : group
        ));
              
      dispatch(setUserAndData({
        dataUserTable: userInDefaultGroup,
        users: users
      }));

      message.success(`${t('Users.HelperMenu.group')} '${localizedGroupName}' ${t('Users.HelperMenu.deleted')}`);
      dispatch(setMoreInfoHidden(true));
      dispatch(setGroupChoosen(null));
    } catch (e) {
      message.error(t('Users.HelperMenu.deleteError'))
    } finally {
      setConfirmOpen(false)
      setConfirmLoading(false);
    }
  }

  return (
    <Form form={form} onFinish={onSaveEdit} layout='vertical' className='personal_info'>
      <Item name='group_name' label={t('Users.HelperMenu.groupname')}>
        <Input
          disabled={!isAvailableEditUserGroup}
          value={groupName}
          onChange={setGroupSettings}
          prefix={<FontSizeOutlined />}
        />
      </Item>

      <div className='form_buttons'>
        {isAvailableEditUserGroup && <>
          {isChanged 
            ? <Button onClick={onCancelClick}>{t('Users.HelperMenu.cancel')}</Button>
            : <Popconfirm
              title={t('Users.HelperMenu.delete')}
              open={confirmOpen}
              description={`${t('Users.HelperMenu.confirmDelete')}'${localizedGroupName}'?`}
              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
              cancelButtonProps={{onClick: () => setConfirmOpen(false)}}
              okButtonProps={{ loading: confirmLoading }}
              cancelText={t('Users.HelperMenu.cancel')}
              okText={t('Users.HelperMenu.ok')}
              onConfirm={onDeleteClick}
            >
              <Button
                danger
                onClick={() => setConfirmOpen(true)}
                type='text'
              >
                {t('Users.HelperMenu.delete')}
              </Button>
            </Popconfirm>
          }
          <Button
            loading={isUpdating}
            disabled={!isChanged}
            type='primary' 
            htmlType='submit'
          >
            {t('Users.HelperMenu.save')}
          </Button>
        </>}
      </div>
    </Form>
  )
}

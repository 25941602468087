import React from 'react'
import classes from './MoveModal.module.scss';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { Modal, Tree, TreeProps, message } from 'antd';
import { useAppSelector } from 'store/hook';
import { documnentIcons } from 'helpers/documentIcons';
import { TFunction } from 'i18next';
import api from 'api';
import { IFolder } from 'interfaces';

interface DataNode {
  title: string;
  key: string;
  isLeaf?: boolean;
  children?: DataNode[];
  icon?: JSX.Element;
}

type MoveModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  isLoading: boolean;
  submit: (folderId: string, targetFolder: string) => void;
  title: string;
  t: TFunction
}

const getTreeData = (t: TFunction, folders?: IFolder[]): DataNode[] => [
  {
    title: t('Documents.modals.rootDir'),
    key: rootDirId,
    icon: <div className={classes.rootFolder}>{documnentIcons['rootfolder' as keyof typeof documnentIcons]}</div>,
    children: folders?.map(folder => ({
      title: folder.name,
      key: folder.id!,
      icon: <div className={classes.rootFolder}>{documnentIcons['folder' as keyof typeof documnentIcons]}</div>,
    }))
  },
];

const rootDirId = 'null';

export default function MoveModal({
  isOpen,
  onCancel,
  isLoading,
  submit,
  title,
  t
}: MoveModalProps) {
  const [treeData, setTreeData] = React.useState<DataNode[]>([]);
  const [targetFolder, setTargetFolder] = React.useState<React.Key[]>([rootDirId]);
  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([rootDirId]);
  const [autoExpandParent, setAutoExpandParent] = React.useState<boolean>(true);
  const [targetFolderName, setTargetFolderName]= React.useState<string>(rootDirId);

  const { foldersForPermissions } = useAppSelector((store) => store.permissions); 
  const { isOpenMoveEntityModal } = useAppSelector((store) => store.windowState);
  const { currentFolder, entityOfContextMenu } = useAppSelector((store) => store.documents);

  const currentFolderId = currentFolder[0] ? currentFolder[currentFolder.length - 1]?.id : 'null';
  const isDisableAction = targetFolder[0] === currentFolderId || targetFolder[0] === undefined;
  
  React.useEffect(() => {
    foldersForPermissions[0] && !treeData[0] && setTreeData(getTreeData(t, foldersForPermissions));
  }, [foldersForPermissions]);

  React.useEffect(() => {
    if (isOpenMoveEntityModal) {
      setTargetFolder([rootDirId]);
      setExpandedKeys([rootDirId]);
      setTargetFolderName(t('Documents.modals.rootDir'));
    } else {
      setTargetFolder([]);
      setExpandedKeys([]);
      setTargetFolderName('');
    }
  }, [isOpenMoveEntityModal, entityOfContextMenu]);

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    const folderOfRoot = foldersForPermissions.find(folder => folder.id === selectedKeys[0]);

    if (folderOfRoot) {
      if (!folderOfRoot.permissions?.includes('can_upload')) {
        message.error(`${t('Documents.error.accessToMove')}"${folderOfRoot.name}"`)
        return;
      }
    }

    setTargetFolderName(String(info.node.key === 'null' ? t('Documents.modals.rootDir') : info.node.title));
    setTargetFolder(selectedKeys);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onLoadData = async ({ key, children }: any) => {
    if (children) {
      return;
    }

    const folderOfRoot = foldersForPermissions.find(folder => folder.id === key);

    if (folderOfRoot) {
      if (!folderOfRoot.permissions?.includes('can_upload')) {
        message.error(`${t('Documents.error.accessToMove')}"${folderOfRoot.name}"`)
        return;
      }
    }
    
    const { data: childrenFolders } = await api.getFoldersOfFolder(key);
    const childrenElements = childrenFolders.map(folder => ({
      title: folder.name,
      key: folder.id!,
      icon: <div className={classes.rootFolder}>{documnentIcons['folder' as keyof typeof documnentIcons]}</div>,
    }));

    setTreeData((origin) =>
      updateTreeData(origin, key, childrenElements),
    );
  };

  // It's just a simple demo. You can use tree map to optimize update perf.
  const updateTreeData = (list: DataNode[], key: React.Key, children: DataNode[]): DataNode[] =>
  list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });

  return (
    <Modal
      open={isOpen}
      onCancel={onCancel}
      centered
      okText={t('Documents.contextMenu.move')}
      cancelText={t('Documents.management.cancel')}
      okButtonProps={{disabled: isDisableAction}}
      confirmLoading={isLoading}
      title={title}
      onOk={() => submit(String(targetFolder[0]), targetFolderName)}
      className={classes.modalMove}
    >
      <div className={classes.moveWrap}>
        <Tree
          loadData={onLoadData}
          treeData={treeData}
          selectedKeys={targetFolder}
          showIcon={true}
          onSelect={onSelect}
          autoExpandParent={autoExpandParent}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
        />
      </div>

      { isLoading &&
        <UploadingComponent />
      }
    </Modal>
  )
}

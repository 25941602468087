import { Form, Input, Modal, message } from 'antd';
import { useState } from 'react';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import api from 'api';
import { TFunction } from 'i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setAgreementInfo, setAgreementState } from 'store/slices/dataAgreementsSlice';
import { setDocuments } from 'store/slices/dataDocumentsSlice';
import classes from '../FileDetailsModal.module.scss';

type Props = {
  open: boolean;
  documentId: string;
  documentPermissions: string[];
  onCancel: () => void;
  t: TFunction;
};

export default function DeclineAgreementModal({ open, documentPermissions, documentId, onCancel, t }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { agreementInfo, needAgreementFromUsers, agreements, myAgreement } = useAppSelector(
    (state) => state.agreements
  );
  const { documents, folders, selectedDocument, selectedFolder } = useAppSelector((store) => store.documents);
  const { userData } = useAppSelector((store) => store.userData);
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const onSubmit = async (values: { comment: string }) => {
    try {
      setIsLoading(true);
      await api.declineAgreement({ comment: values.comment, id: documentId, entity: 'document' });

      const author = {
        name: `${userData?.first_name} ${userData?.last_name}`,
        id: userData?.id,
        email: userData?.email,
      };
      dispatch(setAgreementInfo({ ...agreementInfo!, status: 1, comment: values.comment, entity: 'document', author }));
      const changedMyAgreement = myAgreement ? { ...myAgreement, agreement_state: false } : null;
      const newNeedAgreementFromUsers = needAgreementFromUsers.map((agreementFromUsers) =>
        agreementFromUsers.user_id === changedMyAgreement?.user_id ? changedMyAgreement : agreementFromUsers
      );

      dispatch(
        setAgreementState({
          myAgreement: changedMyAgreement,
          needAgreementFromUsers: newNeedAgreementFromUsers,
          agreements: agreements.map((agreement) => ({
            ...agreement,
            users: agreement.users?.map((user) =>
              user.user_id === changedMyAgreement?.user_id ? changedMyAgreement : user
            ),
          })),
        })
      );

      selectedFolder
        ? dispatch(
            setDocuments({
              documents,
              folders: folders.map((folder) => {
                return folder.id === selectedFolder.id ? { ...folder, agreement_status: 1 } : folder;
              }),
            })
          )
        : dispatch(
            setDocuments({
              documents: documents.map((document) => {
                return document.id === selectedDocument!.id
                  ? { ...document, agreement_status: 1, permissions: documentPermissions }
                  : document;
              }),
              folders,
            })
          );

      onCancel();
      message.success(t('Documents.details.agreement.successDeclined'));
    } catch (Err) {
      message.error(t('Documents.details.agreement.failedDecline'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t('Documents.details.agreement.message')}
      open={open}
      okText={t('Documents.details.agreement.declineButton')}
      okButtonProps={{ className: classes.declineButton }}
      onCancel={onCancel}
      onOk={form.submit}
      confirmLoading={isLoading}
    >
      <Form onFinish={onSubmit} className={classes.decline} form={form}>
        <Form.Item
          name='comment'
          rules={[{ required: true, message: t('Documents.details.agreement.textArea') }]}
          className={classes.decline_item}
        >
          <Input.TextArea
            placeholder={t('Documents.details.agreement.textPlacehoder')}
            autoSize={{ maxRows: 8, minRows: 4 }}
            count={{
              show: true,
              max: 500,
              exceedFormatter: (txt, { max }) => txt.split('').slice(0, max).join(''),
            }}
          />
        </Form.Item>
      </Form>
      {isLoading ? <UploadingComponent /> : null}
    </Modal>
  );
}

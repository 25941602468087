import { requestTemplate } from 'api';
import { CancelToken } from 'axios';
import { IDetails, IDocument, IFolder } from 'interfaces';

type DeleteResponce = {
  success: boolean,
  message: string,
}

//FOLDERS
const getFolders = (
  idRoom: string, sourceToken?: CancelToken
) => requestTemplate.get<IFolder[]>(`/folders/?room_id=${idRoom}`, {
  cancelToken: sourceToken,
});

const getFolderOfFolder = (folder_id: string, room_id: string) =>
  requestTemplate.get<IFolder>(`/folders/by_id/?room_id=${room_id}&folder_id=${folder_id}`);

const getFoldersOfFolder = (
  idFolder: string, sourceToken?: CancelToken
) => requestTemplate.get<IFolder[]>(`/folders/?folder_id=${idFolder}`, {
  cancelToken: sourceToken,
});

const uploadFolder = (
  payload: { formData: FormData, name: string, action: string },
) => requestTemplate.post<IDocument>(`/documents/bulk_create_folder/?document_name=${payload.name}`, payload.formData,
  { headers: { 'Content-type': 'multipart/form-data' }},
);

const getFoldersPermissions = (
  payload: { room_id: string, group_id?: string },
) => requestTemplate.get<IFolder[]>(`/folders/permissions/`, { params: payload });

const createFolder = (
  payload: { name: string, room: string, folder_id: string | null, details: IDetails },
) => requestTemplate.post<IFolder>(`/folders/?action=create&id=${payload.room}`, payload);

const addDocumentsToFolder = (
  payload: {document_ids: string[], folder_id: string | null, room_id: string },
) => requestTemplate.post<{ message: string }>('/folders/documents/', payload);

const setFolderPermission = (
  payload: { permission_types: string[], folder_id: string, room_id: string, group_id: string, details: IDetails },
) => requestTemplate.post<{ message: string }>(`/folders/permissions/?action=update&id=${payload.room_id}`, payload);

const deleteFolder = (folder_id: string) => requestTemplate.delete<DeleteResponce>(`/folders/?folder_id=${folder_id}`);

const renameFolder = (folder_id: string, payload: { name: string }) =>
  requestTemplate.put<DeleteResponce>(`/folders/?folder_id=${folder_id}`, payload);

const folders = {
  getFolders,
  uploadFolder,
  getFoldersOfFolder,
  getFoldersPermissions,
  createFolder,
  addDocumentsToFolder,
  setFolderPermission,
  deleteFolder,
  renameFolder,
  getFolderOfFolder
};

export default folders;

import { message } from 'antd';
import api from 'api';
import auth from 'api/auth';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { getBrowserClientData } from 'helpers/browserData/browserData';
import { setCookie } from 'helpers/cookies';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export default function TFAuth() {
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.password) {
      fetchAuth(params.password, '');
    }
  }, [params]);

  const fetchAuth = async (auth_password: string, email: string) => {
    try {
      const response = await auth.verifyAuth({ auth_password, email });
      const browserData = await getBrowserClientData();

      setCookie('athena-token', response.data.access_token, 1);
      setCookie('refresh_token', response.data.refresh_token, 1);
      const form_data = new FormData();
      form_data.append('browser_data', JSON.stringify(browserData));
      message.destroy();
      navigate('/');

      await api.sendBrowserFingerprint(form_data);
    } catch (err) {}
  };

  return <UploadingComponent />;
}

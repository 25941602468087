import React, { ChangeEvent } from 'react';
import { Form, FormInstance, Input, InputRef, Modal } from 'antd';
import { TFunction } from 'i18next';
import { OrgFields } from '../../../helpers/organizationFieldsDictionary';
import classes from './AddOrganizationModal.module.scss';
import { validateField } from '../AddingInfoModal/validateField';

type AddOrganizationModalProps = {
  form: FormInstance<any>;
  open: boolean;
  loading: boolean;
  setIsAddModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: any) => void;
  t: TFunction;
};

export default function AddOrganizationModal ({
  form,
  loading,
  open,
  setIsAddModalOpen,
  onSubmit,
  t,
}: AddOrganizationModalProps) {
  const nameInput = React.useRef<InputRef | null>(null);
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {    
    values?.[OrgFields.Name]
      ? form.validateFields([OrgFields.Name]).then(
        () => setSubmittable(true),
        () => setSubmittable(false),
      )
      : setSubmittable(false)
  }, [values]);

  React.useEffect(() => nameInput.current?.focus(), [])

  const onCancel = () => {
    setIsAddModalOpen(false);
    form.resetFields();
  };

  const onLinkChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (
      !event.target.value.startsWith('http://') &&
      !event.target.value.startsWith('https://') &&
      !event.target.value.startsWith('www.')
    ) {
      form.setFieldValue('link', `www.${event.target.value}`);
    }
  };
  
  return (
    <Modal
      closable={false}
      onOk={form.submit}
      okButtonProps={{disabled: !submittable}}
      okText={t('Organizations.create')}
      onCancel={onCancel}
      className={classes.modal}
      open={open}
      confirmLoading={loading}
      centered
      title={t('Organizations.addNew')}
    >
      <Form form={form} name='org_create' layout='vertical' onFinish={onSubmit}>
        <Form.Item
          rules={[
            { required: true },
          ]}
          name={OrgFields.Name}
          
          label={t(`Organizations.modals.add.${OrgFields.Name}`)}
        >
          <Input ref={nameInput} placeholder={t(`Organizations.modals.add.${OrgFields.Name}`)} />
        </Form.Item>
        <Form.Item name={OrgFields.Description} label={t(`Organizations.fields.${OrgFields.Description}`)}>
          <Input.TextArea autoSize={{ minRows: 5, maxRows: 15 }} placeholder={t(`Organizations.modals.add.placeholders.${OrgFields.Description}`)} />
        </Form.Item>
        <Form.Item
          name={OrgFields.Link}
          label={t(`Organizations.fields.${OrgFields.Link}`)}
          rules={[
            { whitespace: true },
            validateField(OrgFields.Link, t)
          ]}
        >
          <Input onChange={onLinkChange} placeholder={t(`Organizations.modals.add.placeholders.${OrgFields.Link}`)} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

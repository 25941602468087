import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';

const SettingsLoader = (props: any) => {
  
  const { t } = useTranslation();

  return  <ContentLoader
    speed={2}
    width={'100%'}
    height={650}
    backgroundColor='#fff'
    foregroundColor='#ecebeb'
    title={t('Documents.table.loader')}
    {...props}
  >
    <rect x='0' y='20' rx='0' ry='0' width='1400' height='250' />
    <rect x='0' y='290' rx='0' ry='0' width='1400' height='250' />
    <rect x='0' y='560' rx='0' ry='0' width='1400' height='150' />
    
  </ContentLoader>
};

export default SettingsLoader;

import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'api';


const fetchProfileData = createAsyncThunk('profileData/fetchProfile', async (_, thunkApi) => {
  try {
    const response = await api.getUserInfo();
    return response.data;
  } catch (err) {
    return thunkApi.rejectWithValue('Download failed');
  }
});

export { fetchProfileData };

import { Form, Input, Modal, message } from 'antd';
import api from 'api';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IDocument, IFolder } from 'interfaces';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setDocuments } from 'store/slices/dataDocumentsSlice';

type Props = {
  open: boolean;
  onCancel: () => void;
  entity: IDocument | IFolder;
};

export default function RenameModal({ open, onCancel, entity }: Props) {
  const [value, setValue] = useState(entity.name);
  const [isLoading, setIsLoading] = useState(false);
  const { documents, folders } = useAppSelector((state) => state.documents);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      name: entity.name,
    });
  }, []);

  const onFinish = async (values: any) => {
    try {
      setIsLoading(true);
      await api.renameFolder(entity.id!, { name: values.name });
      dispatch(
        setDocuments({
          documents,
          folders: folders.map((folder) => (folder.id === entity.id ? { ...folder, name: value } : folder)),
        })
      );
      onCancel();
    } catch (err) {
      message.error(t('Documents.error.failedRename'));
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      cancelText={t('main.cancelButton')}
      title={`${t('Documents.contextMenu.rename')} ${
        entity.type === 'folder' ? t('Documents.modals.delete.folder') : t('Documents.modals.delete.file')
      }`}
      okButtonProps={{ disabled: value === entity.name || !value || !value.trim(), loading: isLoading }}
      onOk={form.submit}
      styles={{
        body: {
          padding: '20px',
          paddingTop: '44px',
        },
      }}
    >
      <Form form={form} onFinish={onFinish}>
        <Form.Item name='name'>
          <Input value={value} onChange={onChange} />
        </Form.Item>
      </Form>

      {isLoading && <UploadingComponent />}
    </Modal>
  );
}

import React from 'react';
import { Button, Card, Checkbox, Divider, Dropdown, MenuProps, Space, Typography, message, theme} from 'antd';
import { DeleteOutlined, ShareAltOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchAllNotes } from 'store/reducers/notesCreator';
import Spinner from 'components/Spinner/Spinner';
import { TFunction } from 'i18next';
import classes from './NotesTab.module.scss';
import { setNotes, setSelectedNote } from 'store/slices/dataNotes';
import { INotes } from 'interfaces';
import api from 'api';

const { useToken } = theme;

type NotesCardProps = {
  onDeleteClick: (id: string) => void;
  t: TFunction;
};

export default function NotesCards ({ onDeleteClick, t }: NotesCardProps) {
  const [openDropdown, setOpenDropdown] = React.useState(false);
  const [visilbeTo, setVisilbeTo] = React.useState<string[]>([]);
  const [isSharingProcess, setIsSharingProcess]= React.useState(false);

  const lastNote = React.useRef<HTMLDivElement | null>(null);

  const { notes, isNotesErorr, isNotesLoading, selectedNote } = useAppSelector((state) => state.notes);
  const { users, isGroupLoading } = useAppSelector((state) => state.dataUsers);
  const { selectedDocument } = useAppSelector((state) => state.documents);
  const { userData } = useAppSelector((state) => state.userData);

  const dispatch = useAppDispatch();
  const { token } = useToken();

  React.useEffect(() => {
    lastNote.current?.scrollIntoView({block: 'end', inline: 'nearest'});
  }, [notes]);

  React.useEffect(() => {
    if (selectedDocument) dispatch(fetchAllNotes(selectedDocument.id!));
  }, [selectedDocument]);

  React.useEffect(() => {
    if (isNotesErorr) message.error(isNotesErorr);
  }, [isNotesErorr]);

  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyles = { boxShadow: 'none', maxHeight: 250, overflowY: 'scroll' };

  const handleOpenChange = (nextOpen: boolean, info: any, note: INotes) => {
    if (info.source === 'trigger' && nextOpen) setVisilbeTo(note.visible_to);
    if (info.source === 'menu' || nextOpen) {
      dispatch(setSelectedNote(note.id));
      setOpenDropdown(true);
    } else {
      dispatch(setSelectedNote(''));
      setVisilbeTo([]);
      setOpenDropdown(false);
    }
  };

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    setVisilbeTo(prev => prev.includes(e.key) ? prev.filter(el => el !== e.key) : [...prev, e.key]);
  };

  const handleShare = async () => {
    setIsSharingProcess(true);
    try {
      await api.shareNote({note_id: selectedNote, visible_to: visilbeTo})
      const newNotes = notes?.map(note => note.id === selectedNote
        ? {
          ...note,
          visible_to: visilbeTo,
        }
        : note
      )
      dispatch(setNotes(newNotes!));
      message.success(t('Documents.details.notes.shareSuccess'));
    } catch (e) {
      message.error(t('Documents.details.notes.shareError'));
    } finally {
      dispatch(setSelectedNote(''));
      setIsSharingProcess(false);
      setOpenDropdown(false);
      setVisilbeTo([]);
    }
  };

  const items: MenuProps['items'] = users?.filter(user => user.id !== userData?.id).map(user => ({
    key: user.id,
    style: {paddingRight: 5},
    label: <div className={classes.userLabelWrap}>
      <div>
        <div className={classes.labelName}>{`${user.first_name} ${user.last_name}`}</div>
        <div className={classes.labelEmail}>{user.email}</div>
      </div>
      <Checkbox
        checked={visilbeTo?.includes(user.id)}
      />
    </div>
  }));

  const CardTitle = (note: INotes) => (
    <div className={classes.notes_title}>
      <div className={classes.name}>
        {note.author}, {note.timestamp}
      </div>

      {note.can_share && <div className={classes.buttons}>
        <Dropdown
          onOpenChange={(nextOpen, info) => handleOpenChange(nextOpen, info, note)}
          menu={{
            items,
            onClick: handleMenuClick
          }}
          open={openDropdown && selectedNote === note.id}
          placement='bottomRight'
          dropdownRender={(menu) => (
            <div style={contentStyle} onClick={e => e.preventDefault()}>
              { isGroupLoading
                ? <div className={classes.spinnerWrap}><Spinner /></div>
                : React.cloneElement(menu as React.ReactElement, { style: menuStyles })
              }
              <Divider style={{ margin: 0 }} />
              <Space style={{ padding: 8 }}>
                <Button
                  type='primary'
                  loading={isSharingProcess && selectedNote === note.id}
                  onClick={handleShare}
                  disabled={[...visilbeTo]?.sort().join() === [...note.visible_to]?.sort().join()}
                >
                  { note.visible_to.length
                    ? t('Documents.details.notes.change')
                    : t('Documents.details.notes.share')
                  }
                </Button>
              </Space>
            </div>
          )}
        >
          <Button
            loading={isSharingProcess && selectedNote === note.id}
            icon={<ShareAltOutlined style={{fontSize: 16}}/>}
          />
        </Dropdown>
        
        <Button 
          onClick={() => onDeleteClick(note.id)}
          icon={<DeleteOutlined />}
        />
      </div>}
    </div>
  )

  const notesElement = {
    true: (
      <div className={classes.holder}>
        <div>{t('Documents.details.notes.nullNotes')}</div>
      </div>
    ),
    false: (
      notes?.map((note, i) => (
        <Card
          ref={lastNote}
          key={note.id}
          className={classes.notes_card}
          title={CardTitle(note)}
        >
          <Typography.Paragraph className={classes.notes_paragraph}>
            {note.text}
          </Typography.Paragraph>
        </Card>
      ))
    ),
  }
  
  return (
    <>
      { isNotesLoading && !notes?.length
        ? <div className={classes.holder}><Spinner size={60} /></div>
        : <div>{notesElement[String(notes?.length === 0) as keyof typeof notesElement]}</div>
      }
    </>
  );
};

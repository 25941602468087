import ContentLoader from 'react-content-loader'
import { useTranslation } from 'react-i18next';

const ProfileLoader = (props: any) => {

  const { t } = useTranslation();

  return <div style={{display: 'flex', justifyContent: 'center'}}>
    <ContentLoader 
      speed={2}
      width={600}
      height={700}
      viewBox='0 0 600 700'
      backgroundColor='#f3f3f3'
      title={t('Documents.table.loader')}
      foregroundColor='#ecebeb'
      {...props}
    >
      <rect x='50' y='20' rx='4' ry='4' width='250' height='32' /> 
      <rect x='50' y='95' rx='4' ry='4' width='275' height='52' /> 
      <rect x='375' y='95' rx='4' ry='4' width='275' height='52' /> 
      <rect x='50' y='175' rx='4' ry='4' width='600' height='52' /> 
      <rect x='50' y='255' rx='4' ry='4' width='600' height='52' />
      <rect x='50' y='335' rx='4' ry='4' width='600' height='200' />
{/* 
      <rect x='780' y='20' rx='4' ry='4' width='250' height='32' /> 
      <rect x='780' y='99' rx='50' ry='50' width='100' height='100' /> 
      <rect x='900' y='111' rx='4' ry='4' width='200' height='80' /> 
      <rect x='780' y='235' rx='4' ry='4' width='320' height='160' />  */}
    </ContentLoader>
  </div> 
}

export default ProfileLoader

import React from 'react';
import { Button, message } from 'antd';
import { TFunction } from 'i18next';
import { IDocument, IFolder } from 'interfaces';
import classes from '../FileDetailsModal.module.scss';
import { CheckCircleOutlined } from '@ant-design/icons';
import api from 'api';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { setAgreementState } from 'store/slices/dataAgreementsSlice';
import { setDocuments } from 'store/slices/dataDocumentsSlice';

type AgreementButtonsProps = {
  selectedDocument: IDocument | null;
  selectedFolder: IFolder | null;
  documentPermissions: string[];
  onDeclineOpen: () => void;
  t: TFunction;
};

export default function AgreementButtons({ t, onDeclineOpen, documentPermissions }: AgreementButtonsProps) {
  const [isChangingStatus, setIsChangingStatus] = React.useState(false);

  const { myAgreement, agreements, needAgreementFromUsers } = useAppSelector((store) => store.agreements);
  const { selectedDocument, selectedFolder } = useAppSelector((state) => state.documents);

  const { documents, folders } = useAppSelector((store) => store.documents);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const dispatch = useAppDispatch();
  
  const changeStatusHandle = async () => {
    setIsChangingStatus(true);
    try {
      await api.changeAgreementStatus(
        {
          id: String(selectedFolder ? selectedFolder.id : selectedDocument?.id),
          entity: selectedFolder ? 'folder' : 'document'
        },
        dataRoom?.id!,
        selectedFolder ? selectedFolder?.id! : selectedDocument?.id!
      );

      const changedMyAgreement = myAgreement ? {...myAgreement, agreement_state: !myAgreement.agreement_state} : null
      const newNeedAgreementFromUsers = needAgreementFromUsers.map(agreementFromUsers => 
        (agreementFromUsers.user_id === changedMyAgreement?.user_id ? changedMyAgreement : agreementFromUsers)
      );

      const newAgreements = agreements.map(agreement => ({
        ...agreement,
        users: agreement.users?.map(user => user.user_id === changedMyAgreement?.user_id ? changedMyAgreement : user)
      }))

      dispatch(setAgreementState({
        myAgreement: changedMyAgreement,
        needAgreementFromUsers: newNeedAgreementFromUsers,
        agreements: newAgreements,
      }));

      // логика для апрува одним юзером из группы
      const isApprovedDoc = !newAgreements.map(group => {
        const aprovedUser = group.users?.find(user => user.agreement_state === true)
        return Boolean(aprovedUser)
      }).includes(false)

      // логика для апрува всеми юзерами
      // const unchanchedStatus = newNeedAgreementFromUsers.find((agreement) => agreement.agreement_state === null);
      const declinedStatus = newNeedAgreementFromUsers.find((agreement) => agreement.agreement_state === false);

      selectedFolder
        ? dispatch(setDocuments({documents, folders: folders.map(folder => {
          return folder.id === selectedFolder.id
            // логика для апрува всеми юзерами
            // ? { ...folder, agreement_status: declinedStatus ? 1 : unchanchedStatus ? 0 : 2 }
            ? { ...folder, agreement_status: declinedStatus ? 1 : !isApprovedDoc ? 0 : 2 }
            : folder
        })}))
        : dispatch(
          setDocuments({
            documents: documents.map((document) => {
              return document.id === selectedDocument!.id
                ? // логика для апрува всеми юзерами
                  // ? { ...document, agreement_status: declinedStatus ? 1 : unchanchedStatus ? 0 : 2 }
                  {
                    ...document,
                    agreement_status: declinedStatus ? 1 : !isApprovedDoc ? 0 : 2,
                    permissions: documentPermissions,
                  }
                : document;
            }),
            folders,
          })
        );
      
      myAgreement?.agreement_state
        ? message.success(t('Documents.details.agreement.agreementSuccessfully'))
        : message.success(t('Documents.details.agreement.canceledSuccessfully'));
    } catch {
      myAgreement?.agreement_state
        ? message.error(t('Documents.details.agreement.canceledError'))
        : message.error(t('Documents.details.agreement.agreementError'));
    } finally {
      setIsChangingStatus(false);
    }
  }

  const isStatusNoZero = selectedDocument ? selectedDocument.agreement_status !== 0 : selectedFolder?.agreement_status !== 0;
  const isRejectedApprove = Boolean(needAgreementFromUsers.find(agreement => agreement.agreement_state === false));
  const isAgreementstatusNotNull = Boolean(myAgreement?.agreement_state);

  const isDisableButtons = isStatusNoZero || isRejectedApprove || isAgreementstatusNotNull
  
  return (
    <div className={classes.agreement_buttons}>
      <Button
        onClick={changeStatusHandle}
        type='primary'
        loading={isChangingStatus && !myAgreement?.agreement_state}
        disabled={isDisableButtons}
        icon={<CheckCircleOutlined className={classes.lightGreen}/>}
      >
        {myAgreement?.agreement_state ? t('Documents.details.agreement.agreed') : t('Documents.details.agreement.agree')}
      </Button>
      <Button
        onClick={onDeclineOpen}
        type='primary'
        className={!isDisableButtons ? classes.agreement_cancelButton : 'none'}
        disabled={isDisableButtons}
        loading={isChangingStatus && Boolean(myAgreement?.agreement_state)}
      >
        {t('Documents.details.agreement.cancel')}
      </Button>
    </div>
  )
}

export const signatureRules = {
  en: <>
    <div style={{ textAlign: 'center' }}>
      <b>Electronic Signature Agreement</b>
    </div>

    <div style={{ padding: '0 0 0 20px', textAlign: 'justify' }}>
      <ol>
        <li>Definitions
          <ul>
            <li style={{marginLeft: 20, listStyleType: 'disc'}}><span style={{fontWeight: 600}}>Electronic Signature (ES):</span> Information in electronic form that is attached to or logically associated with other information in electronic form (the signed document) and is used to identify the person signing the document.</li>
            <li style={{marginLeft: 20, listStyleType: 'disc'}}><span style={{fontWeight: 600}}>Parties:</span> The participants in this Agreement, including but not limited to the User and the Electronic Signature Service Provider.</li>
          </ul>
        </li>

        <li>Subject of the Agreement
          <ol>
            <li>The User agrees to use the electronic signature provided by the Provider for signing documents in electronic form.</li>
            <li>The Provider commits to ensuring the reliability and security of the electronic signature, as well as the safeguarding of data associated with the signing process.</li>
          </ol>
        </li>

        <li>Terms of Use
          <ol>
            <li>The electronic signature carries the same legal force as a handwritten signature, in accordance with the legislation: the Electronic Signatures in Global and National Commerce Act (ESIGN Act).</li>
            <li>The User confirms that they possess all necessary authority to use the electronic signature.</li>
            <li>The User agrees to:
              <ul>
                <li style={{marginLeft: 20, listStyleType: 'disc'}}>Not disclose information about the electronic signature to third parties.</li>
                <li style={{marginLeft: 20, listStyleType: 'disc'}}>Immediately notify the Provider in the event of loss of control over the ES keys or suspicion of unauthorized use.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>Liability
          <ol>
            <li>The Provider shall not be liable for any consequences resulting from the improper use of the electronic signature by the User.</li>
            <li>The User bears full responsibility for the security and confidentiality of the ES keys.</li>
          </ol>
        </li>

        <li>Term and Termination
          <ol>
            <li>This Agreement comes into effect upon its signing (electronic consent) and remains in force until either party decides to terminate it.</li>
            <li>Termination of the Agreement may be initiated by either party with a 30-calendar-day notice to the other party.</li>
          </ol>
        </li>

        <li>Amendments to the Agreement
          <ol>
            <li>The Provider reserves the right to make changes to this Agreement. The User will be notified of any changes via email or through the service interface.</li>
          </ol>
        </li>

        <li>Final Provisions
          <ol>
            <li>All disputes arising from this Agreement shall be resolved through negotiations. If it is not possible to settle the dispute amicably, the dispute shall be submitted to the court at the location of the Provider.</li>
            <li>This Agreement is drafted in accordance with the laws of [specify country or region] and is governed by them.</li>
          </ol>
        </li>

      </ol>
    </div>
  </>,
  ru: <>
    <div style={{ textAlign: 'center' }}>
      <b>Соглашение об использовании электронной подписи</b>
    </div>

    <div style={{ padding: '0 0 0 20px', textAlign: 'justify' }}>
      <ol>
        <li>Определения:
          <ul>
            <li>- <span style={{fontWeight: 600}}>Электронная подпись (ЭП)</span> - это информация в электронной форме, которая присоединена к другой информации в электронной форме (подписываемому документу) или иным образом связана с такой информацией и используется для определения лица, подписывающего документ.</li>
            <li>- <span style={{fontWeight: 600}}>Стороны</span> - Участники данного соглашения, включая, но не ограничиваясь, Пользователя и Провайдера услуг электронной подписи.</li>
          </ul>
        </li>

        <li>
          Предмет соглашения:
          <ol>
            <li>Пользователь соглашается использовать электронную подпись, предоставляемую Провайдером, для подписания документов в электронной форме.</li>
            <li>Провайдер обязуется обеспечить надежность и безопасность использования электронной подписи, а также сохранность данных, связанных с процессом подписания.</li>
          </ol>
        </li>

        <li>
          Условия использования
          <ol>
            <li>Электронная подпись имеет ту же юридическую силу, что и собственноручная подпись, в соответствии с законодательством: Закон о электронных подписях в глобальной и национальной торговле (ESIGN Act)</li>
            <li>Пользователь подтверждает, что обладает всеми необходимыми полномочиями для использования электронной подписи.</li>
            <li>Пользователь обязуется:
              <ul>
                <li>- Не передавать информацию об электронной подписи третьим лицам.</li>
                <li>- Немедленно уведомлять Провайдера в случае утраты контроля над ключами ЭП или подозрения на несанкционированное использование.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          Ответственность
          <ol>
            <li>Провайдер не несет ответственности за последствия, вызванные неправомерным использованием электронной подписи Пользователем.</li>
            <li>Пользователь несет полную ответственность за сохранность и конфиденциальность ключей ЭП.</li>
          </ol>
        </li>

        <li>
          Срок действия и расторжение
          <ol>
            <li>Настоящее соглашение вступает в силу с момента его подписания (электронного согласия) и действует до тех пор, пока одна из сторон не решит его расторгнуть.</li>
            <li>Расторжение соглашения может быть произведено любой стороной с уведомлением другой стороны за 30 календарных дней.</li>
          </ol>
        </li>

        <li>
          Изменения в соглашении
          <ol>
            <li>Провайдер имеет право вносить изменения в данное соглашение. О всех изменениях Пользователь будет уведомлен через электронную почту или через интерфейс сервиса.</li>
          </ol>
        </li>

        <li>
          Заключительные положения
          <ol>
            <li>Все споры, возникающие из настоящего соглашения, решаются путем переговоров. В случае невозможности урегулирования спора мирным путем, спор передается на рассмотрение в суд по месту нахождения Провайдера.</li>
            <li>Настоящее соглашение составлено в соответствии с законодательством [укажите страну или регион] и регулируется им.</li>
          </ol>
        </li>

      </ol>
    </div>
  </>,
  kg: <>
    <div style={{ textAlign: 'center' }}>
      <b>Электрондук кол тамганы пайдалануу келишими</b>
    </div>

    <div style={{ padding: '0 0 0 20px', textAlign: 'justify' }}>
      <ol>
        <li>Аныктамалар
          <ul>
            <li>- <span style={{fontWeight: 600}}>Электрондук кол тамга (ЭКТ)</span> - бул электрондук формадагы башкы маалыматка (кол коюла турган документке) кол койгон адамды аныктоо үчүн колдонулган электрондук формадагы маалымат.</li>
            <li>- <span style={{fontWeight: 600}}>Тараптар</span> - Бул келишимге катышуучу тараптар: электрондук кол тамганы Колдонуучу тарап жана Кызмат Көрсөтүүчу тарап (Провайдер), бирок алар менен чектелбейт.</li>
          </ul>
        </li>

        <li>Келишимдин предмети
          <ol>
            <li>Провайдер тарабынан берилген электрондук кол тамганы колдонууга Колдонуучу тарап, документтерге кол коюу үчүн макул.</li>
            <li>Провайдер электрондук кол тамганы колдонуунун ишенимдүүлүгүн жана коопсуздугун, ошондой эле кол коюу процесси менен байланышкан маалыматтардын сакталышын камсыз кылууга милдеттенет.</li>
          </ol>
        </li>

        <li>Колдонуу шарттары
          <ol>
            <li>Мыйзамдарга ылайык, электрондук кол тамга, кол менен жазылган кол тамга менен бирдей юридикалык күчкө ээ: глобалдык жана улуттук соодадагы электрондук кол коюу Мыйзамы (ESIGN Act)</li>
            <li>Колдонуучу электрондук кол тамганы колдонуу үчүн бардык ыйгарым укуктарга ээ экендигин тастыктайт.</li>
            <li>Колдонуучу милдеттенет:
              <ul>
                <li>- Электрондук кол тамга жөнүндө маалыматты, үчүнчү тараптарга бербөөгө.</li>
                <li>- ЭКТ ачкычтарына көзөмөл жоголсо же уруксатсыз колдонууга шектенсе, провайдерге дароо билдирүүгө.</li>
              </ul>
            </li>
          </ol>
        </li>

        <li>Жоопкерчилик
          <ol>
            <li>Колдонуучу электрондук кол тамганы туура эмес пайдалануусунан келип чыккан кесепеттер үчүн, Провайдер жооп бербейт.</li>
            <li>Колдонуучу ЭКТнын ачкычтарынын сакталышы жана купуялуулугу үчүн толук жоопкерчилик тартат.</li>
          </ol>
        </li>

        <li>Жарактуулук мөөнөтү жана токтотуу
          <ol>
            <li>Бул Келишим ага кол коюлган (электрондук келишим) учурдан тартып күчүнө кирет жана Тараптардын бири аны токтотууну чечкенге чейин колдонулат.Бул Келишим ага кол коюлган (электрондук келишим) учурдан тартып күчүнө кирет жана Тараптардын бири аны токтотууну чечкенге чейин колдонулат.</li>
            <li>Келишимди бузуу: Тараптардын бири тарабынан экинчи Тарапка 30 календардык күн мурда кабарлоо менен жүргүзүлүшү мүмкүн.</li>
          </ol>
        </li>

        <li>Келишимге өзгөртүүлөр
          <ol>
            <li>Провайдер ушул Келишимге өзгөртүүлөрдү киргизүүгө укуктуу. Бардык өзгөрүүлөр Колдонуучу тарапка электрондук почта же тейлөө интерфейси аркылуу кабарланат.</li>
          </ol>
        </li>

        <li>Корутунду жоболор
          <ol>
            <li>Ушул Келишимден келип чыккан бардык талаш-тартыштар сүйлөшүүлөр жолу менен чечилет. Талаш-тартышты тынчтык жолу менен жөнгө салуу мүмкүн болбогон учурда, талаш-тартыш провайдердин жайгашкан жери боюнча сотко берилет.</li>
            <li>Бул келишим [өлкөнү же регионду аныктоо] мыйзамдарына ылайык түзүлгөн жана жөнгө салынат.</li>
          </ol>
        </li>

      </ol>
    </div>
  </>
} 

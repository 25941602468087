import React from "react"
import ContentLoader from "react-content-loader"
import { useTranslation } from "react-i18next";

const OrgRowLoader = (props: any) => {

  const { t } = useTranslation();

  return <ContentLoader 
    speed={2}
    width={300}
    height={40}
    viewBox="0 0 300 40"
    title={t('Documents.table.loader')}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <circle cx="52" cy="20" r="20" /> 
    <rect x="88" y="9" rx="5" ry="5" width="231" height="20" />
  </ContentLoader>
};

export default OrgRowLoader

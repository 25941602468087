import { requestTemplate } from 'api';
import { IDocument } from 'interfaces';

// CONFIDENTIAL
const getConfidentialDocuments = (
  room_id: string
) => requestTemplate.get<IDocument[]>(`documents/confidential/?room_id=${room_id}`);

// не используются?
const getDocumentsConfident = <T = any> (document_id: string) => requestTemplate.get<T>(`/documents/confident/?document_id=${document_id}`);
const setDocumentsConfident = <T = any> (payload: FormData) => requestTemplate.post<T>('/documents/confident/?action=create', payload, { headers: { 'Content-Type': 'multipart/form-data' }});
const deleteDocumentsConfident = <T = any> (document_id: string) => requestTemplate.delete<T>(`/documents/confident/?action=delete&document_id=${document_id}`);

const confidential = {
  getConfidentialDocuments,
  getDocumentsConfident,
  setDocumentsConfident,
  deleteDocumentsConfident,
};

export default confidential;

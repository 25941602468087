import React from 'react';
import { Button, Layout } from 'antd';
import classes from './SidesComponent.module.scss';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import OrganizationsSidesLoader from '../Sceletons/OrganizationsSidesLoader';
import OrganizationCard from 'components/SideOrganizationCard/SideOrganizationCard';
import AddOrgToSideModal from 'components/Modals/AddOrgToSideModal/AddOrgToSideModal';
import { setIsAddOrgModalOpen, setIsOrgMenuCollapsed } from 'store/slices/dataSidesSlice';
import { fetchUsersOrganizations } from 'store/reducers/sidesCreator';
import SidesSider from 'components/SidesSider/SidesSider';

export default function SidesComponent() {
  const { sideOrganisations, isLoadingSides, isAddOrgModalOpen, usersOrganizations, selectedOrganization } = useAppSelector((store) => store.sides);
  const { userAndRoomStatus } = useAppSelector((store) => store.userData);

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  
  const openAddOrgModal = () => {    
    dispatch(setIsAddOrgModalOpen(true))
    !usersOrganizations[0] && dispatch(fetchUsersOrganizations());
  };

  React.useEffect(() => {
    if (selectedOrganization) dispatch(setIsOrgMenuCollapsed(false));
    else dispatch(setIsOrgMenuCollapsed(true));
  },[selectedOrganization]);

  return (
    <Layout style={{overflow: 'hidden', height: 'calc(100vh - 123px)'}}>

      <Layout className={classes.wrap}>
        
        <div className={classes.sidesTitle}>
          {t('Sides.title')}
        </div>

        <div className={classes.sidesArea}>
          {(isLoadingSides || userAndRoomStatus !== '')
            ? <OrganizationsSidesLoader />
            : <>
              {
                sideOrganisations[0] 
                  ? sideOrganisations.map(organization => (
                    <OrganizationCard
                      key={organization.id}
                      organization={organization}
                    />
                  ))
                  : <div className={classes.emptySides}>
                      {t('Sides.empty')}
                  </div>
              }

              <Button
                className={classes.addButton}
                icon={<FontAwesomeIcon icon={faSquarePlus} />}
                onClick={openAddOrgModal}
              >
                {t('Sides.add')}
              </Button>
            </>
          }

        </div>

        {isAddOrgModalOpen && 
          <React.Suspense fallback={<div />}>
            <AddOrgToSideModal isOpen={isAddOrgModalOpen} />
          </React.Suspense>
        }
      </Layout>

      <SidesSider />

    </Layout>
  )
}

import { Table, Tooltip, message } from 'antd';
import organizations from 'api/organizations';
import TableLoader from 'components/Sceletons/TableLoader';
import dayjs from 'dayjs';
import { IColumn } from 'interfaces';
import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './OrganizationSchedule.module.scss';
import { scheduleColumn } from './columns/scheduleColumns';

const Schedule = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [dataTable, setDataTable] = React.useState<any[]>([]);
  const [modifiedColumns, setModifiedColumns] = React.useState<IColumn[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    getSchedule();
  },[]);

  React.useEffect(()=> {
    const customColumns = scheduleColumn.map((col: IColumn) => ({
      ...col,
      render: (value: any, info: any) => renderCell({value, info, col}),
      title: t(`Organizations.columns.schedule.${col.dataIndex}`),
    }))
    setModifiedColumns(customColumns);
  },[dataTable]);


  const renderCell = (props: any) => {
    const { value, info, col } = props;

    if(col.key === 'link') {
      return (
        <Tooltip
          title={value}
        >
          <div style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
            <a href={value}>Google Meet</a>
          </div>
        </Tooltip>
      )
    }
    return value;
  };

  const getSchedule = async () => {
    setIsLoading(true);
    try {
      const response = await organizations.getScheduleData();      
      const preparedData = response.data.map((event: any) => {
        const time = dayjs(event.start_time).format('DD.MM.YYYY, HH:mm:ss');
                
        return {
          ...event,
          key: event.id,
          start_time: time,
          invitee_name: event.invitee.name,
          invitee_email: event.invitee.email,
        }
      })
      setDataTable(preparedData)
    } catch (e: any) {
      // console.log(e);
      message.error(t("Organizations.error.failedGetSchedule"));
    } finally {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <div className={classes.loaderWrap}>
        <TableLoader
          height={'calc(100vh - 280px)'}
        />
      </div>
    )
  };
  
  return (
    <div className={classes.scheduleWrap}>
      <Table 
        dataSource={dataTable}
        columns={modifiedColumns}
        pagination={false}
        size='middle'
        className={classes.scheduleTable}
        scroll={{
          y: 'calc(100vh - 355px)',
        }}
      />
    </div>
  );
}

export default Schedule;

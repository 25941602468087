import { Form, Input, InputRef, Modal } from 'antd';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import React from 'react';
import classes from '../SingingTab.module.scss'
import { OTPProps } from 'antd/es/input/OTP';
import { useTranslation } from 'react-i18next';

type EnterPasswordModalType = {
  open: boolean;
  onCancel: () => void;
  signDocument: (password: string) => Promise<void>;
  isAddingSgining: boolean;
};

const EnterPasswordModal = ({
  open,
  onCancel,
  signDocument,
  isAddingSgining
}: EnterPasswordModalType) => {
  const [form] = Form.useForm();

  // const textInput = React.useRef<OTPRef | null>(null);
  const textInput = React.useRef<InputRef | null>(null);

  const { t } = useTranslation();

  React.useEffect(() => textInput.current?.focus(), []);

  const sendPassword = async (values: {signing_password: string}) => {
    console.log(values.signing_password);
    await signDocument(values.signing_password)
    onCancel()
  }

  // const sharedProps: OTPProps = {
  //   length: 6,
  //   size: 'large',
  // };

  return (
    <Modal
      title={t('Documents.details.signingPassword.title')}
      centered
      open={open}
      onOk={form.submit}
      width={'55%'}
      onCancel={onCancel}
      className={classes.passwordModal}
      okButtonProps={{ loading: isAddingSgining }}
      okText={t('Documents.details.signing.signButton')}
      cancelText={t('main.cancelButton')}
    >
      <Form form={form} onFinish={sendPassword}>
        <Form.Item
          label={t('Documents.details.signingPassword.inputLabel')}
          name={'signing_password'}
          rules={[
            { required: true, message: t('Documents.details.signingPassword.requiredMessage') },
            // { pattern: new RegExp(/^[0-9]+$/i), message: t('Documents.details.signingPassword.patternMessage') }
          ]}
        >
          {/* <Input.OTP {...sharedProps} ref={textInput} /> */}
          <Input size='large' ref={textInput} />

        </Form.Item>
      </Form>

      {isAddingSgining &&
        <UploadingComponent />
      }
    </Modal>
  );
}

export default EnterPasswordModal;

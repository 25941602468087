import React from 'react';
import { Avatar, Card, Checkbox, message, Modal } from 'antd';
import { setIsAddOrgModalOpen, setSideOrganisations } from 'store/slices/dataSidesSlice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import classes from './AddOrgToSideModal.module.scss';
import colors from '../../../scss/variables/colors.module.scss';
import OrgRowLoader from '../../Sceletons/OrgRowLoader';
import { useTranslation } from 'react-i18next';
import api from 'api';
import UploadingComponent from 'components/UploadingComponent/UploadingComponent';
import { IOrganization } from 'interfaces';

type OrgModalProps = {
  isOpen: boolean;
};

const orgCardStyle: React.CSSProperties = {
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  margin: 8,
  padding: 15,
  borderRadius: 5,
};

export default function AddOrgToSideModal({isOpen}: OrgModalProps) {
  const [checkedOrgs, setCheckedOrgs] = React.useState<string[]>([]);
  const [isAddingOrgToSide, setIsAddingOrgToSide] = React.useState(false);

  const { usersOrganizations, sideOrganisations , isLoadingUserOrganizations } = useAppSelector((store) => store.sides);
  const { dataRoom } = useAppSelector((store) => store.dataRoom);

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const orgsAlreadyPresent = sideOrganisations.map((org) => org.id);
  const skeletons = [...new Array(2)].map((_, index) => (
    <div className={classes.loaderWrap} key={index}>
      <OrgRowLoader />
    </div>
  ))

  const addOrg = (id: string) => {
    checkedOrgs.includes(id)
      ? setCheckedOrgs(checkedOrgs.filter(orgId => orgId !== id))
      : setCheckedOrgs(prev => [...prev, id]);
  };

  const addOrgsToSide = async () => {
    setIsAddingOrgToSide(true);
    try {
      await Promise.all(
        checkedOrgs.map(async (orgId) => {
          await api.addOrganizationToSide({room_id: dataRoom?.id!, organization_id: orgId});
        })
      )
      const newOrganizations: IOrganization[] = [];
      checkedOrgs.forEach(organization => {
        const targetOrg = usersOrganizations.find(org => org.id === organization);
        targetOrg && newOrganizations.push(targetOrg);
        message.success(`${t('Sides.Modal.successAdd')} '${targetOrg?.name}'`);
      });
      dispatch(setSideOrganisations([...sideOrganisations, ...newOrganizations]));
    } catch(e) {
      message.error(t('Sides.Modal.errorAdd'));
    } finally {
      setIsAddingOrgToSide(false)
      dispatch(setIsAddOrgModalOpen(false));
    }
  }

  return (
    <Modal
      styles={{ body: {maxHeight: '80vh'} }}
      className={classes.modalAddOrg}
      open={isOpen}
      centered
      title={t('Sides.Modal.addTitle')}
      okText={t('Sides.Modal.addButton')}
      onCancel={() => dispatch(setIsAddOrgModalOpen(false))}
      okButtonProps={{disabled: !checkedOrgs[0] }}
      onOk={addOrgsToSide}
      confirmLoading={isAddingOrgToSide}
      cancelText={t('main.cancelButton')}
    >
      { isLoadingUserOrganizations ? skeletons : 
        <Card bordered={false}>
          { usersOrganizations[0]
            ? usersOrganizations.map(org => {
              const isOrgAlreadyPresent = orgsAlreadyPresent.includes(org.id);
              const isChecked = checkedOrgs.includes(org.id);

              return (
                <Card.Grid
                  onClick={() => !isOrgAlreadyPresent && addOrg(org.id)}
                  key={org.id}
                  hoverable={!isOrgAlreadyPresent}
                  style={{
                    ...orgCardStyle,
                    backgroundColor: isOrgAlreadyPresent ? colors.verylightgrey : 'white',
                    cursor: isOrgAlreadyPresent ? 'default' : 'pointer',
                  }}
                >
                  <Checkbox checked={isOrgAlreadyPresent || isChecked} disabled={isOrgAlreadyPresent} style={{paddingRight: 15}}/>
                  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Avatar
                      style={{ backgroundColor: '#f7f1fe', color: '#7f56d8' }}
                      size={40}
                    >
                      {org.name?.split(' ').map(word => word[0]).join('')}
                    </Avatar>
                    <div style={{paddingLeft: 15}}>{org.name}</div>
                  </div>
                </Card.Grid>
              )
            })
            : <div>
              <div>{t('Sides.Modal.noAvailable')}</div>
              <div>{t('Sides.Modal.instruction')}</div>
            </div>
          }
        </Card>
      }


      { isAddingOrgToSide &&
        <UploadingComponent />
      }
    </Modal>
  )
}

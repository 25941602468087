// @ts-ignore
const urlws = window.REACT_APP_WS_API !== 'REPLACE_REACT_APP_WS_API' ? window.REACT_APP_WS_API : process.env.REACT_APP_WS_API || 'ws://localhost:8000';

// @ts-ignore
export const socketUrl = window.REACT_APP_SERVER_API !== 'REPLACE_REACT_APP_SERVER_API' ? window.REACT_APP_SERVER_API : process.env.REACT_APP_SERVER_API || 'http://localhost:8000';

const wsSession = (user_id: string) => (`${urlws}/users/${user_id}/`);


const ws = {
  wsSession,
} 

export default ws

import { Col, Row, message } from 'antd';
import { PersonalInforamtion } from 'components/ProfileInfo';
import ProfileLoader from 'components/Sceletons/ProfileLoader';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { fetchProfileData } from 'store/reducers/profileCreator';
import classes from './Profile.module.scss';

export default function Profile() {
  const dispatch = useAppDispatch();
  const { profileIsLoading, profileIsError } = useAppSelector((state) => state.profile);

  const { t } = useTranslation()

  useEffect(() => {
    dispatch(fetchProfileData());
  }, []);

  useEffect(() => {
    if (profileIsError) {
      message.error(profileIsError);
    }
  }, [profileIsError]);

  return (
    <div style={{ width: '100%'}}>
      {/* <div className={classes.title}>{t('Users.HelperMenu.personalInfo')}</div> */}
      <div className={classes.profileBody}>
        {profileIsLoading ? (
          <ProfileLoader />
        ) : (
          <Row justify={'space-evenly'}>
            <Col span={12}>
              <PersonalInforamtion />
            </Col>
            {/* <Col span={8}>
              <ProfileInformation />
            </Col> */}
          </Row>
        )}
      </div>
    </div>
  );
}

import { IDocument, IFolder } from 'interfaces';
import dayjs from 'dayjs'

let keyIndex = 0;

const prepareDocuments = (documents: IDocument[], folders: IFolder[]) : (IDocument | IFolder)[] => {
  const modifiedDocuments = documents.map((document) => {
    const inSeconds = dayjs(document.upload_at).valueOf();
    keyIndex += 1;

    return {
      ...document,
      type: document.extension,
      // upload_at: rightTime.format('DD.MM.YYYY, HH:mm:ss'),
      upload_at: inSeconds,
      key: `${document.extension} ${document.token} ${document.id}`,
      clicked: false,
      isOpenContextMenu: false
    };
  });
  
  const modifiedFolders = folders.map((folder) => {
    keyIndex += 1;

    return {
      ...folder,
      type: 'folder',
      key: `folder ${folder.id} ${keyIndex}`,
      children: null,
      clicked: false,
      isOpenContextMenu: false
    };
  });

  keyIndex = 0;  

  return [...modifiedFolders, ...modifiedDocuments];
};

export default prepareDocuments;

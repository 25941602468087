import { CloseCircleOutlined } from '@ant-design/icons';
import classes from './Viewer.module.scss';

const Error = ({ msg, onClose }: { msg: string, onClose: () => void }) => {
  return <div className={classes.error} onClick={onClose}>
      <CloseCircleOutlined className={classes.close} onClick={onClose} />
      {msg}
    </div>;
};

export default Error;

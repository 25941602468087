import React from 'react';
import classes from './RoomsTab.module.scss';
import { Dropdown, MenuProps, Tooltip, Badge } from 'antd';
import { dateFormatType, IDataRoom } from 'interfaces';
import cn from 'classnames';
import { useAppSelector } from 'store/hook';
import { MoreOutlined, SettingOutlined, LockOutlined, LockFilled } from '@ant-design/icons';
import getDateWithTimeZone from 'helpers/getDateWithTimeZone';
import { onlyUserPages } from 'routes/Main/Main';
import { useTranslation } from 'react-i18next';

type RoomCardProps = {
  room: IDataRoom;
  onOpenClick: (_: any, room: IDataRoom, isLoadingRoom: boolean) => Promise<void>;
  controlItems: (room: IDataRoom) => MenuProps['items'];
  toRoomSettings: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, room: IDataRoom) => void;
  searchValue?: string;
  markText?: (text: string, markerRef: React.RefObject<HTMLDivElement>, timeout?: number) => void;
  dateFormat: dateFormatType;
};

const RoomCard = ({ room, onOpenClick, controlItems, toRoomSettings, searchValue, markText, dateFormat }: RoomCardProps) => {
  const {isLoadingUserPermissions } = useAppSelector((store) => store.userData);
  const { isLoadingRooms } = useAppSelector((store) => store.dataRoom);
  const { selectedKeys } = useAppSelector((store) => store.windowState);

  const { name, last_action_timestamp, agreement_enable } = room;
  const { t } = useTranslation();
    
  const isLoadingRoom = isLoadingRooms || isLoadingUserPermissions;
  const previousRoomId = localStorage.getItem('previousRoomId');
  const markerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (markText) {
      const text = searchValue || '';
      markText(text, markerRef);
    }
  }, [searchValue]);

  const isCurrentRoom = previousRoomId === room.id;

  return (
    <div
      className={cn(
        classes.room,
        isCurrentRoom ? classes.activeRoom : 'null',
        onlyUserPages.includes(selectedKeys[0]) ? classes.cursor : 'null',
        isLoadingRoom ? classes.loadingRoom : 'null'
      )}
      id={`room_card_${room.id}`}
      onClick={(e) => onOpenClick(e, room, isLoadingRoom)}
    >
      <div className={classes.roomContent}>
        <div className={classes.jusctifySpace}>
          <div ref={markerRef} className={classes.roomTitle}>
            {agreement_enable &&
              <Tooltip placement='topLeft' title={t('Rooms.NDARoom')}>
                {isCurrentRoom ? <LockFilled className={classes.iconNDA}/> : <LockOutlined className={classes.iconNDA}/>}
              </Tooltip>
            }
            <Tooltip placement='topLeft' mouseEnterDelay={1} title={name}>
              <span className={classes.nameOverflow}>{name}</span>
            </Tooltip>
          </div>

          <Dropdown menu={{ items: controlItems(room) }} placement='topLeft'>
            <MoreOutlined onClick={(e) => e.stopPropagation()} className={classes.iconBtn} rotate={90} />
          </Dropdown>
        </div>

        <div className={classes.jusctifySpace}>
          <div className={classes.roomDescription}>{getDateWithTimeZone(last_action_timestamp, dateFormat)}</div>

          <div className={classes.badges}>
            {room.new_count ? 
              <Tooltip placement='topLeft' mouseEnterDelay={1} title={t('Rooms.badges.new_files')}>
                <Badge size='small' count={room.new_count} color="#4f89f7" />
              </Tooltip> : null
            }
            {room.message_count ? 
              <Tooltip placement='topLeft' mouseEnterDelay={1} title={t('Rooms.badges.new_messages')}>
                <Badge size='small' count={room.message_count} />
              </Tooltip> : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;

import { requestTemplate } from 'api';

const shareDocument = (
  payload: { document_token: string, email: string, user_name: string, mode: string, expires_option: string, expires_value: string },
) => requestTemplate.post<string>(`/temp_links/`, payload);

const incrementCounter = (temp_link_id: string) => requestTemplate.post(`/temp_links/view/?temp_link_id=${temp_link_id}`);

const share = {
  shareDocument,
  incrementCounter,
};

export default share;

import { AxiosResponse } from 'axios';

export const downloadSomeFile = async (request: () => Promise<AxiosResponse<any, any>>, fileName: string) => {
  const result = await request();
  const fileURL = window.URL.createObjectURL(new Blob([result.data]));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
  return fileURL;
};

export const getFile = (data: Blob, fileName: string) => {
  const fileURL = window.URL.createObjectURL(new Blob([data], { type: data.type }));
  const fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);
  fileLink.click();
  fileLink.remove();
  return fileURL;
};

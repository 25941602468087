import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { IColumn } from 'interfaces';

// const getSheetName = (field: string) => {
//   const sheetNames = {
//     incidents: 'Инциденты',
//     events: 'События безопасности',
//     logs: 'Журнал действий',
//     incidentsCard: 'Карточка инцидента',
//     incidentEvents: 'События безопасности',
//     default: 'some_info',
//   };

//   return sheetNames[field as keyof typeof sheetNames] || 'some_info';
// };

// export const getFileName = (field: string, object_info?: string) => {
//   const excelNames = {
//     incidents: `Список инцидентов - ${dayjs(Date.now()).format('HH:mm:ss DD-MM-YYYY')}`,
//     events: `Список событий безопасности - ${dayjs(Date.now()).format('HH:mm:ss DD-MM-YYYY')}`,
//     logs: `Журнал действий пользователей ПАДП - ${dayjs(Date.now()).format('HH:mm:ss DD-MM-YYYY')}`,
//     incidentsCard: `Карточка инцидента №${object_info || '-'}`,
//     incidentEvents: `Список событий безопасности спровацировавших инцидент №${object_info || '-'}`,
//   };

//   return excelNames[field as keyof typeof excelNames] || 'some_info';
// };

export const exportHelper = (data: any, columnsState: IColumn[], nameOfDocument: string) => {
  const excel = new Excel();
  excel.defaultTheadCellStyle.background = 'c2c2c2';

  const currentColumns = columnsState.map((column) => ({
    title: column.title,
    dataIndex: column.dataIndex,
    key: column.key,
    width: 250,
  }));

  const sheetName = 'report';

  const name = nameOfDocument.split('.');
  name.pop();
  
  excel
    .setRowHeight(15, 'px')
    .addSheet(sheetName)
    .addColumns(currentColumns as IExcelColumn[])
    .addDataSource(data, { str2Percent: true })
    .saveAs(`${name}.xlsx`);
};
